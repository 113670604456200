import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token} from '../component/include'
import Select from 'react-select'
import { longDate, getTime, getDays, getWeekNumber } from '../component/globalFunction'

const MyAllocation =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [staff, setStaff]= useState([])
    const [schedule, setSchedule]=useState({
        staff:[],
        subject:[],
        reportType:'subject',
        weekDate: new Date()
    });
    const config = {
        headers: { 'content-type': 'multipart/form-data'}
    }
    const [isDisplay, setIsDisplay] = useState(false)
    const [isDisplaySubject, setIsDisplaySubject] = useState(false)
    const [allocation, setAllocation]= useState([])
    
    const [weekNumber, setWeekNumber] = useState(getWeekNumber(new Date()));
    const [allocationSubject, setAllocationSubject]= useState([])
    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([]) 



    const  fetchStaff =()=>{  
        
        const fd = new FormData();
   let sql = "Select code, staffName, fileNo from tbl_staff"
       let url = ServerUrl+'/fetchBySql_controller/'+Token;
       fd.append("sql", sql)
      
       axios.post(url, fd, config).then(result =>setStaff(result.data)) 
   
    } 

    const fetchSchoolClass =()=>{
        let url = ServerUrl + '/fetchColumnById_controller/tbl_school_class/schoolClass/1/'+Token;
        axios.get(url).then(result=>setSchoolClass(result.data))

    }
    const fetchSubject =()=>{
        let Url = ServerUrl+'/fetch_controller/tbl_subject/'+Token;
        axios.get(Url).then(result=>setSubject(result.data))
    }



    const handleSelect = (option, action)=>{
        setWeekNumber(weekNumber); 
         setSchedule({...schedule, [action.name]: option});
         setErrors({...errors, [action.name]:'', scheduleList:''}) 
         setIsDisplay(false)
         setIsDisplaySubject(false)   
     };

     const getYear=(date)=>{
        let now = new Date(date)
        let year = now.getFullYear()
     
        return year
    }

     const handleFetch=(event)=>{
        event.preventDefault();
        let week = new Date().getFullYear()+'/'+weekNumber

        if(schedule.reportType==='subject'){
             if(schedule.subject.length===0){
                 setErrors({...errors, subject:'Please select subject'})
             }else{
                         fetchSubjectTable(week)
                          setIsDisplaySubject(true)
                         setIsDisplay(false) 
             }
        }else{
                 if(schedule.staff.length===0){
                     setErrors({...errors, staff:'Please select staff'})
                 }else{
                     fetchAllocation(schedule.staff.value, week);
                     setIsDisplay(true)
                     setIsDisplaySubject(false)
                 }
        }
        setWeekNumber(getWeekNumber(schedule.weekDate))
    }








   
    const getSubject =(ID)=>{
        const result = subject&&subject.filter((list)=>list.ID===ID);
        const answer = result.map((c)=>c.subjectName);
        return  answer
        
    }


    const getStaff=(ID)=>{
        const result = staff.filter((list)=>list.ID===ID);
       const answer = result.map((c)=>`${c.staffName.slice(0, 20)}` ); 
       
       return answer
    }

    const getClassName = (ID) =>{        
       
        const result = schoolClass && schoolClass.filter((list)=>list.ID===ID);
        const answer = result.map((c)=>c.cname);
        
        return  answer
        }
  
        const getSectionName = (cID, sID, date) =>{       
           const result = schoolClass && schoolClass.filter((list)=>list.ID===cID); 
           const section = result.map((data) =>
        data.section.filter((list)=>list.ID===sID))
  
        const newResult = section[0]?section[0]:[]
        const answer = newResult.map((c)=>c.sname); 
        const ClassName = getClassName(cID);
        const finalAnswer = answer.length===0?ClassName:answer
        const newDate = date?longDate(date):''
        
        return finalAnswer?<div><b>{newDate}</b><div>{finalAnswer}</div></div>:''
      }

      const getSectionStaff = (cID, sID, date, stf, sbj, idx) =>{       
        const result = schoolClass && schoolClass.filter((list)=>list.ID===cID); 
        const section = result.map((data) =>
     data.section.filter((list)=>list.ID===sID))

     const newResult = section[0]?section[0]:[]
     const answer = newResult.map((c)=>c.sname); 
     const ClassName = getClassName(cID);
     const finalAnswer = answer.length===0?ClassName:answer
     const newDate = date?longDate(date):''
     const st = stf?getStaff(stf):''
        let item = <div key={idx}><b>{newDate}</b><div>{finalAnswer} / {st} </div></div>
        const finalResult = sbj===schedule.subject.value?item:''
      return finalResult
   }


    

 
    const getPeriodDetails=(list, ID)=>{

         const result = list&&list.filter((list)=>list.ID===ID);
        const answer = result&&result.map((c)=>`${getTime(c.startTime)} ~ ${getTime(c.endTime)}`);
        const answer2 = result&&result.map((c)=>c.periodName)
        
    return  answer?<div><b>{answer2}</b><div>{answer}</div></div>:''
    }

    const getPeriodDetailsSubject=(list, ID, sbj, idx)=>{

        const result = list&&list.filter((list)=>list.ID===ID);
       const answer = result&&result.map((c)=>`${getTime(c.startTime)} ~ ${getTime(c.endTime)}`);
       const answer2 = result&&result.map((c)=>c.periodName)
       
        let item =  answer?<div key={idx}><b>{answer2}</b><div>{answer}</div></div>:''
   const finalResult = sbj===schedule.subject.value?item:''
      return finalResult
   }

    
   
  
    
    
    const handleOnClickNext = useCallback(() => {        
        let week = getYear(schedule.weekDate)+'/'+(weekNumber+1)
        if(schedule.reportType==='subject'){
            
            fetchSubjectTable(week)
        }else{
            
            fetchAllocation(schedule.staff.value, week);
        }
        setWeekNumber(Number(weekNumber + 1));  
    }, [weekNumber])
    

    const handleOnClickPrevious = useCallback(() => {
        let week = getYear(schedule.weekDate)+'/'+(weekNumber-1)
        if(schedule.reportType==='subject'){
            fetchSubjectTable(week)
        }else{
            fetchAllocation(schedule.staff.value, week);
        }
        setWeekNumber(Number(weekNumber - 1)); 
    }, [weekNumber])   

   

    const handleChange=(event)=>{
        let value = event.target.value;
        setWeekNumber(weekNumber); 
        setSchedule({...schedule, [event.target.name]:value});
        setErrors({...errors, [event.target.name]:''})
        setIsDisplay(false)
        setIsDisplaySubject(false)
    }

    const fetchSubjectTable =(weekNum)=>{

        setNotice({...notice, 
            isLoading: true
        }); 

        let sql = "Select scheduleList, weekDate from tbl_staff_schedule where  weekNumber ='"+weekNum+"'"

        let url = ServerUrl+'/fetchBySql_controller/'+Token;
        const fd = new FormData();
        fd.append("sql", sql)
        
        axios.post(url, fd, config).then(result =>{
            setNotice({...notice, 
                isLoading: false
            }); 
            if(result.data.length!==0){
            
    var item;

    var Monday =[]
    var Tuesday =[]
    var Wednessday =[]
    var Thursday =[]
    var Friday =[]
    var Saturday =[]



    
    result.data.map((c)=>
   getDays(c.weekDate)==='Monday'?Monday.push(c.scheduleList):
   getDays(c.weekDate)==='Tuesday'?Tuesday.push(c.scheduleList):
   getDays(c.weekDate)==='Wednessday'?Wednessday.push(c.scheduleList):
   getDays(c.weekDate)==='Thursday'?Thursday.push(c.scheduleList):
   getDays(c.weekDate)==='Friday'?Friday.push(c.scheduleList):
   Saturday.push(c.scheduleList)
   )

   item ={Monday:Monday, Tuesday:Tuesday, Wednessday:Wednessday, Thursday:Thursday, Friday:Friday, Saturday:Saturday}
    
        setAllocationSubject(item)
            }else{
                setAllocationSubject([])
            }
        })


    }

    

    const fetchAllocation =(staffId, weekNum)=>{

    setNotice({...notice, 
        isLoading: true,
        isSuccess : false})

        let sql = "Select scheduleList, weekDate from tbl_staff_schedule where staffId = '"+staffId+"' and   weekNumber ='"+weekNum+"'"

        let url = ServerUrl+'/fetchBySql_controller/'+Token;
        const fd = new FormData();
        fd.append("sql", sql)
        
        axios.post(url, fd, config).then(result =>{
            setNotice({...notice, 
                isLoading: false,
                isSuccess : false})

            if(result.data.length!==0){
            
            var value = [];
    var item;

    var Monday
    var Tuesday
    var Wednessday
    var Thursday
    var Friday
    var Saturday
    var Sunday

    result.data.map((c)=>
   getDays(c.weekDate)==='Monday'?Monday=c.scheduleList:
   getDays(c.weekDate)==='Tuesday'?Tuesday=c.scheduleList:
   getDays(c.weekDate)==='Wednessday'?Wednessday=c.scheduleList:
   getDays(c.weekDate)==='Thursday'?Thursday=c.scheduleList:
   getDays(c.weekDate)==='Friday'?Friday=c.scheduleList:
   getDays(c.weekDate)==='Saturday'?Saturday=c.scheduleList:
   Sunday=c.scheduleList
   )

   item ={Monday:Monday?Monday:'', Tuesday:Tuesday?Tuesday:'', Wednessday:Wednessday?Wednessday:'', Thursday:Thursday?Thursday:'', Friday:Friday?Friday:'', Saturday:Saturday?Saturday:''}
   value.push(item);
        setAllocation(value)
           // setPeriodList(result.data)
            }else{
                setAllocation([])                
            }
        })
        
    }

    
    


 

useEffect(()=>{
    fetchSubject()
    fetchStaff()    
    fetchSchoolClass()
    
},[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Timetable">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!"> Staff|Subject Allocation</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff|Subject Allocation</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
        <section className="col-md-6">
        <div className="form-group"><label>Report Type</label>
     <div className="form-radio">
<div className="radio radio-inline"><label>
        <input type="radio" checked={schedule.reportType ==="subject"} onChange={handleChange} value='subject' name='reportType' />
            <i className="helper"></i>Subject</label></div>

            <div className="radio radio-inline"><label>
        <input type="radio" checked={schedule.reportType ==="staff"} onChange={handleChange} value="staff" name='reportType' />
            <i className="helper"></i>Staff</label></div>
</div> </div> 

</section>

      <section className="col-md-6">
      <div style={{display:schedule.reportType ==="subject"?'none':'block'}} className="form-group">
						<label >Staff<span style={{color:'red'}}>*</span></label>
                         <Select  options={
                           staff&& staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>`${option.label} ${option.staffName}`}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={schedule.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>			
											</div>	

            <div style={{display:schedule.reportType ==="subject"?'block':'none'}} className="form-group">
						<label >Subject<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={schedule.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	
			</div>

</section>

	</div>

 </div> </div> 
							
</div>

</div>   

     
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	<button type="button" id="submit" onClick={handleFetch}  className="btn btn-primary "><i className="fa fa-search"></i> Proceed</button>
								</footer>		</section>		
							</div>
     
     </div>  </div>   </div> </div>








     <div  className="row  " style={{display:!isDisplaySubject?'none':'block'}}>   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i>Staff & Subject Allocation</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                    <li title="Print"><i className=" fa fa-print"></i></li>
                 <li title="Previous Week" ><i onClick={handleOnClickPrevious} className=" fa fa-mail-reply"></i></li>
                 <li title="Next Week" ><i onClick={handleOnClickNext} className=" fa fa-mail-forward"></i></li>

                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">

            <div className="table-responsive">
        <table className="table">
            <thead>
                <tr> 
                    <th colSpan="6"><center>WEEKLY SCHEDULE</center></th>
                
                </tr>
                <tr>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednessday</th>  
                    <th>Thursday</th>
                    <th>Friday</th>                   
                    <th>Saturday</th>
                </tr>
            </thead>
            <tbody>           
                <tr >
            <td>{allocationSubject.length!==0?allocationSubject.Monday.length!==0?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {allocationSubject.length!==0?allocationSubject.Monday.length!==0?allocationSubject.Monday.map((dt)=>JSON.parse(dt).map((tb, idx)=>tb.subject===schedule.subject.value?
                            <tr key={idx}>                            
                            <td>{getPeriodDetailsSubject(tb.List, tb.periodID, tb.subject, idx)}</td>
                            <td>{getSectionStaff(tb.classID, tb.sectionID, tb.date, tb.staffId, tb.subject, idx)} </td>               
                        </tr>:[]) ):[]:[]}
                        </tbody>
                    </table>:'':''}
                   </td>
            <td>{allocationSubject.length!==0?allocationSubject.Tuesday.length!==0?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allocationSubject.length!==0?allocationSubject.Tuesday.length!==0?allocationSubject.Tuesday.map((dt)=>JSON.parse(dt).map((tb, idx)=>tb.subject===schedule.subject.value?
                            <tr key={idx}>                            
                            <td>{getPeriodDetailsSubject(tb.List, tb.periodID, tb.subject, idx)}</td>
                            <td>{getSectionStaff(tb.classID, tb.sectionID, tb.date, tb.staffId, tb.subject, idx)} </td>              
                        </tr>:[]) ):[]:[]}
                        </tbody>
                    </table>:'':''}
            </td>
                    <td>{allocationSubject.length!==0?allocationSubject.Wednessday.length!==0?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {allocationSubject.length!==0?allocationSubject.Wednessday.length!==0?allocationSubject.Wednessday.map((dt)=>JSON.parse(dt).map((tb, idx)=>tb.subject===schedule.subject.value?
                            <tr key={idx}>                            
                            <td>{getPeriodDetailsSubject(tb.List, tb.periodID, tb.subject, idx)}</td>
                            <td>{getSectionStaff(tb.classID, tb.sectionID, tb.date, tb.staffId, tb.subject, idx)} </td>              
                        </tr>:[]) ):[]:[]}
                        </tbody>
                    </table>:'':''}
                   </td>
                   <td>{allocationSubject.length!==0?allocationSubject.Thursday.length!==0?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {allocationSubject.length!==0?allocationSubject.Thursday.length!==0?allocationSubject.Thursday.map((dt)=>JSON.parse(dt).map((tb, idx)=>tb.subject===schedule.subject.value?
                            <tr key={idx}>                            
                            <td>{getPeriodDetailsSubject(tb.List, tb.periodID, tb.subject, idx)}</td>
                            <td>{getSectionStaff(tb.classID, tb.sectionID, tb.date, tb.staffId, tb.subject, idx)} </td>              
                        </tr>:[]) ):[]:[]}
                        </tbody>
                    </table>:'':''}
                    </td>
                    <td>{allocationSubject.length!==0?allocationSubject.Friday.length!==0?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {allocationSubject.length!==0?allocationSubject.Friday.length!==0?allocationSubject.Friday.map((dt)=>JSON.parse(dt).map((tb, idx)=>tb.subject===schedule.subject.value?
                            <tr key={idx}>                            
                            <td>{getPeriodDetailsSubject(tb.List, tb.periodID, tb.subject, idx)}</td>
                            <td>{getSectionStaff(tb.classID, tb.sectionID, tb.date, tb.staffId, tb.subject, idx)} </td>              
                        </tr>:[]) ):[]:[]}
                        </tbody>
                    </table>:'':''}
                   </td>
                    <td>
                    {allocationSubject.length!==0?allocationSubject.Saturday.length!==0? 
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {allocationSubject.length!==0?allocationSubject.Saturday.length!==0?allocationSubject.Saturday.map((dt, id)=>
                        <tr key={id}>
                            <td>{JSON.parse(dt).map((tb, idx)=>getPeriodDetailsSubject(tb.List, tb.periodID, tb.subject, idx))}</td>
                            <td>{JSON.parse(dt).map((tb, idx)=>getSectionStaff(tb.classID, tb.sectionID, tb.date, tb.staffId, tb.subject, idx))} </td>                        
                        </tr>

                            ):[]:[]}
                        </tbody>
                    </table>:'':''}
                    </td>
                </tr>
             {allocationSubject.length===0?<tr><td colSpan="6"><div className="alert alert-danger background-danger">No Record Found</div></td></tr>:[]} 
               
               
            </tbody> 
        </table>

        </div>



            </div>

</div> </div> 
                           
</div>

</div> 




















         <div  className="row  " style={{display:!isDisplay?'none':'block'}}>   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i>Staff & Subject Allocation</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                    <li title="Print"><i className=" fa fa-print"></i></li>
                        <li title="Previous Week" ><i onClick={handleOnClickPrevious} className=" fa fa-mail-reply"></i></li>
                        <li title="Next Week" ><i onClick={handleOnClickNext} className=" fa fa-mail-forward"></i></li>

                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">

            <div className="table-responsive">
        <table className="table">
            <thead>
                <tr> 
                    <th colSpan="6"><center>WEEKLY SCHEDULE</center></th>
                
                </tr>
                <tr>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednessday</th>  
                    <th>Thursday</th>
                    <th>Friday</th>                   
                    <th>Saturday</th>
                </tr>
            </thead>
            <tbody>
            {allocation&&allocation.map((al,id)=>            
                <tr key={id}>
            <td>{al.Monday?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {JSON.parse(al.Monday).map((th, id)=>
                            <tr key={id}>
                                <td>{getPeriodDetails(th.List, th.periodID)}</td>
                                <td>{getSectionName(th.classID, th.sectionID, th.date)} / {getSubject(th.subject)}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    :''}</td>
                    <td>{al.Tuesday?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {JSON.parse(al.Tuesday).map((th, id)=>
                            <tr key={id}>
                                <td>{getPeriodDetails(th.List, th.periodID)}</td>
                                <td>{getSectionName(th.classID, th.sectionID, th.date)} /{getSubject(th.subject)}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    :''}
                    </td>
                    <td>{al.Wednessday?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {JSON.parse(al.Wednessday).map((th, id)=>
                            <tr key={id}>
                                <td>{getPeriodDetails(th.List, th.periodID)}</td>
                                <td>{getSectionName(th.classID, th.sectionID, th.date)} / {getSubject(th.subject)}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    :''}</td>
                    <td>{al.Thursday?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {JSON.parse(al.Thursday).map((th, id)=>
                            <tr key={id}>
                                <td>{getPeriodDetails(th.List, th.periodID)}</td>
                                <td>{getSectionName(th.classID, th.sectionID, th.date)} / {getSubject(th.subject)}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    :''}</td>
                    <td>{al.Friday?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {JSON.parse(al.Friday).map((th, id)=>
                            <tr key={id}>
                                <td>{getPeriodDetails(th.List, th.periodID)}</td>
                                <td>{getSectionName(th.classID, th.sectionID, th.date)} / {getSubject(th.subject)}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    :''}</td>
                    <td>{al.Saturday?
                    <table>
                        <thead>
                            <tr>
                                <th>Period & Time</th>
                                <th>Class & Subject</th>
                            </tr>
                        </thead>
                        <tbody>
                        {JSON.parse(al.Saturday).map((th, id)=>
                            <tr key={id}>
                                <td>{getPeriodDetails(th.List, th.periodID)}</td>
                                <td>{getSectionName(th.classID, th.sectionID, th.date)} / {getSubject(th.subject)}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    :''}</td>
                </tr>
            )}{allocation.length===0?<tr><td colSpan="6"><div className="alert alert-danger background-danger">No Record Found</div></td></tr>:[]}
               
            </tbody> 
        </table>

        </div>



            </div>

</div> </div> 
                           
</div>

</div> 



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(MyAllocation) 