import React, {useState, useEffect, useRef} from 'react'
import PageHeader from '../component/pageheader'
import {LoadEffect, TableLoader } from '../component/loader'
import {Alerts, WarningModal} from '../component/notify'
import {ServerUrl, Token, config, ImagesUrl, Currency, FormatNumber, API_URL, API_IMAGE} from '../component/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { getDate } from '../component/globalFunction'
import Select from 'react-select'
import { formatGroupLabel} from '../component/globalFunction'

import { useLocation } from 'react-router-dom'


import { useSelector } from 'react-redux'
import Error403 from '../settings/error403'

 const AdmissionFlow =()=> {
	 let myRef = useRef()
     const structure = useRef(null)
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true)
    const [settings, setSettings]=useState([])
    const totalStudent  = useSelector(state => state.rootReducer.totalStudent);
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        applicationType:'all',
        gender:'all',
        applicationID:'',
        admissionStatus:'all'
    })
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);
    const [allApplication, setAllApplicationType]= useState([])
    const [pattern, setPattern]=useState('')

    const [allDocument, setAllDocument]= useState([])
    const [errors, setErrors] = useState({});
    const [feecharge, setFeecharge] = useState([]);
    const [students, setStudents] = useState([]);  
    const [classFee, setClassFee] =  useState([]);
    const [totalFees, setTotalFees] =  useState(0);
const [isDiplaying, setIsDiplaying] = useState(false);

const [actualFee, setActualFee] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [studentTransfer, setStudentTransfer]= useState({
        admissionNumber:'',
        admission:false,
        feecharge:'',
        remark:'',
        feeStructure:'',
         toClass:[],
         student:'',
})


const [payment, setPayment]= useState({

    applicationID:'',
    studentName:'',
    remark:'',
    email:'',
    amountPaid:0,
    paymentMethod:'',
    paymentDate:getDate(),
    receiptNo:''
    
})

const handleSelectPayment = (option, action)=>{
	setPayment({...payment, [action.name]: option});
	setErrors({...errors, [action.name]:''})
 }

 const generateReceipt =()=>{
    setErrors({...errors, receiptNo:''})
	setPayment({...payment, receiptNo:Math.random().toString(36).substr(2,9).toLocaleUpperCase() })
}

const handleChangePayment =(event)=>{
    let {name, value} = event.target;
    if(name==='amountPaid'){
		value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
	  }
    setPayment({...payment, [name] : value });
    setErrors({...errors, [name]:''})
}

const handlePayment =(row)=>{
    setPayment({...payment,
        applicationID:row.applicationID,
        studentName:row.studentName,
        amountPaid:Number(row.feeCharge),
        feecharge:Number(row.feeCharge),
        email:row.email
    })
    let open = document.getElementById('openPayModal').click()
    
}

const fetchSettings =()=>{
    const fd = new FormData();
fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
    axios.post(url, fd, config).then(result=>{
        setSettings(result.data[0])
      
    })
}


    const fetchFeecharge =()=>{
        let url = API_URL + '/fetch/data/tbl_fees_charge'
    axios.get(url, config).then(result=>setFeecharge(result.data.data))
  }

  const handleSelect = (option, action)=>{
    setStudentTransfer({...studentTransfer, [action.name]: option});
    setErrors({...errors, [action.name]:''})
     }

     const handleSelectTo = (option, action)=>{
        setStudentTransfer({...studentTransfer, [action.name]: option
        });
        setErrors({...errors, [action.name]:''})
        setIsDiplaying(true);
        handleFetchClassFee(option.value);
        structure.current.scrollIntoView({ behavior: "smooth" });
         }



    const handleChangeFee =(index, e)=>{
    const fees =JSON.parse(JSON.stringify(classFee))   
    const value =e.target.value;
    fees[index].price = value;

    var totalFee = 0;
    fees.map(item=>totalFee+=Number(item.price))
    setClassFee(fees)
    setTotalFees(totalFee)
    
        }


const getActualFee = (ID) =>{        
    const result = actualFee && actualFee.filter(list=>list.ID===ID);
    const answer =result?result.map(lst=>lst.price):[]
    return   answer

    }

   



         const  handleFetchClassFee =(section)=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl + '/fetchField/tbl_class_fees/feesDetail/sectionID/'+section
                setNotice({...notice, 
                    isLoading: true
                });
                axios.post(url, fd, config).then(result => {   
                    if(result.data.length!==0){
                        var totalFee = 0;
                        result.data.map(item=>totalFee+=Number(item.price))
                        setClassFee(result.data);
                    setActualFee(result.data);
                    setTotalFees(totalFee)
                    }  else{
                        setClassFee('');
                    setActualFee('');
                    setTotalFees(0)
                    }
                    
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    setNotice({...notice, 
                        isLoading: false
                    });
                })
            }


    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }
    


    const fetchApplication =()=>{


        let url = API_URL+'/school/admission/all';
        axios.get(url,  config).then(result=>{
            if(result.data.data.length!==0){
                setAllApplicationType(result.data.data)
        
            }else{
                setAllApplicationType([]) 
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }

    


    const handleDelete =()=>{
          
        let dataId = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status===true){
                dataId.push(newContent[i].code)
                
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', 'Please select at least one applicant to delete')
              
        }else{
            setNotice({...notice, isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)

            const fd = new FormData();
            fd.append('data', data)
            fd.append('jwt', Token)
        let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_admission_request';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Default!', 'default', response.data.message)
                  
                      } else{
                        setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                 // Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                setErrors({...errors, errorMessage: error.message})
              }).finally(()=>{
                  setNotice({...notice,  isLoading: false })
                  fetchContent()
              }) 
            }
            close.click();
    }

   

    const fetchSchoolInfo =()=>{
        let url = API_URL + '/fetch/data/tbl_basic_details'
        axios.get(url, config).then(result=>{   
       var patterns = result.data.data[0].admissionNumberPattern
       let newPattern = patterns.replace('{schoolAbbreviation}', result.data.data[0].schoolAbbreviation);
        setPattern(newPattern);
        })

    }



    const fetchContent =()=>{
        setNotice({...notice,   isDataFetching: true  });


        var sql ="SELECT f.ID, f.code, f.class_assigned, t.applicationName, r.dateSubmitted, r.applicationID, r.dateCreate, r.passport, r.admissionStatus, r.studentName, r.paymentStatus, r.submitted, f.is_doc_uploaded, f.is_application_completed, f.is_test_conducted, f.notes, f.is_fees_paid, f.is_admitted from tbl_admission_flow f, tbl_admission_request r, tbl_admission_application t, tbl_school_admission a, tbl_school_session s where s.id = a.schoolSession and t.code = a.applicationType and r.applicationType = a.code and r.applicationID = f.application_code " ;

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and r.dateCreate between "'+search.fromDate+'" and "'+search.toDate+'"'
        }

        if(search.applicationType !== 'all'){
            sql = sql + " and r.applicationType = '"+search.applicationType+"'"
        }

        if(search.gender !=='all'){
            sql = sql + '  and r.gender = "'+search.gender+'"'
        }


        if(search.applicationID !==''){
            sql = sql + '  and r.applicationID = "'+search.applicationID+'"'
        }

        if(search.admissionStatus !=='all'){
            sql = sql + '  and r.admissionStatus = "'+search.admissionStatus+'"'
        }

        sql = sql + '  order by r.ID DESC'

          
        const fd = new FormData();
        fd.append("sql", sql);
        
    let url = API_URL+'/exam/result/fetch_raw'
        axios.post(url, fd, config).then(result=>{ 
            if(result.data.data.length!==0){

                setContent(result.data.data)
                
            }else{
                setContent([])
            }
            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
            //Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }

 


  const handleChangeStudent =(event)=>{
        
    const {name, value} = event.target;
        setStudentTransfer({...studentTransfer, [name] : value });
        setErrors({...errors, [name]:''})
        
       }

  

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

  const handleCheckOne =(e)=>{  
    let {name, value} = e.target

    const newContent = [...content]
    const otherContent = newContent.filter(item=>item.applicationID!==name)
    const custom = newContent.filter(item=>item.applicationID===name)
    custom[0].status = value==='true'?false:true
    const currentContent = custom.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent.sort((a, b) =>(a.ID > b.ID) ? 1 : -1)) 
        
       }
    

  const handleCheckAll =()=>{
    var newContent = [...content]
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
 }

 
 



 

    const tableHeader = [
        {dataField: 'code', 
        
headerClasses: ' bg-inverse',
        text: 'SN', editable: false, formatter:(cell, row, index)=>index+1},

{dataField: 'passport', text: 'Passport',

headerClasses: ' bg-inverse',
editable: false, formatter:(cell, row)=><img className="img-50" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+row.gender+".png"}} src={API_IMAGE+'/students/'+cell} title={row.studentName}  alt={row.studentName} />},


    {dataField: 'studentName', text: 'Full Name',
    
    classes:'fixed-column',
    headerClasses: 'fixed-column bg-inverse',
    formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell} - {row.applicationID}</span>},


    {dataField: 'paymentStatus', 
    headerClasses:'bg-danger',
    text:<span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> Application Fee paid</span>,
    editable: false,
    formatter:(cell, row)=><span className={`badge f-16 
    ${cell==='Pending'?'badge-danger':'badge-success'}`}>{cell}</span>},


    {dataField: 'submitted', 
    headerClasses:'bg-danger',
    text:<span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> Application Submitted</span>,
    
    editable: false,
    formatter:(cell, row)=><span className={`badge f-16 
    ${cell==='false'?'badge-danger':'badge-success'}`}>{cell==='false'?'Not Yet':'Yes'}</span>},

    {dataField: 'is_doc_uploaded', text:<span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> Is Required Docs Uploaded? (Birth Cert, Passport, *Last Academic Report)</span>,
    
    headerClasses: ' bg-danger',
    classses:'w-200',
    editor: {
        type: Type.SELECT, options:[{value:'Yes', label:'Yes'},
        {value:'No', label:'No'}]
      },
    formatter:(cell, row)=><span className={`badge f-16 
    ${cell==='No'?'badge-danger':'badge-success'}`}>{cell}</span>},

   

    {dataField: 'is_application_completed', 
    
    headerClasses: ' bg-danger',
    
    text:<span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> Is Application Completed (Registrar)</span> ,  sort: true, 
    editor: {
        type: Type.SELECT, options:[{value:'Yes', label:'Yes'},
        {value:'No', label:'No'}]
      },
    formatter:(cell, row)=><span className={`badge f-16 
    ${cell==='No'?'badge-danger':'badge-success'}`}>{cell}</span>},


    {
        
    dataField: 'is_test_conducted', 
    headerClasses: ' bg-danger',
    text:<span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> Is Entrance Test Conducted</span>,
    
    editor: {
        type: Type.SELECT, options:[{value:'Yes', label:'Yes'},
        {value:'No', label:'No'}]
      },
    formatter:(cell, row)=><span className={`badge f-16 
    ${cell==='No'?'badge-danger':'badge-success'}`}>{cell}</span>},



    {dataField: 'notes',
    
    headerClasses: ' bg-danger',
    text: <span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>Notes on Students performance (entrance test scores and other observations if applicable)</span>,
    
    editor: {
        type: Type.TEXTAREA
      },
    formatter:(cell, row)=>cell},


    {dataField: 'admissionStatus', 
    headerClasses: ' bg-success',
    text: 'Admission Status', sort: true, editable: false, formatter:(cell)=><span className={`badge f-16  
    ${cell==='Pending'?'badge-danger':'badge-success'}`}>{cell}</span>},


    {dataField: 'class_assigned',
    
    headerClasses: ' bg-success',
    text:<span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}> Name of Class (Assigned Section Head)</span>,
    editor: {
        type: Type.TEXT
      },
    formatter:(cell, row)=>cell},




    {dataField: 'is_admitted',
     text:<span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> Is Admitted on the portal and Admission Letter issued (Registrar)</span>,
    
    headerClasses: ' bg-danger',
    editor: {
        type: Type.SELECT, options:[{value:'Yes', label:'Yes'},
        {value:'No', label:'No'}]
      },
    formatter:(cell)=><span className={`badge f-16 
    ${cell==='No'?'badge-danger':'badge-success'}`}>{cell}</span>},




        {dataField: 'is_fees_paid',
        editor: {
            type: Type.SELECT, options:[{value:'Yes', label:'Yes'},
            {value:'No', label:'No'}]
          },
        headerClasses: ' bg-inverse',
        text: <span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> Is School Fees Paid</span>,  formatter: (cell, row)=><span className={`badge f-16 
        ${cell==='No'?'badge-danger':'badge-success'}`}>{cell}</span>},
       
     ];

    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
             fd.append('jwt', Token);
            let url = ServerUrl+'/updateCustom_controller/tbl_admission_flow'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then(  )
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control form-control-lg" placeholder="Type to search!!!" />
                                 
                                        

      
                                 </div>
                                 
                     <BootstrapTable 
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      




      const handleSubmit = event =>{
        event.preventDefault();
        setErrors(ValidateInput(studentTransfer)); 
        setIsSubmitting(true);
    } 

const ValidateInput =(values)=>{
	let errors = {};   
        

    if(values.toClass.length===0){
        errors.toClass ='Please select to Class';
	}
    
    if(students.length===0){
        errors.student ='There are no student in the selected class ';
       // window.scrollTo(0,0);
    } 

    if(!Array.isArray(values.student)){
        errors.student ='Please select student to be admitted';
       // window.scrollTo(0,0);
    }

    if(values.feecharge.length===0){
        errors.feecharge ='Please select fees charge';
        //window.scrollTo(0,0);
    }

    if(classFee.length===0){
        errors.feeStructure ='There is no school fees define ';
    } 
    
    
    return errors;
	}
	

    const getLink =(code, link)=>{

        const linkHandler ={
          defe: '/students/documents/'+link,
          de2fe:'/students/documents/'+link,
          de3fe:'/students/documents/'+link,
        de34e:'/students/documents/'+link,
          de54e:'/students/documents/'+link
        }
        return linkHandler[code];
        }

    const fetchSchoolDocument =()=>{
        let url = API_URL + '/fetch/data/tbl_school_documents'
    axios.get(url, config).then(response =>{
          const option  = response.data.data
          const list  = []
          for (var i = 0; i< option.length;  i++) {
            list.push(getLink(option[i].code, option[i].file_url));
                }
          setAllDocument(list.filter(item=>item!==undefined))
        })
      }

      

	
useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

function submit(){  

    window.scrollTo(0,0);
     
    setNotice({...notice, isLoading: true}) 

      const fd = new FormData(); 
        
      fd.append("jwt", Token);
       fd.append('classId', studentTransfer.toClass.classID);
        fd.append('sectionId', studentTransfer.toClass.value);
        fd.append('feeStructure', JSON.stringify(classFee, null, 2));
        fd.append('feeCharge', studentTransfer.feecharge.value);
        fd.append('remark', studentTransfer.remark);
        fd.append('totalFee', totalFees);
        fd.append('studentList', JSON.stringify(studentTransfer.student, null, 2));
        fd.append('ClassName', studentTransfer.toClass.label);
        fd.append('admissionOfficer', settings.admissionOfficer);
        fd.append('attachment', JSON.stringify(allDocument, null, 2));
     

      let url = ServerUrl+'/save_controller/tbl_student_admission'
	  axios.post(url, fd, config)
	  .then(response =>{
		if (response.data.type ==='success'){
            setErrors({...errors, successMessage: response.data.message})
		} else{
            setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	}  
	})
	.catch((error)=>{
        setErrors({...errors, errorMessage: JSON.stringify(error.message)})
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
      setIsDiplaying(false);
      setIsSubmitting(false); 
      setStudentTransfer({...studentTransfer,
        admission:false,
        feecharge:'',
        remark:'',
        feeStructure:'',
         toClass:[],
         student:'',
    });
    fetchContent()
})   
}



const handleSubmitPayment =()=>{
    let error = {...errors}; 
        let formIsValid = true;

	 let msg ='This field is required';
	 if(!payment.receiptNo){
        error.receiptNo =msg;
        formIsValid = false;
    } 
    if(!payment.applicationID){
        error.receiptNo ='Record not found';
        formIsValid = false;
    }

    if(!payment.paymentDate){
        error.paymentDate =msg;
        formIsValid = false;
    }

    if(!payment.paymentMethod){
        error.paymentMethod =msg;
        formIsValid = false;
    }

    if(Number(payment.amountPaid) < Number(payment.feeCharge)){
        error.amountPaid ='Underpayment not allow';
        formIsValid = false;
    } 


    setErrors(error)

    if(formIsValid){
      let close = document.getElementById('closePay').click();

   const fd = new FormData();
   fd.append("transaction_id", payment.receiptNo)
   fd.append("txref", payment.receiptNo)
   fd.append("amount", Number(payment.amountPaid))
   fd.append("rawResponse", '')
   fd.append("platform", payment.paymentMethod?.value)
   fd.append("jwt", Token)
   fd.append("paymentStatus", 'success')
 
   fd.append("email", payment.email)
   fd.append("fullname", payment.studentName)
   fd.append("applicationID", payment.applicationID)
   fd.append("amountTopay", payment.feeCharge)
 fd.append('currency', 'NGN');
 
 fd.append('remark', payment.remark);
 
   let url = ServerUrl+'/save_controller/tbl_payment_history';
 
  setNotice({...notice,   isLoading: true})
 axios.post(url, fd, config)
 .then(result =>{
  if(result.data.type ==='success'){
 
   Alerts('Success!', 'success', 'You have successfully made payment')
  fetchContent()
  
   }else{
    Alerts('Error!', 'danger', JSON.stringify(result.data,  null, 2))

   }    
 })
 .catch((error)=>{
   setErrors({...errors, errorMessage: error.message})
 }).finally(()=>{
   setNotice({...notice, 
     isLoading: false}) 
    setPayment( {
        applicationID:'',
        studentName:'',
        remark:'',
        email:'',
        amountPaid:0,
        paymentMethod:'',
        paymentDate:getDate(),
        receiptNo:''
    })
 }) 
 }
}  
  


const menu  = useSelector(state => state.rootReducer.sidebar);
useEffect(()=>{   
  //setIsAuthenticated(verifyRouter(location.pathname))
        fetchSchoolInfo()
        fetchApplication()
        fetchFeecharge()
        fetchSettings()
        fetchSchoolDocument()
       },[]);

        return (<>  {isAuthenticated? 
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="Admission Flow">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Admission Flow</a> </li>
                    </PageHeader>


                    {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal message='Are you really sure? Once deleted you cannot get it back' handleAction={handleDelete} mID={'delAll'} /> 

        

{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}




			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
        <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <label  className='pull-right text-bold'>From Date</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>To Date</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}   name="toDate" value={search.toDate} />
                        </div>
                    </div>
                </div>
                </div>

                <div className="row"> 
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Application Type</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                  <select  name="applicationType" value={search.applicationType} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        {allApplication&&allApplication.map((data, id)=><option key={id} value={data.code} >{data.startYear+' '+data.applicationName}</option>)}
                    </select>
                        </div>
                    </div>
                </div>

            

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Gender</label>
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="gender" value={search.gender} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>
            <div className="row">                   
                

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Application ID</label>
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                <input type="text" className="form-control " placeholder="Application Number" onChange={handleChange}  name="applicationID" value={search.applicationID} />
                </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'> Status</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="admissionStatus" value={search.admissionStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Admitted">Admitted</option>
                    </select>
                        </div>
                    </div>
                </div>
                </div>
               

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>





                        {studentTransfer.admission?    <div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit"></i> Student Admission</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather minimize-card icon-minuss"></i></li>
					<li><i className="feather icon-trash-2s close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
		<fieldset>
        <div className="row">
<div className="col-sm-12">

				
	<div className="card z-depth-0">
										<section className="card-block">
                                           
										
	<div className="form-group">
	
	<label >Fee Charge <span style={{color:'red'}}>*</span> </label>
	<Select  options={feecharge&&feecharge.map((list, idx)=> {
								   return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
								 })
							} 
							getOptionLabel={option =>`${option.session}  ${option.label}`}       
	onChange={handleSelect} name="feecharge" value={studentTransfer.feecharge} className={errors.feecharge ? 'form-control form-control-danger' : ''}  /> 
	<span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>
				</div>

	<div className="form-group">
						<label>Select Student</label>
						<Select isMulti options={
                            students.map((list, idx)=> {
                               return {key:idx, passport:list.passport, value: list.applicationID, label: list.applicationName, studentName:list.studentName, email:list.email, fatherName:list.fatherName, gender:list.gender, birthDate:list.birthDate, placeOfBirth:list.placeOfBirth, country:list.country, stateCode:list.stateCode, lga:list.lga, religion:list.religion, tribe:list.tribe, languageSpoken:list.languageSpoken, address:list.address, fatherPhone:list.fatherPhone, motherName:list.motherName, motherPhone:list.motherPhone, isEmailVerified:list.isEmailVerified, schoolAttended:list.schoolAttended, admissionNumber:list.admissionNumber, regSession:list.startYear }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} - ${option.value} `}
onChange={handleSelect} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={studentTransfer.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
											
                                            
                                            
                 <div className="form-group">
						<label>To Class</label>
						<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectTo} className={errors.toClass ? 'form-control form-control-danger' : ''} name="toClass" value={studentTransfer.toClass}  /> <span style={{color:'red'}}>{errors.toClass && errors.toClass}</span>
												
										</div>
		</section></div></div>
                                        
                                        
                                        </div>
                                        {isDiplaying ? 		
							<div className='row' >

					<section className="col-md-12"><br/>
<label><b>Class Fee Structure</b></label>
	 
<div className="table-responsive" >
      <table className="table ">
        <tbody >

{classFee.length!==0 ? classFee.map((fee, idx)=> 
<tr key={idx}>
				<td>{fee.fees}<br/><span style={{fontSize: '10px',color: '#fa0000'}}>Actual Fee {Currency+FormatNumber(getActualFee(fee.ID))}</span></td>

				<td><input type="number" min="0" className="form-control" onChange={(e)=>handleChangeFee(idx, e)}  name="price[]" value={fee.price} /></td>
			</tr>
				):<tr><td colSpan="3">
					<div className="alert alert-danger background-danger">There are no fee structure define for the selected class, please define fee first</div>
					</td></tr>
			
			}
				</tbody>
				{classFee.length!==0 ?	<tfoot>
					<tr>
						<td colSpan='' className='text-right'><b>Total Fees:</b></td>
						<td><b>{Currency+FormatNumber(totalFees)}</b></td>
					</tr>
				</tfoot>:[]}
		</table>
		</div>
					</section>
					<section className="col-md-12">
					{classFee.length!==0 ? <table className="table ">
				<tbody >
			
			<tr>
			<td colSpan="3"><label>Remark</label>
				<textarea value={studentTransfer.remark} rows='2' name='remark' className='form-control' onChange={handleChangeStudent}> {studentTransfer.remark}</textarea>
					</td>
			

			</tr>
			</tbody></table>:[]}
			</section></div>	:""}
										
            <div ref={structure} />		</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary "><i className="fa fa-refresh"></i> Confirm Admission</button>
	
								</footer>				
							</div>
							</div>
							</div>
	                   
                            </div>:''}






							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Admission Request</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
           
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

          <a href="#!"  data-toggle="modal" id="openPayModal" data-target="#payModal" ></a>

          <div className="modal fade " id='payModal' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
        <div className="modal-header">                                       
          <h4 className="modal-title">{payment.studentName}</h4>        
          <button type="button" className="close" id='btnLogoutsession' data-dismiss="modal" aria-hidden="true">×</button>             
        </div> 

              <div className="modal-body">


             
				<div className="row">
				<div className="col-md-12 "> 
				

				<div className="form-group">
				<label> Receipt Number or Payment Reference <span style={{color:'red'}}>*</span></label>
<div className="input-group">
				<input type="text" className={errors.receiptNo?'form-control form-control-danger': 'form-control'} onChange={handleChangePayment} name="receiptNo" value={payment.receiptNo} />

				<span className="input-group-addon bg-dark"  id="basic-addon" onClick={generateReceipt}> <i className="fa fa-gears"></i> &nbsp;Generate</span>
				</div>
				<span style={{color:'red'}}>{errors.receiptNo && errors.receiptNo}</span>

            	</div>


            <div className="form-group">
         <label> Payment Method </label>
         <Select  options={[
                    { value: 'CASH', label: 'CASH' },
					{ value: 'BANK', label: 'BANK'},
					{ value: 'CHEQUE', label: 'CHEQUE'},
					{ value: 'OTHERS', label: 'OTHERS'},
                ]} 
			onChange={handleSelectPayment} className={errors.paymentMethod?'form-control form-control-danger': ''} name="paymentMethod" value={payment.paymentMethod}  />
 <span style={{color:'red'}}>{errors.paymentMethod && errors.paymentMethod}</span>

            </div>	 	


			<div className="form-group">
         <label> Date of Payment </label>

         <input type="date"  className={errors.paymentDate?'form-control form-control-danger': 'form-control'} onChange={handleChangePayment} name="paymentDate" value={payment.paymentDate} />

		  <span style={{color:'red'}}>{errors.paymentDate && errors.paymentDate}</span>

            </div>


			<div className="form-group">
         <label> Amount Paid </label>

         <input type="text" min="0" className={errors.amountPaid?'form-control form-control-danger': 'form-control'} onChange={handleChangePayment} onFocus={(e)=>e.target.select()} name="amountPaid" value={payment.amountPaid} />

		  <span style={{color:'red'}}>{errors.amountPaid && errors.amountPaid}</span>

            </div>

			<div className="form-group">
         <label> Remark </label>

		 <textarea  name="remark" className="form-control" placeholder="Remark" value={payment.remark} onChange={handleChangePayment} rows="2" >{payment.remark}</textarea>
		  
            </div>



			
			</div></div>

              </div>

              <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-default " id="closePay" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary" onClick={handleSubmitPayment}>Save Payment</button>
              </div>
          </div>
      </div>
  </div>


            </div>
            </div>:
            <Error403 />}

</> 

 );
        
}

export default React.memo(AdmissionFlow) 