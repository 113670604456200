import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'

import { Alerts, WarningModal } from '../../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl,  Token, config, sectionID, ImagesUrl} from '../../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getTime } from '../component/globalFunction'

const StudentPickDrop =()=> {
    const [errors, setErrors] = useState({});
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
const [pickDrop, setPickDrop] = useState({
        student:[],
        studentClass:[],
        pickType:[],
        picker:[],
        pickDate:'',
        pickTime:''

})
const [allPicker, setAllPicker]= useState([])

    const [allArrival, setAllArrival]= useState([])
    const [students, setStudents] = useState([]);

    
   
const long=(conv)=>{
    var date = new Date(conv).toDateString().slice(3, 15)
    
    return date
}

const getDateFormat =()=>{
    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    
    var today = year + "-" + month + "-" + day;   

document.getElementById('pickDate').max = today
setPickDrop({...pickDrop,
    pickDate:today
    })
}

    const handleChange = event =>{
		 const {name, value} = event.target
        setPickDrop({...pickDrop, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSelect = (option, action) =>{
        setPickDrop({...pickDrop, [action.name]: option});
      setErrors({...errors, [action.name]:''}); 

   }

   
   const handleSelectStudent = (option, action) =>{
    setPickDrop({...pickDrop, [action.name]: option, picker:[]});
  setErrors({...errors, [action.name]:''}); 
  getPicker(option.value)
}


const getPicker =(code)=>{
const item = students&&students.filter(list=>list.code===code)
const answer = JSON.parse(item[0].pickDrop)
setAllPicker(answer)
}




    const fetchAllArrival =()=>{
        if(sectionID !==undefined && sectionID !==''){
        const fd = new FormData();
        fd.append('jwt', Token); 

        let sql = "Select a.ID, a.code, s.studentName, s.admissionNumber, a.picker, a.pickType, a.pickDate, a.pickTime	 from tbl_student_pickDrop a, tbl_students s  where a.student = s.code and s.sectionID ='"+sectionID+"' order by a.ID DESC"

        let url = ServerUrl+'/fetchBySql_controller'      
        fd.append("sql", sql)
        axios.post(url, fd, config).then(result =>{
            

            if(result.data.length!==0 && Array.isArray(result.data)){
                setAllArrival(result.data) 
            }else{
                setAllArrival([]) 
            }
            
        }
    ).catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
    }
}

    const handleFetchAllStudents =()=>{
        if(sectionID !==undefined && sectionID !=='')
var sql ="Select ID, code, stateId, country, registerNumber, pickDrop, gender, dateOfBirth, admissionStatus, regSession, passport, studentName, admissionNumber, classID, sectionID, fatherName, father_mobile, dateRegister  from tbl_students where sectionID ='"+sectionID+"'"; 

const fd = new FormData();
fd.append("sql", sql);
fd.append("jwt", Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
   
        if(result.data.length!==0 && Array.isArray(result.data)){
            setStudents(result.data)
        }else{
            setStudents([])
        }
    } )
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }


 const handleSubmit = event =>{
        event.preventDefault();
let error = {}

if(!pickDrop.pickDate){
    error.pickDate ='Please select date';
}
if(pickDrop.pickType.length===0){
    error.pickType ='Please select type';
}
if(pickDrop.student.length===0){
    error.student ='Please select Student';
}
if(pickDrop.picker.length===0){
    error.picker ='This field is required';
}

if(!pickDrop.pickTime){
    error.pickTime ='Please select time';
}


    setErrors(error);
    if(Object.keys(error).length === 0){
        submit()
        }
    } 


function submit(){  
      
    const fd = new FormData();
            fd.append('jwt', Token); 
    				
    fd.append('student', pickDrop.student.value);
    fd.append('pickType', pickDrop.pickType.value);
    fd.append('pickDate', pickDrop.pickDate); 
    fd.append('pickTime', pickDrop.pickTime); 
    fd.append('picker', pickDrop.picker.label)
    

    setNotice({...notice, isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_student_pickDrop'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else if(response.data.type ==='info'){
                    Alerts('Info!', 'default', response.data.message)}
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            fetchAllArrival()
            setNotice({...notice, isLoading: false})
            setIsSubmitting(false); 
      setPickDrop({ 
        student:[],
        pickType:[],
        pickDate:'',
        pickTime:'',
        picker:[]}); 
        })
        setStudents([])
      setAllPicker([])
}




    const handleDelete = (code)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller/tbl_student_pickDrop'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
            fetchAllArrival()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }
        

    const tableHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>

<a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+cell}    className='btn btn-danger btn-sm' title="Delete"><i className="icofont icofont-delete-alt"></i></a>
         
         <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} />  

        </div>, editable: false},
        {dataField: 'studentName', text: 'Student',  editable:false, formatter: (cell, row)=>`(${row.admissionNumber}) ${cell}  `},
        {dataField: 'pickType', text: 'Type', editor: {
            type: Type.SELECT, options:[
                { value: 'Pick', label: 'Pick'},
              { value: 'Drop', label: 'Drop'}
    ]
          } },       
        {dataField: 'pickDate', text: 'Date', formatter:(cell, row)=>long(cell), editor: {
            type: Type.DATE
          } },
    {dataField: 'pickTime', text: 'Time', formatter:(cell)=>getTime(cell)}, 
    {dataField: 'picker', text: 'Person', editable:false}
     ]

     
    const TableRecord=(props)=>{
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token); 
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
          
            let url = ServerUrl+'/updateById_controller/tbl_student_pickDrop'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                           
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
     
useEffect(()=>{
    handleFetchAllStudents()
    fetchAllArrival()
    getDateFormat()
},[]);

        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Arrival & Departure">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Pick & Drop</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						   <div className="col-sm-12">
                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Pick & Drop</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
									
            <section className="col-md-6">                 
<div className="form-group">
						<label>Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                           students&&students.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName, co:list.fatherName, phone:list.father_mobile  }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} C/O  ${option.co}  ${option.phone}`}
onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={pickDrop.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>


                                            </section>
            <section className="col-md-6"> 


                           <div className="form-group">
						<label>Select Pick & Drop Person <span style={{color:'red'}}>*</span></label>
						<Select  options={
                           allPicker&&allPicker.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.pickDropName, phone:list.pickDropPhone, photoLink:list.photoLink }
                             })} 
                        getOptionLabel={option =>`(${option.label}) -  ${option.phone}`}
onChange={handleSelect} className={errors.picker ? 'form-control form-control-danger' : ''} name="picker" value={pickDrop.picker}  /> <span style={{color:'red'}}>{errors.picker && errors.picker}</span>
											</div>



                                            </section>
            <section className="col-md-4"> 

                                            <div className="form-group">
          <label> Type <span style={{color:'red'}}>*</span></label>
          <Select  options={[
                     { value: 'Pick', label: 'Pick'},
              { value: 'Drop', label: 'Drop'},
                 ]} 
 onChange={handleSelect} className={errors.pickType ? 'form-control form-control-danger' : ''} name="pickType" value={pickDrop.pickType}  /> 
 <span style={{color:'red'}}>{errors.pickType && errors.pickType}</span>
             </div>

             </section>
            <section className="col-md-4"> 
	<div className="form-group">
						<label >Date<span style={{color:'red'}}>*</span></label>
		<input type="date" name="pickDate" id="pickDate" min="2020-01-01" max="" value={pickDrop.pickDate} onChange={handleChange} className={errors.pickDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.pickDate && errors.pickDate}</span>	
			</div>

            </section>
            <section className="col-md-4"> 
            <div className="form-group">
						<label >Time<span style={{color:'red'}}>*</span></label>
		<input type="time" id="pickTime" name="pickTime" value={pickDrop.pickTime} min="2020-01-01" max="" onChange={handleChange} className={errors.pickTime  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.pickTime && errors.pickTime}</span>	
			</div>
			
            </section>
            <section className="col-md-6">

{pickDrop.picker.length!==0?   <img id="viewPassport2" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/no.jpg'}} src={`${ImagesUrl}/students/pickupdrop/${pickDrop.picker.photoLink}`}  style={{display:'block', height:'100%',width:'100%'}}  />
                   :''}
   </section >	
										</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>


                            </div>
                            </div>



                            <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Student Pick & Drop Records</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allArrival}   columns={tableHeader}  />} 

    </div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(StudentPickDrop) 