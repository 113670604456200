import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, selectStyles, API_URL, API_IMAGE} from '../component/include'
import { useParams} from 'react-router'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useLocation } from 'react-router-dom'

import TextEditor from '../api/editor'
import Error403 from '../settings/error403'
import { getTime } from '../component/globalFunction'

const EditPost =()=> {

    let myRef= useRef()
      const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
        
    }); 
	let params = useParams()
const [photo, setPhoto] = useState({
	image:'',
	displayImage:'',
	fileUrl:''
  })

  
    let location =  useLocation()
	const [isAuthenticated, setIsAuthenticated] = useState(true);
const staff = useSelector((state) => state.rootReducer.staff);
const [category, setCategory] = useState([])



 const [errors, setErrors] = useState({
	title:'',
	fullDescription:'',
	 author:'',
	category:'',
	slug:'',
	displayImage:'',
	errorMessage:'',
	successMessage:''
 })

 const [content, setContent] = useState([]);

 const fetchPost =  ()=>{
	let url = API_URL+'/blog/post/display/one/'+params.code
setNotice({...notice, isLoading:true})
axios.get(url, config)
  .then(response =>{
		if( Array.isArray(response.data.data)){
		 setContent(response.data.data)
		}else{
	 setErrors({...errors, errorMessage: response.data})
			  } 
})
   .catch((error)=>{
			 setErrors({...errors, errorMessage:error.message})
		  }).finally(()=>{
			setNotice({...notice, isLoading:false})
			 }) 
			}



const handleChangeDescription =(data)=>{


	const app =[...content]
			 app[0].fullDescription = data;
		  setContent(app);
		  setErrors({...errors, fullDescription:''});
	  }
  
	  const handleChangeArea = (event) =>{	
		 let {name, value} = event.target;	
		  const app =[...content]
			 app[0][name] = value;
		  setContent(app);
	  }
  
	const handleAllowComment = (event) =>{		
  
	 let {name, value} = event.target;
	   const app =[...content] 
			
	   value=value==="open"?"closed":"open"
		  app[0][name] = value;
		  setContent(app);
		  
	  }
  
	   
  
	  const handleChange = (event) =>{	
		
		 let {name, value} = event.target;
		  const app =[...content] 
			 app[0][name] = value;
		  setContent(app);
		 setErrors({...errors, [name]:''});
	  }
  
  
	   const handleSelect = (option, action)=>{
		  const app =[...content] 
			app[0][action.name] = option.value;
	  setContent(app);
		  setErrors({...errors, [action.name]:''})
		   }
  
  const getCategory=(code)=>{
	  const result = category.filter((item)=>item.code===code);
	const answer = result.length!==0?result[0].categoryName:''
	return  answer
  }
  
  const getUser=(code)=>{
	  const result = staff.filter((item)=>item.code===code);
	const answer = result.length!==0?result[0].staffName:''
	return  answer
  }
  


 const submit =(event, status)=>{
	 event.preventDefault();
	 var errormessage = [];

	 let errors = {}; 
	 let msg ='This field is required';

let post = content[0];

	
   
    if(!post.category){
		errors.category=msg;
	} 


if(!post.author){
	errors.author=msg;
	} 


if(!post.fullDescription){
	errors.fullDescription =msg;
	}  

   if(!post.title){
	errors.title =msg;
	} 

	


		setErrors(errors) 
    if(Object.keys(errors).length === 0){

		setNotice({...notice, isLoading:true})
	
	
		 const fd = new FormData();
	 Object.entries(post).forEach(([key, value]) => {
			 fd.append(key, value);
		 }); 

		 if(photo.image){
			fd.append('image', photo.image);   
			   } 
			   
		fd.append('status', status);


   let url = API_URL+'/blog/post/update/'+params.code;
   axios.post(url, fd, config)
   .then(response =>{
	 if(response.data.status === 200){
	    Alerts('Saved!', 'success', response.data.message)
	
			   } else{
	  setErrors({...errors, errorMessage: response.data.message})
				
	 window.scrollTo({top:0, left:0, behavior:'smooth'})
			   }   
		   })
		   .catch((error)=>{
			  setErrors({...errors, errorMessage:error.message})
		   }).finally(()=>{
			 setNotice({...notice, isLoading:false}) 
		   })
	 }
 }


 const fetchCategory =()=>{
	let url = API_URL+'/blog/category/display/all';
	   axios.get(url, config).then(result=>{  
		 if(Array.isArray(result.data.data)){
		   setCategory(result.data.data)
		 }
	  })
	  .catch((error)=>{
		  Alerts('Error!', 'danger', error.message)
	  })
}



 useEffect(()=>{
	
	if (typeof window !== undefined) {
	  let   queryString = new URLSearchParams(window.location.search).get("link")

	  setErrors({...errors, successMessage: queryString})

		 }
	
  }, [])


 const copyToClipboard =()=>{
         
    const el = document.createElement('textarea');
    el.value = errors.successMessage
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}




 const  handleFile = function(fieldName){
    return function(newValue){                 
        const value = newValue.target.files[0]
      // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
    let displayMessage = '';
      var fileName =  document.getElementById('photo').value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile ==="jpg" || extFile ==="jpeg" || extFile ==="png" || extFile ==="gif" || extFile ==="JPG" || extFile ==="JPEG" || extFile ==="PNG" || extFile ==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 3) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 3mb';
       document.getElementById('photo').value = '';
	   setPhoto('');
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e) {
       
           //Check if input has files.
		   let fileUrl = Math.random().toString(36).slice(2,9)+'.png';
                
              setPhoto({image:input.target.files[0], displayImage:e.target.result, fileUrl:fileUrl})
			  setErrors({...errors, displayImage:'', errorMessage:''});        
              
          };
          reader.readAsDataURL(input.target.files[0]); 
          
        }
    }
    }else{
        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 3mb'
       document.getElementById(input.target.name).value = '';
       setPhoto('');
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
} 




const handleRemoveImage = (path, ID)=>{  
	setNotice({...notice, isLoading:true})

	let close =   document.getElementById('btnWarningDialog-'+ID)
	close.click();


  let url = ServerUrl+'/update_controller/remove_image_post'
  const fd = new FormData();
  fd.append("path", path);
  fd.append("ID", ID);
  fd.append("jwt", Token);


axios.post(url, fd, config)
 .then(response =>{
  if(response.data.type ==='success'){
 
	 Alerts('Saved!', 'success', response.data.message)
	
	  
const app =[...content] 
app[0].post_image = ''
setContent(app);

		 } else{

setErrors({...errors, errorMessage:response.data.message})
		 }   
 })
 .catch((error)=>{
	 setErrors({...errors, errorMessage:error.message})
 }).finally(()=>{
	setNotice({...notice, isLoading:false})
 })
}



const handleDelete = (code)=>{  
	setNotice({...notice,  isLoading: true}) 

	   let close =   document.getElementById('btnWarningDialog-'+code)
		  close.click();
		let url = API_URL+'/blog/post/delete/'+code
axios.get(url, config)
	   .then(response =>{
		if(response.data.status ===200){

			window.open('/view_posts', '_self')
			   } else{

	setErrors({...errors, errorMessage:response.data.message})
			   }   
	   })
	   .catch((error)=>{
		   setErrors({...errors, errorMessage:error.message})
	   })
}



useEffect(()=>{
  //setIsAuthenticated(verifyRouter(location.pathname))
  fetchCategory()
  fetchPost()
}, [])

        
        return (  <> 
   <div  className="main-body">
   {isAuthenticated?  <div className="page-wrapper">
   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Edit Post">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-desktop"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Blog</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Edit Post</a> </li>
                    </PageHeader>

{/* The Page Body start here */}
                <div className="page-body">

              
					{content&&content.map((post, index)=>   <div className="row" key={index}>
							
					<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(post.code)} mID={post.code} /> 
          <div className="col-md-9">

		 
 {errors.successMessage? <div class="alert alert-success background-success">
		  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"><i onClick={copyToClipboard} className='fa fa-copy text-white'></i>
                    </button>
					<h4> {errors.successMessage} </h4>
				</div>:''}





				{errors.errorMessage?	<div class="alert alert-danger background-danger">
						<button type="button" class="close" data-dismiss="alert" aria-label="Close">
							<i class="icofont icofont-close-line-circled text-white"></i>
						</button>
						<h4>{errors.errorMessage}</h4>
					</div>:''}




	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Update Post</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
       
		<div className="card-block">
        <form method='post' >
		<fieldset>
            
            <div className="row">
									
										<section className="col-md-12">
                                    
									                            						
			<div className="form-group">
						<label >Select Type<span style={{color:'red'}}>*</span></label>
				
			<Select options={[
				{label:'News', value:'News'},
				{label:'Events', value:'Events'}
			]} 
                          
onChange={handleSelect}  name="newtype" className={errors.newtype? 'form-control form-control-danger' : ''}   value={{label:post.newtype, value:post.newtype}}  styles={selectStyles}  /> 


<div className="text-danger">{errors.newtype}</div>


			</div>

						<div className="form-group">
									<label >Add Title<span style={{color:'red'}}>*</span></label>

				<input type="text" placeholder='Post title here' name="title"   className={errors.title ? 'form-control form-control-danger' : 'form-control'}  value={post.title} onChange={handleChange}  />
            <div className="text-danger">{errors.title}</div>
							
							</div>


			<div className="form-group">
  <input id="photo"  onChange={handleFile('photo')} className="d-none" name="photo" type="file" accept="image/*" />              

  <label  htmlFor="photo" title="Change Cover" className='btn btn-outline-primary'> <i className="fa fa-image"></i>    Add Media </label>

&nbsp;
{photo.displayImage?
 <img id="viewPhoto" className="img-fluid" width="50" height="50" 
			 src={photo.displayImage} alt="preview" /> :''}


 <div className="text-danger">{errors.displayImage}</div>
          

			</div>


			
			<div className="form-group">
			<TextEditor
					height={400}
					
					value={post.fullDescription}
					onEditorChange={handleChangeDescription}        
				  /> 

          
                
  <div className="text-danger">{errors.fullDescription}</div>
          </div>

		
						<div className="form-group"><br/><br/>
						<label >Excerpt</label>
						<textarea className="form-control" name="excerpt" onChange={handleChangeArea} rows={3} value={post.short}>{post.excerpt}</textarea>

				<small>Excerpts are optional hand-crafted summaries of your content that can be used in your website</small>
			</div>



			<div className="form-group">
						<label >Author<span style={{color:'red'}}>*</span></label>
				
			<Select options={
                             staff&&staff.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.staffName}
                              })} 
                          
onChange={handleSelect}  name="author" className={errors.author? 'form-control form-control-danger' : ''}   value={{value:post.author, label:getUser(post.author)}}  styles={selectStyles}  /> 


<div className="text-danger">{errors.author}</div>


			</div>


			<div className="form-group">
						<label >Slug <span style={{color:'red'}}>*</span></label>
						<input type="text" name="slug" disabled className={errors.slug ? 'form-control form-control-danger' : 'form-control'}  value={post.slug}  onChange={handleChange} />
           <div className="text-danger">{errors.slug}</div>

				<small>The “slug” is the URL-friendly version of the name. If empty, the system will generate one automatically.</small>
			</div>



			<div className="form-group">
						<label >Post Category <span style={{color:'red'}}>*</span></label>
				
<Select options={
                             category&&category.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.categoryName}
                              })} 
                          
onChange={handleSelect}  name="category" className={errors.category? 'form-control form-control-danger' : ''}   value={{value:post.category, label:getCategory(post.category)}}   styles={selectStyles}  /> 

                   <div className="text-danger">{errors.category}</div>

				<small> Category this post belong to.</small>
			</div>



			<div className="form-group">
						<label >Subtitle </label>

						<textarea className="form-control" name="shortDescription" onChange={handleChangeArea} rows={3} value={post.shortDescription}>{post.shortDescription}</textarea>

				<br/>
				<small> This text will appear under the title (optional)</small>
			</div>

			<div className="form-group">
						<label >Quote </label>
				<input type="text" name="quote"  className='form-control' value={post.quote}  onChange={handleChange} />
         

				<small> Show a quote (only when this article shows up in blocks that support quote and only on blocks that are on one column).</small>
			</div>

			</section>
			</div>	</fieldset>
				
                                </form>	</div>
                            
							</div>
							</div>
		
	
	

	<div className="col-md-3">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Discussion</h5>
			
		</div>
		<div className="card-block">


		<div className="col-md-12">
      
<div className="border-checkbox-section">
		
		<div className="border-checkbox-group border-checkbox-group-success">
					<input className="border-checkbox" type="checkbox" id="checkbox1"value={post.allowComment} onChange={handleAllowComment} name="allowComment" checked={post.allowComment==="open"?true:false} />
					<label className="border-checkbox-label" htmlFor="checkbox1"> Allow comments</label>
				</div>

				<div className="border-checkbox-group border-checkbox-group-success">
					<input className="border-checkbox" type="checkbox" id="checkbox2" value={post.approveComment} name="approveComment" onChange={handleAllowComment} checked={post.approveComment==="open"?true:false} />
					<label className="border-checkbox-label" htmlFor="checkbox2"> Approve comment</label>
				</div>
				</div>

	</div>
    </div></div>

	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Image Uploaded</h5>
			
		</div>
		<div className="card-block">


		<div className="col-md-12">
		<div className=" pull-center">
                                      
		<img id="viewPassport2" className="img-fluid mb-2" width="200" height="200" src={API_IMAGE+"/blog/post/"+post.image_url} alt="preview" /> <br/>
		
						{post.image_url!==""?  <a href="#"  onClick={()=>handleRemoveImage("post/"+post.image_url, post.code)} className='text-danger'><i className="fa fa-trash"></i>   Remove Image</a>:''}
						</div>


	</div>
    </div></div>



	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Publish</h5>
			
		</div>
		<div className="card-block">


		<div className="col-md-12">
      
<p> <i className="fa-solid fa-check-double"></i> Status: <b>{post.status}</b></p>


{post.status==="Published"?
<p> <i className="fa-solid fa-clock"></i> Publish on: { new Date(post.created_at).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: '2-digit' })}  at {getTime(post.created_at.slice(11,19))}</p>:


<p> <i className="fa-solid fa-clock"></i> Drafted on: { new Date(post.updated_at).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: '2-digit' })}  at {getTime(post.updated_at.slice(11,19))}</p>}


<button type="button" onClick={(e)=>submit(e,"Draft")} className="btn  btn-outline-primary mb-3 mr-2 " >
                Save As  Draft
                    </button>

					<div style={{ display:'flex', justifyContent:'space-between', marginHorizontal:2 }}>   
 <button type='button'  data-toggle="modal"   data-target={`#warningdialog-${post.code}`}   className='btn btn-danger '> <i className="fa fa-trash"></i> </button> &nbsp;

                    <button type="button" onClick={(e)=>submit(e,"Published")} className="btn btn-info " >
               <i className="fa fa-share"></i>   Publish 
                    </button>
                    </div>  

	</div>
    </div></div>



</div>

</div>)}
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(EditPost) 