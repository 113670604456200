import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {Currency, FormatNumber, ImagesUrl, ServerUrl,  Token, staffCode, config} from '../../component/include'

import {  longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

const StaffSalary =()=> {
    const [errors, setErrors] = useState({});
    const [schoolInfo, setSchoolInfo]=useState([])
    const [result, setResult] = useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
    const [payment, setPayment] = useState({
       staff:[],
       fromDate:'',
        toDate:'',
    })

    const fetchSchoolInfo =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }

   

        
       

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setPayment({...payment, [name]:value})
            setErrors({...errors, [name]:''})
            setResult('')
            
        }
    
            const fetchResult =()=>{
               
            let sql = "Select s.ID, s.code, f.fileNo, f.staffName,  s.paymentMonth, s.paymentDate, s.totalAmount, p.paymentType from tbl_staff f, tbl_salary_payment s, tbl_payment_type p  where f.code = s.staff and  s.paymentType = p.code and s.staff ='"+staffCode+"'"


            if(payment.fromDate!=='' && payment.toDate!==''){
                sql = sql+ "   and s.paymentDate >= '"+payment.fromDate+"' and s.paymentDate <= '"+payment.toDate+"'"
            }

            sql = sql +"  order by s.paymentDate DESC"
  setNotice({...notice, isLoading: true}) 
  const fd = new FormData();
  fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{
                if(result.data.length!==0){
                    setResult(result.data)
                }else{
                    Alerts('Info!', 'default', 'No record found for the selected criterials')
                }
                
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })   
        } 
            
    
     
     useEffect(()=>{
        fetchSchoolInfo()
    },[]);


        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Salary">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Salary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Staff Salary</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximize full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
        
        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
      
      
      <section className="col-md-6">
      <div className="form-group">
   <label >From Date</label>
   <input type="date" className='form-control' name="fromDate"   onChange={handleChange} value={payment.fromDate} />

               </div>

   

</section>
      	
      

            <section className="col-md-6">
      <div className="form-group">
   <label >To Date</label>
   <input type="date" className='form-control' name="toDate"   onChange={handleChange} value={payment.toDate} />

               </div>

</section>
				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={()=>fetchResult()} className="btn btn-primary "><i className="fa fa-search"></i> Search</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table'  key={id}>
       <tbody>
                <tr>
                    <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}     />
 </td>
                </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Staff Salary </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                   {/*  <td >Staff: <b> </b></td> */}
                    <td >Date Range: From <b>{longDate(payment.fromDate)+ ' to '+ longDate(payment.toDate)}</b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>Staff</th>
                <th>Invoice</th>
                <th>Paid Month</th>
                <th>Payment Date</th>
                    <th>Payment Mode</th>
                    <th>Amount ({Currency})</th>
                    
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{st.fileNo+' '+st.staffName}</td>
                <td>{st.ID}</td>
                <td>{st.paymentMonth}</td>
                <td>{longDate(st.paymentDate)}</td>
                <td>{st.paymentType}</td>
                <td>{FormatNumber(st.totalAmount)}</td>
                </tr>):[]}
              </tbody>
           
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/staff_salary.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='month' value ={longDate(payment.fromDate)+ ' to '+ longDate(payment.toDate)} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(StaffSalary) 