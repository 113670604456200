import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, Currency} from '../component/include'
import { FormatNumber} from '../component/globalFunction'

import {LoadEffect, TableLoader}  from '../component/loader'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Link } from 'react-router-dom'

const Wallet =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const steps = [{title: 'Activities'}, {title: 'Accounts '}, {title: 'Summary '}]

    const [activeStep, setActiveStep] = useState(1);
    const [history, setHistory]= useState([])
    const [wallet, setWallet]= useState([])

    const [result, setResult] = useState({
        studentClass:[],
        studentSection:[],
        status:[],
        learningType:[],
        orderBy:{value:' order by studentName ASC', label:'order by Name'}
    })

    const [stat, setStat] = useState({
        debit:0,
        credit:0,
        balance:0,
    })
  
    

    const handleOnClickStepper = (step) => {
        setActiveStep(step);   
    }

const getPercent =()=>{

    let percent = (100 * (Number(stat.credit)- Number(stat.debit)))/Number(stat.credit)

    return percent.toFixed(0)
}
 

    const tableSummary = [
        {dataField: 'admissionNumber', text: 'Admission Number', footer:'', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
        {dataField: 'studentName', text: 'Name', editable: false, footer:'Total', headerStyle: {backgroundColor: '#f0ff4d'}},
        {dataField: 'credit', text: 'Credit', editable: false, footer:columnData => FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell)=>FormatNumber(cell)},
        {dataField: 'debit', text: 'Debit', editable: false, footer:columnData => FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell)=>FormatNumber(cell)},

        {dataField: 'balance', text: 'Balance', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, footer:columnData => FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), formatter: (cell)=>FormatNumber(cell)},
        {dataField: 'ID', text: 'Action', headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell, row)=><div>

        <a className="btn btn-success btn-sm"  href={'/wallet/transactions/'+row.studentCode}  >  <i className='fa fa-newspaper-o'></i> Add Transaction</a>
            </div>, editable: false}
   ];



    const tableHeader = [
        
        {dataField: 'dateCreate', text: 'Date', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
        {dataField: 'studentName', text: 'Name', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
        {dataField: 'transactionType', text: 'Type', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
        {dataField: 'description', text: 'Description', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
        {dataField: 'amount', text: 'Debit', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell, row)=>row.transactionMode==='Debit'?FormatNumber(cell):''},     
       
        {dataField: 'status', text: 'Credit', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell, row)=>row.transactionMode==='Credit'?FormatNumber(row.amount):''}, 
        {dataField: 'balance', text: 'Balance', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell, row)=>FormatNumber(cell)},
       
   ];


   const handleFetchWallet =()=>{

    let sql ='Select t.studentCode, t.transactionType, s.admissionNumber, s.studentName, (sum(case when t.amount >0 and t.transactionMode ="Credit" then t.amount else 0 end) - sum(case when t.amount >0 and t.transactionMode ="Debit" then t.amount else 0 end)) as balance, sum(case when t.amount >0 and t.transactionMode ="Credit" then t.amount else 0 end) as credit, sum(case when t.amount >0 and t.transactionMode ="Debit" then t.amount else 0 end) as debit  from tbl_wallet_transactions t, tbl_students s where s.code = t.studentCode  group by t.studentCode order by t.studentCode DESC'


    const fd = new FormData(); 
    fd.append("jwt", Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
    setWallet(result.data)

  

      }  else{ 
       setWallet([])

      }
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}


    const fetchHistory =()=>{
        var sql ="Select t.ID, t.code, t.studentCode, t.transactionType, t.amount, t.dateCreate, t.balance, t.transactionMode, t.description, t.status, s.admissionNumber, s.studentName from tbl_wallet_transactions t, tbl_students s where s.code = t.studentCode order by ID DESC"; 
        const fd = new FormData(); 
        fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
            setHistory(result.data)
            let debit =  result.data.filter(item=>item.transactionMode==='Debit')
            .reduce((acc, item)=>Number(item.amount)+Number(acc), 0)
            let credit =  result.data.filter(item=>item.transactionMode==='Credit')
            .reduce((acc, item)=>Number(item.amount)+Number(acc), 0)
        
            let balance= credit -debit;
           setStat({
            debit:debit,
            credit:credit,
            balance:balance
           })

          }  else{
            setHistory([])
            setStat({
                debit:0,
                credit:0,
                balance:0,
            })
          }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }




const BasicTable=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
        fd.append("jwt", Token);
       let url = ServerUrl+'/updateCustom_controller/tbl_wallet_transactions'
      axios.post(url, fd, config)
       .then()
       .catch((error)=>alert(error)) 
       
     } 
  
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } entries</span>
      );
      
const { SearchBar } = Search;
     const options = {
         showTotal: false,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '250', value: 250},
     { text: 'All', value: props.data.length }]
        
       };
  


     return <React.Fragment>  
    
             <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />


<div className="dt-buttons btn-group pull-right"> 


    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>

        <a href='#' className="btn dropdown-item waves-effect waves-light" > Export CSV</a>
        <a href='#' className="btn dropdown-item waves-effect waves-light" > Print Preview</a>
        </div>
    </div> 
    </div> 


                             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return props.fetchdata;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  </React.Fragment>
  }



useEffect(()=>{
    fetchHistory()
    handleFetchWallet()
},[]);


        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Wallets">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/students/view_students"> Students</Link></li> 
                    <li className="breadcrumb-item"><a href="#!">Class List</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

	
<div className="row">
<div class="col-md-12 col-xl-4">
                                                <div class="card widget-statstic-card">
                                                    <div class="card-header">
                                                        <div class="card-header-left">
                                                            <h5>Overall Balance</h5>
                                                            <p class="p-t-10 m-b-0 text-c-yellow">Learners deposit remain</p>
                                                        </div>
                                                    </div>
                                                    <div class="card-block">
                                                        <i class="feather icon-sliders st-icon bg-c-yellow"></i>
                                                        <div class="text-left">
                                                            <h3 class="d-inline-block">{Currency+FormatNumber(stat.balance)}</h3>
                                                           {/*  <i class="feather icon-arrow-up f-30 text-c-green"></i> */}
                                                            <span class="f-right bg-c-yellow">{getPercent()}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-xl-4">
                                                <div class="card widget-statstic-card">
                                                    <div class="card-header">
                                                        <div class="card-header-left">
                                                            <h5>Overall Deposit</h5>
                                                            <p class="p-t-10 m-b-0 text-c-pink">From all learners</p>
                                                        </div>
                                                    </div>
                                                    <div class="card-block">
                                                        <i class="feather icon-users st-icon bg-c-pink txt-lite-color"></i>
                                                        <div class="text-left">
                                                            <h3 class="d-inline-block">{Currency+FormatNumber(stat.credit)}</h3>
                                                            <i class="feather icon-arrow-down text-c-pink f-30 "></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-xl-4">
                                                <div class="card widget-statstic-card">
                                                    <div class="card-header">
                                                        <div class="card-header-left">
                                                            <h5>Total Withdrawal</h5>
                                                            <p class="p-t-10 m-b-0 text-c-blue">Fees, purchase, etc</p>
                                                        </div>
                                                    </div>
                                                    <div class="card-block">
                                                        <i class="feather icon-shopping-cart st-icon bg-c-blue"></i>
                                                        <div class="text-left">
                                                            <h3 class="d-inline-block">{Currency+FormatNumber(stat.debit)}</h3>
                                                            <i class="feather icon-arrow-up text-c-green f-30 "></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>

<div className="card z-depth-0">
		<div className="card-block">
	
                       <div className="row" >
                       <div class="col-md-12">


            <div className="card-header p-b-0">
                <ul className="nav nav-tabs md-tabs" role="tablist">

                {steps.map((item, idx)=> <li className="nav-item">
                        <a className={activeStep === (idx+1) ?"nav-link active":'nav-link'} onClick={()=>handleOnClickStepper(idx+1)} data-toggle="tab" href="#" role="tab" aria-expanded="true">{item.title}</a>
                        <div className="slide"></div>
                        </li>)}


                </ul>
            </div>
            <div className="card-block tab-content p-t-20">


               {activeStep === 1 ? <div className="tab-panes " id="activities" role="tabpanel" aria-expanded="true">
                    <div className="generic-card-body">


                      	<div className="col-md-12 table-responsive">
        
                            {notice.isDataFetching ? <TableLoader />:
                    <BasicTable data={history}   columns={tableHeader}  />} 
                    </div>

                    </div>
                </div>:

activeStep === 2 ?


                <div className="tab-panes" id="accounts" role="tabpanel" aria-expanded="false">
                    <div className="generic-card-body">
                       

                    <div className="col-md-12 table-responsive">
        
        {notice.isDataFetching ? <TableLoader />:
<BasicTable data={wallet}   columns={tableSummary}  />} 
</div>
                        
                    </div>
                </div>:
                <div className="tab-panes" id="settings" role="tabpanel" aria-expanded="false">
                    <div className="generic-card-body">
                        <h6 className="sub-title">Coming soon </h6>
                        
                    </div>
                </div>}


            </div>
        </div>

            </div>
        </div>
        </div>




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Wallet) 