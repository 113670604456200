import React, {useState} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, FormatNumber, Currency,  config, allowDelete, API_URL, staffCode} from '../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {displayMonthYear, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'
import { useSelector } from 'react-redux'
const SalaryPayment =()=> {

const staff  = useSelector(state => state.rootReducer.staff);
    const [errors, setErrors] = useState({});

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [staffSalary, setStaffSalary]=useState([])
    const [paymentList, setPaymentList]=useState([])
    
    const [payment, setPayment] = useState({
        staff:[],
        payment_mode:[],
        payMonth:'',
        payDate:'',
        remarks:'',
        template:''
    })

    


    const fetchPaymentStaff =(month)=>{
        setNotice({...notice, isLoading:true})
        let url = API_URL+'/payroll/staff_salary_payment/'+month;
        axios.get(url,  config).then(result=>{
            if(result.data.data.length!==0){
                setPaymentList(result.data.data)
                }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading:false})
        })
        }
        
 

    const fetchSalaryStructure =(option)=>{
        
        setNotice({...notice, isLoading:true})
        let url = API_URL+'/payroll/salary_structure/show/one/'+option.value;
        axios.get(url,  config).then(result=>{
            if(result.data.data.length!==0){

                let options = JSON.parse(result.data.data[0].salary)

                let newSalary = [];
                for (var i = 0, l = options.length; i < l; i++) {
                    newSalary.push({
                      unique_code:Math.random().toString(36).substring(2, 9),
                      code:options[i].code, 
                      salaryHead:options[i].salaryHead,
                      title:options[i].title,
                       amount:options[i].amount,
                       salaryCode:options[i].salaryCode,
                       amount_topay:options[i].amount, 
                       paid:options[i].amount, 
                       due:0,
                       template_code:result.data.data[0].code,
                       staff:option.value
                      
                      });
                  }
                  
        setPayment({...payment, staff:option, payMonth:'', template:result.data.data[0].code}); 
                setStaffSalary(newSalary)
                }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading:false})
        })
        }



const handleChange=(event)=>{        
    const {name, value}=event.target
    if(name==='payMonth'){
        setPayment({...payment, [name]:value, payDate:''});
        fetchPaymentStaff(value)
    }else{
        setPayment({...payment, [name]:value})
    }

    setErrors({...errors, [name]:'', structure:''})
}




const handleSelect= (option, action)=>{
    if(action.name==='staff'){
        
        fetchSalaryStructure(option)    
    }else{
        setPayment({...payment, [action.name]:option});
    }
    setErrors({...errors, [action.name]:'', structure:''})
}



const handleChangeSalary = (e, index, amount)=>{
    const std =JSON.parse(JSON.stringify(staffSalary))   
    let  value =e.target.value;
    value = value.replace(/[^0-9]/g, '');

     std[index][e.target.name] = value;
     std[index].due = Number(amount)- Number(value);
     setStaffSalary(std); 

}


const getTotalDue =(salary)=>{
    var earning =0
    salary.map(sl=>
    sl.salaryHead==='EARNING'?earning += Number(sl.due):earning -=Number(sl.due)
    )

    return earning
}


const getTotalAmount =(salary)=>{
    var earning =0
    salary.map(sl=>
    sl.salaryHead==='EARNING'?earning += Number(sl.amount):earning -=Number(sl.amount)
    )

    return earning
}

const getTotalPaid =(salary)=>{
    var earning =0
    salary.map(sl=>
    sl.salaryHead==='EARNING'?earning += Number(sl.paid):earning -=Number(sl.paid)
    )

    return earning
}


    const handleBack =()=>{
        setPayment({
            staff:[],
            payment_mode:[],
            payMonth:'',
            payDate:'',
            remarks:'',
            template:''
        })
            setStaffSalary([]) 
            setPaymentList([])
    } 


    const handleDelete=(staff_code, month)=>{
        let close =   document.getElementById('btnWarningDialog-'+staff_code)
        close.click();
        
        
        let url = API_URL+'/payroll/multi_payment/delete/'+staff_code+'/'+month
    
    
        setNotice({...notice, isLoading: true}) 
          axios.get(url, config)
          .then(response =>{
           if(response.data.status ===200){
              Alerts('Saved!', 'default', response.data.message)
                  } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
           fetchPaymentStaff(month)
              setNotice({...notice,  isLoading: false  })
          }) 
        
    }
   

   


    const tableHeader = [
        {dataField: 'staff_code', text: 'Action', formatter: (cell, row)=><div>
            
        {allowDelete==='Yes'? <a href="#!" data-toggle="modal" className="btn btn-sm btn-danger btn-sm"  data-target={'#warningdialog-'+row.staff_code}     title="Revert Salary Payment"><i className="fa fa-mail-reply"></i></a>:[]}

    <WarningModal message="This will revert all salary payment for the month. Are you really sure?" handleAction={()=>handleDelete(row.staff_code, row.payment_month)} mID={row.staff_code} /> 
    </div>, editable: false},
        {dataField: 'staffName', text: 'Staff', editable:false},

        {dataField: 'earning', text: 'Earning', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },

        {dataField: 'deduction', text: 'Deduction', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },

        {dataField: 'total', text: 'Net Pay', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },
        {dataField: 'payment_month', text: 'Month',  editable:false, formatter:(cell)=>displayMonthYear(cell)},       
        {dataField: 'payment_date', text: 'Date Paid', editable:false, formatter:(cell)=>longDate(cell)} 
     ];


    
 const TableBasic=(props)=>{
       
   
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='staff_code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             <div className="dt-buttons btn-group pull-right"> 


                             <form method='POST' action={ServerUrl+'/printout/staff_bulk_salary_list.php?token='+userToken}  target='_blank'>
        
    <input type='hidden' name='result' value ={JSON.stringify(paymentList, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-outline-primary" > <i className="fa fa-print"></i>Print Preview</button>
        
        </form>

</div>        
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }






     const handleSubmit =(event)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(staffSalary.length===0){
        error.structure ='Salary structure not found';
        formIsValid = false;
    } 
    
    if(payment.staff.length===0){
        error.staff ='Please select staff';
        formIsValid = false;
    } 


    if(payment.template.length===0){
        error.template =msg;
        formIsValid = false;
    } 
    
    if(payment.payment_mode.length===0){
        error.payment_mode =msg;
        formIsValid = false;
    }  
     
    if(!payment.payMonth){
        error.payMonth =msg;
        formIsValid = false;
    }  
    
    if(!payment.payDate){
        error.payDate =msg;
        formIsValid = false;
    }  
    
    if(!formIsValid){
    setErrors(error) 
    
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
    }
    
    if(formIsValid) {
        setNotice({...notice, 
            isLoading: true}) 
       
        const fd = new FormData();
        let code = 'SP'+Math.random().toString(36).substring(2, 9)
    
        fd.append('structure', JSON.stringify(staffSalary, null, 2)); 
    
    
        fd.append('unique_code', code);
        fd.append('added_by', staffCode);
        fd.append('structure_code', payment.template);
        fd.append('payment_mode', payment.payment_mode.value);
        fd.append('payment_month', payment.payMonth);
        fd.append('payment_date', payment.payDate);
        fd.append('remarks', payment.remarks);
    let url = API_URL+'/payroll/auto_salary_payment/add_payment';
      axios.post(url, fd, config)
      .then(response =>{
     if(response.data.status === 200){
         Alerts('success', 'success', response.data.message)
           } else{
             Alerts('danger', 'danger', response.data.message)
                    
                  }  
              })
              .catch((error)=>{
              Alerts('Error', 'danger', error.message)
             
              }).finally(()=>{
                handleBack()
            setNotice({...notice, 
                isLoading: false}) 
              })  
        }
    
    }



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Update Salary Payment">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Salary Payment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Salary Payment List</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
   <div className="form-group">
<label >Select Staff <span style={{color:'red'}}>*</span></label>
      <Select  options={
                            staff.filter(i=>i.employmentStatus ='Working').map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                             getOptionLabel={option =>` ${option.staffName} - ${option.label} `}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={payment.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
   </div>

</section>

<section className="col-md-6">
   

   <div className="form-group">
<label >Payment Month <span style={{color:'red'}}>*</span></label>
<input type="month" className={errors.payMonth ? 'form-control form-control-danger' : 'form-control'} name="payMonth"  onChange={handleChange} value={payment.payMonth} />
<span style={{color:'red'}}>{errors.payMonth && errors.payMonth}</span>
   </div>

</section>


<section className="col-md-6">
<div className="form-group">
<label >Payment Mode <span style={{color:'red'}}>*</span></label>
      <Select options={[
                                    { value: 'CASH', label: 'CASH' },
                                    { value: 'CHEQUE', label: 'CHEQUE'},
                                    { value: 'TRANSFER', label: 'TRANSFER'},
                                    { value: 'BANK', label: 'BANK'},
                                    { value: 'OTHERS', label: 'OTHERS'},
                                ]} 
onChange={handleSelect} className={errors.payment_mode ? 'form-control form-control-danger' : ''} name="payment_mode" value={payment.payment_mode}  /> 
<span style={{color:'red'}}>{errors.payment_mode && errors.payment_mode}</span>
   </div>

  

</section>

<section className="col-md-6">
<div className="form-group">
<label >Payment Date <span style={{color:'red'}}>*</span></label>
<input type="date" className={errors.payDate ? 'form-control form-control-danger' : 'form-control'} name="payDate"  onChange={handleChange} value={payment.payDate} />
<span style={{color:'red'}}>{errors.payDate && errors.payDate}</span>
   </div>
</section>

<section className="col-md-12">
   <div className="form-group">
						<label >Remarks</label>
		<input type="text" name="remarks" value={payment.remarks} onChange={handleChange} className='form-control' /> 
			</div>
</section>


				</div>	
{errors.structure?<div className="alert alert-danger background-danger">{errors.structure}</div>:''}

       
{ staffSalary.length!==0? 
                <div className="row" >
                
                <section className="col-md-12"><label style={{color:'green'}}><b>Salary Structure</b></label>
                            <table className="table">
                            <thead>
                             <tr>
                             <th>Salary Head</th>
                             <th>Amount</th>
                             <th>Amount To Pay</th>
                             </tr>
                         </thead>
                                <tbody> 
                                    {staffSalary&&staffSalary.map((salary, id)=>
         <tr key={id} style={{color:salary.salaryHead==='EARNING'?'green':'red'}}>
                   <td>{salary.title}</td>
                    <td>{Currency+FormatNumber(salary.amount)}</td>
                <td><input  type="text" min='0' pattern='[0-9]' onFocus={(e)=>e.target.select()}  className='form-control' name="paid"  onChange={(e)=>handleChangeSalary(e, id, salary.amount)} value={salary.paid} /></td>

                                    </tr>)} 
                                    <tr>
                                       <td><b>Total</b></td>
            <td >{Currency+FormatNumber(getTotalAmount(staffSalary))}</td>

            <td >{Currency+FormatNumber(getTotalPaid(staffSalary))}</td>
                                      
                                    </tr>
                                    </tbody>
                            </table>
                            
                            
                            </section></div>:''}

                            
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" onClick={()=>handleBack()} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success "><i className="fa fa-save"></i>Make Payment</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div> </div> 
							
</div>
</div> </div>    </div>
                            
                            </div>
</div>
{paymentList.length!==0?

<div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Salary Payment List</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

          {notice.isDataFetching ? <TableLoader />:
      <TableBasic data={paymentList}  columns={tableHeader}  />}  

            </div></div>
    
    </div>
    
    </div>
    </div>:''}


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(SalaryPayment) 