import React, {useEffect} from 'react';

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';




import error403 from './settings/error403';
import error500 from './settings/error500';
import Error404 from './settings/error404';

import login from './users/login';
import forgotpassword from './users/forgotpassword';
import ProtectedView from './protected_view'
import {LicenseTimeout} from './component/notify'
import {  LocalUrl, PortalType } from './component/include';
import recover_password from './users/recover_password';
import Template from './component/template';

import LogOut from './users/logout';
import centralogin from './users/centralogin';
import StaffTemplate from './staff_portal/component/template';

const App =()=>{

  
const checkLicense=()=>{
//prevent working from localhost
if(window.location.hostname ==='localhost'){
    return false;
  }else{
    return true;
  }
}

useEffect(()=>{
setTimeout(() => {
if(checkLicense()===false){
  //document.getElementById('openLicenseModal').click()
//timeup logout user
}
}, 4000)
}, []) 

    return (<>
    <a href="#!" data-toggle="modal" id='openLicenseModal' data-target='#licenseModal' ></a>
    <LicenseTimeout   /> 

    <ReactNotifications />
     <Router  >
      <Switch>
      {/* <Route exact path="/" component={login} /> */}
      <Route exact path="/" component={centralogin} />
      <Route exact path="/logout" component={LogOut} />
      <Route exact path="/forgot_password" component={forgotpassword} />
    <Route exact path="/recover_password" component={recover_password} />

    
    {PortalType==='Admin'?
    <Template />:
    <StaffTemplate />}
    
       {/*  <ProtectedView /> */}


        <Route exact  component={Error404} />
      </Switch>
      </Router>
 </>
    );
  
}
export default App;
