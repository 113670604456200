import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, Currency, config} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, sort, formatGroupLabel, FormatNumber } from '../component/globalFunction'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
const NewStudentFees=()=>{
   
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
	const [errors, setErrors] = useState({});
	
    const [feecharge, setFeecharge] = useState([]);
	const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
	const [fees, setFees] = useState([]);
	const [title, setTitle] = useState('Fee Structure');
	const [feeStructure, setFeeStructure] = useState([]);

const [payment, setPayment] =  useState([]);
const [studentFee, setStudentFee]=useState({
    studentClass:[],
	student:[],
	feecharge:[],
	classFee:[],
	classActualFee:[],
	remark:'',
	total:0,
	discountAmount:0
});

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };


const fetchFeecharge =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
    let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
    axios.post(url, fd, config).then(result=>{
        setFeecharge(result.data.filter(item=>item.status=='true'))
    })
}









		
			

		const handleChange = event =>{
			 const {name, value} = event.target
			
			 setStudentFee({...studentFee, remark:value})
		}


		



 const handleChangeTuition =(index, e)=>{

	
	let value =e.target.value;
   value = value.replace(/[^0-9]/g, '');

	const nfees =JSON.parse(JSON.stringify(fees))   
  	nfees[index][e.target.name] = value;


let discount_percent = nfees[index].discount_percent?nfees[index].discount_percent:0
var discountAmount = (Number(discount_percent)/100) * value


nfees[index].discountAmount = discountAmount;
  let discount = Number(nfees[index].school_fee)-Number(discountAmount);

  nfees[index].discount = discount;
  nfees[index].total= discount;

setFees(nfees)
	  
}


const handleChangeSchoolFee =(index, e)=>{

	
	let value =e.target.value.replace(/[^0-9]/g, '');

	if(Number(value)<1){
		value = 0
	}
	const nfees =JSON.parse(JSON.stringify(fees))   
  	nfees[index][e.target.name] = value;


let discount_percent = nfees[index].discount_percent?nfees[index].discount_percent:0
var discountAmount = (Number(discount_percent)/100) * nfees[index].tuition_fee


nfees[index].discountAmount = discountAmount;
  let discount = Number(value)-Number(discountAmount);

  nfees[index].discount = discount;
  nfees[index].total= discount;

setFees(nfees)
	  
}



const handleChangePercent =(index, e)=>{

	
	let value = e.target.value.replace(/[^0-9]/g, '').slice(0,3);

   if(value<1){
	value =0;
   }
	const nfees =JSON.parse(JSON.stringify(fees))   
  	nfees[index][e.target.name] = value;


var discountAmount = (Number(value)/100) * nfees[index].tuition_fee


nfees[index].discountAmount = discountAmount;
  let discount = Number(nfees[index].school_fee)-Number(discountAmount);

  nfees[index].discount = discount;
  nfees[index].total= discount;

setFees(nfees)
	  
}




			

		const fetchFees =()=>{

			if(studentFee.studentClass.length===0){
				setErrors({...errors, studentClass:'Please select class'})
			}else if(studentFee.feecharge.length===0){
				setErrors({...errors, feecharge:'Please select fees'})
			}else{
				
			
			setNotice({...notice, isLoading: true}) 


			var sql ="Select st.admissionNumber,  st.studentName, st.code as student_code, s.tuition_fee, s.school_fee, s.discount_percent, s.discountAmount, s.payment_status, (select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode = s.code ) as paid, s.feeCharge, s.classID, s.sectionID, s.total  from tbl_fees_charge f, tbl_student_fees s, tbl_students st where s.feeCharge = f.code and s.sectionID = '"+studentFee.studentClass.value+"' and s.feeCharge = '"+studentFee.feecharge.value+"' and st.code =s.studentcode and st.admissionStatus = 'Studying' and  st.termination <>'Yes'  order by st.studentName ASC" ;
		
			const fd = new FormData();
            fd.append('jwt', Token);
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller'
		axios.post(url, fd, config).then(result=>{
			if(Array.isArray(result.data) && result.data.length!==0){
				setFees(result.data)
				setPayment(result.data)
				window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
			}
		
		})
		.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
		}).finally(()=>{
			setNotice({...notice, isDataFetching: false})
			
		})
		}
	}
	
	 
		const handleSelect = (option, action)=>{
			setStudentFee({...studentFee, [action.name]: option, student:[]});
			setErrors({...errors, [action.name]:'' })
			
			setFees([])
			setPayment([])
			setTitle('Fee Structure')
         }
		 
		


		 function handleSubmit(){  
    

			
			const fd = new FormData();
            fd.append('jwt', Token); 
			fd.append('remark', studentFee.remark);
			fd.append('studentList', JSON.stringify(fees, null, 2));
			setNotice({...notice, 
				isLoading: true}) 
		
				 let url = ServerUrl+'/update_controller/tbl_student_fees_update'
				axios.post(url, fd, config)
				.then(response =>{

		 if (response.data.type ==='info') {
						Alerts('Update!', 'default', response.data.message)
					}  else{
						Alerts('Error!', 'danger', JSON.stringify(response.data))
				}   
						
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', JSON.stringify(error.message)) 
				})
				.finally(()=>{
				  setNotice({...notice, isLoading: false})  
				  //fetchFees()
				})   
				  
		}

		 const handleDelete = (feeID, student)=>{  
			setNotice({...notice, 
			   isLoading: true}) 
			   let close =   document.getElementById('btnWarningDialog-'+feeID)
				  close.click();
				  const fd = new FormData();
				  fd.append('jwt', Token);
			   fd.append('code', student)
				fd.append('feesCharge', feeID)
				fd.append('sectionID', studentFee.studentClass.value)

				let url = ServerUrl+'/update_controller/tbl_delete_fees'
			   axios.post(url, fd, config)
			   .then(response =>{
				if(response.data.type ==='info'){
				   Alerts('Info!', 'info', response.data.message)
					   } else{
				   Alerts('Error!', 'danger', JSON.stringify(response.data))
					   }   
			   })
			   .catch((error)=>{
				 Alerts('Error!', 'danger', error.message)
			   }).finally(()=>{
				   setNotice({...notice, 
					   isLoading: false
				   })
			   }) 
		}






		 const tableStudentFeesHeader = [
			{dataField: 'code', text: 'Action', editable: false, formatter:(cell, row)=><div>
				

				<WarningModal  handleAction={()=>handleDelete(row.feeCharge, row.student_code)} mID={row.feeCharge} message='All associated payment with this fees will be deleted as well. Are you sure?' />

				<div className="btn-group dropdown-split-success ">
        <button type="button" className="btn btn-sm btn-outline-success dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-hand"></i>Action
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          



		<form method='POST' action={ServerUrl+'/printout/student_class_fees.php?token='+userToken} target='_blank'>
		<input type='hidden' name='studentCode' value ={studentFee.student.value} />
		<input type='hidden' name='jwtToken' value ={Token} />
		<input type='hidden' name='feescharge' value ={row.fees} />
		<input type='hidden' name='feeCharge' value ={row.feeCharge} />
		<input type='hidden' name='feeCode' value ={row.code} />

		{Number(row.paid) === Number(row.total)?
		<button type="submit" style={{ cursor:'pointer' }} className="dropdown-item waves-effect waves-light"><i className='fa fa-print'></i> Print Invoice</button>:[]}

		
</form>

            <div className="dropdown-divider"></div>
			{Number(row.paid) !== Number(row.total)? <a href="#!" data-toggle="modal" className="dropdown-item "  data-target={`#warningdialog-${row.feeCharge}`} title="Delete"><i className="fa fa-trash"></i>Delete Fees</a>:[]}
        </div>
    </div>
			</div>},
			{dataField: 'studentName', text: 'Student', editable: false, formatter:(cell)=>cell},
			{dataField: 'school_fee', text: 'School Fees ('+Currency+')', editable: false, formatter:(cell)=>FormatNumber(cell)},

			{dataField: 'tuition_fee', text: 'Tuition Fee ('+Currency+')', editable: false, formatter:(cell)=>FormatNumber(cell)},

			{dataField: 'discountAmount', text: 'Discount ('+Currency+')', editable: false, formatter:(cell, row)=><span> {row.discount_percent+'%'} <br/> {FormatNumber(cell)}</span>},


			{dataField: 'status', text: 'Status',  editable: false, formatter:(cell, row)=>Number(row.paid) === Number(row.total)?<b className='text-success'>PAID</b> :<b className='text-danger'>OUTSTANDING</b>}    
		 ];
	
		
	
		 const TableStudentFees=(props)=>{
		   
		const { SearchBar } = Search;
		
		const customTotal = (from, to, size) => (
		<span >&nbsp;Showing { from } to { to } of { size } items</span>
		);
		const options = {
		showTotal: true,
		paginationTotalRenderer: customTotal,
		sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
		{ text: 'All', value: props.data.length }]
		
		};
		return  <ToolkitProvider search columnToggle 
					keyField='code' data={ props.data } columns={ props.columns } >
						
						{
							props => (
							
							<div className="form-group">
	
						<SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
									
				
    <div className="btn-group dropdown-split-primary pull-right mb-2">




        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          
        <form method='POST' action={'#'} target='_blank'>
               
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={'#'} target='_blank'>

       

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>

        </div>
    </div> 

					<BootstrapTable
								{ ...props.baseProps }
											striped
										hover
										condensed
										noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
									pagination={paginationFactory(options) }
									
								/>
							</div>
							)
						}
	</ToolkitProvider>
	}
	
	

	 



const removeRow=(index)=>{
	const newfees =JSON.parse(JSON.stringify(fees))
	
	if(newfees.length>1){

	let newRow = newfees.filter((_, i) => i !== index)
		setFees(newRow)
	}
   }


   


		 useEffect(()=>{
			fetchFeecharge()
		},[]);

        return ( <>
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="New Student & New Session Term 1 Fees">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">New Student Fees</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



				<div className="alert  bg-success text-white  p-2">
		<p className='f-14'>Use this page for any new student who you will like to discount their tuition fees. This can also be use for new session term 1  fees. The purpose of this is to be able to merge the school fees and tuition fees and give discount only on tuition</p>

		</div>



								<div className="row  ">
								<div className="col-sm-5">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Fee Criteria</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
										<div className="row">
										<section className="col-md-12">
										<div className="form-group">
         <label className="bold "> Student Class<span style={{color:'red'}}>*</span></label>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} styles={selectStyles}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={studentFee.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>	


			<div className="row">
		    
            <section className="col-md-12">
            <div className="form-group">
         <label className="bold "> Fee Charge For <span style={{color:'red'}}>*</span></label>
         <Select  options={feecharge&&feecharge.map((list, idx)=> {
                               return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                             })
                        } 
                        getOptionLabel={option =>`${option.session}  ${option.label}`}       
						onChange={handleSelect} className={errors.feecharge ? 'form-control form-control-danger' : ''} name="feecharge" value={studentFee.feecharge} styles={selectStyles} /> <span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>

            </div>
            </section>

			</div>




											<div className="modal-footer">
                  <button type="button" onClick={fetchFees} className="btn btn-inverse">Search Student</button>
              </div>


										</section>
									
									</div>
										
							</div>
							</div>



							</div>
		
	
	<div className="col-md-7">

		
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Student Fees</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
		{notice.isDataFetching ? <TableLoader />:
        <TableStudentFees data={payment}  columns={tableStudentFeesHeader} />}
                </div>
            </div>
            </div> 
	</div>


                </div>


<div className="row">


	
	
{fees.length!==0  ? 
<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>{studentFee.feecharge.label}</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
		    <div className="row">
    
    <div className="col-md-12 table-responsive"> 
    
      <table className="table ">
	  <thead>
                <tr>
    <th> Action</th>
                    <th>Student </th>
					<th>School Fee</th>
					<th>Tuition Fee</th>
                    <th>Discount (%) </th>
                    <th>To Pay</th>
                </tr>
            </thead>

        <tbody >
{ fees.map((fee, idx)=> <tr key={idx}>

<td> <button type="button" onClick={()=>removeRow(idx)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>

	<td>{fee.studentName} <br /><span>{fee.admissionNumber}</span>
                        
       </td>
		<td > <input type="text" min="0" className="form-control" onChange={(e)=>handleChangeSchoolFee(idx, e)} onFocus={(e)=>e.target.select()} name="school_fee" value={fee.school_fee} />
        </td>

				<td > <input type="text" min="0" className="form-control" onChange={(e)=>handleChangeTuition(idx, e)} onFocus={(e)=>e.target.select()} name="tuition_fee" value={fee.tuition_fee} />
                </td>

				<td className='text-center'>
                    <input type="text" min="0" 
					
                    onFocus={(e)=>e.target.select()} 
					className="form-control" onChange={(e)=>handleChangePercent(idx, e)}  name="discount_percent" value={fee.discount_percent} />
					<span className='text-danger '>{ FormatNumber(Number(fee.discountAmount))}</span>
                </td>

			 	<td><b>{FormatNumber(Number(fee.total))} </b> </td> 
			</tr>)
			
			}


				</tbody>
              <tfoot>
			  <tr>
			<td colSpan={6}>

			<textarea  name="remark" className="form-control" placeholder="Remark" value={studentFee.remark} onChange={handleChange} rows="2" >{studentFee.remark}</textarea>
			</td>
			</tr>
				   <tr><td colSpan={6}>
				  <div className="pull-right">
	<button type="button" id="submit"  className="btn btn-inverse " onClick={handleSubmit}>Update Student Fees</button>
	</div></td>
				  </tr> 
			  </tfoot>
		</table>
        </div>
        </div>
                </div>
            </div>
            </div> 
                </div>:""}
</div>

          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(NewStudentFees) 