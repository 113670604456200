import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import { config,  API_URL, API_IMAGE} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'

const Advert =()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
   const [image, setImage] = useState('');
    const [title, setTitle] = useState('')
    const [content, setContent] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [errors, setErrors] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const ValidateInput =()=>{
      let errors = {};   
   

      if(!image){
          Alerts('Error!', 'danger', 'Please choose an image')
          errors.image ='Please enter image.';
      }
         
      return errors;
      }

      const handleChange = event =>{		
        let {name, value} = event.target;	
       
       setTitle(value);
        }
  

    const fetchContent =()=>{

      let url = API_URL+'/adverts/display/all';
         axios.get(url, config).then(result=>{  
          if(Array.isArray(result.data.data)){
            setContent(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }

  const fetchSchool =()=>{

    let url = API_URL+'/school/detail/all';
       axios.get(url, config).then(result=>{  
        if(result.data.data.length!==0){
          setTitle(result.data.data.news_flash)
        }
      })
      .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
      })
}
  

function submitNews(){ 
  if(title) 
  setNotice({...notice,  isLoading: true})     
     
    const fd = new FormData();

    
    fd.append("title", title);
       let url = API_URL+'/school/update';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status ===200){
          Alerts('Saved!', 'success', response.data.message);
              } else{
          Alerts('Error!', 'danger', response.data.message)
              }   
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
          setNotice({...notice, isLoading: false  })
      }) 
}


    function submit(){  
      setNotice({...notice,  isLoading: true})     
         
        const fd = new FormData();

        
        fd.append("display_order", content.length+1);
        fd.append("image", image);
           let url = API_URL+'/advert/add';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status ===200){
              Alerts('Saved!', 'success', response.data.message);
                  } else{
              Alerts('Error!', 'danger', response.data.message)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, isLoading: false  })

            setImage('');
            document.getElementById('advert').value=''
              setIsSubmitting(false);
              fetchContent()
          }) 
  }
  

  const handleSubmit = event =>{
    event.preventDefault();         
    setErrors(ValidateInput(content));
        setIsSubmitting(true);
  }
  
useEffect(()=>{
  if(Object.keys(errors).length === 0 && isSubmitting){
  submit()
  }
  },[errors, isSubmitting])

  useEffect(()=>{
    fetchSchool()
   fetchContent()
   },[]);


   const  handleFile = function(){
    return function(newValue){                 
      // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
    let displayMessage = '';
      var fileName =  document.getElementById('advert').value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile ==="jpg" || extFile ==="jpeg" || extFile ==="png" || extFile ==="gif" || extFile ==="JPG" || extFile ==="JPEG" || extFile ==="PNG" || extFile ==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 3) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 3mb';
       document.getElementById('advert').value = '';
       setImage('')
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e) {
       
          const img = document.getElementById('viewAdvert');
                     img.src = e.target.result             
              
          };
          reader.readAsDataURL(input.target.files[0]); 
          setImage(input.target.files[0])  
        }
    }
    }else{
        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 3mb'
       document.getElementById(input.target.name).value = '';
       setImage('');
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
} 


const SlidesTable =(props)=>{
  const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } entries</span>
  );


  const handleUpdate=(column, newValue, code)=>{       
    const fd = new FormData();
    fd.append('newValue', newValue);
    fd.append('column', column.dataField);
    fd.append('code', code);
    let url = API_URL+'/advert/change_field'
    axios.post(url, fd, config)
        //.then(result => console.log(result.data))
        .then()
        .catch((error)=>Alerts('Error!', 'danger', error.message))
        .finally(()=>{
            fetchContent()
        }) 
    } 
    const options = {
        showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, { text: 'All', value: props.data.length }]
       
      };
 

  return <React.Fragment>  
 
          <ToolkitProvider keyField='code' data={ props.data } columns={ props.columns } >                  
                  {
                      props => (                               
                        <BootstrapTable
                         { ...props.baseProps }
                                     striped
                                 hover
                                 condensed
                                 noDataIndication={<div className="alert alert-danger">No Record Found</div>}
                             pagination={paginationFactory(options) } 
                             cellEdit={ cellEditFactory({
                              mode: 'dbclick',
                              blurToSave: true,
                              autoSelectText: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      if(oldValue !==newValue){
                      handleUpdate(column, newValue, row.code);                        
                     return props.fetchdata;
                    }                      
                  }
                         }) }                              
                         />
                      )
                  }
</ToolkitProvider>
</React.Fragment>
}


const handleDelete =(code)=>{
  let close =   document.getElementById('btnWarningDialog-'+code)
  close.click();
 
  setNotice({...notice, 
      isLoading: true}) 

let url = API_URL+'/advert/delete/'+code;
    axios.get(url, config)
    .then(response =>{
     if(response.data.type ==='success'){
        Alerts('Saved!', 'default', response.data.message)
            } else{
        Alerts('Error!', 'danger', JSON.stringify(response.data))
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        fetchContent()
        setNotice({...notice, 
            isLoading: false
        })
    }) 
}






const tableHeader = [
    
    {dataField: 'image_url', text: 'Logo', editable:false, formatter:(cell,row)=><img  className="image" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+"/no.png"}} src={`${API_IMAGE}/advert/${cell}`}  style={{ height:'50px',width:'100px'}} title={row.title}  /> },

      {dataField: 'status', text: 'Status', validator: (newValue) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'This field is required'
          };
        }
        return true;
      },  formatter: (cell, row)=>row.status==='Active'?<span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>,editor: {
        type: Type.SELECT, options:[
          {value:'Active', label:'Active'}, 
          {value:'Inactive', label:'Inactive'}]
      }},

      {dataField: 'display_order', text: 'Order', sort:true},
    {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>  
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} className='btn btn-danger btn-sm'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>
<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

        </div>, editable: false},  
 ];



        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Adverts">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-image"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">General</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Advert</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

         
         
    <div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Update News Flash</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
  <section className="col-md-12">
   

   <div className="form-group">
<label >Message</label>

<textarea type="text" rows="3" className='form-control' name="title"  onChange={handleChange} value={title} >{title}</textarea>

<span style={{color:'red'}}>{errors.message && errors.message}</span>
   </div>


</section>



</div>


         

<hr/>

   <footer className="pull-right">
   
   <button type="button" onClick={submitNews}  className="btn btn-success "><i className="fa fa-save"></i> Update News</button>
            </footer>
 </div> 
			
 </div>
                    </div>
                      </div>
                    </div></div>					
           </div>
                            
       </div>


                <div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Add Advert</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
     


<div className="row">

<div className="input-field col-sm-6"><label >Select Image</label>
  <input id="advert"  onChange={handleFile('advert')}  className={errors.image? 'form-control form-control-danger' : 'form-control'} name="advert" type="file" accept="image/*" />
  
</div>
<div className="col-sm-6">
<img id="viewAdvert" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+"/no.png"}} src={image}  style={{display:'block',height:'150px',width:'150px'}} />  

</div>

</div>
         

<hr/>

   <footer className="pull-right">
   
   <button type="button" className="btn btn-inverse " ><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
   <button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success "><i className="fa fa-save"></i> Save</button>
            </footer>
 </div> 
			

 </div>
                    </div>
                      </div>
                    </div></div>					
           </div>
                            
       </div>


                <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Adverts</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="col-md-12">
        <div className="table-responsive">

        {notice.isDataFetching ? <TableLoader />:
  <SlidesTable data={content} columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Advert) 