import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import {Type} from 'react-bootstrap-table2-editor';

import { useDispatch, useSelector } from 'react-redux'
import {RadioButton} from '../component/formControl'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


 const ChangePassword =()=>{
    const [editStaff, setEditStaff] = useState(false)
const [usertype, setUsertype] = useState([])
    const [users, setUsers] = useState({
        userType:'',
        username:'',
        email:'',
        password:'',
        code:''

    });
    
const [errors, setErrors] = useState({});
    const allStaff  = useSelector(state => state.rootReducer.staff);
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const fetchUserType =()=>{
        const fd = new FormData();
    fd.append("jwt", Token);
        let url = ServerUrl+'/fetch_controller/tbl_usertype'
        axios.post(url, fd, config).then(result=>setUsertype(result.data))
    }

    const getUsergroup = (code)=>{
        const result = usertype&&usertype.filter(item=>item.code===code)
        const answer = result.length!==0?result[0].groupName:''
        return answer
    }


            const handleChange = (event)=>{
                const {name, value} = event.target
                setUsers({...users, [name] : value });
                setErrors({...errors, [name]:''})
            }

         const handleChangePassword =(row)=>{
            setUsers({
                username:row.username,
                email:row.email,
                userType:row.userType,
                code:row.code,
                password:''
            })
             setEditStaff(true)
             window.scrollTo(0,0)
         }


    const tableStaffHeader = [
            {dataField: 'code', text: 'Action', editable:false, formatter: (cell, row)=><div>
                <button type='button' onClick={()=>handleChangePassword(row)} className='btn btn-sm btn-danger'><i className='fa fa-key'></i> Change Password</button>
            </div>},
            {dataField: 'userType',  text: 'Group',  sort: true, editable:false, formatter:(cell)=>getUsergroup(cell)},
            {dataField: 'staffName', text: 'Name', sort: true, editable:false, formatter: (cell, row)=><div>
            {cell}<br/>{row.employmentStatus==='Working'? <span className="pcoded-badge label label-success">Working</span>: <span className="pcoded-badge label label-danger">{row.employmentStatus}</span>}</div>},
            {dataField: 'email', text: 'Email', sort: true}, 
            {dataField: 'status',  text: 'Active', editor: {
                type: Type.SELECT, options:[{value:true, label:'Active'},
                {value:false, label:'In Active'}]
              }, formatter:(cell, row)=>row.status==='true'? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>}          
         ];



         
const BasicTable =(props)=>{
    
    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
    fd.append("jwt", Token); 
            fd.append('columnField', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
          
           let url = ServerUrl+'/updateById_controller/tbl_staff'
          axios.post(url, fd, config)
         // .then(result => console.log(result.data))
          .then()
          .catch((error)=>console.log(error)) 
          
        } 

        const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
    const options = {
        showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
        
        };
        const { SearchBar } = Search;

    return  <ToolkitProvider search  className="table-responsive"
                keyField='code' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        <div >
                        <SearchBar
                        { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                            


                            <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}

const  updateStaff =(event)=>{  
    event.preventDefault();
	 
	let errors = {};   
let email = users.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);


if(!email){
	errors.email ='Please enter valid email address';
}
if(!users.password){
	errors.password ='Please enter staff password';
}

if(!users.userType){
	errors.userType ='Please select staff position';
}


setErrors(errors);

if(Object.keys(errors).length === 0 ){
    
        const fd = new FormData();
    fd.append("jwt", Token);
       
            fd.append('password', users.password);
            fd.append('userType', users.userType);
            fd.append('email', users.email);
            fd.append('code', users.code);

        setNotice({...notice, 
          isLoading: true}) 
           let url = ServerUrl+'/update_controller/tbl_staff_password'
          axios.post(url, fd, config)
          .then(response =>{
            
            if (response.data.type ==='info'){
                Alerts('Saved!', 'info', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
            window.location.reload()
          setNotice({...notice, isLoading: false}) 
          setEditStaff(false); 
          setUsers({...users, password:''})
    } )

}

}


useEffect(()=>{   
    fetchUserType();
},[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Change Staff Password">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Password</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th"></i> Staff List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


        <div className="card z-depth-0">
            <div className="card-block">
        <div className="col-md-12 table-responsive">
		<table id="remindertable" className="table table-bordered table-striped table-hover" >
	<thead>



       {editStaff?    <tr >
<td colSpan="2"><div className="form-group"><label>Staff Group</label>
           <select onChange={handleChange}  name="userType"  className={errors.userType ? 'form-control form-control-danger' : 'form-control'} value={users.userType} >

<option value="">Select</option>
{usertype&&usertype.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.groupName} </option> })}
           
    </select>
    
    <span style={{color:'red'}}>{errors.userType && errors.userType}</span>
    </div>
                 </td>
                                   
                                            <td>
           <div className="form-group"><label>Email Address</label>
						<input type="email"  className={errors.email? 'form-control form-control-danger' : 'form-control'} name='email' role={'presentation'}  autoComplete='off' value={users.email} onChange={handleChange} />
												
    <span style={{color:'red'}}>{errors.email && errors.email}</span>
                                            </div></td>
                                            <td>
           <div className="form-group"><label>New Password</label>
						<input type="password" name='password' autoComplete='off' role={'presentation'} className={errors.password ? 'form-control form-control-danger' : 'form-control'} value={users.password} onChange={handleChange} />
												
    <span style={{color:'red'}}>{errors.password && errors.password}</span>
                                            </div></td>
                                            <td>
           <div className="form-group"><br/>
						<button type="button" onClick={updateStaff}  className="btn btn-success" ><i className="fa fa-save"></i> Update </button>
												
                                            </div></td>
           </tr> :[]}  
                
	
	</thead>
	</table>
    
    
    
    
    <BasicTable data={allStaff}  columns={tableStaffHeader}  />

	</div></div>

</div></div></div>


</div>
</div>
                

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ChangePassword) 