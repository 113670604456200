import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config, staffCode, FormatNumber, API_URL} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getRank, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
const ExamResult =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [examtype, setExamtype]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allResult, setAllResult]=useState([])
    const [subject, setSubject]= useState([])
    const [result, setResult] = useState({
        studentClass:[],
        subject:[],
        sectionClass:[],
        examtype:[]
    })

    const [stat, setStat] = useState({
        average:0,
        below:0,
        above:0,
        total:0
    })

    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0 && values.sectionClass.length===0 ){
            errors.sectionClass ='Please select class';
        }   
        if(values.examtype.length===0){
            errors.examtype ='Please select exam type';
        } 
        if(values.subject.length===0){
            errors.subject ='Please select subject';
        } 
        return errors;
        }


        const fetchExamType =()=>{
       
            let url = API_URL+'/exam/result/examtype';
            axios.get(url,  config).then(result=>{
            
                let examtype = result.data.examType
                setExamtype(examtype)
            
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }



    const fetchSubjectClass =(type, section)=>{
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+type+"' and r.classID ='"+section+"' group by r.subjectCode  order by r.displayOrder ASC" ;
    


    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
            setSubject(result.data)
        }else{
    setSubject([])
        }
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
    })
    }


    const fetchSubject =(type, section)=>{
        setNotice({...notice, isLoading: true}) 
    let url = API_URL+'/subject/exam/'+type+'/'+section;
    axios.get(url, config).then(result=>{
        if(Array.isArray(result.data.data)){
            setSubject(result.data.data)
        }else{
            setSubject([]) 
        }
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
    })
    }



  

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
       
            if(result.sectionClass.length!==0){
        fetchResult()
            }else if(result.studentClass.length!==0){
                fetchResultClass()
            }
        }
        },[errors])


        

        const handleSelectClass = (option, action)=>{
            if(action.name==='sectionClass'){
                setResult({...result, [action.name]: option, studentClass:[], examtype:[], subject:[] });
               
            }else{
                setResult({...result, [action.name]: option, sectionClass:[], examtype:[], subject:[] });
            }
            setErrors({...errors, [action.name]:''})
           setAllResult([])
          
        }




const handleSelect = (option, action)=>{

    if(result.sectionClass.length!==0){
        fetchSubject(option.value, result.sectionClass.value)
    }else if(result.studentClass.length!==0){
        fetchSubjectClass(option.value, result.studentClass.value)
    }

    setResult({...result, [action.name]: option });
    setErrors({...errors, [action.name]:''})
   setAllResult([])
  
}


const fetchResultClass =()=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT 1+(SELECT count(*) from tbl_exam_result a WHERE a.total > e.total and a.subjectCode = '"+result.subject.value+"'  and a.examCode ='"+result.examtype.value+"' and a.classID ='"+result.studentClass.value+"' ) as rank, s.admissionNumber, s.gender, s.registerNumber, s.studentName, s.termination, s.termination_date, s.termination_remark, e.examDate, e.maxMark, e.firstCA, e.absent, e.secondCA, e.exam, e.comment, e.total, e.subjectposition, e.grade, e.remark, e.code from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ='Studying' and e.subjectCode = '"+result.subject.value+"' and e.examCode ='"+result.examtype.value+"' and e.classID ='"+result.studentClass.value+"' and e.total >0  order by s.studentName ASC"

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
        
      var total = result.data.reduce((acumulator, item)=>acumulator + Number(item.total), 0)
      var avg = total/result.data.length
        var above = result.data.filter(item=>Number(item.total)>=avg)
        var below = result.data.filter(item=>Number(item.total)< avg)

setStat({
    average:FormatNumber(total/result.data.length),
    below:below.length,
    above:above.length,
    total:result.data.length
    
})




const stf = result.data[0].teacher;

if(stf.includes(staffCode)){
setAllResult(result.data.sort((a,b)=>parseFloat(a.total)>parseFloat(b.total)?-1:1))
structure.current.scrollIntoView({ behavior: "smooth" });
    
}else{
    setAllResult([])
    Alerts('Error!', 'danger', 'Only subject teacher can view scores ');
}

    }else{
        setAllResult([])
        setStat({
            average:0,
            below:0,
            above:0,
            total:0
            
        })
        Alerts('Info!', 'info', 'There are no result found for the selected subject')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}

const fetchResult =()=>{
    setNotice({...notice, isLoading: true}) 
    
const fd = new FormData();
fd.append("examtype", result.examtype.value);
fd.append('studentClass', result.sectionClass.value)
fd.append("orderBy", 'Full');
fd.append("subject", result.subject.value);

let url = API_URL+'/exam/result/subject_mark';
axios.post(url, fd, config).then(response=>{
    
    if(response.data.data.length!==0){
        
        let res = response.data.data
      var total = res.reduce((acumulator, item)=>acumulator + Number(item.total), 0)
      var avg = total/res.length
        var above = res.filter(item=>Number(item.total)>=avg)
        var below = res.filter(item=>Number(item.total)< avg)

setStat({
    average:FormatNumber(total/res.length),
    below:below.length,
    above:above.length,
    total:res.length
    
})

setAllResult(res.sort((a,b)=>parseFloat(a.total)>parseFloat(b.total)?-1:1))
//updateRank(result.data)
structure.current.scrollIntoView({ behavior: "smooth" });
    }else{
        setAllResult([])
        setStat({
            average:0,
            below:0,
            above:0,
            total:0
            
        })
        Alerts('Info!', 'info', 'There are no result found for the selected subject')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}







const handleRankName = ()=>{

    const newData = [...allResult]
    let newResult = newData.sort((a,b)=>b.studentName > a.studentName?-1:1)
    setAllResult(newResult)
}


useEffect(()=>{
    fetchExamType()
    
},[]);

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Subject Marks Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Exam Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Subject Marks</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">

                    <section className="col-md-6">
           <div className="form-group">
         <label> Student Section<span style={{color:'red'}}>*</span></label>
         <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectClass}  className={errors.sectionClass ? 'form-control form-control-danger' : ''} name="sectionClass" value={result.sectionClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.sectionClass && errors.sectionClass}</span>

            </div>
     
     </section>    

       <section className="col-md-6">
           <div className="form-group">
         <label> Student Class</label>
         <Select  options={
                            schoolClass&&schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname} 
							 })
							 
                        } 
onChange={handleSelectClass}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>    

     <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam, term: list.term }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>     	
     <section className="col-md-6">
           <div className="form-group">
         <label> Subject<span style={{color:'red'}}>*</span></label>
         <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={result.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	</div>
     
     </section>

				</div>	

	<footer className="pull-right">
	
	<button type="button"  onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	




{allResult.length!==0?

<div className="card z-depth-0">

    
{result.examtype.term ==='MOCK EXAM'?	<div className="card-block">
	
    {schoolInfo&&schoolInfo.map((st, id)=>
                   <div className="row" key={id}>

<table className="table  table-full-width">
   <tbody>
   <tr>
                <td className="text-center" >
                    
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}     />
</td>
            </tr>
        </tbody>
   </table>

</div> 
                   )}
<div className="row">
    <div className="col-md-12">
    <h5 style={{textAlign:'center'}}><u><b>SUBJECT MARK DETAILS </b></u></h5>
    </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

   <table className="table table-bordered  table-full-width">
   <tbody>
            <tr>
                <td >Class: <b>{result.sectionClass.length!==0?result.sectionClass.label:result.studentClass.label}</b></td>
                <td>Exam Type: <b> {result.examtype.label} Examination</b></td>
                <td>Subject: <b> {result.subject.label}</b></td>
            </tr>
        </tbody>
   </table>
    <table className="table table-striped table-bordered  table-full-width">


        <thead>
            <tr>
            <th>SN</th>
                <th onClick={handleRankName} style={{ cursor:'pointer' }}>Student Details <i className="fa fa-caret-down" ></i></th>
                <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Exam (100)</th>
                <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
                <th>Max</th>
                {/* <th onClick={handleRank} style={{ cursor:'pointer' }}>Rank <i className="fa fa-caret-down" ></i></th> */}
                <th>Grade</th>
                <th>Remark</th>
                <th>Comment</th>
            </tr>
        </thead>
        <tbody>{allResult.length!==0?allResult.map((row, idx)=>row.absent==='No' && Number(row.total) >0?  
            <tr key={idx}>
                <td>{idx+1}</td>
            <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.admissionNumber} - {row.studentName} <br/>

            {row.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Terminated on {longDate(row.termination_date)} {row.termination_remark}</span>:''}</td>

                <td>{row.exam}</td>
                <td>{row.total}</td>
                <td>{row.maxMark}</td>
                
               {/*  <td >{row.rank}<sup>{getRank(row.rank)}</sup></td> */}
                <td>{row.grade}</td>
                <td style={{maxWidth:'400px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.remark}</td>
<td style={{maxWidth:'400px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.comment}</td>
            </tr>:[]):[]}
        </tbody> 
    </table>
</div>	 </div> 

<div ref={structure} className="row no-print">
    <div className="col-md-12">
   

   
    <form method='POST' action={ServerUrl+'/printout/subject_marks.php?token='+userToken} target='_blank' className='pull-right' >
            <input type='hidden' name='examCode' value ={result.examtype.value} />
            <input type='hidden' name='jwtToken' value ={Token} />
            
            <input type='hidden' name='term' value ={result.examtype.term} />
            <input type='hidden' name='subject' value ={result.subject.value} />
            <input type='hidden' name='classID' value ={result.sectionClass.length!==0?result.sectionClass.classID:result.studentClass.value} />
        <input type='hidden' name='sectionID' value ={result.sectionClass.length!==0?result.sectionClass.value:result.studentClass.value} />
        
        <input type='hidden' name='classresult' value ={JSON.stringify(allResult, null, 2)} />

            <input type='hidden' name='stat' value ={JSON.stringify(stat, null, 2)} />
           {/*  <button type="button" className="btn btn-primary   " onClick={submitRank} > <i className="fa fa-save"></i> Update Rank</button>&nbsp;
 */}
            <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
    
    </form>
    </div></div>

</div>:

		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}    />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>SUBJECT MARK DETAILS </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{result.sectionClass.length!==0?result.sectionClass.label:result.studentClass.label}</b></td>
                    <td colSpan={2}>Exam Type: <b> {result.examtype.label} Examination</b></td>
                    <td>Subject: <b> {result.subject.label}</b></td>
                </tr>
                <tr>
                <td >Total Student: <b>{stat.total}</b></td>
                    <td >Average: <b> {stat.average}</b></td>
               
                    <td >On & Above Average: <b> {stat.above}</b></td>
                    <td>Below Average: <b> {stat.below}</b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                    <th onClick={handleRankName} style={{ cursor:'pointer' }}>Student Details <i className="fa fa-caret-down" ></i></th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1st CAT (20%)</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2nd CAT (20%)</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Exam (60%)</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
                    <th>Max</th>
                   {/*  <th onClick={handleRank} style={{ cursor:'pointer' }}>Rank <i className="fa fa-caret-down" ></i></th> */}
                    <th>Grade</th>
                    <th>Remark</th>
                    <th>Comment</th>

                </tr>
            </thead>
            <tbody>{allResult.length!==0?allResult.map((row, idx)=> row.absent==='No' && Number(row.total) >0?
                <tr key={idx}>
                    <td>{idx+1}</td>
                <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.admissionNumber} - {row.studentName} <br/>

                {row.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Suspended on {longDate(row.termination_date)} {row.termination_remark}</span>:''}</td>

                    <td>{row.firstCA}</td>
                    <td>{row.secondCA}</td>
                    <td>{row.exam}</td>
                    <td>{row.total}</td>
                    <td>{row.maxMark}</td>
                 {/*    <td>{row.rank}<sup>{getRank(row.rank)}</sup></td> */}
                    <td>{row.grade}</td>
                 
                <td style={{maxWidth:'400px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.remark}</td>
<td style={{maxWidth:'400px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.comment}</td>
                </tr>:[]):[]}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/subject_marks.php?token='+userToken} target='_blank' className='pull-right' >
        <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                
            <input type='hidden' name='term' value ={result.examtype.term} />
                <input type='hidden' name='subject' value ={result.subject.value} />
                <input type='hidden' name='classID' value ={result.sectionClass.length!==0?result.sectionClass.classID:result.studentClass.value} />
        <input type='hidden' name='sectionID' value ={result.sectionClass.length!==0?result.sectionClass.value:result.studentClass.value} />
                <input type='hidden' name='stat' value ={JSON.stringify(stat, null, 2)} />
                
        <input type='hidden' name='classresult' value ={JSON.stringify(allResult, null, 2)} />
                <input type='hidden' name='allResult' value ={JSON.stringify(allResult, null, 2)} />
               {/*  <button type="button" className="btn btn-primary   " onClick={submitRank} > <i className="fa fa-save"></i> Update Rank</button>&nbsp;
 */}
                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>}
        </div>:''}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ExamResult) 