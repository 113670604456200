import { combineReducers } from "redux";
import counterReducer from "./counter";
import { rootReducer } from "./fetchRecords";
import isLoggedReducer from "./isLogged";


const allReducers = combineReducers({
    rootReducer:rootReducer
})

export default allReducers;