import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  config, staffName} from '../component/include'


const BulkRegistration =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    const [position, setPosition]=useState([])
    const [department, setDepartment]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [assetList, setAssetList] = useState([]);



    const Initials = 
    { 
        code:'',
        assetName:'',
		category:'',
		assetType:'',
		department:'',
		purchaseDate:'',
        purchaseAmount:'0'	 
    }

    

const fetchDepartment =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
	let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1';
	axios.post(url, fd, config)
	.then(result =>setDepartment(result.data))
}





       const  GenerateRow =()=>{  
                var value = [];
                for (var i = 1; i <= 10; i++) {
                  value.push({...Initials,  code:Math.random().toString(36).substr(2, 9)});
                }      
                setAssetList(value)

            } 


       const createNewRow =()=>{
        setAssetList(assetList.concat({...Initials, code:Math.random().toString(36).substr(2, 9)}))
        setErrors({...errors, assetList:''})

    }

    const removeRow=(index)=>{
        setAssetList(assetList.filter((_, i) => i !== index)); 
       }


      
    const handleChangeAsset = (e, index)=>{
        const std =JSON.parse(JSON.stringify(assetList))   
            const value =e.target.value;
           std[index][e.target.name] = value;
        setAssetList(std);
    }

    

    const handleSubmit = event =>{
		event.preventDefault();
		setErrors(validateInput());
		setIsSubmitting(true);
        window.scrollTo(0,0);
	} 
	
const validateInput =()=>{
	let errors = {};   
    if(assetList.length===0){
        errors.assetList ='Please register at least one asset';
    }
	
    return errors;
	}
	




useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
       submit()
    }
    },[errors])
	
    
    const  submit=()=>{  
                
        var options = assetList;

        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if(options[i].assetName!==''){
                 value.push({
            
            code:options[i].code, 
            assetName:options[i].assetName,
            category:options[i].category,
            assetType:options[i].assetType,
            department:options[i].department,
            purchaseAmount:options[i].purchaseAmount,
            purchaseDate:options[i].purchaseDate,
            createBy:staffName
        })
            }
       
    }

    if(value.length!==0){

        setNotice({...notice, isLoading: true})   

        
const fd = new FormData();
fd.append('jwt', Token)    
fd.append('assetList',  JSON.stringify(value, null, 2))

     let url = ServerUrl+'/save_controller/tbl_assets_bulk_registration' 
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Success', 'success', response.data.message)
                            }else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                    }   
            })
            .catch((error)=>{
             setErrors({...errors, errorMessage: JSON.stringify(error.message)})
            }).finally(()=>{
                setIsSubmitting(false)
                GenerateRow()
        setNotice({...notice,  isLoading: false}) 
    }) 

}else{
    setErrors({...errors, errorMessage: 'Please fill at least one row complete before you can submit'})
    window.scrollTo(0,0);
}
     }    



useEffect(()=>{
    GenerateRow()
    fetchDepartment()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                
                   {/* <!-- Page-header start --> */}

                   <PageHeader title="Asset Bulk Registration">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="/assets/view_all">Assets</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Bulk Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

<div  className="row  ">   
<div className="well">You can use this page to add bulk registration. Please be careful that you select the right Category, Department and Condition. You can enter as many rows as you like by clicking on Add more.</div>
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Register Asset</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            

{assetList.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    <th>Asset Name</th>
                    <th>Condition</th>
                    <th>Category</th>  
                    <th>Department</th> 
                    <th>Purchase Date</th> 
                    <th>Amount</th> 
                    
    <th ><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>


                {assetList&&assetList.map((pl, id)=>
            <tr key={id}>
                
                    <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>
                    <td ><input type="text"  className="form-control" name='assetName' value={pl.assetName}  onChange={(e)=>handleChangeAsset(e, id)} /></td>

                    <td>
                        <select onChange={(e)=>handleChangeAsset(e, id)}  name="assetType"  value={pl.assetType} className="form-control">

                        <option value="">Select</option>
                        <option  value="New" >New</option> 
                        <option  value="Old" >Old</option>
                        <option  value="Used" >Used</option> 
                        <option  value="Out for Repair" >Out for Repair</option>
                        <option  value="Lost / Stolen" >Lost / Stolen</option> 
                        <option  value="Broken - Not Repairable" >Broken - Not Repairable</option>
                        
                        <option  value="Damage - awaiting Service" >Damage - awaiting Service</option> 
                            </select>
    
                      </td>
                   

                      <td>
                          
                      <select onChange={(e)=>handleChangeAsset(e, id)}  name="category"  value={pl.category} className="form-control">

                        <option value="">Select</option>
                        <option  value="Funitures & Fittings" >Funitures & Fittings</option> 
                        <option  value="Office Equipment" >Office Equipment</option>
                        <option  value="Computers" >Computers</option> 
                        <option  value="Automobile" >Automobile</option>
                        <option  value="Others" >Others</option> 
                            </select>
                      
                      </td>


                      <td>
                          
                          <select onChange={(e)=>handleChangeAsset(e, id)}  name="department"  value={pl.department} className="form-control">
    
    <option value="">Select</option>
    { department&&department.map((list, idx)=> {
               return  <option key={idx} value={list.code} >{list.departmentName} </option> })}
        </select>
                          
                          </td>
    
                          <td><input type="date"  className="form-control" name='purchaseDate' value={pl.purchaseDate}  onChange={(e)=>handleChangeAsset(e, id)} /></td>


                      <td><input type="number" pattern="\d*" className="form-control" name='purchaseAmount' value={pl.purchaseAmount}  onChange={(e)=>handleChangeAsset(e, id)} /></td>
                   
                    <td > <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan='9'>

                    
                <div className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
        </table>

        </div>
        </div>
        </div>
</div>:''}


     

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BulkRegistration) 