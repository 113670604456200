import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import { ImagesUrl, ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'
import html2canvas from 'html2canvas'
import { useDispatch, useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { formatGroupLabel, getTime } from '../component/globalFunction'
import { getDateTime } from '../component/globalFunction'

const BiometricOperation =()=> {

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
const [machine, setMachine] = useState({
    sectionID:[],
    staff:'',
    operation:[],
    device:[],
    activeUser:0

})
const [device, setDevice] = useState([])
const [content, setContent] = useState([])


  

    const handleSelect = (option, action) =>{
        setMachine({...machine, [action.name]: option});
        setContent([])
      setErrors({...errors, [action.name]:''}); 
   }
   
   
    const fetchDevice=()=>{
         const fd = new FormData();
        fd.append('jwt', Token); 
        let sql = "Select ID, code,	machine_name,	model_no,	expiry_date, service_tag_id,	management_api,	auth_token,	encryption_key,	callback_url,	user_type,	purchase_date FROM tbl_biometric_device"

        let url = ServerUrl+'/fetchBySql_controller'      
        fd.append("sql", sql)
        axios.post(url, fd, config).then(result =>setDevice(result.data)   
    ).catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }) 
    }


    const configNext = {
		headers: {
			'content-type': 'text/plain'
		}
	}

const handleAddUser =(row)=>{

    setNotice({...notice,  isLoading: true}) 

    const fd = {
        "Add":{
            "User":{
                "UserID":row.ID,
                "FirstName":row.firstName,
                "LastName":row.lastName,
                "UserType":"Staff"
            }
        },
        "OperationID": "",
        "AuthToken": machine.device.auth_token,
        "Time": getDateTime()
        
    }


       let url = machine.device.management_api+'?stgid='+machine.device.service_tag_id

      axios.post(url, fd, configNext)
	  .then(response =>{

        if(response.data.Status ==='done'){
            submit(row, 'Yes')
           // Alerts('Saved!', 'success', response.data.message)
                }
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }) 

}




const handleUpdateUser =(row)=>{

    setNotice({...notice,  isLoading: true}) 

    const fd = {

        "RealTime": {
            "UserUpdated": {
                "UserID":row.ID,
                "FirstName":row.firstName,
                "LastName":row.lastName,
                "UserType":"Staff",
                 "OperationTime": getDateTime(),
                 "Template": []
            },
        "OperationID": "",
        "AuthToken": machine.device.auth_token,
        "Time": getDateTime()
        }
    }


       let url = machine.device.management_api+'?stgid='+machine.device.service_tag_id

      axios.post(url, fd, configNext)
	  .then(response =>{

        if(response.data.Status ==='done'){
           Alerts('Saved!', 'success', response.data.message)
                }
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }) 

}


const handleDeleteAll =()=>{

    if(machine.activeUser<1){
        Alerts('danger','There are no active user to delete ')
      }else{
    
        let employeeList =[];
        let selectedEmployee = content.filter((item)=>item.is_biometric ==='Yes')
    
        if(Array.isArray(selectedEmployee)){
          selectedEmployee.forEach((list) => {
              employeeList.push(list.code)
          });
        }

    setNotice({...notice, isLoading: true}) 
    let close =   document.getElementById('btnWarningDialog-cell')
       close.click();

       
       const fd = {
        "Delete":{
            "User": "All"
        },
        "OperationID": "",
        "AuthToken": machine.device.auth_token,
        "Time": getDateTime()
        
    }


       let url = machine.device.management_api+'?stgid='+machine.device.service_tag_id

      axios.post(url, fd, configNext)
	  .then(response =>{

        if(response.data.Status ==='done'){

            let table = 'tbl_staff'
            if(machine.device.user_type==='Student'){
              table = 'tbl_students'
            }

            const fds = new FormData();
            fds.append('employeeList', JSON.stringify(employeeList, null, 2));
            fds.append('tableName', table);

            let urls = ServerUrl+'/update_controller/tbl_biometric_device'
            axios.post(urls, fds, config)
            .then(response =>{
                if (response.data.type ==='success'){
                    Alerts('Saved!', 'info', response.data.message)
                }
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })


                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false}) 
        }) 

    }

}

const handleDeleteUser =(row)=>{

    setNotice({...notice,  isLoading: true}) 
    let close =   document.getElementById('btnWarningDialog-'+row.code)
    close.click();
    const fd = {
        "Delete":{
            "User":{
                "UserID":row.ID
            }
        },
        "OperationID": "",
        "AuthToken": machine.device.auth_token,
        "Time": getDateTime()
        
    }


       let url = machine.device.management_api+'?stgid='+machine.device.service_tag_id

      axios.post(url, fd, configNext)
	  .then(response =>{

        if(response.data.Status ==='done'){
            submit(row, 'No')
           // Alerts('Saved!', 'success', response.data.message)
                }
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }) 

}
const getImageSize =(base64String)=>{
   
    var stringLength = base64String.length - 'data:image/png;base64,'.length;
    
    var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
    var sizeInKb=sizeInBytes/1000;

    return sizeInKb;
}
const getBase64StringFromDataURL = (dataURL) =>dataURL.replace('data:', '').replace(/^.+,/, '');

const handleAddUserPhoto =(row)=>{

    setNotice({...notice,  isLoading: true}) 


    const element =  document.getElementById(row.code);
        html2canvas(element).then((canvas) => {
          const base64image = canvas.toDataURL("image/png", 1.0);
          const base64 = getBase64StringFromDataURL(base64image);
         

    const fd = {

       
        "Add": {
            "Photo": {
                 "Type": "Base64",
                    "UserId":row.ID,
                    "Size":getImageSize(base64image),
                    "Data":base64,
                }
          },
        "OperationID": "",
        "AuthToken": machine.device.auth_token,
        "Time": getDateTime()
        
    }

       let url = machine.device.management_api+'?stgid='+machine.device.service_tag_id

      axios.post(url, fd, configNext)
	  .then(response =>{

        if(response.data.Status ==='done'){
           Alerts('Saved!', 'success', response.data.message)
                }
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }) 
        .finally(()=>{
            setNotice({...notice, 
                isLoading: false
            })
        }) 
    })
}


    const fetchContent=()=>{
        setNotice({...notice, 
            isLoading: true}) 
        const fd = new FormData();
       fd.append('jwt', Token); 
      
       let sql ='';

       if(machine.device.user_type==='Staff'){

         sql = "SELECT  SUBSTRING_INDEX(staffName, ' ', 1) as firstName,  SUBSTRING_INDEX(staffName, ' ', -1) as lastName, gender, passport, code, ID, is_biometric from tbl_staff where employmentStatus = 'Working'"

       }else{

         sql = "SELECT  SUBSTRING_INDEX(studentName, ' ', 1) as firstName,  SUBSTRING_INDEX(studentName, ' ', -1) as lastName, gender, passport, code, ID, is_biometric from tbl_students where admissionStatus = 'Studying'"

      
        if(machine.sectionID.length!==0 ){
        sql = sql + '  and sectionID = "'+machine.sectionID.value+'" '
            } 
       }




       let url = ServerUrl+'/fetchBySql_controller'      
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>{

        setContent(result.data)   
        let user = result.data.filter(item=>item.is_biometric==='Yes')
        setMachine({...machine, activeUser:user.length})
       }
   ).catch((error)=>{
       Alerts('Error!', 'danger', error.message)
       }) 
       .finally(()=>{
           setNotice({...notice, 
               isLoading: false
           })
       })
   }


 const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(machine));
        setIsSubmitting(true);

    } 


        const ValidateInput=(values)=>{
            let errors = {}; 
            let msg = 'This field is required';

            if(values.device.length===0){
                errors.device =msg;
            }
            if(values.operation.length===0){
                errors.operation =msg;
            }


            return errors;
            }


function submit(row, status){  
      
    const fd = new FormData();
    fd.append('jwt', Token); 
    fd.append('columnField', status);
    fd.append('column', 'is_biometric');
    fd.append('ID', row.ID);

    let url = ServerUrl+'/updateById_controller/tbl_staff'
    if(machine.device.user_type==='Student'){
      url = ServerUrl+'/updateById_controller/tbl_students'
    }

	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success'){
            setContent(content.filter(item=>item.ID !==row.ID))
            Alerts('Saved!', 'success', 'Successful');
                }
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
        })
}


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
        fetchContent()
    }
    },[errors])


    
    const tableHeader = [

        {dataField: 'passport', text: 'Photo', editable: false, formatter:(cell, row)=>
        
        machine.device.user_type==='Student'?
        <img style={{ height:'70px' }} id={row.code} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+row.gender+".png"}} src={ImagesUrl+'/students/'+cell} title={row.studentName}  alt={row.studentName} />:
        
        <img className="img-70" id={row.code} style={{ height:'70px', width:'70px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+row.gender+".png"}} src={ImagesUrl+'/staff/'+cell} title={row.firstName}  alt={row.firstName} />, editable:false},
        
        {dataField: 'firstName',  text: 'First Name',  sort: true, editable:false, formatter:(cell)=>cell},
        {dataField: 'lastName',  text: 'Last Name',  sort: true, editable:false, formatter:(cell)=>cell},
        {dataField: 'code', text: 'Action', editable:false, formatter: (cell, row)=><div className='row ml-2'>

            
         <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDeleteUser(row)} mID={row.code} /> 

         {/* 
         {row.is_biometric==='Yes'? 

<button type='button' onClick={()=>handleAddUserPhoto(row)} className='btn btn-sm btn-primary mr-2'><i className='fa fa-image'></i> Add Photo</button>:''}  */}


{row.is_biometric==='Yes'? 

<button type='button' onClick={()=>handleUpdateUser(row)} className='btn btn-sm btn-inverse mr-2'><i className='fa fa-user'></i> Update User</button>:''} 


         {row.is_biometric==='Yes'? <button type='button' data-toggle="modal"   data-target={'#warningdialog-'+cell}    className='btn btn-danger delete btn-sm' title="Delete"><i className="icofont icofont-delete-alt"></i>Delete User</button>:

            <button type='button' onClick={()=>handleAddUser(row)} className='btn btn-sm btn-success'><i className='fa fa-plus'></i> Add User</button>}
        </div>},       
     ];

     
    const TableRecord=(props)=>{
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token); 
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
          
            let url = ServerUrl+'/updateById_controller/tbl_biometric_device'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                           
                               
                                 
                          
      <div className="dt-buttons btn-group pull-right"> 
      <button type='button' data-toggle="modal"   data-target={'#warningdialog-cell'} className="btn btn-outline-danger" >
            <i className="fa fa-trash"></i>Delete All User ({machine.activeUser}) </button>	

            </div>
            </div>
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
     
useEffect(()=>{
  fetchDevice()
},[]);

        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={handleDeleteAll} mID={'cell'} /> 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Biometric Operations">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Biometric Operations</a> </li>
                    </PageHeader>


{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						   <div className="col-md-12">
                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Search User</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
									
			<section className="col-md-6">

             <div className="form-group">
          <label> Select Device <span style={{color:'red'}}>*</span></label>
          <Select  options={device&&device.map((list, id)=>{
             return {key:id, value:list.code, label:list.machine_name, model_no:list.model_no, service_tag_id:list.service_tag_id, auth_token:list.auth_token, management_api:list.management_api, user_type:list.user_type}
         })} 
         getOptionLabel={option =>option.label + ' =>'+ option.model_no+" - "+option.user_type}  
 onChange={handleSelect} 
 className={errors.device ? 'form-control form-control-danger' : ''} 
 name="device" value={machine.device}  /> 
 <span style={{color:'red'}}>{errors.device && errors.device}</span>
             </div>


        
			
            </section>

            <section className="col-md-6">
            <div className="form-group">
          <label> Choose API Operation<span style={{color:'red'}}>*</span></label>
          <Select  options={[
                     { value: 'AddUser', label: 'Add a user'},
                     { value: 'DeleteUser', label: 'Delete a user'},
              { value: 'UpdatePassword', label: 'Update a user password'},
              { value: 'DisplayUser', label: 'Load all user details'},
                 ]} 
 onChange={handleSelect} 
 className={errors.operation? 'form-control form-control-danger' : ''} 
 name="operation" value={machine.operation}  /> 
 <span style={{color:'red'}}>{errors.operation && errors.operation}</span>
             </div>



             {machine.device.user_type==='Student'?
             <div className="form-group">
          <label> Student Class<span style={{color:'red'}}>*</span></label>
          <Select  options={
                             schoolClass&&schoolClass.map((list, idx)=> {
                                return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                         } 
                         formatGroupLabel={formatGroupLabel}
 onChange={handleSelect} className={errors.sectionID ? 'form-control form-control-danger' : ''} name="sectionID" value={machine.sectionID}  /> <span style={{color:'red'}}>{errors.sectionID && errors.sectionID}</span>
             </div>:[]}



            </section>
				
                </div>	

<hr/>
	<footer className="pull-right">
	

	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary  btn-round">Display User</button>
	
								</footer>				
							</div>
							</div>


                            </div>
		
	{content.length!==0?

	<div className="col-md-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Biometric Device</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

      {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={content}   columns={tableHeader}  />}  

    </div></div>
</div>


</div>:[]}

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BiometricOperation) 