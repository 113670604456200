import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
import { BrowserRouter as Router, useLocation} from 'react-router-dom'
const LogOut =()=> {

    const clearCookies=()=>{
        
         Cookies.remove('kayus_code')	
        Cookies.remove('kayus_token')

      Cookies.remove('tkarfsh')
      Cookies.remove('tkstnm')
      Cookies.remove('clkysd')
      Cookies.remove('sckayd')

      Cookies.remove('tkrspw')
      Cookies.remove('portal')
      Cookies.remove('kayus_ed')
      Cookies.remove('kayus_dt')
      
      localStorage.setItem('kayus_link', [])
      localStorage.setItem('_expt', Date.now())
      localStorage.setItem('_bexpt', Date.now())

    }



 useEffect(()=>{  
  clearCookies()
  var queryString = new URLSearchParams(window.location.search).get("refferer")

  /* if(queryString!==null){
    window.open("/?refferer="+queryString, '_self')
    }else{
    window.open('/', '_self')
    }  */
    window.open('/', '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut