import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, config} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


const VideoList =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [errors, setErrors] = useState({});
    const [gallery, setGallery]= useState({
        title:'',
        videoUrl:'',
        status:true
    })
    const [allGallery, setAllGallery]= useState([])
    const handleChange=(event)=>{
      const {name, value} = event.target;
        setGallery({...gallery, [name]:value});
        setErrors({...errors, [name]:''})
    }


    
    const handleSubmit = event =>{
        event.preventDefault();    
    if(!gallery.videoUrl){
        setErrors({...errors, title:'Please enter video url'})
    }else{
        let formData = gallery;
        const fd = new FormData(); 
        for(let k in formData){
            fd.append(k,formData[k]);
        }; 
        setNotice({...notice,  isLoading: true}) 
        fd.append('display_order', allGallery.length+1);
        fd.append('code', Math.random().toString(36).substring(2,9));
        fd.append('Active', gallery.status===true?'Active':'Inactive');
           let url = API_URL+'/video/add';
          axios.post(url, fd, config)
          .then(response =>{
            window.scrollTo(0,0)
            if (response.data.status ===200){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                }        
                  
          })
          .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        })
        .finally(()=>{
            fetchGallery()
            setNotice({...notice,  isLoading: false}) 
        setGallery({
            title:'',
            videoUrl:'',
        status:true
        }) 
    })  
    }      

    } 


    const fetchGallery =()=>{
        setNotice({...notice,   isDataFetching: true}) 
            let url = API_URL+'/video/display/all'
            axios.get(url, config).then(result=>{
                if(result.data.data!==0){
                    setAllGallery(result.data.data)
                }
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice,   isDataFetching: false})
            })
    }

   
    const TableRecord =(props)=>{
	   
     
  const handleUpdate=(column, newValue, code)=>{       
    const fd = new FormData();
    fd.append('newValue', newValue);
    fd.append('column', column.dataField);
    fd.append('code', code);
    let url = API_URL+'/video/change_field'
    axios.post(url, fd, config)
              .then(result => {
                fetchGallery()
            })
            
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 
            const { SearchBar } = Search;
        
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                    <div >
                                <div className="form-group">
        
                            <SearchBar
                                         { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                                         
           
           
            </div>
        
            <BootstrapTable
                                     { ...props.baseProps }
                                                 striped
                                             hover
                                             condensed
                                             noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                         pagination={paginationFactory(options) }
                                         cellEdit={ cellEditFactory({
                                           mode: 'dbclick',
                                           blurToSave: true,
                                           autoSelectText: true,
                                 beforeSaveCell: (oldValue, newValue, row, column) => {
                                   if(oldValue !==newValue){
                                   handleUpdate(column, newValue, row.code);
                                   
                                  return true;
                                 }
                                 
                               }
                                         }) }
                                     />
                    </div>
                    )
                            }
        </ToolkitProvider>
        }
        


     
        const handleDelete =(code)=>{
            let close =   document.getElementById('btnWarningDialog-'+code)
            close.click();
           
            setNotice({...notice, 
                isLoading: true}) 
          
          let url = API_URL+'/video/delete/'+code;
              axios.get(url, config)
              .then(response =>{
               if(response.data.status ===200){
                  Alerts('Saved!', 'default', response.data.message)
                      } else{
                  Alerts('Error!', 'danger', JSON.stringify(response.data))
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                fetchGallery()
                  setNotice({...notice, 
                      isLoading: false
                  })
              }) 
          }



    
   
    const handleAction = (cell, row)=>{
        return <React.Fragment>     
        
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} className='btn btn-danger btn-sm'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>
        
        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 
        </React.Fragment>  
    }
    
               
    const tableHeader = [
    {dataField: 'id', text: 'Action', formatter:handleAction, editable: false},
    {dataField: 'code', text: 'Preview', editable: false, formatter:(cell, row)=><iframe width="200" height="100" src={row.video_url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>},
    {dataField: 'video_url', text: 'Video', sort:true, editor: {
        type: Type.TEXTAREA
      }, formatter:(cell, row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} >{cell}</div>},
      {dataField: 'title', text: 'Title', sort:true},
      {dataField: 'display_order', text: 'Order', sort:true},
    {dataField: 'status', text: 'Status', sort:true, editor: {
        type: Type.SELECT, options:[
            {value:'Active', label:'Active'},
            {value:'Inactive', label:'Inactive'}
        ]
      }, formatter:(cell, row)=>row.status==='Active'? <div className="pcoded-badge label label-success">ACTIVE</div>: <div className="pcoded-badge label label-danger">INACTIVE</div>},
      {dataField: 'featured', text: 'Feature', sort:true, editor: {
        type: Type.SELECT, options:[
            {value:'Yes', label:'Yes'},
            {value:'No', label:'No'}
        ]
      }, formatter:(cell, row)=>row.featured},
     ];

useEffect(()=>{
    fetchGallery();
},[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
               
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Videos">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-image"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Gallery</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Videos</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

         
                <div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Add Videos</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-12">
   
   <div className="form-group">
<label className='text-bold'>Title</label>

<input type="text" name="title" onChange={handleChange} 
value={gallery.title} className={errors.title? 'form-control form-control-danger' : 'form-control'} /> 

<span style={{color:'red'}}>{errors.title&& errors.title}</span>
   </div>

</section>

<section className="col-md-12">
   

   <div className="form-group">
<label className='text-bold' >Paste Video Link Here</label>
<textarea type="text" rows="3" className={errors.videoUrl ? 'form-control form-control-danger' : 'form-control'} name="videoUrl"  onChange={handleChange} value={gallery.videoUrl} >{gallery.videoUrl}</textarea>
<span style={{color:'red'}}>{errors.videoUrl && errors.videoUrl}</span>
   </div>

</section>


<section className="col-md-12">
    <label>Status</label>
                                        
        
<div className="border-checkbox-section">
                    
            <div className="border-checkbox-group border-checkbox-group-primary">
                <input className="border-checkbox" type="checkbox" onChange={()=>setGallery({...gallery, status: !gallery.status})} name="status" value={gallery.status} checked={gallery.status} id="status" />
                        <label className="border-checkbox-label" htmlFor="status">Active</label>
                    </div>
                    
                    </div> 

                    </section>

</div>

<hr/>

   <footer className="pull-right">
   
   <button type="button" className="btn btn-inverse " ><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
   <button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success "><i className="fa fa-save"></i> Save</button>
            </footer>
 </div> 
							
</div>

</div>  
</div>  </div>
							
           </div>
                            
       </div></div>


                <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Videos List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="col-md-12">
        <div className="table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allGallery}  columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(VideoList) 