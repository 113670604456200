import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import {LoadEffect, TableLoader } from '../component/loader'
import {Alerts} from '../component/notify'
import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

 const Users =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [search, setSearch]= useState({
        email:'',
        isActive:'all'
    })
    
    const [content, setContent] = useState([]);
    const SearchTable =(event)=>{
        event.preventDefault();
        fetchContent();
     }

    


    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select u.code, u.email, u.fullname,  t.groupName, u.ID, u.isActive from tbl_users u, tbl_usertype t where u.groupId = t.code " ;

        if(search.email.length!==0){
            sql = sql + ' and u.email = "'+search.email+'"'
        }
        if(search.isActive!=='all'){
            sql = sql + '  and u.isActive = "'+search.isActive+'"'
        }
          
        const fd = new FormData();
    fd.append("jwt", Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

   

    const tableHeader = [
        {dataField: 'code',  text: '#', editable: false,  formatter:(cell, row, index)=>index+1},
    {dataField: 'fullname', text: 'Fullname', formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
    {dataField: 'email', text: 'Email', formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
    {dataField: 'groupName', text: 'Group', editable: false},
    {dataField: 'isActive', text: 'Active', sort: true, editor: {
        type: Type.SELECT, options:[{value:'true', label:'Active'},
        {value:'false', label:'Inactive'}]
      }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check  badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/people/user/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false}
     ];
    
    
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
    fd.append("jwt", Token); 
                fd.append('columnField', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
              
               let url = ServerUrl+'/updateById_controller/tbl_users'
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>console.log(error)) 
              
            } 
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
    <a href="/people/user/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>


 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.ID);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      





     useEffect(()=>{
        fetchContent()
       },[]);

        return (<>
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Users"
	   				menu="Users"
					   />


{/* The Page Body start here */}
<div className="page-body">

<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <label className='pull-right'>Email Address </label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="email" value={search.email} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <label className='pull-right'>Is Active </label>
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="isActive" value={search.isActive} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Activated</option>
                        <option value="false">Deactivated</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i>Administrator </h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    
    </div></div></div>
			
							</div>
	                   
                            </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 

 );
        
}

export default React.memo(Users) 