import React from 'react'

import { Store } from 'react-notifications-component';

export const  Alerts =(title, type, content)=>{
  // 'default', 'success', 'info', 'warning', danger
  Store.addNotification({
    message: content,
    type: type,
    title: title,
    insert: "top",
    container: "top-full",
    animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  
    dismiss: {
      duration: 5000,
      onScreen: false,
      pauseOnHover: true
    }
  })
  
}


const allDocument = ["PHP"]



export const InfoModal = (props) =>{
  return      <div className="modal fade " id={`infoDialog-${props.mID}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title"><i className='fa fa-info fa-2x'></i> Wait!</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
                <p>{props.title}</p>
              </div>
              <div className="modal-footer">
                  <button type="button"  id={`btnInfoDialog-${props.mID}`} className="btn btn-default waves-effect " data-dismiss="modal">No, Cancel</button>
                  <button type="button" onClick={props.handleConfirm} className="btn btn-outline-warning waves-effect">Yes I Am</button>
              </div>
          </div>
      </div>
  </div>
}

export const WarningModal = props =>{
  return      <div className="modal fade " id={`warningdialog-${props.mID}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
              <div className="modal-body">
                <div className="text-center"><i className="fa fa-warning fa-5x text-warning"></i>
                  <h5>Hold On!</h5>
                <p>{props.message}</p>
              </div></div>
              <div className="modal-footer">
                  <button type="button"  id={`btnWarningDialog-${props.mID}`} className="btn btn-inverse waves-effect " data-dismiss="modal">No, Thanks</button>
                  <button type="button" onClick={props.handleAction} className="btn btn-outline-warning waves-effect">Yes I Am</button>
              </div>
          </div>
      </div>
  </div>
}

export const SessionTimeout = (props) =>{
  return      <div className="modal fade " id='timeoutModal' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
        <div className="modal-header">                                       
          <h4 className="modal-title">Your Session is About to Expire!</h4>        
          <button type="button" className="close" id='btnLogoutsession' data-dismiss="modal" aria-hidden="true">×</button>             
        </div> 

              <div className="modal-body">
               <p>The system will log you out in the next 60s. Do you want to stay connected?</p> 
              </div>

              <div className="modal-footer">
                  <button type="button" onClick={props.Logout} className="btn btn-default " >Logout</button>
                  <button type="button" onClick={props.Connected} className="btn btn-primary">Stay Connected</button>
              </div>
          </div>
      </div>
  </div>
}


export const LicenseTimeout = () =>{
  return      <div className="modal fade " id='licenseModal' data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              
        <div className="modal-header">                                       
          <h4 className="modal-title">Please read and accept our policies</h4>        
                    
        </div> 

              <div className="modal-body">
               <h6> 
                <b>Dear Parent,</b><br/><br/>
                Thanks for choosing Aduvie and welcome to the Aduvie Portal, our comprehensive Student Information Management System.<br/><br/><br/>

To ensure the quality of our service and continue using this system, we require that you study the following school policies and click on the check box to confirm that you and your child/ren agree in totality to the contents of the documents.
<br/><br/><br/>




</h6> 



              </div>
          </div>
      </div>
  </div>
}





 
export default React.memo(InfoModal)