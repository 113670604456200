import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  config, Currency, FormatNumber, staffCode, API_URL} from '../component/include'


const BulkExpenses =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    
    const [account, setAccount] = useState([])
    const [expensesHead, setExpensesHead]=useState([])
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [expensesList, setExpensesList]= useState([])

    const Initials = 
    { 
        code:'',
        expensesHead:'',
        account:'',
        amount:0,
        expensesDate:'0000-00-00',
        remark:'',
        staffCode:staffCode	 
    }

    const fetchAccount=()=>{
      
        setNotice({...notice, isLoading: true}) 
        let url = API_URL+'/finance/account/balance'
        axios.get(url,  config).then(result=> setAccount(result.data.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
        setNotice({...notice, isDataFetching: false})

        })  
    }

  
  
  
  
      const fetchExpensesHead =()=>{
        const fd = new FormData();
            fd.append('jwt', Token);
          let url = ServerUrl+'/fetch_controller/tbl_expenses_head'
          axios.post(url, fd, config).then(result=>setExpensesHead(result.data))
      }
  




       const  GenerateRow =()=>{  
                var value = [];
                for (var i = 1; i <= 10; i++) {
                  value.push({...Initials,  code:Math.random().toString(36).substr(2, 9)});
                }      
                setExpensesList(value)

            } 


       const createNewRow =()=>{
        setExpensesList(expensesList.concat({...Initials, code:Math.random().toString(36).substr(2, 9)}))
        setErrors({...errors, expensesList:'', errorMessage:''})

    }

    const removeRow=(index)=>{
        setExpensesList(expensesList.filter((_, i) => i !== index)); 
       }


      
    const handleChangeExpenses = (e, index)=>{
        const std =JSON.parse(JSON.stringify(expensesList))   
           let value =e.target.value;

            if(e.target.name==='amount'){
                value = value.replace(/[^0-9]/g, '');
              }
           std[index][e.target.name] = value;
           setExpensesList(std);
         //  setErrors({...errors,  errorMessage:''})
    }

    

    const handleSubmit = event =>{
		event.preventDefault();
		setErrors(validateInput());
		setIsSubmitting(true);
        window.scrollTo(0,0);
	} 
	
const validateInput =()=>{
	let errors = {};   
    if(expensesList.length===0){
        errors.expensesList ='Please register at least one expenses';
    }
	
    return errors;
	}
	




useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
       submit()
    }
    },[errors])


    
    const  submit=()=>{  
                
        var options = expensesList;

        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if(options[i].expensesHead!=='' || options[i].account!=='' || options[i].amount!==0 ){
                 value.push({
            
            code:options[i].code, 
            expensesHead:options[i].expensesHead,
            account:options[i].account,
            amount:options[i].amount,
            expensesDate:options[i].expensesDate,
            remark:options[i].remark,
            staffCode:options[i].staffCode
        })
            }
       
    }

    if(value.length!==0){

        setNotice({...notice, isLoading: true})   

        
const fd = new FormData();
fd.append('jwt', Token)    
fd.append('expensesList',  JSON.stringify(value, null, 2))

     let url = ServerUrl+'/save_controller/tbl_bulk_expenses' 
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Success', 'success', response.data.message)
                            }else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                    }   
            })
            .catch((error)=>{
             setErrors({...errors, errorMessage: JSON.stringify(error.message)})
            }).finally(()=>{
                setIsSubmitting(false)
                GenerateRow()
        setNotice({...notice,  isLoading: false}) 
    }) 

}else{
    setErrors({...errors, errorMessage: 'Please fill at least one row complete before you can submit'})
    window.scrollTo(0,0);
}
     }    



useEffect(()=>{
    GenerateRow()
    fetchExpensesHead();
    fetchAccount()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                
                   {/* <!-- Page-header start --> */}

                   <PageHeader title="Expenses Bulk Registration">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Finance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Bulk Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

<div  className="row  ">   
<div className="well">You can use this page to add bulk expenses. Please be careful that you select the right Expenses Head and  Account. You can enter as many rows as you like by clicking on Add more.</div>
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Create Bulk Expenses</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            

{expensesList.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    <th>Expenses Type</th>
                    <th>Amount</th>
                    <th>Account</th>
                    <th>Date of Expenses</th>  
                    <th>Remark</th> 
                    
    <th ><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>


                {expensesList&&expensesList.map((pl, id)=>
            <tr key={id}>
                
                    <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>
                    <td > 
                        <select onChange={(e)=>handleChangeExpenses(e, id)}  name="expensesHead"  value={pl.expensesHead} className="form-control">

<option value="">Select</option>
{ expensesHead&&expensesHead.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.headName}</option> })}
    </select>
                     </td>

                    <td><input type="text"  onFocus={(e)=>e.target.select()} min='0' pattern="\d*" className="form-control" name='amount' value={pl.amount}  onChange={(e)=>handleChangeExpenses(e, id)} /></td>

                    <td>
                    <select onChange={(e)=>handleChangeExpenses(e, id)}  name="account"  value={pl.account} className="form-control">

<option value="">Select</option>
{ account&&account.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.accountName +' Balance: '+Currency+FormatNumber(list.openingBalance)}</option> })}
    </select>
    
                      </td>


                      <td>
                          
                          <input type="date" className="form-control" name='expensesDate' autoComplete="false" value={pl.expensesDate}  onChange={(e)=>handleChangeExpenses(e, id)} />
                      
                      </td>

                      <td>
                      <input type="text" className="form-control" name='remark' autoComplete="false" value={pl.remark}  onChange={(e)=>handleChangeExpenses(e, id)} />
                      
                      </td>
                   
                    <td > <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan='9'>

                    
                <div className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
        </table>

        </div>
        </div>
        </div>
</div>:''}


     

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BulkExpenses) 