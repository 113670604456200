import React, {useEffect} from 'react';
import {Alerts} from './component/notify'


import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ViewStudents from './students/view_students'
import StudentProfile from './students/profile'
import StudentReview from './students/student_review'
import Family_summary from './students/family_summary';





import AssessmentEntry from './exam/assessment_entry'
import SubjectMark from './exam/subject_mark'
import ClassMark from './exam/class_mark'
import ViewResult from './exam/view_result'

import ResultEntry from '../exam/result_entry' 
import ExamSchedule from '../exam/exam_schedule'
import MockResultEntry from '../exam/mock_result_entry'
import MidTerm from './exam/mid-term_result'
import Subject_mark_summary from '../exam/subject_mark_summary';



import Student_pickDrop from './attendance/student_pickdrop'

import DisplayStaffAttendance from './attendance/display_staff_attendance'
import StudentAttendance from './attendance/student_attendance'
import DisplayStudentAttendance from './attendance/display_student_attendance'
import StudentLateArrival from './attendance/student_arrival_departure_late'
import StudentAttendanceSummary from './attendance/student_attendance_summary'
import StaffAttendanceSummary from './attendance/staff_attendance_summary'
import StaffWorkinHours from './attendance/staff_working_hours'


import ELibrary from './library/e-library'
import BookReturn from './library/issues_return'
import Books from './library/books'



import SalaryStructure from './payroll/salary_structure'
import ApplyLeave from './payroll/apply_leave'
import Payslip from './payroll/payslip'
import StaffSalary from './payroll/staff_salary'



import Setup from './timetable/setup'
import HolidaysList from './timetable/holidays_list'
import LessonPlan from './timetable/lesson_plan'
import TimetableByDate from './timetable/timetableByDate'
import TimetableByDay from './timetable/timetableByDay'
import ViewTimetable from './timetable/view_timetable'
import StaffSchedule from './timetable/staff_schedule'


import Conference from './staff/conference_log'
import Profile from './staff/profile'
import Review from './staff/review'
import Noticeboard from './staff/noticeboard'
import Signature from './staff/signature'
import ChangePassword from './staff/change_password'


import Dashboard from './dashboard/dashboard'
import Error404 from './settings/error404';


import axios from 'axios'
import { SessionTimeout } from './component/notify'
import Cookies from 'js-cookie'
import {config, ServerUrl} from '../component/include'
import {encrypt, decrypt} from './component/authentication'
import Upcoming_birthday from './students/upcoming_birthday';
import Result_comment from './exam/result_comment';
import Documents from './students/documents';
import Transcript from './exam/student_transcript';
import ClassSessionCummulative from '../exam/class_session_cumulative';

const ProtectedStaffView =()=>{

const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

      let closeWarning =   document.getElementById('btnLogoutsession')
              closeWarning.click();

           
const fd = new FormData();
var code =Cookies.get('tkauscd')
const jwt = Cookies.get('tkamtk')
const staffName = decrypt(Cookies.get('tkstnm'))

var classID = Cookies.get('clkysd')
const sectionID = Cookies.get('sckayd')

fd.append('code', code)
  fd.append('staffName', staffName)
  fd.append('classID', classID)
  fd.append('sectionID', sectionID)
fd.append('jwt', jwt)


    let url = ServerUrl+'/login_refresh_controller.php?tablename=tbl_refresh_staff_login';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
          var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
			var inOneMinutes = Date.now() + 58 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
	

  Cookies.set("_tbexpt", inOneMinutes, { expires: inOneHours });
  Cookies.set("_texpt", inSixtyMinutes, { expires: inOneHours });


		Cookies.set('tkauscd', response.data.code, {expires: inOneHours })
		Cookies.set('tkamtk', response.data.jwt,  {expires: inOneHours })   
		Cookies.set('tkarfsh', response.data.userToken,  {expires: inOneHours })
		Cookies.set('tkstnm', encrypt(response.data.staffName),  {expires: inOneHours })

		Cookies.set('clkysd', response.data.classID,  {expires: inOneHours })
		Cookies.set('sckayd', response.data.sectionID,  {expires: inOneHours })

      Alerts('Info!', 'success', 'Successfuly reconnected')

        window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
    window.location.href='/logout'
}

useEffect(()=>{
  if (localStorage.getItem("tlcksr") !== null) {
    document.getElementById('openlockscreenModal').click()
 }
}, [])

 const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(Cookies.get('_tbexpt'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
} 
  
     



useEffect(()=>{
startWarning()
   setInterval(() => {
 var expiredTime = Number(Cookies.get('_texpt'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000)
}, []) 

//const basename = process.env.REACT_APP_BASE_NAME || null;
    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
      <Switch>



      <Route exact path="/staffportal/dashboard"  render={(props) => <Dashboard menu="Dashboard" {...props } />} />  

   <Route exact path="/staffportal/students/documents"  render={(props) => <Documents menu="Dashboard" {...props } />} />  

    <Route exact path="/staffportal/student/profile/:code"  render={(props) => <StudentProfile menu="Dashboard" {...props } />} />  
 <Route exact path="/staffportal/students/view_students"  render={(props) => <ViewStudents menu="Dashboard" {...props } />} /> 
 <Route exact path="/staffportal/students/define_family"  render={(props) => <Family_summary menu="Dashboard" {...props } />} /> 
 <Route exact path="/staffportal/students/review"  render={(props) => <StudentReview menu="Dashboard" {...props } />} />
 

 <Route exact path="/staffportal/conference"  render={(props) => <Conference menu="Dashboard" {...props } />} /> 
 <Route exact path="/staffportal/students/birthdays"  render={(props) => <Upcoming_birthday menu="Dashboard" {...props } />} /> 



        <Route exact path="/staffportal/exam/mock_result_entry"  render={(props) => <MockResultEntry menu="Dashboard" {...props } />} /> 

        <Route exact path="/staffportal/exam/result_entry"  render={(props) => <ResultEntry menu="Dashboard" {...props } />} /> 

        <Route exact path="/staffportal/exam/assesment_entry"  render={(props) => <AssessmentEntry menu="Dashboard" {...props } />} /> 

        <Route exact path="/staffportal/exam/subject_marks"  render={(props) => <SubjectMark menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/exam/result_comment"  render={(props) => <Result_comment menu="Dashboard" {...props } />} /> 
       
        <Route exact path="/staffportal/exam/exam_schedule"  render={(props) => <ExamSchedule menu="Dashboard" {...props } />} /> 
        
        <Route exact path="/staffportal/exam/class_marks"  render={(props) => <ClassMark menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/exam/view_result"  render={(props) => <ViewResult menu="Dashboard" {...props } />} />   
        <Route exact path="/staffportal/exam/subject_mark_summary"  render={(props) => <Subject_mark_summary menu="Subject Summary" {...props } />} /> 

        <Route exact path="/staffportal/exam/class_session_cummulative"  render={(props) => <ClassSessionCummulative  menu="Subject Summary" {...props } />} /> 

        <Route exact path="/staffportal/exam/student_transcript"  render={(props) => <Transcript menu="Dashboard" {...props } />} />   

        <Route exact path="/staffportal/exam/mid_term_result"  render={(props) => <MidTerm menu="Dashboard" {...props } />} />   
        
        <Route exact path="/staffportal/attendance/student_pick_drop"  render={(props) => <Student_pickDrop menu="Dashboard" {...props } />} /> 

        
        <Route exact path="/staffportal/attendance/display_staff_attendance"  render={(props) => <DisplayStaffAttendance menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/attendance/student_attendance"  render={(props) => <StudentAttendance menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/attendance/display_student_attendance"  render={(props) => <DisplayStudentAttendance menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/attendance/student_arrival_departure"  render={(props) => <StudentLateArrival menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/attendance/student_attendance_summary"  render={(props) => <StudentAttendanceSummary menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/attendance/staff_attendance_summary"  render={(props) => <StaffAttendanceSummary menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/attendance/staff_working_hours"  render={(props) => <StaffWorkinHours menu="Dashboard" {...props } />} /> 



        <Route exact path="/staffportal/library/elibrary"  render={(props) => <ELibrary menu="Dashboard" {...props } />} />  
<Route exact path="/staffportal/library/books_report"  render={(props) => <BookReturn menu="Dashboard" {...props } />} />  
<Route exact path="/staffportal/library/books"  render={(props) => <Books menu="Dashboard" {...props } />} /> 


        <Route exact path="/staffportal/payroll/salary_structure"  render={(props) => <SalaryStructure menu="Dashboard" {...props } />} />   
       <Route exact path="/staffportal/payroll/apply_leave"  render={(props) => <ApplyLeave menu="Dashboard" {...props } />} />                   
       <Route exact path="/staffportal/payroll/payslip"  render={(props) => <Payslip menu="Dashboard" {...props } />} />  
       <Route exact path="/staffportal/payroll/staff_salary"  render={(props) => <StaffSalary menu="Dashboard" {...props } />} />  


     


        <Route exact path="/staffportal/timetable/setup"  render={(props) => <Setup menu="Dashboard" {...props } />} /> 
        <Route exact path="/staffportal/timetable/setup_class_timetable"  render={(props) => <TimetableByDay menu="Dashboard" {...props } />} />   
        <Route exact path="/staffportal/timetable/class_lesson_plan"  render={(props) => <TimetableByDate menu="Dashboard" {...props } />} />    
        
        <Route exact path="/staffportal/timetable/lesson_plan"  render={(props) => <LessonPlan menu="Dashboard" {...props } />} />     
       <Route exact path="/staffportal/timetable/holidays_list"  render={(props) => <HolidaysList menu="Dashboard" {...props } />} />  
       <Route exact path="/staffportal/timetable/view_timetable"  render={(props) => <ViewTimetable menu="Dashboard" {...props } />} />  
       <Route exact path="/staffportal/timetable/staff_schedule"  render={(props) => <StaffSchedule menu="Dashboard" {...props } />} />  
        



      <Route exact path="/staffportal/staff/review"  render={(props) => <Review menu="Dashboard" {...props } />} />  
      <Route exact path="/staffportal/staff/noticeboard"  render={(props) => <Noticeboard menu="Dashboard" {...props } />} />  
      <Route exact path="/staffportal/staff/change_password"  render={(props) => <ChangePassword menu="Dashboard" {...props } />} />  
      <Route exact path="/staffportal/staff/signature"  render={(props) => <Signature menu="Dashboard" {...props } />} />  
      <Route exact path="/staffportal/staff/profile"  render={(props) => <Profile menu="Dashboard" {...props } />} />  


        <Route exact   render={(props) => <Error404 menu="Dashboard" {...props } />} /> 
      </Switch>
      </>
    );
  
}
export default ProtectedStaffView;
