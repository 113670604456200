import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {config, Currency, FormatNumber, API_URL} from '../component/include'
import Select from 'react-select'
import { longDate, ValidateIsOpen } from '../component/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import { useSelector } from 'react-redux'

const StartAdmission =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
        
    }); 
    
    
  let location =  useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(true)
 //   const [schoolSession, setSchoolSession] = useState([])	
const schoolSession  = useSelector(state => state.rootReducer.schoolSession);
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);


const initial ={
    studentClass:'',
    startDate:'',
    endDate:'',
    applicationType:'',
    schoolSession:'',
    feeCharge:''
    }
    const [admission, setAdmission]=useState(initial)

    const [application, setApplication]= useState([])
    const [allAdmission, setAllAdmission]= useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
   

    const fetchAdmission =()=>{
        setNotice({...notice, isDataFetching: true})  

        let url = API_URL+'/school/admission/all';
        axios.get(url,  config).then(result=>{
            if(result.data.data.length!==0){
                setAllAdmission(result.data.data)
        
            }else{
                setAllAdmission([]) 
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false})
    })
    }

const handleReset =()=>{
    setAdmission(initial)

}
    

const fetchApplication =()=>{
  let url = API_URL+'/admission/settings/all'
      axios.get(url, config).then(result =>{

        let ans = result.data.data.filter((item=>item.status ==='Active'))
        setApplication(ans)

      })
  }


    const handleChange=(event)=>{
        let {name, value}=event.target

        if(name==='feeCharge'){
            value = value.replace(/[^0-9]/g, '');
        }
        setAdmission({...admission, [name]:value});
        setErrors({...errors, [name]:''})
    }
    
    const handleSelect = (option, action)=>{
        setAdmission({...admission, [action.name]: option});
        setErrors({...errors, [action.name]:'', periodList:''})            
        
    };

    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(admission));
        setIsSubmitting(true);
    }  

    const ValidateErrors=(values)=>{
        let errors = {};  
       

    if(!Array.isArray(values.studentClass) && values.studentClass.length===0){
        errors.studentClass ='Please select applicable class';
    }
    if(values.schoolSession.length===0){
        errors.schoolSession ='Please select session';
    }

    if(values.applicationType.length===0){
        errors.applicationType ='Please select application type';
    }
        return errors;
        }


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])



    const  submit=()=>{  
              
    setNotice({...notice,   isLoading: true})   

        var options = admission.studentClass
    var value = [];
    for (var i = 0, l = (options || []).length; i < l; i++) {
      value.push({classID:options[i].classID, sectionID:options[i].value});
    }

                    const fd = new FormData(); 
                   fd.append('startDate', admission.startDate);
                   fd.append('endDate', admission.endDate); 
                   fd.append('feeCharge', admission.feeCharge); 
                   fd.append('schoolSession', admission.schoolSession.value);
                   fd.append('applicationType', admission.applicationType.value); 
                   fd.append("schoolClass", JSON.stringify(value, null, 2))


              let url = API_URL+'/school/admission/start'         
              axios.post(url, fd, config) 
              .then(response =>{
                if (response.data.status ===200){
                    Alerts('Saved!', 'success', response.data.message)
                } else{
                    setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            }  
            })
            .catch((error)=>{
                setErrors({...errors, errorMessage: JSON.stringify(error.message)})
     
            }).finally(()=>{
                fetchAdmission()
                setNotice({...notice,  isLoading: false})  
        setIsSubmitting(false)
        setAdmission(initial)

    })
     }    


     
     const handleDelete =(code)=>{
        let close =   document.getElementById('btnWarningDialog-'+code)
        close.click();

        setNotice({...notice,  isLoading: true}) 

    let url = API_URL+'/school/admission/delete/'+code;
          axios.get(url, config)
          .then(response =>{
           if(response.data.status ===200){
          Alerts('Saved!', 'default', response.data.message)
              } else{
          Alerts('Error!', 'danger', JSON.stringify(response.data))
              }   
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
        fetchAdmission()
          setNotice({...notice, 
              isLoading: false
          })
      }) 
}
   

   


    const tableHeader = [
              
        {dataField: 'status', text: 'Status', editable:false, formatter:(cell, row)=>ValidateIsOpen(row.startDate, row.endDate)===true?<span className="badge  badge-success">Open </span>:<span className="badge  badge-danger">Close</span>},
        
        {dataField: 'applicationName', text: 'Application Type', editable:false},

        {dataField: 'schoolClass', text: 'Classes', editable:false, formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell!=='[]'?JSON.parse(cell).map(item=>item.sectionID+', '):''}</span>},

        {dataField: 'feeCharge', text: 'Form Fee', validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'Fee  required a valid Number'
              };
            }
            return true;
          }, formatter:(cell)=>Currency+FormatNumber(cell) },
          {dataField: 'startYear', text: 'Session',  editable:false},

          {dataField: 'startDate', text: 'Start Date', editor: {
            type: Type.DATE },  formatter: (cell)=>longDate(cell)},

            {dataField: 'endDate', text: 'End Date', editor: {
                type: Type.DATE },  formatter: (cell)=>longDate(cell)},


                {dataField: 'created_at',  text: 'Created On', hidden:true, editable: false},
          {dataField: 'code', text: 'Action', hidden:true, formatter: (cell)=><div><a href="#!" data-toggle="modal"   data-target={`#warningdialog-${cell}`}  className="btn btn-danger btn-sm"    title="Delete"><i className="icofont icofont-delete-alt"></i></a>


          <WarningModal  message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} /> </div>, editable: false}, 
     ];

    

     const TableRecord=(props)=>{
        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );

        const handleUpdate=(column, newValue, code)=>{  
            
            const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('code', code);
            let url = API_URL+'/school/admission/change_field'
            axios.post(url, fd, config)
                //.then(result => console.log(result.data))
                .then()
                .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        
              
            } 
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='id' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 <div className="btn-group dropdown-split-success pull-right">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.code);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      


useEffect(()=>{
    fetchApplication()
    fetchAdmission()
    
  setIsAuthenticated(verifyRouter(location.pathname))
},[]);



        return (  <>
        
        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
   {isAuthenticated?  <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Start Admission">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Admission</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Start Admission</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Start Admission</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <form method='post' onSubmit={handleSubmit}> 
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
     	
      <div className="form-group">
						<label >Application Type<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            application&&application.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.applicationName}
                        })} 
onChange={handleSelect} className={errors.applicationType ? 'form-control form-control-danger' : ''}  name="applicationType" value={admission.applicationType}   /> 
								
<span style={{color:'red'}}>{errors.applicationType&& errors.applicationType}</span>				
											</div>
   
                                            <div className="form-group">
<label >Start Date <span style={{color:'red'}}>*</span></label>
<input type="datetime-local" required className={errors.startDate ? 'form-control form-control-danger' : 'form-control'} name="startDate"  onChange={handleChange} value={admission.startDate} />
<span style={{color:'red'}}>{errors.startDate && errors.startDate}</span>
   </div>

   <div className="form-group">
						<label >Select Session<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            schoolSession&&schoolSession.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.startYear}
                        })} 
onChange={handleSelect} className={errors.schoolSession ? 'form-control form-control-danger' : ''}  name="schoolSession" value={admission.schoolSession}   /> 
								
<span style={{color:'red'}}>{errors.schoolSession && errors.schoolSession}</span>				
											</div>
</section>





<section className="col-md-6">
<div className="form-group">
						<label >Select Applicable Classes <span style={{color:'red'}}>*</span></label>
                        <Select isMulti options={[
                            {value:'Creche', label:'Creche'},
                            {value:'Pre-Nursery', label:'Pre-Nursery'},
                            {value:'Nursery', label:'Nursery'},
                            {value:'Primary', label:'Primary'},
                            {value:'Junior Secondary School', label:'Junior Secondary School'},
                            {value:'Senior Secondary School', label:'Senior Secondary School'},
                        ]
							 
                        } 
onChange={handleSelect} className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={admission.studentClass}  /> <span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>			
											</div>

           


   <div className="form-group">
<label >End Date <span style={{color:'red'}}>*</span></label>
<input type="datetime-local" required  className={errors.endDate ? 'form-control form-control-danger' : 'form-control'} name="endDate"  onChange={handleChange} value={admission.endDate} />
<span style={{color:'red'}}>{errors.endDate && errors.endDate}</span>
   </div>




   <div className="form-group">
<label >Application Fee <span style={{color:'red'}}>*</span></label>
<input type="number" pattern='[0-9]' min={0} step={1} className='form-control' required  name="feeCharge" onChange={handleChange} value={admission.feeCharge} />
   </div>

</section>


</div>

 </div> </div> 
							
</div>

</div>   

     
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" onClick={handleReset} className="btn btn-inverse "><i className="fa fa-refresh"></i> Clear</button>&nbsp;&nbsp;
	<button type="submit"    className="btn btn-primary "><i className="fa fa-save"></i> Save</button>
								</footer>		</section>		
							</div>
                            </form>
     </div>  </div>
							
                            </div>
                            
                            </div>

                            <div className="row" >

                            <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Admissions</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allAdmission}   columns={tableHeader}  />} 
</div>
	</div>
    </div></div>
</div>
</div>
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(StartAdmission) 