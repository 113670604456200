import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  ImagesUrl, config, API_URL, API_IMAGE} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, longDate} from '../component/globalFunction'

import { useDispatch, useSelector } from 'react-redux'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const Newsletter =()=> {
    const [errors, setErrors] = useState({});
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [image, setImage] = useState('');
    const user  = useSelector(state => state.rootReducer.user);
    const [news, setNews]= useState({
       
      code:'',
      caption:'',
      file_url:'',
      send_mail:'Yes',	
      newsMonth:'',
    })

    const [noticeDetails, setNoticeDetails]=useState([])
    const [newsletter, setNewsletter]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChangeData =(data)=>{
		setNews({...news, caption : data });
	}

    const handleChange = event =>{
       setNews({...news, [event.target.name] : event.target.value });
       setErrors({...errors, [event.target.name]:''})
      
    }
   

    const  handleFile = function(fieldName){
        return function(newValue){  
       readURL(newValue);
       }
       
    } 
    
    
   
    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(news));
        setIsSubmitting(true);
    } 
      
    const ValidateErrors=(values)=>{
        let errors = {};   
       
         
        if(!values.file_url){
          errors.file_url ='Please upload a file';
      }

        if(!values.caption){
            errors.caption ='Please enter description';
        }

        if(!values.newsMonth){
          errors.newsMonth ='Please enter month';
      }

        return errors;
        }

    
    
    

    function submit(){        
        setNotice({...notice,  isLoading: true})         
            const fd = new FormData();
            let formData = news; 
           for(let k in formData){
               fd.append(k,formData[k]);
           }; 
           fd.append("passport", image);
           fd.append("created_by",user.staffName);
           let url = API_URL+'/newsletter/add'
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status ===200){
				
				Alerts('Saved!', 'success', response.data.message)
       window.location.reload()
					} else{

            setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            window.scrollTo(0,0)
					}   
			})
			.catch((error)=>{
        setErrors({...errors, errorMessage: JSON.stringify(error.message)})
        window.scrollTo(0,0)
			}).finally(()=>{
        fetchNewsletter()
        setNotice({...notice,  isLoading: false}) 
      })
    }




    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }
        },[errors])



        const fetchNewsletter =()=>{

          setNotice({...notice,   isDataFetching: true}) 
          let url = API_URL+'/newsletter/display/all'
          axios.get(url, config).then(result=>{
              if(result.data.data!==0){
                setNewsletter(result.data.data)
              }
          })
          .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice,   isDataFetching: false})
          })
      }



const readURL = (input)=>{
       
    let displayMessage = '';
    const PreviewImage = input.target.name;
      //  let newImage = "viewPassport";
        var fileName = document.getElementById(PreviewImage).value;

    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF" || extFile==="PDF" || extFile==="pdf"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 6) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 5 Mb';
       document.getElementById(input.target.name).value = '';
       setNews({...news, file_url:''})
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            setNews({...news, file_url:input.target.files[0]})
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e) {
             
            };
            reader.readAsDataURL(input.target.files[0]);
            setImage(input.target.files[0]) 
        }
    }
    }else{

        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG|PDF|pdf  files are allowed with maximum of 5MB'
        document.getElementById(input.target.name).value = '';
        setNews({...news, file_url:''})
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
    }
    
} 



const handleDelete =(code)=>{
  let close =   document.getElementById('btnWarningDialog-'+code)
  close.click();
 
  setNotice({...notice, isLoading: true}) 

let url = API_URL+'/newsletter/delete/'+code;
    axios.get(url, config)
    .then(response =>{
     if(response.data.status ===200){
        Alerts('Saved!', 'default', response.data.message)
            } else{
        Alerts('Error!', 'danger', JSON.stringify(response.data))
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
      fetchNewsletter()
        setNotice({...notice,  isLoading: false })
    }) 
}





				
const tableHeader = [
{dataField: 'code', text: 'Action', formatter:(cell, row)=><div>
 <button type='button' className='btn-danger' data-toggle="modal"   data-target={`#warningdialog-${row.code}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></button>
  
   <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

</div>, editable: false},

    {dataField: 'caption',  text: 'Caption',  formatter:(cell)=><span style={{display:'block',  width:300, whiteSpace:'normal', wordWrap:'break-word' }} dangerouslySetInnerHTML={{__html:cell}} ></span>,   sort: true, validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Caption'
          };
        }
        return true;
      }},
    {dataField: 'status',  text: 'Status', sort: true, formatter:(cell, row)=>row.status==='Active'? <div className="pcoded-badge label label-success">ACTIVE</div>: <div className="pcoded-badge label label-danger">INACTIVE</div>, editor: {
      type: Type.SELECT, options:[{value:'Active', label:'Active'},
      {value:'Inactive', label:'Inactive'}]

    }},


    {dataField: 'file_url', text: 'Url Link', formatter:(cell)=>cell!==""?<a href={API_IMAGE+"/newsletter/"+cell} target="new" style={{color:'blue'}} title="view"> Click to View</a>:'', editable:false, sort: true},
    {dataField: 'newsMonth',  text: 'Month', editable:false},
    {dataField: 'send_mail',  text: 'Send Notice', editable:false},
    {dataField: 'created_by',  text: 'Created By', editable:false, hidden:true},
    {dataField: 'created_at',  text: 'Created On', editable:false, hidden:true},
 ];
 



 const RecordTable=(props)=>{

    const CustomToggleList = ({
        columns,
        onColumnToggle,
        toggles
      }) => ( 
      <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
          {
            columns
              .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
              }))
              .map(column => (
                
                <button
                  type="button"
                  key={ column.dataField }
                  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                  data-toggle="button"
                  aria-pressed={ column.toggle ? 'true' : 'false' }
                  onClick={ () => onColumnToggle(column.dataField) }
                >
                  { column.text }
                </button>
              ))
          }
        </div>
        
      );

    const handleUpdate=(column, newValue, code)=>{       
      const fd = new FormData();
      
    fd.append('newValue', newValue);
    fd.append('column', column.dataField);
    fd.append('code', code);
    let url = API_URL+'/newsletter/change_field'
      
       axios.post(url, fd, config)
       //.then(result => console.log(result.data))
       .then()
       .catch((error)=>console.log(error)) 
       
     } 
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                             <div className="btn-group dropdown-split-success">
  
  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <i className="fa fa-search-plus"></i> Columns
  </button>
  
  <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    <CustomToggleList { ...props.columnToggleProps } />
  </div>
  </div>

                             </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.code);
                           
                          return '';
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }


useEffect(()=>{
    fetchNewsletter()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Newsletter">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-commenting-o"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">CMS</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Newsletter</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


                <div className="row  ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Newsletter</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		
        <div className="card-block">
         
		<div className="row">
     <section className="col-md-12">
           <div className="form-group">
           <label > Description</label>
           <QuillText value={news.caption} onChange={handleChangeData} /> 

           <span style={{color:'red'}}>{errors.caption && errors.caption}</span>
            </div>
     
     </section>

     <section className="col-md-6">
           <div className="form-group">
           <label > Select Image/PDF</label>
           <input id="passport"  onChange={handleFile('passport')} className="form-control" name="passport" type="file" accept="image/*, application/pdf" /> 
            </div>
            
           <span style={{color:'red'}}>{errors.file_url && errors.file_url }</span>
     </section> 

     <section className="col-md-6">
           <div className="form-group"><label > Month</label>
           <input   value={news.newsMonth} className={errors.newsMonth ? 'form-control form-control-danger' : 'form-control'} name="newsMonth" type="month"  onChange={handleChange} /> <span style={{color:'red'}}>{errors.newsMonth && errors.newsMonth}</span>

            </div>
     
     </section>


     <section className="col-md-7">
                              <label>Send To Parent & Staff </label>
              
              <div className="form-group">
              <div className="form-radio">
        <div className="radio radio-inline"><label >
                <input type="radio" checked={news.send_mail==='Yes'} onChange={handleChange} value="Yes"   name="send_mail" />
                                <i className="helper"></i>Yes</label></div>

            <div className="radio radio-inline"><label >
                <input type="radio"   checked={news.send_mail==='No'} onChange={handleChange} value="No" name="send_mail" />
                                <i className="helper"></i>No</label></div>

              
                                       </div>
                  </div></section>

				</div>	<hr/>


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-inverse "><i className="fa fa-save"></i> Upload Newsletter</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

<div className="row  ">   <div className="col-sm-12">
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Newsletter</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">         
        <div className="col-md-12 table-responsive">
         {notice.isDataFetching ? <TableLoader />:
         <RecordTable data={newsletter}  columns={tableHeader}  /> 
        } 

</div>

</div>
    
    </div>
    
    </div>
    </div>




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Newsletter) 