import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {FormatNumber, ImagesUrl, ServerUrl,  Token, config, schoolName} from '../component/include'

import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { convertDate, formatGroupLabel, getBritishDate, getTime, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

const DisplayStudentLog =()=> {
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState([])
const [isSubmitting, setIsSubmitting] = useState(false); 
    const [schoolInfo, setSchoolInfo]=useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [head, setHead]= useState([])
    const [attendance, setAttendance] = useState({
       fromDate:'',
       toDate:'',
       sectionID:[],
    })

    const fetchSchoolInfo =()=>{
        const fd = new FormData();
            fd.append('jwt', Token); 
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }
      

    const handleSelect= (option, action)=>{
        setAttendance({...attendance, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        setResult('')
    }
        

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setAttendance({...attendance, [name]:value})

            setResult([])
            setErrors({...errors, [name]:''})
        }
       
        
    
            const fetchResult =()=>{

                let sql =   'SELECT s.studentName, a.indicator as attendance, cast(TIMESTAMPDIFF(minute, a.created_at, a.updated_at)/60.0 as decimal(10,2)) as totalHours, a.created_at as timeIn, a.updated_at as timeOut, a.attendanceDate  from tbl_students s, tbl_student_attendance a where   s.code = a.studentCode  and a.attendanceDate >= "'+convertDate(attendance.fromDate)+'" and a.attendanceDate <= "'+convertDate(attendance.toDate)+'" '

                console.log(sql)
            if(attendance.sectionID.length!==0){
                sql = sql + '  and s.sectionID = "'+attendance.sectionID.value+'"'
            }
            
            sql = sql+ '  order by a.attendanceDate'
      setNotice({...notice, isLoading: true}) 
      const fd = new FormData();
      fd.append('jwt', Token); 
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{
                if(result.data.length!==0){
                    setResult(result.data)
                    

let dates = result.data.map((item)=>item.attendanceDate)
let unique_date = dates.filter((item, index, arr) => arr.indexOf(item) === index)
          
setHead(unique_date)

        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
                }else{
                    Alerts('Info!', 'default', 'No record found for the selected criterials')
                }
                
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })  
        } 
        
       

            const validateInput =(values)=>{
                let errors = {};     
                
                if(!values.fromDate){
                    errors.fromDate ='This field is required';
                }
                if(!values.toDate){
                    errors.toDate ='This field is required';
                }
                return errors;
                }
            
            

            const handleSubmit =(event)=>{
                event.preventDefault();
                setErrors(validateInput(attendance));
                setIsSubmitting(true);
            } 


            useEffect(()=>{
                if(Object.keys(errors).length === 0 && isSubmitting){
                fetchResult()
                }
            },[errors])
     

            const getHistory =(data)=>{
                const rs = result&&result.filter((item)=>item.attendanceDate ===data)
                
                return rs;
                }  




        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Attendance Log">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Student Attendance Log</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Student Attendance Log</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
            

        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-4" >
        <div className="form-group">
          <label> Student Class</label>
          <Select  options={
                             schoolClass&&schoolClass.map((list, idx)=> {
                                return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                         } 
                         formatGroupLabel={formatGroupLabel}
 onChange={handleSelect} className={errors.sectionID ? 'form-control form-control-danger' : ''} name="sectionID" value={attendance.sectionID}  /> <span style={{color:'red'}}>{errors.sectionID && errors.sectionID}</span>
             </div>

</section>

      <section className="col-md-4" >

      <div className="form-group">
          <label> Start Date <span style={{color:'red'}}>*</span></label>
      <input type="date"  name="fromDate" value={attendance.fromDate} 
         onChange={handleChange} className={errors.fromDate ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.fromDate && errors.fromDate}</span>	
			</div>

</section>


      
<section className="col-md-4">
     <div className="form-group">
          <label> End Date <span style={{color:'red'}}>*</span></label>
      <input type="date"  name="toDate" value={attendance.toDate} 
         onChange={handleChange} className={errors.toDate ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.toDate && errors.toDate}</span>	
			</div>
 
     
     </section>		
     


				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary "><i className="fa fa-search"></i> Search Attendance</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
        <div className="row" >

<table className="table  table-full-width">
       <tbody>
                <tr>
                <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Student Attendance Log</b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">


       {head&&head.map((data, id)=>
        <table className="table table-sm table-bordered table-hover" key={id}>
                <thead>
                    <tr>
                        <th colSpan={9} className='text-center '> 
                        <h5>Attendance Log Of  {getBritishDate(data)} </h5>
                        </th>
                    </tr>
                
                  
                    <tr style={{backgroundColor:'#E7E0EE'}}>
                     <th>S/N</th>
                    <th style={{backgroundColor:'#E7E0EE'}} >Staff Name</th>
                    <th >Date</th>
                    <th >Time In</th>
                    <th >Time Out</th>
                    <th >Attendance</th>
                    <th >Total Hours</th>
                  </tr>
           
                </thead>
                <tbody>

               {getHistory(data).map((list, index)=>  <tr key={index}>
                        <td >{index+1}</td>
                        <td >{list.studentName}</td>
                        <td >{list.attendanceDate}</td>
                        <td >{list.timeIn}</td>
                        <td >{list.timeOut}</td>
                        <td>{list.attendance}</td>
                        <td >{list.totalHours + ' Hours'}</td>

                </tr>)}
                          

                </tbody>
                   
            </table>
        )}
        
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/staff_attendance_log.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
              
                <input type='hidden' name='head' value ={JSON.stringify(head, null, 2)} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(DisplayStudentLog) 