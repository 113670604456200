import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import { LoadEffect } from '../component/loader'
import {Alerts} from '../component/notify'
import {ServerUrl, staffCode,  ImagesUrl, Token, config} from '../component/include'

import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import users from './users'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	const [group, setGroup] = useState([])
	 
    const staff  = useSelector(state => state.rootReducer.staff);
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [content, setContent] = useState({
		code:Math.random().toString(36).substr(2,9),
		email:'',
		password:'',
		fullname:'',
		gender:'Male',
		telephone:'',
		isLogo:false, 
		group:[],
		isActive:true,
		staffCreate: staffCode,
		staff:''
	})
	
	const [image, setImage] = useState('');

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = ImagesUrl+"/users/"+content.gender+'.png'
		document.getElementById('logo').value = '';
		setImage('')
		setContent({...content, isLogo:false})
	}
	
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='isActive'){
			value = value==='false'?true:false
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const fetchGroup =()=>{
		var sql ="Select code, status, groupName from tbl_usertype order by groupName ASC" ;
		const fd = new FormData();
    fd.append("jwt", Token);
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller'
		axios.post(url, fd, config).then(result=>{  
			setGroup(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }
	
	
		 const handleSelectStaff = (option, action)=>{
			setContent({...content,
				
				code:option.value,
				email:option.email,
				fullname:option.label,
				gender:option.gender,
				telephone:option.telephone,
				staff: option
			});
			console.log(content, option)
			 }


const ValidateExistence=()=>{
	
	if(content.email!==''){
		const fd = new FormData();
		fd.append("jwt", Token);
		fd.append('data', content.email);
		
		let url = ServerUrl + '/checkExistence/tbl_users/email'
		axios.post(url, fd, config).then(response=>{
			if(response.data===true){
				myRef.current.focus()
				setErrors({...errors, email:content.email+' already choosen'})
				setContent({...content, email:''});
				return response.data;
			}
	  })
	}
}
	
    function submit(){  
		setNotice({...notice, isLoading: true})     
		   
		  let formData = content;
		  const fd = new FormData();
    fd.append("jwt", Token);
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			fd.append('groupId', content.group.value)
			if(image!==''){
				fd.append("image", image);
			}
			let url = ServerUrl+'/save_controller/tbl_users'
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();
	  ValidateExistence();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.fullname){
            errors.fullname ='Please enter fullname';
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }
		if(values.group.length===0){
            errors.group ='Please select usergroup';
        }
        if(values.password.length <6){
            errors.password ='Please enter minimum of 6 characters';
        } 
       
       
        return errors;
		}
		
		const  handleFile = function(fieldName){
			return function(newValue){                 
				//const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 0.5) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
					  
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])  
				  setContent({...content, isLogo:true})
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 
		

	useEffect(()=>{
		fetchGroup()
		},[]);


        return (<>
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Add a new user"
	   				menu="Users"
					   menuLink="/users"
					   subsubmenu="Add a new user"
					   />


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/people/users')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/people/user/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/people/user/edit/'+content.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/people/users" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> User info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           


										   



<div className="form-group row">
					<div className="col-sm-3">
							<label className='pull-right'> Select Staff </label>
					</div>
									
						<div className="col-sm-9 ">

						
<Select  options={
                            staff.map((list, idx)=> {
                                return {key:idx, value: list.code, label:list.staffName, email:list.email, gender:list.gender, fileNo:list.fileNo, telephone:list.mobile }
                            })
                       }    
           getOptionLabel={option =>option.label +' - '+ option.fileNo} 
onChange={handleSelectStaff}  name="teacher" value={content.staff} styles={selectStyles}  />
							<span style={{color:'red'}}>{errors.staff}</span>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<label className='pull-right'> Email Address </label>
					</div>
									
						<div className="col-sm-9 ">

							<div className="input-group">
						<input type="text" onChange={handleChange} role={'presentation'} autoComplete='off'  onBlur={ValidateExistence} ref={myRef} name="email" value={content.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span style={{color:'red'}}>{errors.email}</span>
							</div>
					</div>




					<div className="form-group row">
					<div className="col-sm-3">
							<label  className='pull-right'>Password </label>
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="password" autoComplete="off" onChange={handleChange} role={'presentation'}  name="password" value={content.password} className={errors.password  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span style={{color:'red'}}>{errors.password}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<label className='pull-right'> Fullname </label>
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} autoComplete='off'  role={'presentation'} name="fullname" value={content.fullname} className={errors.fullname  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span style={{color:'red'}}>{errors.fullname}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<label className='pull-right'>Telephone </label>
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} autoComplete='off'  role={'presentation'} name="telephone" value={content.telephone} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<label className='pull-right'> Gender </label>
					</div>
									
						<div className="col-sm-9 ">
						
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={content.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={content.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>

							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<label className='pull-right'> Active </label>
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isActive} name="isActive" checked={content.isActive} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


				<div className="form-group row">
					<div className="col-sm-3">
							<label className='pull-right'>User group </label>
					</div>
									
						<div className="col-sm-9 ">
						<Select  options={
				group&&group.map((node, id)=>{
					return {value:node.code, key:id, label:node.groupName}
				})} 
                
onChange={handleSelect}  name="group"  value={content.group} styles={selectStyles} /> 
<span style={{color:'red'}}>{errors.group}</span>
</div>
	</div>
	<div className="form-group row">
					<div className="col-sm-3">
							<label  className='pull-right'> Profile Image </label>
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-100" id="viewLogo"  src={ImagesUrl+"/users/"+content.gender+'.png'} alt="Pictures"  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 

 );
        
}

export default React.memo(Create) 