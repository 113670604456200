import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, config} from '../component/include'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
const Settings =()=> {
    let myRef= useRef()
      const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
        
    }); 
    
  let location =  useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(true)
    const [applicationType, setApplicationType] = useState({
        applicationName:''
    })

    const [allApplication, setAllApplicationType]= useState([])
   
    const handleChange = event =>{
		let {name, value} = event.target
        setApplicationType({...applicationType, [name] : value });
      
    }

    
    const fetchApplication =()=>{
      setNotice({...notice, isDataFetching:true})
    let url = API_URL+'/admission/settings/all'
        axios.get(url, config).then(result => setAllApplicationType(result.data.data))
        .finally(()=>{
          
      setNotice({...notice, isDataFetching:false})
        })
    }


    const handleDelete =(code)=>{
      let close =   document.getElementById('btnWarningDialog-'+code)
      close.click();

      setNotice({...notice,  isLoading: true}) 

  let url = API_URL+'/admission/settings/delete/'+code;
        axios.get(url, config)
        .then(response =>{
         if(response.data.status ===200){
        Alerts('Saved!', 'default', response.data.message)
            } else{
        Alerts('Error!', 'danger', JSON.stringify(response.data))
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
      fetchApplication()
        setNotice({...notice, 
            isLoading: false
        })
    }) 
}




    

      
    const tableHeader = [
              
        {dataField: 'applicationName', text: 'Name', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Application Name is required'
              };
            }
            return true;
          } },
          {dataField: 'status', text: 'Status', sort: true, formatter:(cell)=><i className={`badge 
          ${cell==='Inactive'?'badge-danger':'badge-success'}`}>{cell}</i>
          , editor: {
              type: Type.SELECT, options:[{value:'Active', label:'Active'},
              {value:'Inactive', label:'Inactive'}]
            }},
          /* {dataField: 'code', text: 'Action', formatter: (cell)=><div>
              
              
              <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${cell}`}  className="btn btn-danger btn-sm"    title="Delete"><i className="icofont icofont-delete-alt"></i></a>


          <WarningModal  message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} /> </div>, editable: false} */,  
     ];

    

     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
            const fd = new FormData();
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('code', code);
            let url = API_URL+'/admission/settings/change_field'
            axios.post(url, fd, config)
                //.then(result => console.log(result.data))
                .then()
                .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        
            } 
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.code);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      




function submit(event){  
    event.preventDefault();
setNotice({...notice,  isLoading: true}) 

       const fd = new FormData(); 
       fd.append('applicationName', applicationType.applicationName)
       
       let url = API_URL+'/admission/settings/add';
       axios.post(url, fd, config)
       .then(response =>{
           if (response.data.status ===200){
               Alerts('Saved!', 'success', response.data.message)
           } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
       }  
       })
       .catch((error)=>{
          // Alerts('Error!', 'danger', JSON.stringify(error.message)) 

       }).finally(()=>{
           fetchApplication()
        setApplicationType({
          applicationName:''
         }) 
         setNotice({...notice, isLoading: false}) 
       })
    
}

useEffect(()=>{
  
  setIsAuthenticated(verifyRouter(location.pathname))
fetchApplication()
}, [])

        
        return (  <> 
        
        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
   {isAuthenticated?  <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Application Type">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-user-plus"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Admission</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Application Type</a> </li>
                    </PageHeader>

{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							
                               
          <div className="col-sm-4">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Application Type</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
       
		<div className="card-block">
        <form method='post' onSubmit={submit}>
		<fieldset>
            
            <div className="row">
									
										<section className="col-md-12">
                                    
										<div className="form-group">
						<label >Application Name<span style={{color:'red'}}>*</span></label>
				<input type="text" name="applicationName" required value={applicationType.applicationName} onChange={handleChange} className='form-control' ref={myRef} /> 
			</div>

										</section>
										</div>	</fieldset>

	<footer className="pull-right">
	
	<button type="submit" id="submit"  className="btn btn-primary ">Save</button>
	
								</footer>				
                                </form>	</div>
                            
							</div>
							</div>
		
	
	

	<div className="col-sm-8">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Application Type</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allApplication}   columns={tableHeader}  />} 
</div>
	</div>
    </div></div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Settings) 