import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, smsAccountUrl} from '../component/include'
import Select from 'react-select'
import { Link } from 'react-router-dom'


import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
 const Settings =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
    });  

    const theme = 'snow';
    const [notification, setNotification] = useState([])
    const [sendMessage, setSendMessage] = useState({        
        staff:[],
        allStaff:'Yes',
        messageNumber:'Email',
        message:''
    })
   
    const [staffList, setStaffList] = useState([]);
	
const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({});

const [activeStep, setActiveStep] = useState(1);

const [staffMobile, setStaffMobile] = useState([])
const [staffEmail, setStaffEmail] = useState([])
const steps = [{title: 'Message '}, {title: 'Confirm '}]


const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'
        ]
    ],
  };

  const placeholder = 'Enter your message...';

  const formats =  ['bold', 'italic', 'underline', 'strike'  ];

  const {quill, quillRef } = useQuill({ theme, modules, formats, placeholder });


const handleFetch =()=>{
    filterAllContact()
 }


const filterAllContact =()=>{

    var options = staffList;
    if(sendMessage.allStaff==='No'){
        options = sendMessage.staff
    }
    var values = [];
    var emails = [];
    for (var i = 0, l = options.length; i < l; i++) {

        if(options[i].mobile !==''){
            values.push(options[i].mobile);
                }


        if(options[i].email !==''){
            emails.push(options[i].email);
                }

    }


    setStaffEmail(emails);
    setStaffMobile(values);
}



const handleChange = event =>{
    let {name, value} = event.target
    if (name==='allStaff'){
        value = value ==='Yes'? 'No':'Yes';        
    }
handleFetch()
   setSendMessage({...sendMessage, [name] : value});
   setErrors({...errors, [name]:''})
  
}


const handleSelect = (option, action)=>{
   
    setSendMessage({...sendMessage, [action.name]: option});
    setErrors({...errors, [action.name]:''}) 
}



const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        handleFetch()
    }
    
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
        handleFetch()
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
        handleFetch()
    }, [activeStep])
    

     const  fetchStaff =()=>{      
        var sql = "select ID, code, staffName, mobile, email, altMobile from tbl_staff where employmentStatus='Working' "
        const fd = new FormData();
        fd.append('jwt', Token);
          fd.append("sql", sql);
          let url = ServerUrl+'/fetchBySql_controller'
          axios.post(url, fd, config).then(result=> setStaffList(result.data))
          .catch((error)=>
          Alerts('Error!', 'danger', error.message))
          .finally(()=>{
              setNotice({...notice, isLoading: false  })
    })    
     }

    const handleSubmit=(event)=>{
        event.preventDefault();
		 setErrors(ValidateInput(sendMessage));
        setIsSubmitting(true); 
        handleFetch()
      
    }

    useEffect(()=>{
        fetchStaff()  
    },[]);

 

    const ValidateInput=(values)=>{
        let errors = {};     
       
        if(values.allStaff==='No'){

            if(values.staff.length===0){
            errors.staff ='Please select student';
            }
        }
       /*  if(!values.message){
            errors.message ='Please enter message';
        } */
           
       return errors; 
       }
       
useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
    },[errors])

    const submit=()=>{
        
        var ct = 1;
        let set ='';

        staffMobile.map(item=>{
            set = set +item
            if(ct< staffMobile.length) set = set+', '
            ct = ct+1
        })


      setNotice({...notice,  isLoading: true})     
		  
		const fd = new FormData();
	    fd.append('jwt', Token);
	

        fd.append('mailMessage', String(quill?.root.innerHTML));
        fd.append('message', sendMessage.message);
        fd.append('telephones', String(set));
        fd.append('emails', JSON.stringify(staffEmail, null, 1));
        fd.append('mode', sendMessage.messageNumber);


		   let url = ServerUrl+'/save_controller/tbl_sendBulkMessage'
		  axios.post(url, fd, config)
		  .then(response =>{
			setErrors({...errors, infoMessage: JSON.stringify(response.data)})  
            window.scrollTo(0,0) 
		  })
		  .catch((error)=>{
			setErrors({...errors, errorMessage: error.message})
		  }).finally(()=>{
			  setNotice({...notice,   isLoading: false })
		  })   

    }



        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
        <PageHeader title="Staff Communication">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-commenting-o"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Communication</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Send Message</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">





                {errors.infoMessage?
<div className="alert alert-info background-info">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.infoMessage}</strong> 
    </div>:''}

{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


                <div className="row  ">
   <div className="col-sm-12">

<div className="card z-depth-0">
    <div className="card-block">
    <div className="card z-depth-0">
        <div className="card-block">


        <Link to="parent" className="btn btn-danger "> <i className="fa fa-graduation-cap"></i> Parent</Link>&nbsp;&nbsp;
        <Link to="staff" className="btn btn-success "> <i className="fa fa-user"></i> Staff</Link>

        

</div>
</div></div>
</div>

</div>  </div>
<div className="row">
<div className="col-sm-12">
                  <div className="alert alert-success border-success background-success"  ><h5>Message Will Be Sent Using Email and or SMS </h5> <a href={smsAccountUrl} target="_blank" ><i className="fa fa-hand-o-right fa-spin"></i> Click Here To Recharge Your SMS Account </a></div></div>
                                      </div>
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Send SMS to Staff</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximize full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	  <div className="row setup-content" id="messages">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-3">
          <label>Select Staff</label>
</section>
          <section className="col-md-3">
      <div  className="form-group">
                     
<div className="border-checkbox-section">
					
                    <div className="border-checkbox-group border-checkbox-group-primary">
                        <input className="border-checkbox" type="checkbox"  onChange={handleChange} name="allStaff" value={sendMessage.allStaff} checked={sendMessage.allStaff==='Yes'? true:false} id="allStaff" />
                                <label className="border-checkbox-label" htmlFor="allStaff">All</label>
                            </div>
                            
                            </div> 
   </div>
   
   
   
   </section>

   <section style={{display:sendMessage.allStaff==='Yes'?'none':'block'}} className="col-md-6">
   <div className="form-group">
						<Select isMulti options={
                           staffList&&staffList.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.mobile, staffName:list.staffName, email:list.email, altMobile:list.altMobile, mobile:list.mobile}
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.staffName}`}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={sendMessage.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
											</div>                                 
	
     </section>
         </div>
         <div className="row">
                          
                          <section className="col-md-3">
                              <label>Messaging Option</label>
                  </section>
              
                  <section className="col-md-9">
              
              <div className="form-group">
              <div className="form-radio">
                       <div className="radio radio-inline"><label  >
                               <input type="radio" checked={sendMessage.messageNumber==='Email'} onChange={handleChange} value="Email"   name="messageNumber" />
                                               <i className="helper"></i>Email Only
                                               
                                               <a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
                          
                          <span className="tooltip-text3">
                              <span className="tooltip-inner2">Message will be sent to student registered All email (Fathers and Mothers)</span>
                              </span>
                          </span>
                          </a></label></div>
              
                                               <div className="radio radio-inline"><label  >
                               <input type="radio"   checked={sendMessage.messageNumber==='sms'} onChange={handleChange} value="sms" name="messageNumber" />
                                               <i className="helper"></i>SMS Only
                                               <a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
                          
                          <span className="tooltip-text3">
                              <span className="tooltip-inner2">Message will be sent to student registered  Number (Fathers and Mothers)</span>
                              </span>
                          </span>
                          </a>
                                               
                                               </label>
                                               
                                               
                                               </div>
              
              
                                               <div className="radio radio-inline"><label  >
                               <input type="radio"   checked={sendMessage.messageNumber==='both'} onChange={handleChange} value="both" name="messageNumber" />
                                               <i className="helper"></i>Both SMS and Email
                                               <a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
                          
                          <span className="tooltip-text3">
                              <span className="tooltip-inner2">Message will be sent to student registered  Email and Number (Fathers and Mothers)</span>
                              </span>
                          </span>
                          </a>
                                               
                                               </label>
                                               
                                               
                                               </div>
              
                                       </div>
                  </div>	
              
                  </section>
              
                                  </div>
                                  {sendMessage.messageNumber==='sms' || sendMessage.messageNumber==='both' ? 
                    <div className="row">
                          
                          <section className="col-md-3">
                              <label>SMS Content</label>
                  </section>
              
                  <section className="col-md-9">

               
            <div className="form-group">
             <textarea  className={errors.message ? 'form-control form-control-danger' : 'form-control'} rows="6" name="message" value={sendMessage.message} onChange={handleChange}> {sendMessage.message}</textarea><span style={{color:'red'}}>{errors.message && errors.message}</span>

   <div style={{color:'green'}}>{sendMessage.message.length} Characters,  {(Math.ceil(Number(sendMessage.message.length)/160))}  Pages, Remaining {(160 -(Number(sendMessage.message.length)%160))}</div>
                  </div> 
                  
                  </section>
                                  </div>:''}
                                 
                              </div>
                              
      </div>	
           	</div>
      
      </div>	
           
      </div>
   
</div>:<div className="row setup-content" id="confirm">
		    
            <div className="col-md-12">
      
            <div className="card z-depth-0">
      
        <div className="card-block">
            <div className="card z-depth-0">
      
        <div className="card-block">
            <div className="row">
                       <center><h1>Read And Confirm</h1></center>      
            <section className="col-md-12">
            <div style={{color:'red'}}>{errors.staff && errors.staff}</div>
            <div style={{color:'red'}}>{errors.message && errors.message}</div>
    <div className="well well-sm alert alert-warning border-warning background-warning"  >{sendMessage.message}</div>
            <div className="well well-sm alert alert-info border-info background-info"  >


            {sendMessage.messageNumber==='sms' || sendMessage.messageNumber==='both' ?   <p>Number Of SMS In This Request Parts : {(Math.ceil(Number(sendMessage.message.length)/160))} * {staffMobile.length} = {(Math.ceil(Number(sendMessage.message.length)/160)) * Number(staffMobile.length)}</p>:''}


 {sendMessage.messageNumber==='sms' || sendMessage.messageNumber==='both' ?    <p>Total Unit Needed:  {(Math.ceil(Number(sendMessage.message.length)/160)) * Number(staffMobile.length)*2}</p>:''}


                {sendMessage.messageNumber==='Email' || sendMessage.messageNumber==='both' ?<p>Number Of People To Send Message In Email {staffEmail.length}</p> :''}
                
            
            </div> 
      </section>
         
               </div>     </div>
                                    
            </div>	
                     </div>
            
            </div>	
                 
            </div>
         
      </div>}


      
      <div className="form-group "><b> Message Content</b><div className="h-10"></div>
                        
                       
                        <div style={{ width: '100%', height: 300 }}>
                                                <div ref={quillRef} />
                        
                                                </div>
                                        
                                                </div>
                        
                                                <br/>
                        <span style={{color:'red'}}>{errors.message}</span>
                        <div style={{ height: 10 }}></div>


<div className="h-20"></div>


                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Send Message </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Settings) 