import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config, schoolName, FormatNumber} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, longDate, ordinalSurfix} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
const ClassList =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [examtype, setExamtype]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allResult, setAllResult]=useState([])
    const [subject, setSubject]= useState([])
    const [result, setResult] = useState({
        studentClass:[],
        subject:[],
        examtype:[]
    })

    const [stat, setStat] = useState({
        average:0,
        below:0,
        above:0,
        total:0
    })

    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        }   
        if(values.examtype.length===0){
            errors.examtype ='Please select exam type';
        } 
        if(values.subject.length===0){
            errors.subject ='Please select subject';
        } 
        return errors;
        }


        const fetchExamType =()=>{
            var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active'  group by r.examCode order by exam ASC" ;
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setExamtype(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

    const fetchSubject =(type, section)=>{
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+type+"' and r.sectionID ='"+section+"' group by r.subjectCode  order by r.displayOrder ASC" ;
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
    setSubject(result.data)
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
    })
    }

  

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        fetchResult()
        }
        },[errors])

const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   if(action.name==='studentClass'){
    fetchSubject(result.examtype.value, option.value)
   }else if(action.name==='examtype'){
   fetchSubject(option.value, result.studentClass.value)
   }
   setAllResult([])
  
}



const fetchResult =()=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT 1+(SELECT count(*) from tbl_exam_result a WHERE a.total > e.total and a.subjectCode = '"+result.subject.value+"'  and a.examCode ='"+result.examtype.value+"' and a.sectionID ='"+result.studentClass.value+"' ) as rank, s.admissionNumber, s.gender, s.registerNumber, s.studentName, s.termination, s.termination_date, s.termination_remark, e.examDate, e.maxMark, e.firstCA, e.absent, e.secondCA, e.exam, e.comment, e.total, e.subjectposition, e.grade, e.remark, e.code from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ='Studying' and e.subjectCode = '"+result.subject.value+"' and e.examCode ='"+result.examtype.value+"' and e.sectionID ='"+result.studentClass.value+"'  order by s.studentName ASC"

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
        var total = 0;
        var ans = result.data.map(item=>total+=Number(item.total))
        var avg = total/result.data.length
        var above = result.data.filter(item=>Number(item.total)>=avg)
        var below = result.data.filter(item=>Number(item.total)< avg)
setStat({
    average:FormatNumber(total/result.data.length),
    below:below.length,
    above:above.length,
    total:result.data.length
    
})

setAllResult(result.data)
updateRank(result.data)
structure.current.scrollIntoView({ behavior: "smooth" });
    }else{
        Alerts('Info!', 'info', 'There are no result found for the selected subject')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}



const updateRank =(resultData)=>{
    const fd = new FormData(); 
  fd.append('subjectResult', JSON.stringify(resultData, null, 2));
fd.append('jwt', Token);
     let url = ServerUrl+'/update_controller/tbl_exam_result_rank';
    axios.post(url, fd, config)
}



const submitRank =()=>{

    const fd = new FormData(); 
  fd.append('subjectResult', JSON.stringify(allResult, null, 2));
setNotice({...notice,   isLoading: true}) 
fd.append('jwt', Token);
     let url = ServerUrl+'/update_controller/tbl_exam_result_rank';
    axios.post(url, fd, config)
    .then(response =>{
      if (response.data.type ==='info'){
          Alerts('Saved!', 'default', response.data.message)
      } else{
          Alerts('Error!', 'danger', JSON.stringify(response.data))
  }  
  })
  .catch((error)=>{
      Alerts('Error!', 'danger', JSON.stringify(error.message)) 
  })
  .finally(()=>{
    setNotice({...notice, isLoading: false})
  })  
  
}

useEffect(()=>{
    fetchSubject()
    fetchExamType()
    
},[]);

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Subject Marks Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Exam Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Subject Marks</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">

                    <section className="col-md-4">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>       

     <section className="col-md-4">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>     	
     <section className="col-md-4">
           <div className="form-group">
         <label> Subject<span style={{color:'red'}}>*</span></label>
         <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={result.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	</div>
     
     </section>

				</div>	

	<footer className="pull-right">
	
	<button type="button"  onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	




{allResult.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>SUBJECT MARK DETAILS </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{result.studentClass.label}</b></td>
                    <td>Exam Type: <b> {result.examtype.label} Examination</b></td>
                    <td>Subject: <b> {result.subject.label}</b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                    <th>Student Details</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1st CAT (20%)</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2nd CAT (20%)</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Exam (60%)</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
                    <th>Max</th>
                    <th>Rank</th>
                    <th>Grade</th>
                    <th>Remark</th>
                    <th>Present</th>
                    <th>Comment</th>

                </tr>
            </thead>
            <tbody>{allResult.length!==0?allResult.map((row, idx)=> 
                <tr key={idx}>
                    <td>{idx+1}</td>
                <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.admissionNumber} - {row.studentName} <br/>

                {row.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Terminated on {longDate(row.termination_date)} {row.termination_remark}</span>:''}</td>

                    <td>{row.firstCA}</td>
                    <td>{row.secondCA}</td>
                    <td>{row.exam}</td>
                    <td>{row.total}</td>
                    <td>{row.maxMark}</td>
                    <td><b>{ordinalSurfix(row.rank)}</b></td>
                    <td>{row.grade}</td>
                    <td>{row.remark}</td>
                    <td>{row.absent==='YES' || row.absent==='Yes'? <div className="text-center"><i  className="fa fa-times  badge-inverse-danger"></i></div>: 
                    <div className="text-center"><i  className="fa fa-check  badge-inverse-success"></i></div>}</td>
<td style={{maxWidth:'400px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.comment}</td>
                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/subject_marks.php?token='+userToken} target='_blank' className='pull-right' >
        <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='subject' value ={result.subject.value} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='stat' value ={JSON.stringify(stat, null, 2)} />
                <button type="button" className="btn btn-primary   " onClick={submitRank} > <i className="fa fa-save"></i> Update Rank</button>&nbsp;

                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ClassList) 