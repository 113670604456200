import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect, TableLoader}  from '../../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../../component/notify'
import PageHeader from '../../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, Currency, staffName, config, API_URL} from '../../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, FormatNumber } from '../../component/globalFunction'
import { userToken } from '../../component/authentication'


const ExtraPayment=()=>{
	const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
  
const [studentList, setStudentList] = useState([]);
const [errors, setErrors] = useState({});
const [payment, setPayment] =  useState([]);
const [studentFee, setStudentFee]=useState({
	student:[],
	receiptNo:'',
	paymentMethod:{ value: 'BANK', label: 'BANK'},
	amountPaid:0,
	remark:'',
	paymentDate:'',
	pay_now:false
});
const [allFees, setAllFees]= useState([])


const generateReceipt =()=>{
	setStudentFee({...studentFee, receiptNo:Math.random().toString(36).substring(2,9).toLocaleUpperCase() })

	setErrors({...errors, receiptNo:''})
}


const fetchStudents =()=>{
    setNotice({...notice, isLoading: true}) 
let url = API_URL+'/students/all'
axios.get(url,  config).then(result=>{
    if(Array.isArray(result.data.data)){
		setStudentList(result.data.data)
	}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
})
}




const fetchPayment =(code)=>{
	setNotice({...notice, isLoading: true})  

	let url = API_URL+'/fees/student_payment/'+code;
	axios.get(url,  config).then(result=>{
		if(result.data.fees.length!==0){
			setAllFees(result.data.fees)

		}else{
			setAllFees([])
		}
		if(result.data.payment.length!==0){
			setPayment(result.data.payment)

			window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
		}else{
			setPayment([])
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, isLoading: false})  
	})
	}

const handleSelect = (option, action)=>{
	setStudentFee({...studentFee, [action.name]: option});
	setErrors({...errors, [action.name]:''})
	fetchPayment(option.value)
 }

 const handleSelectPayment = (option, action)=>{
	setStudentFee({...studentFee, [action.name]: option});
	setErrors({...errors, [action.name]:''})
 }
 const handleChange = event =>{
	let {name, value} = event.target

	if(name==='amountPaid'){
		value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
	  }
  setStudentFee({...studentFee, [name]:value})
  setErrors({...errors, [name]:''})
}






	const handleSubmit = event =>{
        event.preventDefault();   
        
        
        let errors = {};   
		
		if(studentFee.student.length===0){
			errors.student ='Please select student';
		}
		if(!studentFee.paymentDate){
			errors.paymentDate ='Please enter payment date';
		}
		if(!studentFee.receiptNo){
			errors.receiptNo ='Please enter receipt number';
		}
		if(!studentFee.paymentMethod){
			errors.paymentMethod='Please select payment method';
		}
		
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       submit()
    }


    }  


const handleSelected =(data, status) =>{

	let subscribe =  payment.filter(item=>item.extra_code===data.code )

	if(subscribe.length===0){
	let list = allFees.map((item)=>{
		if(item.code ===data.code){
			return {...item, selected:status}
		}
		return item
	})
setAllFees(list)
setStudentFee({...studentFee, pay_now:false})
	}else{

		Alerts('Error!', 'danger', 'Student already registered for the selected item ')
	}
}

const handlePayNow =()=>{

	let total =  allFees.filter((items)=>items.selected==='Yes').reduce((acc, item) => Number(acc) + Number(item.amount), 0)

	setStudentFee({...studentFee, pay_now:true, amountPaid:total})
}

		 
	function submit(){  
    
			const fd = new FormData();

			var options = allFees.filter((items)=>items.selected==='Yes')
			var value = [];
			for (var i = 0, l = (options || []).length; i < l; i++) {
			  value.push(
				{
					extra_code:options[i].code,
					amount:options[i].amount
				
				});
			}


			fd.append('student_code', studentFee.student.value);
			fd.append("extras", JSON.stringify(value, null, 2))
			fd.append('reference', studentFee.receiptNo);
			fd.append('payment_method', studentFee.paymentMethod.value);
			fd.append('date_paid', studentFee.paymentDate);
			fd.append('remark', studentFee.remark);
			setNotice({...notice,  isLoading: true}) 
		
				 let url = API_URL+'/extras/fee/payment'
				axios.post(url, fd, config)
				.then(response =>{
		 			if (response.data.status==200) {
						Alerts('Saved!', 'success', response.data.message)
					}  else{
						Alerts('Error!', 'danger', response.data.message)
				}   
						
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', error.message) 
				})
				.finally(()=>{
					fetchPayment(studentFee.student.value)
				  setNotice({...notice, isLoading: false})  
				 setStudentFee({...studentFee,
					receiptNo:'',
					pay_now:false,
					paymentMethod:[],
					amountPaid:0,
					remark:'',
					paymentDate:''
				 })
				})   
				  
		}


		const handleDelete =(code)=>{
			let close =   document.getElementById('btnWarningDialog-'+code)
			close.click();
		   
			setNotice({...notice, 
				isLoading: true}) 
	
		let url = API_URL+'/extra/payment/destroy/'+code;
			  axios.get(url, config)
			  .then(response =>{
			   if(response.data.type ==='success'){
				  Alerts('Saved!', 'default', response.data.message)
					  } else{
				  Alerts('Error!', 'danger', JSON.stringify(response.data))
					  }   
			  })
			  .catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			  }).finally(()=>{
				  fetchPayment(studentFee.student.value)
				  setNotice({...notice, 
					  isLoading: false
				  })
			  }) 
	}




	const tablePaymentHeader = [
		
		{dataField: 'code', text: 'Action', formatter:(cell, row)=><form method='POST' action={ServerUrl+'/printout/payment_receipt_extra.php?token='+userToken} target='_blank'>
		
		<input type='hidden' name='reference' value ={row.reference} />
         <input type='hidden' name='jwtToken' value ={Token} />
		 <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${cell}`}  className="btn btn-danger btn-sm"    title="Delete"><i className="icofont icofont-delete-alt"></i></a>&nbsp;


		<button type='submit' className="btn btn-info btn-sm"  title='Print' > <i className="fa fa-print"></i> </button>
		
		<WarningModal  message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} />

		</form>},
		{dataField: 'reference', text: 'Receipt No'},
		{dataField: 'term_name', text: 'Term'},
		{dataField: 'title', text: 'Title'},
		{dataField: 'payment_mode', text: 'Mode'}, 
		{dataField: 'amount', text: 'Amount ('+Currency+')',  formatter:(cell)=>FormatNumber(cell)},
		{dataField: 'remark', text: 'Remark', formatter:(cell)=><span style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
		{dataField: 'date_paid', text: 'Date Paid', formatter:(cell)=>longDate(cell)}
		
	 ];

	

	 const TablePayment=(props)=>{
	   
	const { SearchBar } = Search;
	const CustomToggleList = ({
		columns,
		onColumnToggle,
		toggles
	  }) => ( 
	  <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
		  {
			columns
			  .map(column => ({
				...column,
				toggle: toggles[column.dataField]
			  }))
			  .map(column => (
				
				<button
				  type="button"
				  key={ column.dataField }
				  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
				  data-toggle="button"
				  aria-pressed={ column.toggle ? 'true' : 'false' }
				  onClick={ () => onColumnToggle(column.dataField) }
				>
				  { column.text }
				</button>
			  ))
		  }
		</div>
		
	  );
	const customTotal = (from, to, size) => (
	<span >&nbsp;Showing { from } to { to } of { size } items</span>
	);
	const options = {
	showTotal: true,
	paginationTotalRenderer: customTotal,
	sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
	{ text: 'All', value: props.data.length }]
	
	};
	return  <ToolkitProvider search columnToggle 
				keyField='code' data={ props.data } columns={ props.columns } >
					
					{
						props => (
							<div >
						<div className="form-group">

					<SearchBar
								 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
								

	
		
	
		<div className="btn-group dropdown-split-success">
			
			<button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i className="fa fa-search-plus"></i> Columns
			</button>
			
			<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
			<CustomToggleList { ...props.columnToggleProps } />
			</div>
			</div>
									
				<div className="dt-buttons btn-group pull-right"> 
				<form method='POST' action={ServerUrl+'/printout/student_payments_history_extra.php?token='+userToken} target='_blank'>

				<input type='hidden' name='payment' value ={JSON.stringify(payment, null, 2)} />
				<input type='hidden' name='studentCode' value ={studentFee.student.value} />
				<input type='hidden' name='jwtToken' value ={Token} />

				<button type="submit" className="btn btn-outline-primary "  > <i className="fa fa-eye"></i>Print Preview
		</button>
		</form>
		
	</div>                   
	</div>

	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					
				/>
			</div>
			)
					}
</ToolkitProvider>
}



		 useEffect(()=>{
			fetchStudents();
		},[]);

        return ( <>
			
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Extra-Curricular Payment">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Extra-Curricular</a> </li>
                    <li className="breadcrumb-item"><a href="#!"> Payment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-graduation-cap" ></i> LEARNERS</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
										<div className="row">
										<section className="col-md-12">
									

			<div className="form-group">
						<label>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            studentList&&studentList.map((list, idx)=> {
                               return {key:idx, value: list.code, fatherName:list.fatherName, mobile:list.father_mobile, label: list.admissionNumber, studentName:list.studentName, fatherEmail:list.fatherEmail }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName }

onChange={handleSelect}  name="student" value={studentFee.student}  /> 
											</div>
										</section>
									
									</div>
										
							</div>
							</div>

							</div>
		
	
	
{allFees &&allFees.length!==0?
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>EXTRA-CURRICULAR LIST</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
				<div className="card z-depth-0">
				<div className="card-block">
		
				<div className="row">
				<div className="col-md-12 table-responsive"> 
				
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>TERM</th>
						
						<th> TITLE </th>
						<th>AMOUNT ({Currency})</th>
						<th> ACTION</th>
					</tr>
				</thead>
				<tbody >
					{allFees.length!==0?allFees.map((dat, id)=>{
					return	<tr className={dat.selected==='Yes'?'bg-primary':''} key={id}>
						<td>{dat.term_name}</td>
					<td><b>{dat.title}</b> <br/>Only For {"=>"} <span className='text-bold text-danger'> {dat.sections!=='[]'?JSON.parse(dat.sections).map(item=>item.sectionID+', '):''}</span></td>
					
					<td>{FormatNumber(dat.amount)}</td>

					<td>
						{dat.selected==='No'?
					<button type="button" onClick={()=>handleSelected(dat, 'Yes')} className="btn btn-inverse btn-sm ml-2"><i className='fa fa-plus-circle'></i>Select</button>:<button onClick={()=>handleSelected(dat, 'No')} type="button" className="btn btn-warning btn-sm ml-2"><i className='fa fa-minus-circle'></i>Remove</button>}

					
					</td>
						</tr>
					}):[]}
				</tbody>

				<tfoot>
			<tr>
				<th className='text-right text-bold' colSpan={3}>Amount to Pay</th>
				<th >{FormatNumber(allFees.filter((items)=>items.selected==='Yes').reduce((acc, item) => Number(acc) + Number(item.amount), 0))}</th>
			</tr>

			{allFees.filter((items)=>items.selected==='Yes').length>0?<tr>
				<td className='text-right ' colSpan={4}><button onClick={handlePayNow} type="button" className="btn btn-outline-primary btn-block "><i className='fa fa-money'></i>Pay Now</button></td>
			</tr>:[]}
		</tfoot>

			</table>
			
			</div></div>
				
		</div></div>


		<div ref={structure} />
		{studentFee.pay_now?
		<div className="card z-depth-0">
				<div className="card-block">
		
				<div className="row">
				<div className="col-md-12 "> 
				

				<div className="form-group">
				<label> Receipt Number or Payment Reference <span style={{color:'red'}}>*</span></label>
<div className="input-group">
				<input type="text" className={errors.receiptNo?'form-control form-control-danger': 'form-control'} onChange={handleChange} name="receiptNo" value={studentFee.receiptNo} />

				<span className="input-group-addon"  id="basic-addon" onClick={generateReceipt}> <i className="fa fa-gears"></i> &nbsp;Generate Code</span><span className="required-span"> *</span>
				</div>
				<span style={{color:'red'}}>{errors.receiptNo && errors.receiptNo}</span>

            	</div>


            <div className="form-group">
         <label> Payment Method </label>
         <Select  options={[
                    { value: 'CASH', label: 'CASH' },
					{ value: 'BANK', label: 'BANK'},
					{ value: 'CHEQUE', label: 'CHEQUE'},
					{ value: 'OTHERS', label: 'OTHERS'},
                ]} 
			onChange={handleSelectPayment} className={errors.paymentMethod?'form-control form-control-danger': ''} name="paymentMethod" value={studentFee.paymentMethod}  />
 <span style={{color:'red'}}>{errors.paymentMethod && errors.paymentMethod}</span>

            </div>	 	


			<div className="form-group">
         <label> Date of Payment </label>

         <input type="date"  className={errors.paymentDate?'form-control form-control-danger': 'form-control'} onChange={handleChange} name="paymentDate" value={studentFee.paymentDate} />

		  <span style={{color:'red'}}>{errors.paymentDate && errors.paymentDate}</span>

            </div>


			<div className="form-group">
         <label> Amount Paid </label>

         <input type="text" min="0" className={errors.amountPaid?'form-control form-control-danger': 'form-control'} onChange={handleChange} onFocus={(e)=>e.target.select()} name="amountPaid" disabled value={studentFee.amountPaid} />

		  <span style={{color:'red'}}>{errors.amountPaid && errors.amountPaid}</span>

            </div>

			<div className="form-group">
         <label> Remark </label>

		 <textarea  name="remark" className="form-control" placeholder="Remark" value={studentFee.remark} onChange={handleChange} rows="2" >{studentFee.remark}</textarea>
		  
            </div>



			
			</div></div>
				
		</div></div>:''}

		<hr/>

		{studentFee.pay_now?
<footer className="pull-right">
<button type="button" id="submit" className="btn btn-inverse " onClick={handleSubmit}>Save Payment</button>
</footer>:''}
            </div>
            </div> 
                </div>:[]} 


                </div>

{payment.length!==0?
				<div className="row">

				<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Paid List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
	
        <TablePayment data={payment}  columns={tablePaymentHeader} />
                </div>
            </div>
            </div> 
                </div></div>:''}


          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(ExtraPayment) 