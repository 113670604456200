import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'

import { userToken } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useDispatch, useSelector } from 'react-redux'

const TransportMaster =()=> {
    const [errors, setErrors] = useState({});

    const [allVehicle, setAllVehicle]= useState([])
    const staff = useSelector(state => state.rootReducer.staff);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [route, setRoute] = useState({
        code:Math.random().toString(36).substr(2,9),
        vehicleCode:'',
        startPoint:'',
        endPoint:'',
        driver:'',
        cordinator:'[]',
        status:true
    })


    const [allRoute, setAllRoute]= useState([])
    const [activeStep, setActiveStep] = useState(1);

        const fetchAllVehicle =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetch_controller/tbl_vehicle';
            axios.post(url, fd, config).then(result=>setAllVehicle(result.data)) 
        }


    const handleChange = event =>{
		const {name, value} = event.target
        setRoute({...route, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSelect = (option, action)=>{
        setRoute({...route, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }
   

         const fetchAllRoute =()=>{

            setNotice({...notice,   isDataFetching: true})
            let sql = "Select r.ID, r.code, r.coordinator, r.status, r.startPoint, r.endPoint, v.vehicleName, v.vehicleNumber from tbl_route r, tbl_vehicle v where v.code = r.vehicle "
        
            const fd = new FormData();
            fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>setAllRoute(result.data))
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice,   isDataFetching: false})
            })

        }



    const handleCheckbox =()=>{
        const value = !route.status
         setRoute({...route, status : value});
        }

     


        const handleDelete = (ID)=>{  
			setNotice({...notice,  isLoading: true}) 
			   let close =   document.getElementById('btnWarningDialog-'+ID)
				  close.click();
				  const fd = new FormData();
				  fd.append('jwt', Token);
			   fd.append('ID', 'ID')
				fd.append('data', ID)
		
				let url = ServerUrl+'/delete_controller/tbl_route'
			   axios.post(url, fd, config)
			   .then(response =>{
				if(response.data.type ==='success'){
				   Alerts('Saved!', 'success', response.data.message)
					   } else{
				   Alerts('Error!', 'danger', JSON.stringify(response.data))
					   }   
			   })
			   .catch((error)=>{
				 Alerts('Error!', 'danger', error.message)
			   }).finally(()=>{
				   setNotice({...notice, 
					   isLoading: false
				   })
			   }) 
		}

  
        const handleStaffName=(cell,row)=>{
            if(cell!=='[]'){
            return JSON.parse(cell).map((itm, idx)=>{
                return <div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} key={idx}>{getStaff(itm.code)}</div>
            } )
        }else{
            return ''
        }
        }
    
        const getStaff = (code) =>{   
            
             const result = staff.filter(list=>list.code===code);
            const answer = result.length!==0?result.map(c=>`(${c.fileNo}) ${c.staffName}` ):''
    
              return  answer;
            }

    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell)=><div> <a href="#!" data-toggle="modal" className="btn btn-danger btn-sm"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>

<WarningModal  handleAction={()=>handleDelete(cell)} mID={cell} message='Once deleted, you will no be able to recover this record. Are you realy sure?' /> </div>},

        {dataField: 'vehicleName', editable:false, text: 'Vehicle', formatter:(cell, row)=><div>{row.vehicleName+" "+row.vehicleNumber}

        </div> },
          {dataField: 'startPoint', text: 'Start Point', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Start Point is required'
              };
            }
            return true;
          } },
          {dataField: 'endPoint', text: 'End Point', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'End Point is required'
              };
            }
            return true;
          } },
          {dataField: 'coordinator', text: 'Coordinator',  editable:false, formatter:handleStaffName },
          {dataField: 'status', text: 'Status', formatter: (cell, row)=>row.status==='true'? <span className="pcoded-badge label label-success">ACTIVE</span>: <span className="pcoded-badge label label-danger">INACTIVE</span>, editor: {
            type: Type.SELECT, options:[
                {value:'true', label:'Active'},
            {value:'false', label:'Inactive'}]
          }}, 
     ];
	

	 const TableRoute=(props)=>{
	    const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
const handleUpdate=(column, newValue, ID)=>{       
    const fd = new FormData();
            fd.append('jwt', Token);
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
     
       let url = ServerUrl+'/updateCustom_controller/tbl_route'
      axios.post(url, fd, config)
      //.then(result => console.log(result.data))
      .then()
      .catch((error)=>Alerts('Error!', 'danger', error.message)) 
    } 
	const { SearchBar } = Search;

	const customTotal = (from, to, size) => (
	<span >&nbsp;Showing { from } to { to } of { size } items</span>
	);
	const options = {
	showTotal: true,
	paginationTotalRenderer: customTotal,
	sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
	{ text: 'All', value: props.data.length }]
	
	};
	return  <ToolkitProvider search columnToggle 
				keyField='ID' data={ props.data } columns={ props.columns } >
					
					{
						props => (
							<div >
					  <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>


      <div className="dt-buttons btn-group pull-right"> 
    
        
    
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=route_printout'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/route_printout.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div> 
        </div>

	<BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return true;
                         }
                         
                       }
                                 }) }
                             />
			</div>
			)
					}
</ToolkitProvider>
}


         

  
     const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(route));
        setIsSubmitting(true);
    } 

    
const ValidateInput =(values)=>{
	let errors = {};   
    
    if(!values.startPoint){
        errors.startPoint ='Please enter starting Point';
    }
    if(!values.endPoint){
        errors.endPoint ='Please enter end point';
    }
   

    if(values.vehicleCode.length===0){
        errors.vehicleCode ='Please select vehicle';
    }

    return errors;
	}

function submit(){  
      
    setNotice({...notice,  isLoading: true}) 


       const fd = new FormData(); 
       let formData = route; 
      for(let k in formData){
          fd.append(k,formData[k]);
      }; 

      var value = [];
      if(route.cordinator!=='[]'){
        
        var data = route.cordinator;
          for (var i = 0, l = data.length; i < l; i++) {
               value.push({code:data[i].value});
               }
        
      }

      fd.append('coordinators', JSON.stringify(value, null, 2));
   
      fd.append('vehicle', route.vehicleCode.value);
      

    fd.append('jwt', Token);

       let url = ServerUrl+'/save_controller/tbl_route';
	  axios.post(url, fd, config)
	  .then(response =>{
window.scrollTo(0,0)
        if (response.data.type ==='success'){
            setErrors({...errors, successMessage: response.data.message})
            
        } else{
            setErrors({...errors, errorMessage: JSON.stringify(response.data)})
        
            }        
			  
	  })
	  .catch((error)=>{
        setErrors({...errors, errorMessage: error.message})
    })
    .finally(()=>{
        fetchAllRoute()
        setNotice({...notice,  isLoading: false})
      setIsSubmitting(false); 
      setRoute({ 
          code:Math.random().toString(36).substr(2,9),
        vehicleCode:'',
        startPoint:'',
        endPoint:'',
       
        cordinator:'[]',
        status:true}); 
   }) 
}

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

useEffect(()=>{
    fetchAllRoute();
    fetchAllVehicle()
},[]);
    
        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Transport Route">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Transport</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Route</a> </li>
                    </PageHeader>
{/* The Page Body start here */}




<div className="page-body">


{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Vehicle Route</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
           

	   <div className="row setup-content" id="Route">
		    
                                           
                                           <div className="col-sm-12">
                <div className="card z-depth-0">
                    <div className="card-header">
                        <h5><i className="fa fa-edit" ></i> Add Route</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-block">
                    <fieldset>
                        <div className="row">
                                                
                    <section className="col-md-6">
                    
                    <div className="form-group">
    <label >Select Vehicle<span style={{color:'red'}}>*</span></label>

    <div className="form-group">
      <Select  options={
                            allVehicle&&allVehicle.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.vehicleName, number:list.vehicleNumber  }
                             })} 
                        getOptionLabel={option =>`(${option.number}) ${option.label}`}
onChange={handleSelect} className={errors.vehicleCode ? 'form-control form-control-danger' : ''} name="vehicleCode" value={route.vehicleCode}  /> <span style={{color:'red'}}>{errors.vehicleCode && errors.vehicleCode}</span>

          </div>
</div>
</section>

<section className="col-md-6">

<div className="form-group">
    <label >Start Point<span style={{color:'red'}}>*</span></label>
<input type="text" name="startPoint" value={route.startPoint} onChange={handleChange} className={errors.startPoint  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.startPoint && errors.startPoint}</span>	
</div>



</section>
<section className="col-md-6">
<div className="form-group">
    <label >End Point<span style={{color:'red'}}>*</span></label>
<input type="text" name="endPoint" value={route.endPoint} onChange={handleChange} className={errors.endPoint  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.endPoint && errors.endPoint}</span>	
</div>


</section>
<section className="col-md-6">

<div className="form-group">
    <label >Coordinators</label>

    <div className="form-group">
      <Select isMulti options={
                            staff&&staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.staffName, phone:list.mobile  }
                             })} 
                        getOptionLabel={option =>`(${option.phone}) ${option.label}`}
onChange={handleSelect} className={errors.cordinator ? 'form-control form-control-danger' : ''} name="cordinator" value={route.cordinator}  /> <span style={{color:'red'}}>{errors.cordinator && errors.cordinator}</span>

          </div>
</div>
 </section>


 <section className="col-md-6">
    <label>Status</label>
                                        
        
<div className="border-checkbox-section">
                    
            <div className="border-checkbox-group border-checkbox-group-primary">
                <input className="border-checkbox" type="checkbox" onChange={handleCheckbox} name="status" value={route.status} checked={route.status} id="status" />
                        <label className="border-checkbox-label" htmlFor="status">Active</label>
                    </div>
                    
                    </div> 

                    </section>
        </div>

	</fieldset>
            
                <footer className="pull-right">
                
                <button type="button" className="btn btn-inverse "><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
                <button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
                
                                            </footer>				
                                        </div>
                                        </div>
                                        
                                        </div>
                                        </div>


                                        <div className="row">
            
                <div className="col-md-12">
                <div className="card z-depth-0">
                    <div className="card-header">
                        <h5> <i className="fa fa-th" ></i> Listing All Route</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-block">
            
            
                      <div className="card z-depth-0">
                        <div className="card-block">
                    <div className="col-md-12 table-responsive">
                    {notice.isDataFetching ? <TableLoader />:
               <TableRoute data={allRoute} columns={tableHeader}  />} 
            </div>
                </div>
                </div></div></div>
            </div>
            </div>  

                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
               
   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(TransportMaster) 