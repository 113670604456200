import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'

import {formatGroupLabel, getDays, getTime} from '../component/globalFunction'
const TimetableDate =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([]) 
    const [code, setCode]= useState('')
    const [tableList, setTableList] = useState([]);
    const [periodList, setPeriodList] = useState([]);
    const [lessonPlan, setLessonPlan] = useState([]);
    const [staff, setStaff]= useState([])
    const [timetable, setTimetable]=useState({
    schoolClass:[],
    tableDate:'',
    status:'No',
    notification:'No',
    comment:''
    })
    const [activities, setActivities]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [CurrentSession, setCurrentSession] = useState('')
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
    const fetchSchoolClass =()=>{
        const fd = new FormData();
fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 

    }

    const fetchSubject =()=>{
        const fd = new FormData();
fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }

    const  fetchActivities =()=>{  
        
        const fd = new FormData();
        fd.append('jwt', Token);
   let sql = "Select ID, activity from tbl_activities order by activity ASC"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setActivities(result.data)) 
   
    }  
    

    const  fetchStaff =()=>{  
        
        const fd = new FormData();
fd.append('jwt', Token);
   let sql = "Select code, staffName, fileNo from tbl_staff"
       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
      
       axios.post(url, fd, config).then(result =>setStaff(result.data)) 
   
    }    
    const handleChange=(event)=>{
        let {name, value} = event.target
        if(name==='status'){
            value = value==='No'?'Yes':'No'
        }
        setTimetable({...timetable, [name]:value});
        if(name==='tableDate'){
            fetchPeriodList( timetable.schoolClass.value, value)
        }
        setLessonPlan('') 
        setErrors({...errors, [name]:''})
    }

    const handleSelect = (option, action)=>{
        setTimetable({...timetable, [action.name]: option});
        setErrors({...errors, [action.name]:''})  
        setLessonPlan('')         
        fetchPeriodList(option.value, timetable.tableDate)

    }

    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(timetable));
        setIsSubmitting(true);
    } 

    const ValidateErrors=(values)=>{
        let errors = {};  
       if(!timetable.tableDate){
            errors.tableDate ='Please select day';
        }
         if(values.schoolClass.length===0){
                errors.schoolClass ='Please select class';
            }
        return errors;
        }


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
        fetchTableList()
    }
    },[errors])

       
       
        const  fetchPeriodList =(section, lessonDate)=>{     
           let sql = "Select periodList from tbl_timetable where day = '"+getDays(lessonDate)+"' and  sessionYear = '"+CurrentSession+"' and sectionID ='"+section+"'"
    
           const fd = new FormData();
fd.append('jwt', Token);
           fd.append("sql", sql);
           let url = ServerUrl+'/fetchBySql_controller'
           axios.post(url, fd, config).then(result =>{
                if(result.data.length!==0){
                    const res = result.data[0].periodList
                    setPeriodList(JSON.parse(res))
                    }else{
                        setPeriodList('')
                    }
                })
            }  


    
    const  fetchTableList =()=>{  
       
        setNotice({...notice, 
            isLoading: true})
            
       let sql = "Select code, lessonPlan from tbl_lesson_plan where lessonDate = '"+timetable.tableDate+"' and  sessionYear = '"+CurrentSession+"' and sectionID ='"+timetable.schoolClass.value+"'"

       const fd = new FormData();
fd.append('jwt', Token);
       fd.append("sql", sql);
       let url = ServerUrl+'/fetchBySql_controller'
       axios.post(url, fd, config).then(result =>{

            if(result.data.length!==0){
                
                const res = result.data[0].lessonPlan
                    setCode(result.data[0].code)
                    setLessonPlan(JSON.parse(res))
                }else if(periodList.length!==0){
                    setLessonPlan(periodList)
                }else{
                    Alerts('Error!', 'danger', 'Class timetable not yet setup')
                }
    
        setNotice({...notice, 
            isLoading: false})
            }) 
    
            setIsSubmitting(false)
        }  


    const getWeekNumber=(date)=>{
        let now = new Date(date)
        let year = now.getFullYear()
        let oneJan = new Date(now.getFullYear(), 0, 1);
        let week = Math.ceil((((now.getTime()-oneJan.getTime())/86400000)+oneJan.getDay()+1)/7)
      
        return year+'/'+week
    }
    
    const handleChangePeriod = (e, index)=>{
        const std =JSON.parse(JSON.stringify(lessonPlan))   
            const value =e.target.value;
           std[index][e.target.name] = value;
        setLessonPlan(std);
    }


    const createNewRow =()=>{
        setLessonPlan(lessonPlan.concat({ID:Math.random().toString(36).substr(2, 9), periodName:'', startTime:'', endTime:'', periodType:'', subject:'', staff:'', chapter:'', topic:''}))
        setErrors({...errors, lessonPlan:''})
    }

    const removeRow=(index)=>{
        setLessonPlan(lessonPlan.filter((_, i) => i !== index)); 
       }


    const  submit=()=>{  
              
    setNotice({...notice, 
        isLoading: true})   

        const fd = new FormData();
        fd.append('jwt', Token);
       
              let url = ServerUrl+'/save_controller/tbl_lesson_plan'          
                if(timetable.status==='No'){   
                fd.append('classID',  timetable.schoolClass.classID)
                fd.append('sectionID',  timetable.schoolClass.value)
                fd.append('lessonDate',  timetable.tableDate)
                fd.append('day',  getDays(timetable.tableDate))
                fd.append('sessionYear',  CurrentSession)
                fd.append('weekNumber',  getWeekNumber(timetable.tableDate))                
                fd.append('lessonPlan',  JSON.stringify(lessonPlan, null, 2))
                fd.append('code',  code)  
                }else{
         url = ServerUrl+'/save_controller/tbl_events_holidays_specific'
                    let datelist = []
                    datelist.push(timetable.tableDate)
                    fd.append('title',  timetable.comment)
                    fd.append('eventType',  'Holidays') 
                    fd.append('sessionYear',  CurrentSession)                   
                    fd.append('dateList',  JSON.stringify(datelist, null, 2))
                }
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Update!', 'default', response.data.message)
                            }else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
        setTimetable({
            schoolClass:[],
    tableDate:'',
    status:'No',
    notification:'No',
    comment:''
        })
        setNotice({...notice, 
            isLoading: false}) 
        setLessonPlan([])
        setPeriodList('')
     }    )
    
    }



useEffect(()=>{
    fetchSchoolClass()
    fetchSubject()
    fetchStaff()    
    fetchActivities()
    handleSession()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Class Lesson Plan">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Lesson Plan</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Define Lesson Plan</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
      <div className="form-group">
						<label >Select Class<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            schoolClass.map((list, idx)=> {
                                return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.schoolClass ? 'form-control form-control-danger' : ''}  name="schoolClass" value={timetable.schoolClass}   /> 
								
<span style={{color:'red'}}>{errors.schoolClass && errors.schoolClass}</span>				
											</div>	
   

          <div className="form-group">
 <div className="checkbox-color checkbox-primary">
                     <input id='status' name="status" value={timetable.status} type="checkbox" onChange={handleChange} checked={timetable.status==='Yes'? true:false}  /> 
                     <label htmlFor='status'>Mark As Holiday</label>
                     </div>
   </div>

</section>

<section className="col-md-6">
   

   <div className="form-group">
<label >To Date</label>
<input type="date" className={errors.tableDate ? 'form-control form-control-danger' : 'form-control'} name="tableDate"  onChange={handleChange} value={timetable.tableDate} />
<span style={{color:'red'}}>{errors.tableDate && errors.tableDate}</span>
   </div>

</section>


				</div>	
                <div  className="row" >
                
   <section style={{display:timetable.status==='Yes'?'block':'none'}} className="col-md-6">
   <div className="form-group">
<label >Comment</label>
<textarea type="text" className={errors.comment ? 'form-control form-control-danger' : 'form-control'} name="comment" rows="3"  onChange={handleChange} value={timetable.comment} >{timetable.comment}  </textarea><span style={{color:'red'}}>{errors.comment && errors.comment}</span>

</div>
</section>
<section style={{display:timetable.status==='Yes'?'block':'none'}} className="col-md-6">
  
   
<div  className="form-group"><label >Holiday Notification Will Be Sent</label>
     <div className="form-radio">
<div className="radio radio-inline"><label>
        <input type="radio" checked={timetable.notification ==="Yes"} onChange={handleChange} value='Yes' name='notification' />
            <i className="helper"></i>Yes</label></div>

            <div className="radio radio-inline"><label>
        <input type="radio" checked={timetable.notification ==="No"} onChange={handleChange} value="No" name='notification' />
            <i className="helper"></i>No</label></div>
</div> </div> 

</section> 
<span style={{color:'red'}}>  {errors.lessonPlan && errors.lessonPlan}</span>
 </div>

{timetable.status==='No'?
 <div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>		</section>		
    </div>:
    
    <div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={()=>submit()}  className="btn btn-inverse "><i className="fa fa-save"></i> Submit</button>
								</footer>		</section>		
    </div>
    
    }
 </div> </div> 
							
</div>

</div>   



<div style={{display:timetable.status==='Yes'?'none':'block'}} className="card z-depth-0" >
		<div style={{display:lessonPlan.length===0?'none':'block'}} className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
    <th><a onClick={createNewRow} href="#!"><i className="fa fa-plus"></i> Add</a></th>
                    <th>Period Name</th>
                    <th>Time</th>
                    <th>Period Type</th>  
                    <th>Subject</th>
                    <th>Teacher</th>
                    <th>Chapter</th>
                    <th>Topic</th>   
                </tr>
            </thead>
            <tbody>
                {lessonPlan&&lessonPlan.map((pl, id)=>
            <tr key={id}>
                <td> <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                    <td><input type="text" className="form-control" name='periodName' value={pl.periodName}  onChange={(e)=>handleChangePeriod(e, id)} /></td>
                    <td><b>{getTime(pl.startTime)+' - '+getTime(pl.endTime)}</b> </td>
                    <td>
                    <select onChange={(e)=>handleChangePeriod(e, id)}  name="periodType"  value={pl.periodType} className="form-control">
                        <option value="">Select Type</option>
                        { activities&&activities.map((list, idx)=> {
                               return  <option key={idx} value={list.ID} >{list.activity}</option> })}
                        </select>
                   
                    </td>
                    <td>
                    <select onChange={(e)=>handleChangePeriod(e, id)}  name="subject"  value={pl.subject} className="form-control">

                    <option value="">Select</option>
                   { subject&&subject.map((list, idx)=> {
                               return  <option key={idx} value={list.code} >{list.subjectName} ({list.abbreviation})</option> })}
                        </select>
                    

                    </td>
                    <td>
                    <select onChange={(e)=>handleChangePeriod(e, id)}  name="staff"  value={pl.staff} className="form-control">

<option value="">Select</option>
{ staff&&staff.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.fileNo} {list.staffName}</option> })}
    </select>

                    </td>
                    <td><input type="text" className="form-control" name='chapter' value={pl.chapter}  onChange={(e)=>handleChangePeriod(e, id)} /></td>
                    <td><input type="text" className="form-control" name='topic' value={pl.topic}  onChange={(e)=>handleChangePeriod(e, id)} /></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <td colSpan='9'>
                <footer className="pull-right">
	
	<button type="button" id="submit" onClick={()=>submit()}  className="btn btn-inverse "><i className="fa fa-save"></i> Save</button>
								</footer>
                </td>
            </tfoot>
        </table>

        </div>
        </div>
        </div>
</div>


     

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(TimetableDate) 