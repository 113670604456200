import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token, config, schoolName, API_URL} from '../component/include'

import Select from 'react-select'
import { getBritishDate } from '../component/globalFunction'

const DeleteAttendance =()=> {
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState([])
    const [schoolInfo, setSchoolInfo]=useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
   
    const [attendance, setAttendance] = useState({
        attendanceType:{value:'tbl_student_attendance', label:'Student'},
        attendanceDate:'',
    })



    const fetchSchoolInfo =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }
       

    const DeleteThis = async()=>{
        setNotice({...notice, isLoading: true}) 
            let close =   document.getElementById('btnWarningDialog-cell')
               close.click();

            let url = API_URL+'/student/attendance/destroy/'+attendance.attendanceDate
      await axios.get(url, config)
            .then(response =>{

             if(response.data.status ===200){
                Alerts('Saved!', 'success', 'Attendance Deleted')
                setResult([])
                    } else{
                Alerts('Error!', 'danger', response.data.message)
                    }   
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false
                })
            }) 
    }
              
        const handleSelect= (option, action)=>{

            setAttendance({...attendance, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            setResult([])
        }

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setAttendance({...attendance, [name]:value})
            setResult([])
            setErrors({...errors, [name]:''})
        }
       
  
     const fetchResult =()=>{
                 if(!attendance.attendanceDate){
                    setErrors({...errors, attendanceDate:'Please select attendnace date'})
                
                }  else{
                    let sql = ''

            if(attendance.attendanceType.label==='Student'){

                sql =   'SELECT  s.admissionNumber, (case when a.indicator  = "P" then 2 else 0 end) as P, (case when a.indicator  = "H" then 1 else 0 end) as H, (case when a.indicator   = "L" then 2 else 0 end) as L,  (case when a.indicator  = "A" then 2 else 0 end) as A, s.studentName, a.attendanceDate from tbl_students s, tbl_student_attendance a where s.code = a.studentCode and a.attendanceDate = "'+attendance.attendanceDate+'"'
            }

            if(attendance.attendanceType.label==='Staff'){

                sql =   'SELECT  s.fileNo as admissionNumber, s.staffName as studentName, (case when a.attendance  = "P" then 2 else 0 end) as P, (case when a.attendance   = "H" then 1 else 0 end) as H, (case when a.attendance   = "A" then 2 else 0 end) as A, (case when a.attendance   = "L" then 2 else 0 end) as L, a.attendanceDate from tbl_staff s, tbl_staff_attendance a where s.code = a.staffCode and a.attendanceDate = "'+attendance.attendanceDate+'"'
            }
            
            
  setNotice({...notice, isLoading: true}) 
            const fd = new FormData();
            fd.append("sql", sql);
            let url = API_URL+'/exam/result/fetch_raw';
            axios.post(url, fd, config).then(response=>{
                if(response.data.data.length!==0){
                    setResult(response.data.data)
                    window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
                }else{
                    Alerts('Info!', 'default', 'No record found for the selected criterials')
                    setResult([])
                }
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })  
        } 
            }
    
     
     useEffect(()=>{
        fetchSchoolInfo()
    },[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}
                   
         <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={DeleteThis} mID={'cell'} />  


        <PageHeader title="Delete Attendance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!"> Delete</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Attendance</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
            
     <div className="alert alert-primary">
        <h3><p className="alert alert-danger border-danger background-danger" >Important : Choose Date Carefully</p></h3>

     <p> This should be  used <strong >when you have to delete attendance. </strong></p>
        
 <p >Attendance deleted here cannot be recovered it can only be remarked.</p>
						
	</div>


        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-6">
            <div className="form-group">
          <label> Attendance Type<span style={{color:'red'}}>*</span></label>
          <Select  options={[
            {value:'tbl_student_attendance', label:'Student'},
            {value:'tbl_staff_attendance', label:'Staff'}]
                         } 
                         onChange={handleSelect}     
                name="attendanceType" value={attendance.attendanceType}  /> 
        <span style={{color:'red'}}>{errors.attendanceType && errors.attendanceType}</span>
             </div>

      </section>
      

      <section className="col-md-6">
<div className="form-group">
<label >Attendance Date <span style={{color:'red'}}>*</span></label>
<input type="date" className={errors.attendanceDate ? 'form-control form-control-danger' : 'form-control'} name="attendanceDate"  onChange={handleChange} value={attendance.attendanceDate} />
<span style={{color:'red'}}>{errors.attendanceDate && errors.attendanceDate}</span>
   </div>
   </section>

				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchResult} className="btn btn-primary "><i className="fa fa-search"></i> Search Attendance</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
                <tr>
                <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={'schoolName'}  />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                     
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Attendance ({getBritishDate(attendance.attendanceDate)}) </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

     
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>S/N</th>
                <th>Reg No</th>
                    <th >Name</th>
                    <th>P</th>
                    <th>H</th>
                    <th>A</th>
                    <th>L</th>
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                <td >{st.admissionNumber}</td>
                    <td >{st.studentName}</td>
                <td>{st.P}</td>
                <td>{st.H}</td> 
                    <td>{st.A}</td>  
                    <td>{st.L}</td>        
                </tr>):[]}
              </tbody>

        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <button type="button" className="btn btn-danger  no-print" data-toggle="modal"   data-target={'#warningdialog-cell'} > <i className="fa fa-trash"></i> Delete Attendnace</button>
        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(DeleteAttendance) 