import React from 'react'
//import InputMask from 'react-input-mask'

export const FormLabel = (props) =>{
    return  <label className="bold pull-right">{props.name}	<a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
            
            <span className="tooltip-text3">
                <span className="tooltip-inner2">{props.toolTip}</span>
                </span>
            </span>
            </a>
            </label>
             
}


export const RadioButton = (props) =>{

    return  <div className="radio radio-inline">
                <label>
                    <input type="radio" onChange={props.handleChange} checked={props.checked} value={props.value} name={props.name} />
                    <i className="helper"></i>{props.label}
                </label>
            </div>
}

export const InputRequiredIcon = React.memo((props) =>{

    return  <div className="form-group">
			<label >{props.label}<span style={{color:'red'}}>*</span></label>
			<div className={props.classGroup}>
				<span className="input-group-addon">
		<i className={props.icon}></i>
		</span>
        <input type={props.type} id={props.name} name={props.name} defaultValue={props.value}  className={props.className} onChange={props.handleChange} placeholder={props.label} />
			</div></div>
})
export const InputMaskIcon = React.memo((props) =>{

    return  <div className="form-group">
			
            {/* <label >{props.label}</label>
			<div className="input-group input-group-primary">
				
            use cookies instead of localstorage
            <span className="input-group-addon">
                var email = localStorage.getItem('email'), password = localStorage.getItem('password');
$.POST('login_response.php', {'email':email,'password':password}, function(data){
  alert('Login Successful.  Redirect to a different page or something here.');
}



KAYUS: The smart choice for school, family and community
		<i className={props.icon}></i>
		</span>
        <InputMask mask={props.mask} name={props.name} value={props.value}  className="form-control" onChange={props.handleChange} placeholder={props.label} />
			</div> */}
            
            </div>
})
export const MaskInput = React.memo((props) =>{

    return <div className="form-group">
                        <label >{props.label}</label>
                        <input mask={props.mask} name={props.name} value={props.value}  className="form-control" onChange={props.handleChange} placeholder={props.label} />
                                    
            </div>
})
export const InputIcon = React.memo((props) =>{

    return <div className="form-group">
			<label >{props.label}</label>
			<div className={props.classGroup}>
				<span className="input-group-addon">
		<i className={props.icon}></i>
		</span>
        <input type={props.type} id={props.name} name={props.name} defaultValue={props.value}  className={props.className} onChange={props.handleChange} placeholder={props.label} />
			</div></div>
})
 const InputRequired = (props) =>{

    return  <div className="form-group">
                        <label >{props.label}<span style={{color:'red'}}>*</span></label>
                        <input type={props.type} id={props.name} onChange={props.handleChange} name={props.name} defaultValue={props.value} required className={props.className} placeholder={props.label} />
                                    
            </div>
}
export const Input = React.memo((props) =>{

    return <div className="form-group">
                        <label >{props.label}</label>
                        <input type={props.type} onChange={props.handleChange} id={props.name} name={props.name} defaultValue={props.value} className={props.className} placeholder={props.label} />
                                    
            </div>
})
export const TextArea = React.memo((props) =>{

    return <div className="form-group">
                <label htmlFor={props.name} className="block">{props.label} </label>
                <textarea type="text" value={props.textValue} className={props.className} onChange={props.handleChange} id={props.name} name={props.name} rows={props.rows}  placeholder={props.label}>{props.textValue}</textarea>
                    
                </div>
})
export default React.memo(InputRequired)