import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, staffName} from '../component/include'
import Select from 'react-select'


 const AssetRegistration =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
	const initial = {
	
		assetName:'',
		category:'',
		assetType:'',
		barcode:'',
		serialNo:'',
		model:'',
		remarks:'',
		purchaseDate:'',
		purchaseAmount:'0',
		warantyExpiryDate:'0000-00-00',
		currentLocation:'',
		department:'',
		lifeSpan:'',
		description:'',
		isMovable:'Yes',
		isInsured:'No',
		insuranceDate:'0000-00-00',
		createBy:staffName	
	}

const [asset, setAsset] = useState(initial)
const [image, setImage] = useState('')
const [errors, setErrors] = useState({});
const [isSubmitting, setIsSubmitting] = useState(false);
const [department, setDepartment]=useState([])

const fetchDepartment =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
	let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1';
	axios.post(url, fd, config)
	.then(result =>setDepartment(result.data))
}


const handleSubmit = event =>{
	event.preventDefault();
	 
setErrors(ValidateInput(asset));
ValidateExistence();
	setIsSubmitting(true);
} 

const handleBack =()=>{
	setAsset(initial)
}


function submit(){  
      
	let formData = asset; 
	const fd = new FormData();
            fd.append('jwt', Token);
	for(let k in formData){
        fd.append(k,formData[k]);
    }; 

	if(image!==''){
		fd.append('image', image);
	}

	 fd.append('departments', asset.department.value);
	 fd.append('condition', asset.assetType.value);
	 fd.append('categories', asset.category.value);
	 fd.append('code', Math.random().toString(36).substr(2,9));
 setNotice({...notice,  isLoading: true}) 

	let url = ServerUrl+'/save_controller/tbl_assets'
   axios.post(url, fd, config)
   .then(response =>{

	window.scrollTo(0,0)
	if (response.data.type ==='success'){
		setErrors({...errors, successMessage: response.data.message})
		
	} else{
		setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	
		}        
		  
  })
  .catch((error)=>{
	setErrors({...errors, errorMessage: error.message})
})
.finally(()=>{
	setImage('');
  document.getElementById('image').value= '';
  setNotice({...notice, isLoading: false}) 
handleBack(); 
setIsSubmitting(false)
})  

}


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
	},[errors])
	

const ValidateInput =(values)=>{
let errors = {};   
if(!values.assetName){
	errors.assetName ='Please enter asset name';
	window.scrollTo(0,0);
}

if(!values.purchaseDate){
	errors.purchaseDate ='Please choose purchase Date';
}

if(values.assetType.length===0){
	errors.assetType ='Please select asset type';
}

if(values.category.length===0){
	errors.category ='Please select asset category';
}

return errors;
}


const ValidateExistence=()=>{
	
	if(asset.barcode!==''){
		const fd = new FormData();
            fd.append('jwt', Token); 
		fd.append('data', asset.barcode);
		
		let url = ServerUrl + '/checkExistence/tbl_assets/barcode'
		axios.post(url, fd, config).then(response=>{
			if(response.data===true){
				setErrors({...errors, barcode:asset.barcode+' already exist'})
				setAsset({...asset, barcode:''});
			}
	  })
	}
}

const  handleFile = function(fieldName){
    return function(newValue){  
      
   readURL(newValue);
   }
   
} 


const readURL = (input)=>{
   
    let displayMessage = '';
    const PreviewImage = input.target.name;
      //  let newImage = "viewPassport";
        var fileName = document.getElementById(PreviewImage).value;

    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = input.target.name +': File uploaded is more than maximum size Allow. it exceeds 1 Mb';
       document.getElementById(input.target.name).value = '';
      
       setImage('')
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            setImage(input.target.files[0])
            
        }
    }
    }else{

        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 900kb'
        document.getElementById(input.target.name).value = '';
       
        setImage('')
    }   
    
    if(displayMessage.length > 0){
        setErrors({...errors, errorMessage: displayMessage})
        
      }
    
} 

const handleChange = event =>{
	const {name, value} = event.target
	setAsset({...asset, [name] : value });
   setErrors({...errors, [name]:''});
}

const handleSelect = (option, action)=>{
	setAsset({...asset, [action.name]: option});
	setErrors({...errors, [action.name]:''})
	 }
	 
	
useEffect(()=>{
    fetchDepartment()
 },[]);
        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Asset Registration">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="/assets/view_all">Assets</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">


	
{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

	
								
								<div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Asset Information</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
		<div className="card z-depth-0">
		<div className="card-block">
			<div className="row">
									
										<section className="col-md-6">
										
										<div className="form-group">
						<label >Asset Name<span style={{color:'red'}}>*</span></label>
						<input type="text" name="assetName" value={asset.assetName} onChange={handleChange} className={errors.assetName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.assetName && errors.assetName}</span>	
							
											</div>
											<div className="form-group">
						<label >Select Category <span style={{color:'red'}}>*</span></label>
						<Select  options={[
                    { value: 'Funitures & Fittings', label: 'Funitures & Fittings' },
                    { value: 'Office Equipment', label: 'Office Equipment' },
					{ value: 'Computers', label: 'Computers' },
					{ value: 'Automobile', label: 'Automobile' },
					{ value: 'Others', label: 'Others' }
                ]} 
				className={errors.category ? 'form-control form-control-danger' : ''}  onChange={handleSelect}  name="category" value={asset.category}   /> 
							<span style={{color:'red'}}>{errors.category && errors.category}</span>
											</div>

											<div className="form-group">
						<label >Select Condition <span style={{color:'red'}}>*</span></label>
						<Select  options={[
                    { value: 'New', label: 'New' },
                    { value: 'Old', label: 'Old' },
					{ value: 'Used', label: 'Used' },
					{ value: 'Missing', label: 'Missing' },
					{ value: 'Out for Repair', label: 'Out for Repair' },
					{ value: 'Lost / Stolen', label: 'Lost / Stolen' },
					{ value: 'Broken - Not Repairable', label: 'Broken - Not Repairable' },
					{ value: 'Damage - awaiting Service', label: 'Damage - awaiting Service' }
					

                ]} 
				className={errors.assetType ? 'form-control form-control-danger' : ''}    onChange={handleSelect}  name="assetType" value={asset.assetType}   /> 
					
					<span style={{color:'red'}}>{errors.assetType && errors.assetType}</span>
											</div>		
										</section>
										
					<section className="col-md-6">
					<div className="form-group">
						<label >Barcode</label>
						<input type="text" name="barcode" onBlur={ValidateExistence} autoComplete="false" value={asset.barcode} onChange={handleChange} className={errors.barcode  ? 'form-control form-control-danger' : 'form-control'} /> 
						
											</div>

											<div className="form-group">
						<label >Registered / Serial Number</label>
						<input type="text" name="serialNo" value={asset.serialNo} onChange={handleChange} className='form-control' />
												
											</div>

											<div className="form-group">
						<label >Model</label>
						<input type="text" name="model" value={asset.model} onChange={handleChange} className='form-control' />
												
											</div>
							</section></div></div></div>


								
      <div className="card z-depth-0">
	
    <div className="card-block">
        <div className="row">

        <div className="col-sm-12" id="staffAttendance" >
        <h4 >Asset Location </h4><hr/><br/>
        </div>
        </div>

    <div className="row">
    <section className="col-md-6">
	<div className="form-group">
						<label > Department</label> 
						<Select options={
                            department&&department.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.departmentName }
                             })} 
onChange={handleSelect}  name="department" value={asset.department}  /> 
												
			</div>


                <div className="form-group">
    <label >Current Location </label>
    <input type="text" className='form-control' name="currentLocation" onChange={handleChange} value={asset.currentLocation} />
                </div>


                <div className="form-group">
    <label >Description</label>
    <input type="text" className='form-control'  name="description" onChange={handleChange} value={asset.description} />

           </div>

</section>	
                            
                            <section className="col-md-6">
                                
							<div className="form-group">
						<label >Purchase Date <span style={{color:'red'}}>*</span></label>
					<input type="date" name="purchaseDate" value={asset.purchaseDate} onChange={handleChange} className={errors.purchaseDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.purchaseDate  && errors.purchaseDate}</span>
											</div>

											<div className="form-group">
						<label >Purchase Amount</label>
						<input type="number" name="purchaseAmount" value={asset.purchaseAmount} onChange={handleChange} className='form-control' /> 
												
											</div>

                <div className="form-group">
                    <label >Is Movable</label>
                    
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={asset.isMovable==="Yes"?true:false} onChange={handleChange} value={"Yes"} name="isMovable" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={asset.isMovable ==="No"?true:false} onChange={handleChange} value={"No"} name="isMovable"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    
                    </div>
                            </section>

                            </div>
                            </div>
    
    </div>

			<div className="card z-depth-0">
		<div className="card-block">

		<div className="row">

<div className="col-sm-12" id="staffAttendance" >
<h4 >Fair Usage </h4><hr/><br/>
</div>
</div>
						<div className="row">			
										<section className="col-md-6">

										<div className="form-group">
						<label >Waranty Expiry Date</label>
						<input type="date" name="warantyExpiryDate" value={asset.warantyExpiryDate} onChange={handleChange} className='form-control' />
												
											</div>
										
											<div className="form-group">
						<label >Expected Life Span</label>
						<input type="number" name="lifeSpan" value={asset.lifeSpan} onChange={handleChange} className='form-control' />
												
											</div>		
											<div className="form-group">
						<label >Remarks</label>
						<input type="text" name="remarks" value={asset.remarks} onChange={handleChange} className='form-control' />
												
											</div>
										
										</section>
										<section className="col-md-6">
																					
										<div className="form-group">
                    <label >Is Insured?</label>
                    
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={asset.isInsured==="Yes"} onChange={handleChange} value={"Yes"} name="isInsured" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={asset.isInsured ==="No"} onChange={handleChange} value={"No"} name="isInsured"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    
                    </div>
					<div className="form-group">
						<label >Insurance Date</label>
						<input type="date" name="insuranceDate" value={asset.insuranceDate} onChange={handleChange} className='form-control' />
												
											</div>

										 <div className="form-group">
                           <label >Asset Image</label><br/>
                  <input id="image"  onChange={handleFile('image')} className='form-control' name="image" type="file" accept="image/*" /> <span style={{color:'red'}}>{errors.image && errors.image}</span>
          <div className="note" style={{color:'red'}}>
          <strong>Size 900 kb,</strong>gif|jpg|png|jpeg Only Allow
      </div>	   	
              </div>
										</section>
										</div></div></div>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "> <i className='fa fa-home'></i> Save Asset</button>
	
								</footer>				
							</div>
							</div>
							</div>
							</div>
							
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(AssetRegistration) 