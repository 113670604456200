import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, Currency, config} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, sort, formatGroupLabel, FormatNumber, getClassName } from '../component/globalFunction'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
const DiscountFees =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
  
    const [feecharge, setFeecharge] = useState([]);
const [studentFee, setStudentFee]=useState({
    studentClass:[],
    feecharge:[],
    remark:''
});
const [errors, setErrors] = useState({});
const [students, setStudents] =  useState([]);
    


const fetchFeecharge =()=>{
    const fd = new FormData();
            fd.append('jwt', Token);
    let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
    axios.post(url, fd, config).then(result=>setFeecharge(result.data.filter(item=>item.status=='true')))
}


       
        const handleSelect = (option, action)=>{
            
            setStudentFee({...studentFee, [action.name]: option});
            setErrors({...errors, [action.name]:''})
            setStudents('')
         }


        






   
const handleFetchStudent=()=>{
    setNotice({...notice,  isLoading: true})
    var sql ="SELECT s.admissionNumber, s.studentName, s.code as studentCode, f.feeCharge, f.total, f.payment_status, f.discountAmount, f.remark, f.code, f.classID, f.sectionID,  f.school_fee, f.tuition_fee, f.discount_percent FROM tbl_student_fees f,  tbl_students s WHERE f.studentCode = s.code  and s.admissionStatus = 'Studying'  and f.feeCharge ='"+studentFee.feecharge.value+"' and   f.sectionID ='"+studentFee.studentClass.value+"'  and f.discountAmount >0 " ;
    
	const fd = new FormData();
            fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	axios.post(url, fd, config).then(result=>{

        if(result.data.length!==0){
        setStudents(result.data)
        }
    })
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
            
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
    })
}


     

const handleDelete = (row)=>{  
    setNotice({...notice, 
       isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+row.code)
          close.click();
          const fd = new FormData();
            fd.append('jwt', Token);
          fd.append('code', row.studentCode)
           fd.append('feesCharge', row.feeCharge)
           fd.append('sectionID', row.sectionID)

           let url = ServerUrl+'/update_controller/tbl_delete_fees'
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='info'){
              Alerts('Info!', 'info', response.data.message)
                  } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }   
          })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
         
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}




      const tableStudentHeader = [
        {dataField: 'admissionNumber', footer:'Admission Number', text: 'Admission Number',  editable: false},
        {dataField: 'studentName',  footer:'Student Name', text: 'Student Name',  editable: false},
        
        {dataField: 'school_fee', text: 'Actual Fee ('+Currency+')', footer:columnData => FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)),  editable: false, formatter:(cell,row)=>FormatNumber(Number(row.total) + Number(row.discountAmount))},


        {dataField: 'discountAmount', text: 'Discount ('+Currency+')',  editable: false,  footer:columnData => FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), formatter: (cell)=>FormatNumber(cell) },
        {dataField: 'total', text: 'To Pay ('+Currency+')',  footer:columnData => FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), formatter: (cell)=>FormatNumber(cell),  editable: false, formatter:(cell)=>FormatNumber(cell)},


        {dataField: 'payment_status', text: 'Status',   footer:'Status', editable: false, formatter: (cell)=>cell==='PAID'?<span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>},
        {dataField: 'remark', text: 'Remark',  footer:'Remark',  editable: false},
        {dataField: 'code', text: 'Action', footer:'Action', editable: false, formatter:(cell, row)=><div>
            
            <form method='POST' action={ServerUrl+'/printout/student_fees_preview.php?token='+userToken} target='_blank'>
        <input type='hidden' name='feecharge' value ={row.feeCharge} />
                <input type='hidden' name='student' value ={row.studentCode} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='section' value ={row.sectionID} />
                <button type="submit" className="btn btn-inverse btn-sm " title='Preview' > <i className="fa fa-print"></i> Print</button>
                &nbsp;
                <button type="button" className="btn btn-danger btn-sm "  data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button>
        </form>
        <WarningModal  handleAction={()=>handleDelete(row)} mID={cell} message='All associated payment with this fees will be deleted as well. Are you sure?' />
        </div>},      
     ];

     const TableStudent=(props)=>{
       
            
    
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
    
                        <SearchBar
                                     { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                   
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }
    


    

useEffect(()=>{
    fetchFeecharge()
},[]);

        return (  <>
   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">

        <PageHeader title="Discounted Fees">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a></li>
      <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
<li className="breadcrumb-item"><a href="#!">Discounted</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                
                <div className="row  ">
					<div className="col-sm-12">
	<div className="card z-depth-0">
                <div className="card-header">
                    <h5><i className="fa fa-edit" ></i> Fees Structure</h5>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="feather icon-maximizes full-card"></i></li>
                            <li><i className="feather icon-minus minimize-card"></i></li>
                            <li><i className="feather icon-trash-2 close-card"></i></li>
                        </ul>
                    </div>
                </div>
		<div className="card-block">
		    
            <div className="row">
		    
            <section className="col-md-6">
            <div className="form-group">
         <label> Select Fee Charge  <span style={{color:'red'}}>*</span></label>
         <Select  options={feecharge&&feecharge.map((list, idx)=> {
                               return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                             })
                        } 
                        getOptionLabel={option =>`${option.session}  ${option.label}`}       
onChange={handleSelect} className={errors.feecharge ? 'form-control form-control-danger' : ''} name="feecharge" value={studentFee.feecharge}  /> <span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>

            </div>
            </section>


            <section className="col-md-6">
            <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={studentFee.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
            </section> 

            </div>


           
<hr/>

	<footer className="pull-right">
	<button type="button" id="submit" onClick={handleFetchStudent} className="btn btn-inverse ">Search Fees</button>
	</footer>				
            </div>
            </div>
            </div>
</div>



{students.length!==0?
<div className="row">
<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th" ></i> Discounted  Student</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
	<div className="card-block">
    <div className="col-md-12 table-responsive">
      
       
    {notice.isDataFetching ? <TableLoader />:
        <TableStudent data={students}  columns={tableStudentHeader} />}

	</div></div>

</div>
</div> 
</div> :''}
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(DiscountFees) 