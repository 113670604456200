import React, {useState, useEffect,  useRef} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import { config,  API_URL} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import TextEditor from '../api/editor'
import Error403 from '../settings/error403'

const Enquiry =()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
   const [image, setImage] = useState('');

    const [question, setQuestion] = useState({
      question:'',
      answer:'',
     
    })
    const [content, setContent] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [errors, setErrors] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(true);

 



    const fetchContent =()=>{

      let url = API_URL+'/enquiry/display/all';
         axios.get(url, config).then(result=>{  
          if(Array.isArray(result.data.data)){
            setContent(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }



  useEffect(()=>{
   fetchContent()
   },[]);


const DataTable =(props)=>{
  const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } entries</span>
  );

  
  const { SearchBar } = Search;
  const handleUpdate=(column, newValue, code)=>{       
    const fd = new FormData();
    fd.append('newValue', newValue);
    fd.append('column', column.dataField);
    fd.append('code', code);
    let url = API_URL+'/enquiry/change_field'
    axios.post(url, fd, config)
        //.then(result => console.log(result.data))
        .then()
        .catch((error)=>Alerts('Error!', 'danger', error.message))
        .finally(()=>{
            fetchContent()
        }) 
    } 
    const options = {
        showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, { text: 'All', value: props.data.length }]
       
      };
 

  return <React.Fragment>  
 
          <ToolkitProvider keyField='code' data={ props.data } columns={ props.columns } >                  
                  {
                      props => ( 
                        <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;

                             </div>                            
                        <BootstrapTable
                         { ...props.baseProps }
                                     striped
                                 hover
                                 condensed
                                 noDataIndication={<div className="alert alert-danger">No Record Found</div>}
                             pagination={paginationFactory(options) } 
                             cellEdit={ cellEditFactory({
                              mode: 'dbclick',
                              blurToSave: true,
                              autoSelectText: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      if(oldValue !==newValue){
                      handleUpdate(column, newValue, row.code);                        
                     return props.fetchdata;
                    }                      
                  }
                         }
                         
                         ) }                              
                         />
                          </div>
                      )
                  }
</ToolkitProvider>
</React.Fragment>
}


const handleDelete =(code)=>{
  let close =   document.getElementById('btnWarningDialog-'+code)
  close.click();
 
  setNotice({...notice, 
      isLoading: true}) 

let url = API_URL+'/enquiry/delete/'+code;
    axios.get(url, config)
    .then(response =>{
     if(response.data.status ===200){
        Alerts('Saved!', 'default', response.data.message)
            } else{
        Alerts('Error!', 'danger', JSON.stringify(response.data))
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        fetchContent()
        setNotice({...notice, 
            isLoading: false
        })
    }) 
}



const tableHeader = [
    
    {dataField: 'fullname', text: 'Name', formatter:(cell, row)=><span style={{display:'block',  width:300, whiteSpace:'normal', wordWrap:'break-word' }} >{row.fullname+', '+row.telephone+', '+row.email}</span>, editable:false},


    {dataField: 'subject', text: 'Subject', formatter:(cell)=><span style={{display:'block',  width:300, whiteSpace:'normal', wordWrap:'break-word' }} >{cell}</span>, editable:false},
    {dataField: 'message', text: 'Message', formatter:(cell)=><span style={{display:'block',  width:300, whiteSpace:'normal', wordWrap:'break-word' }} >{cell}</span>, editable:false},

      {dataField: 'status', text: 'Status', validator: (newValue) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'This field is required'
          };
        }
        return true;
      },  formatter: (cell, row)=>row.status==='Responded'?<span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>,editor: {
        type: Type.SELECT, options:[
          {value:'Responded', label:'Responded'}, 
          {value:'No Response Yet', label:'No Response Yet'}]
      }},


    {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>  
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} className='btn btn-danger btn-sm'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>
<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

        </div>, editable: false},  
 ];



        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Enquiry">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">General</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Enquiry</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

         
               

                <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Enquiry</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="col-md-12">
        <div className="table-responsive">

        {notice.isDataFetching ? <TableLoader />:
  <DataTable data={content} columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Enquiry) 