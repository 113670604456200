import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'

import {ServerUrl, Token, config, API_URL, Currency} from '../component/include'
import axios from 'axios'
import {  convertDate,  getMonth, getMonthByDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'


const Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
    const [account, setAccount]= useState([]) 
    const [income, setIncome]= useState([]) 
    const [expenses, setExpenses]= useState([]) 

    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)
    
const [statistics, setStatistics] = useState({
    account:0,
    income:0,
    expenses:0,
    accountType:0
});


const fetchAccount=()=>{
      
  setNotice({...notice, isLoading: true}) 
  let url = API_URL+'/finance/account/balance'
  axios.get(url,  config).then(result=> setAccount(result.data.data))
  .catch((error)=>{
  Alerts('Error!', 'danger', error.message)
  }).finally(()=>{
  setNotice({...notice, isDataFetching: false})

  })  
}


    const fetchIncome =()=>{
       

      var sql = "select expensesDate as label, concat('expenses', '') as type, sum(amount) as data  from tbl_expenses where expensesDate >=  '"+convertDate(firstDayOfYear)+"' and expensesDate <= '"+convertDate(lastDayOfYear)+"'   GROUP BY  DATE_FORMAT(expensesDate, '%m.%Y')   union select incomeDate as label, concat('income', '') as type,  sum(amount) as data  from tbl_income where incomeDate >=  '"+convertDate(firstDayOfYear)+"' and incomeDate <= '"+convertDate(lastDayOfYear)+"'   GROUP BY DATE_FORMAT(incomeDate, '%m.%Y') "
      
               const fd = new FormData();
               fd.append("sql", sql);
               fd.append('jwt', Token)
               let url = ServerUrl+'/fetchBySql_controller';
               axios.post(url, fd, config).then(result=>{ 
                let responseData = []
                for (var i = 0; i< result.data.length;  i++) {
                  responseData.push(
                      {  label:getMonthByDate(result.data[i].label),
                          income:result.data[i].type==='income'?result.data[i].data:0,
                          expenses:result.data[i].type==='expenses'?result.data[i].data:0,
                          type:result.data[i].type
                      });
                      } 
                             setIncome(responseData)

                 
               })
           } 

           


        const yearlyExpenses = {
          labels: income.filter(data=>data.type==='expenses').map(data=>data.label),
          datasets: [
            {
              label: 'Expenses ',
              fill: true,
              lineTension: 0.5,
              backgroundColor: 'rgb(0,0,0,1)',
              borderColor: 'rgba(10, 76, 181)',
              borderWidth: 2,
              data: income.filter(data=>data.type==='expenses').map(data=>data.expenses)
            }
          ]
        };


        const yearlyIncome = {
          labels: income.filter(data=>data.type==='income').map(data=>data.label),
          datasets: [
            {
              label: 'Income ',
              fill: true,
              lineTension: 0.5,
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: income.filter(data=>data.type==='income').map(data=>data.income)
            }
          ]
        };


           const fetchExpenses =()=>{
            var sql = "select expensesDate as label, concat('expenses', '') as type, sum(amount) as data  from tbl_expenses where expensesDate >=  '"+convertDate(first)+"' and expensesDate <= '"+convertDate(last)+"'   GROUP BY expensesDate   union select incomeDate as label, concat('income', '') as type,  sum(amount) as data  from tbl_income where incomeDate >=  '"+convertDate(first)+"' and incomeDate <= '"+convertDate(last)+"'   GROUP BY incomeDate "
            
                     const fd = new FormData();
                     fd.append("sql", sql);
                     fd.append('jwt', Token)
                     let url = ServerUrl+'/fetchBySql_controller';
                     axios.post(url, fd, config).then(result=>{ 
                       if(Array.isArray(result.data)){

                        let responseData = []
            for (var i = 0; i< result.data.length;  i++) {
              responseData.push(
                  {  label:result.data[i].label,
                      income:result.data[i].type==='income'?result.data[i].data:0,
                      expenses:result.data[i].type==='expenses'?result.data[i].data:0,
                      type:result.data[i].type
                  });
                  } 
                  
                         setExpenses(responseData)
                       }
                      
                     })
                 } 


         const MonthlyIncome = {
            labels: expenses.filter(data=>data.type==='income').map(data=>data.label),
            datasets: [
              {
                label: 'Income '+Currency,
                fill: true,
                lineTension: 0.5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: expenses.filter(data=>data.type==='income').map(data=>data.income)
              }
            ]
          }; 
          
          const MonthlyExpenses = {
            labels: expenses.filter(data=>data.type==='expenses').map(data=>data.label),
            datasets: [
              {
                label: 'Expenses '+Currency,
                fill: true,
                lineTension: 0.5,
                backgroundColor: 'rgb(0,0,0,1)',
                borderColor: 'rgba(10, 76, 181)',
                borderWidth: 2,
                data: expenses.filter(data=>data.type==='expenses').map(data=>data.expenses)
              }
            ]
          }; 
         

          const fetchStatistics = async ()=>{
            
            let sqls = "Select  (select count(*) from tbl_account_type ) as accountType, (select count(*) from tbl_account ) as account, (select count(*) from tbl_expenses_head ) as expenses, (select count(*) from tbl_income_head ) as income   "
            
             const fds = new FormData();
             fds.append("sql", sqls);
             fds.append('jwt', Token)
             let urls = ServerUrl+'/fetchBySql_controller';
             axios.post(urls, fds, config).then(result=>{ 
                
                setStatistics({
                  accountType:result.data[0].accountType,
                  account:result.data[0].account,
                  expenses:result.data[0].expenses,
                  income :result.data[0].income
                })
             })
         } 
         

         const accountList = {
          labels: account.map((data)=>data.accountName),
          datasets: [
            {
                       
              label: 'Total Balance',
              backgroundColor: [
                '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
              hoverBackgroundColor: [
              '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
              data: account.map((data)=>data.openingBalance)
            }
          ]
        }


        

   useEffect(()=>{
     fetchAccount()
     fetchExpenses()
     fetchIncome()
     fetchStatistics()
},[]);

        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Finance Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


<div className="row">


<div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Total Account Type</p>
                    <h4 className="m-b-0">{statistics.accountType}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-credit-card f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                            <p className="m-b-5">Total Account Created</p>
                        <h4 className="m-b-0">{statistics.account}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-money f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Total Income Type</p>
                        <h4 className="m-b-0">{statistics.income}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-money f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Expenses Type</p>
                            <h4 className="m-b-0">{statistics.expenses}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="fa fa-credit-card f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>


      </div>

    <br/>





        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
    Income and Expenses  ({getMonth(new Date().getMonth())}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={MonthlyIncome}
          options={{
            legend:{
              display:true,
              position:'top'
            }
          }}
          
        /> 

<Line
          data={MonthlyExpenses}
          options={{
            legend:{
              display:true,
              position:'top'
            }
          }}
          
        /> 

             </div>
          </div>
        </div></div> 


        <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> ACCOUNT BALANCE</strong>
				</div>
             <div className="card-body">

              <Bar
          data={accountList}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
        />  
             </div>
          </div>
        </div></div>



        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
    Income and Expenses  ({new Date().getFullYear()}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={yearlyIncome}
          options={{
            legend:{
              display:true,
              position:'top'
            }
          }}
          
        /> 
<br/>
<Line
          data={yearlyExpenses}
          options={{
            legend:{
              display:true,
              position:'top'
            }
          }}
          
        /> 

             </div>
          </div>
        </div></div> 


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Chart) 