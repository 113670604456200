import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, staffName, config} from '../component/include'
import Select from 'react-select'
import { Alerts, WarningModal } from '../component/notify'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'

const StaffReview =()=>{

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [errors, setErrors] = useState({});
    const [review, setReview] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false); 
const [profile, setProfile]=useState({
    staff:[],
    review:'',
    dateReview:'',
    reviewBy:staffName,
    visibleToStaff:'YES'
});

const [settings, setSettings]=useState([])
const staff  = useSelector(state => state.rootReducer.staff);

const handleChange = (e)=>{
    const {name, value} = e.target;
    setProfile({...profile, [name]: value});
    setErrors({...errors, [name]:''})
 }
const handleSelect = (option, action)=>{
    setProfile({...profile, [action.name]: option});
    fetchReview(option.value)
 }
   
 const fetchSettings =()=>{
    const fd = new FormData();
fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
    axios.post(url, fd, config).then(result=>{
        setSettings(result.data)
      
    })
}

    const handleDelete = (code)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token);
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller/tbl_staff_review'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               fetchReview()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }
    const fetchReview =(code=profile.staff.value)=>{
        setNotice({...notice, 
            isLoading: true})
        var sql ="Select s.staffName, s.fileNo, r.review, r.dateReview, r.visibleToStaff, r.code  from tbl_staff_review r, tbl_staff s where s.code =r.staffCode and r.staffCode ='"+code+"' "; 
        const fd = new FormData();
        fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setReview(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
    }
    
    

    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>


<a href="#!" data-toggle="modal" className="btn btn-danger btn-sm delete"  data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i></a>&nbsp;
      
      
       <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

        </div>, editable: false},

        {dataField: 'staffName', text: 'Staff Name', sort: true, editable: false},
          {dataField: 'review',  text: 'Review',  sort: true, formatter:(cell)=><span style={{display:'block',  width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
        {dataField: 'dateReview',  text: 'Date',  editor: {
            type: Type.DATE
          }},
        {dataField: 'visibleToStaff',  text: 'Public', editable:false,  formatter: (cell)=>cell==='YES'? <span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>,
        editor: {
            type: Type.SELECT, options:[
                        {value:'true', label:'YES'},
                        {value:'false', label:'NO'}
            ] }, sort: true}
     ];

    const StaffTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
          
            let url = ServerUrl+'/updateById_controller/tbl_staff_review'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>

      <div className="dt-buttons btn-group pull-right"> 
      		
      <form method='POST' action={ServerUrl+'/printout/staff_review.php?token='+userToken} target='_blank'>
               
      <button data-toggle="modal" type='button'   data-target='#addStaffReview' className="btn btn-outline-success mr-2" > <i className="fa fa-plus"></i>Add New</button> 
      <input type='hidden' name='staffCode' value ={profile.staff.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                
                <input type='hidden' name='headofSchool' value ={String(settings.map((st)=>st.headofSchool))} />
        <button type="submit" className="btn btn-inverse" > <i className="fa fa-eye"></i> Preview</button>
        
        </form>

</div> 

                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    
      const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(profile));
        setIsSubmitting(true);
    }

    
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.staff.length===0){
            errors.staff ='Please select staff';
        } 
        if(!values.review){
            errors.review ='Please enter review';
        } 
        if(!values.dateReview){
            errors.dateReview ='Please enter review';
        } 
        return errors;
        }

function submit(){
            setNotice({...notice, isLoading: true})    
            
            const fd = new FormData();
            fd.append('jwt', Token);
                let formData = profile; 
               for(let k in formData){
                   fd.append(k,formData[k]);
               };   
               fd.append('staffCode', profile.staff.value);
               fd.append('code', 'r'+Math.random().toString(36).substr(2,9));
               
             let url = ServerUrl+'/save_controller/tbl_staff_review'
                      axios.post(url, fd, config)
                .then(response =>{
                    if (response.data.type ==='success'){
                        Alerts('Saved!', 'success', response.data.message)
                    } else{
                        Alerts('Error!', 'danger', JSON.stringify(response.data))
                }
                        
                })
                .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
        setNotice({...notice, 
            isLoading: false})
            fetchReview()
            setIsSubmitting(false);
            setProfile({...profile,
                review:[],
                dateReview:'',
                    remarks:''
            }) 
        })


let close =   document.getElementById('closeStaffReveiew')
close.click();
            }



useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])


    
useEffect(()=>{
    fetchSettings()
},[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper"> 

        <PageHeader title="Staff Review">
        <li className="breadcrumb-item"><a  href="#!"> <i className="feather icon-home"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Review</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



                <div className="modal fade " id='addStaffReview' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add a new Review</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="row">
              <section className="col-md-12">
    <div className="form-group">
    
    <label >Date Review </label>
   <input type='date' className="form-control" name="dateReview" value={profile.dateReview} onChange={handleChange} />   
                </div>
        </section>


        <section className="col-md-12">
    <div className="form-group">
    
    <label >Review </label>
   <textarea type='text' className="form-control" name="review" value={profile.review} onChange={handleChange} > {profile.review}</textarea>  
                </div>



                <div className="col-md-6">
           <div className="form-group">
         <label> Visible to Staff</label>
         <div className="form-radio">
                <div className="radio radio-inline"><label title='Yes'>
						<input type="radio" checked={profile.visibleToStaff ==="YES"} onChange={handleChange} value="YES" name="visibleToStaff" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label title='No'>
						<input type="radio" checked={profile.visibleToStaff ==="NO"} onChange={handleChange} value="NO" name="visibleToStaff" />
										<i className="helper"></i>No</label></div>

								</div>
            </div>
     
     </div>
        </section>


				</div>
				
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeStaffReveiew' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={handleSubmit} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>

                <div>
                
                <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Staff</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-12">
    
        <div className="form-group">
        
        <label >Select Staff </label>
        <Select  options={staff&&staff.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                                     })
                                }    
                    getOptionLabel={option =>option.label +' - '+ option.fileNo}    
        onChange={handleSelect}  name="staff" value={profile.staff}  />   
                    </div>
			</section>

									</div>
										
							</div>
							</div>

							</div>
                </div>




           {profile.staff.length!==0?     <div className="row">
                                    
                <div  className="col-md-12" >                   
<div className="card z-depth-0">
            <div className="card-header">
                <h5>Staff Review</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <StaffTable data={review}  columns={tableHeader}  />}
            </div></div>

    </div></div>
            
            </div>:''}
    
                </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>
</> 
 );
        
}
export default React.memo(StaffReview) 