import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, API_URL, Token, FormatNumber, Currency,  ImagesUrl, config, staffCode } from '../../component/include'

import { useDispatch, useSelector } from 'react-redux'
import { userToken } from '../../component/authentication'
import { displayMonthYear } from '../../component/globalFunction'

const Payslip =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [staff, setStaff] = useState([]); 
    const [payment, setPayment] = useState([]); 
  
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [payslip, setPayslip] = useState({
        staff:[],
        payMonth:''
    })
    
   

    const handleChange=(event)=>{
        setPayslip({...payslip, [event.target.name]:event.target.value});
        setErrors({...errors, [event.target.name]:''})
    }
 
 
    
    const fetchPayslip =()=>{

        if(!payslip.payMonth){
           Alerts('Error', 'danger', 'Please select pay month')
        }else{

        
        setNotice({...notice, isLoading:true})
        let url = API_URL+'/payroll/pay_slip/'+payslip.payMonth;
        axios.get(url,  config).then(result=>{
            if(result.data.data.length!==0){
             setPayment(result.data.data)
                setStaff(result.data.staff) 

                }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading:false})
        })
        } 
        }


    const handleSubmit = event =>{
        event.preventDefault();  
        fetchPayslip()
    }  


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Print Payslip">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Print Payslip</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Print Payslip</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       

<section className="col-md-12">
   
<div className="form-group">
<label >Month Year</label>
<input type="month" className={errors.payMonth ? 'form-control form-control-danger' : 'form-control'} name="payMonth" id="payMonth"  onChange={handleChange} value={payslip.payMonth} />
<span style={{color:'red'}}>{errors.payMonth && errors.payMonth}</span>
   </div>
</section>
</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="">

    <form method='POST' action={ServerUrl+'/printout/staff_bulk_pay_slip.php?token='+userToken}  target='_blank'>
        
        <input type='hidden' name='result' value ={JSON.stringify(staff.filter((item=>item.staff_code===staffCode)), null, 2)} />
    
        <input type='hidden' name='payment' value ={JSON.stringify(payment.filter((item=>item.staff_code===staffCode)), null, 2)} />
    
                    <input type='hidden' name='jwtToken' value ={Token} />
                    {staff.length!==0?<button type="submit"   className="btn btn-dark "><i className="fa fa-print"></i> Print</button>:[]} &nbsp;
            
	<button type="button"  onClick={handleSubmit}  className="btn btn-primary "><i className="fa fa-file"></i> Get Payslip</button>
            </form> 

								</footer>		</section>		
							</div></div> </div> 
							
</div>

</div>      


  </div> </div> 
							
                            </div>
                            
                            </div>


                           
<div className="row">

{staff&&staff.filter((item=>item.staff_code===staffCode)).map((list, index)=><div className="  col-md-6 " key={index}>
<div className="card z-depth-0 " >

<div  className="card-block">
<table   class='table table-responsive table-condensed table-bordered table-sm '>
   
{schoolInfo&&schoolInfo.map((st, id)=> <thead  key={id}>
    <tr  className=''>
 <td colSpan={3} className='text-center'>	
 
 <img id="viewSchoolLogo2" className=" img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/about/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  style={{ height:'100px',width:'100px'}} title="School Logo" alt='logo'  />   
 
 <h4 class="logo_name">{st.schoolName}</h4>
 <span>{st.address}</span><br/>
 <span>{st.mobile}</span><br/><br/>

 <h5  class="bg-success text-bold p-1"> PAY SLIP	</h5>
  </td>
  
  </tr>


    </thead>)}
          <tbody>
					<tr>
			<td><b>File No : {list.fileNo} </b></td>
			<td colSpan={2} ><b>Name : {list.staffName} </b></td>
                    </tr>
                    <tr>
                  <td><b>Month : {  displayMonthYear(list.payment_month)} </b></td>
              <td colSpan={2}><b>Date Paid : {list.payment_date} </b> </td>
                    </tr>
                    <tr>
                        <td><b>Total Paid : {Currency+FormatNumber(list.total)} </b></td>
                        <td colSpan={2}><b>Mode : {list.payment_mode}</b> </td>
                    </tr>
                    <tr>
                         <th colspan="3" style={{border: 'solid 1px', backgroundColor:'#404E67', color:'#fff', textAlign: 'center'}}><i class="fa fa-money"></i> Payment Details</th>
                        </tr>
                        <tr className="thead-default">
                                    <th>Description</th>
                                    <th>Earnings</th>
                                    <th>Deductions</th>
                                </tr>
    {payment.filter((item=>item.staff_code===list.staff_code)).map((node, id)=>
                                <tr key={id}>
                            <td>{node.title}</td>
                            <td>{node.item_type==='EARNING'?Currency+FormatNumber(node.paid):'-'}</td>
                                    <td>{node.item_type==='DEDUCTION'?Currency+FormatNumber(node.paid):'-'}</td>
                        </tr>)}

                    </tbody>
                    <tfoot>

                    
                        <tr>
                            <th colspan="2">Total Deduction</th>
                            <td>{Currency+FormatNumber(list.deduction)}</td>
                        </tr>
                        <tr>
                            <th colspan="2">Total Earnings</th>
                            <td>{Currency+FormatNumber(list.earning)}</td>
                        </tr>
                        <tr>
                            <th colspan="2">Net Total</th>
                            <th>{Currency+FormatNumber(list.total)}</th>
                        </tr>
                    </tfoot>
                </table>
                    </div></div>
                            
                            </div>)}
</div>





   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Payslip) 