import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl, Token, config } from '../component/include'


 const UploadStaffAttendance=()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
const [CurrentSession, setCurrentSession] = useState('')
const [csvFile, setCsvFile]=useState({
    staffCsv:'',
    fileName:'Please select .csv files (allowed file size 200KB)',
    attendanceDate:'',
    currentSession: CurrentSession
})

const [errors, setErrors] = useState({});
const [isSubmitting, setIsSubmitting] = useState(false); 

    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
 const handleSubmit = event =>{
    event.preventDefault();
    setErrors(ValidateErrors(csvFile));
    setIsSubmitting(true);
}
const  submit=()=>{  
    
    let formData = csvFile;
    
    const fd = new FormData();
            fd.append('jwt', Token);  
     for(let k in formData){
        fd.append(k,formData[k]);
    };  
    setNotice({...notice,   isLoading: true}) 
     
       let url = ServerUrl+'/save_controller/ImportStaffAttendance'

      axios.post(url, fd, config).then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else if(response.data.type ==='info'){
                    Alerts('Info!', 'default', response.data.message)}
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
      setIsSubmitting(false);  
      setNotice({...notice,   isLoading: false}) 
     setCsvFile({...csvFile, 
        staffCsv:'', 
        attendanceDate:'',
        fileName:'Please select .csv files (allowed file size 200KB)'}); 
    document.getElementById('staffCsv').value=''
})  
} 


useEffect(()=>{
if(Object.keys(errors).length === 0 && isSubmitting){
submit()
}
},[errors])

 const ValidateErrors=(values)=>{
    let errors = {};     
    if(!values.staffCsv){
        errors.staffCsv ='You have not choose any file to upload';
    }
    if(!csvFile.attendanceDate){
        errors.attendanceDate ='Please select attendance date to upload';
    }
    
    return errors;
    }


    const handleChange=(event)=>{
		const name = event.target.name;
		const value = event.target.value;
    setCsvFile({...csvFile, [name]:value})        
       setErrors({...errors, [name]:''});
    }

    const  handleFile = function(fieldName){
        return function(newValue){             
            setErrors({...errors, [fieldName]:''})
       readURL(newValue);
       }
       
    } 
   

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        

        if (extFile==="csv" || extFile==="CSV"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 0.5) {
            displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
           document.getElementById(input.target.name).value = '';
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                   const value = input.target.files[0]
            setCsvFile({...csvFile, staffCsv:value, fileName:value.name})
           
            }
        }
        }else{

            displayMessage = 'Only csv|CSV  files are allowed with maximum of 500kb'
            document.getElementById(input.target.name).value = '';
            setCsvFile({...csvFile, staffCsv:'', fileName:'Please select .csv files (allowed file size 500KB)'})
        }  
        
        if(displayMessage.length > 0){
            Alerts('Error!', 'danger', displayMessage)
          }
        
    } 



    useEffect(()=>{
        handleSession()
    },[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

                   {/* <!-- Page-header start --> */}

        <PageHeader title="Attendance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Upload Staff Attendance</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Prepare your staff attendance</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
                    <div className="well">
                        <div className="box-body">
                        <div className="row">
                            <div className="col-sm-3 text-right  text-danger"><u><b>Required Columns</b></u></div>
                            <div className="col-sm-7"><u><b>Allowed Values</b></u></div>                           
                        <div className="col-sm-3 text-right text-bold"><b>Portal Code</b></div>
                    <div className="col-sm-7">Staff code<br/>                     </div>          								 <div className="col-sm-3 text-right text-bold"><b>Staff Name</b></div>
                    <div className="col-sm-7">Staff Name<br/>                     </div> 
                            <div className="col-sm-3 text-right text-bold"><b>inTime(HH:MM:00)</b></div>
                        <div className="col-sm-7">Time In (24 Hrs)     <br/>  </div>    
                        <div className="col-sm-3 text-right text-bold"><b>OutTime(HH:MM:00)</b></div>
                        <div className="col-sm-7">Time Out (24 hrs)    <br/>  </div>                        
                            <div className="col-sm-3 text-right text-bold"><b>Indicator</b></div>
                            <div className="col-sm-7">Attendance(P-Present||H-Halfday||A-Absent||L-Leave)<br/>                    <br/></div>                                               <br/>      
                                </div>                       
                                 
                                </div>
                        </div>
                    </div>
                </div>
	

</div>
</div>

<div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert  border-success  ">
					<h4 className="alert-heading ">Staff Attendance Template</h4>
					
                
<p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
<span >The correct column order is (<b>
    Portal Code, Staff Name,  inTime(HH:MM a), OutTime(HH:MM a), Attendance(P-Present||H-HalfdayAll||A-AbsentAll||L-Leave)</b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><p className="text-align-left">
						
                    <form method='POST' action={ServerUrl+'/csvSample_controller/staffAttendance'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
                
        <button type="submit" className="btn btn-sm btn-inverse"> <i className="fa fa-download"></i> Download Sample</button>
        
        </form>
					</p>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
                        <h4 className="alert-heading ">Upload file with .csv extention</h4>
							<section>
                            <div className="form-group">
   <label >Select Date</label>
   <input type="date" className={errors.attendanceDate ? 'form-control form-control-danger' : 'form-control'} name="attendanceDate" id="attendanceDt"  onChange={handleChange} value={csvFile.attendanceDate} />

   <span style={{color:'red'}}>{errors.attendanceDate && errors.attendanceDate}</span>
               </div>


                            <div className="form-group">
							<label> Choose File </label>
                    <input onChange={handleFile('staffCsv')} className={errors.staffCsv ? 'form-control form-control-danger' : 'form-control'} name="staffCsv" id="staffCsv" type="file" accept=".csv" /> 
						<span className="note" style={{color:'red'}}>{csvFile.fileName}</span>	
										</div>          
                                        
							</section>
						</fieldset><span style={{color:'red'}}>{errors.staffCsv && errors.staffCsv}</span></div>
						<footer>
							<button type="button" id="submit" onClick={handleSubmit} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Attendance</button>
						</footer>

	</div>

</div>

</div></div>
	
		

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(UploadStaffAttendance) 