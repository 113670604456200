import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,   config} from '../component/include'
import Select from 'react-select'



import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const ApplyLeave =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [total, setTotal]= useState([])
    const [staff, setStaff]= useState([])    
    const [leave, setLeave]=useState([])
    const [staffLeave, setStaffLeave]=useState([])    
    const [newLeave, setNewLeave]=useState([])
    const [leaveList, setLeaveList]= useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [CurrentSession, setCurrentSession] = useState('')
    const [applyLeave, setApplyLeave] = useState({
        staff:[],
        leaveType:[],
        fromDate:'',
        toDate:'',
        days:'',
        sessionID: CurrentSession
    })
    
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 

    const fetchLeave =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_staff_leave_settings';
        axios.post(url, fd, config).then(result=>setLeave(result.data))

    }

    const getLeave = (code, field) =>{
        const result = leave.filter(list=>list.code===code);
       const answer = result.map(c=>c[field]); 
         return  answer;
       }

       const getTotal = (code) =>{
        const result = total.filter(list=>list.leaveType===code);
       const answer = result.length!==0?result.map(c=>c.total):0; 
         return  Number(answer)
       }

       const combineLeave=()=>{
        let value = []
        staffLeave&&staffLeave.map((itm, idx)=>{
             
             value.push({code:itm.code, leaveName:getLeave(itm.code, 'leaveName'), leaveType:getLeave(itm.code, 'leaveType'), timeCycle:getLeave(itm.code, 'timeCycle'), days:itm.days, remain:Number(itm.days) -getTotal(itm.code), approve:getTotal(itm.code)});
             return ''
         } );
         
         setNewLeave(value)
     }


    const fetchStaffLeave =(staff)=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_salary_structure/leaveStructure/staff/'+staff
        axios.post(url, fd, config).then(result=>setStaffLeave(result.data))
            
    }


    const handleSelect= (option, action)=>{
        setApplyLeave({...applyLeave, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        if(action.name==='staff'){
            fetchStaffLeave(option.value) 
            fetchLeaveList(option.value)  
            fetchTotalDays(option.value) 
            setNewLeave([])       
        }
       
       
    }
    
    const handleChange=(event)=>{
        setApplyLeave({...applyLeave, [event.target.name]:event.target.value});
        setErrors({...errors, [event.target.name]:''})
        
    }
  
    const  fetchLeaveList =(staff)=>{  
        const fd = new FormData();
        fd.append('jwt', Token);
   let sql = "Select s.ID, s.code, s.days, f.staffName, f.fileNo, l.leaveName, s.sessionYear,	s.fromDate,	s.toDate, s.status  from tbl_staff_leave s, tbl_staff_leave_settings l, tbl_staff f where s.staff = f.code and s.leaveType = l.code  and s.staff ='"+staff+"'"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setLeaveList(result.data)) 
   
    }   



    const long=(conv)=>{
        var date = new Date(conv).toDateString().slice(3, 15)
        
        return date
    }
    const  fetchTotalDays =(staff)=>{  
        const fd = new FormData();
        fd.append('jwt', Token);
   let sql = "Select code, leaveType, SUM(days) as total from tbl_staff_leave where status ='Approved' and staff ='"+staff+"' and sessionYear='"+CurrentSession+"' Group by leaveType"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setTotal(result.data)) 
   
    }  

  

    
    const getDiffer=(toDate)=>{

        var today =  new Date();
        var secondDate =  new Date(toDate);
        var diff_time = secondDate.getTime()-today.getTime()
        var differ = diff_time/(1000*3600*24);
        return differ
    } 


    const handleAction = (cell, row)=>{
        return <div>     
           {Number(getDiffer(row.toDate))<=0?'':
    <button className="btn btn-sm btn-danger" onClick={()=>UpdateLeave(row.ID, 'Canceled')}  title="Cancel">Cancel</button>}
    &nbsp;&nbsp;

     {row.status==='Canceled'?<button onClick={()=>UpdateLeave(row.ID, 'Approved')} className="btn btn-sm btn-success" title="Approve"> Approve</button>:'' } 
         
        </div> 
    }


    const tableAssignLeaveHeader = [
        {dataField: 'leaveName', text: 'Leave Name', editable: false},
        {dataField: 'timeCycle', text: 'Time Circle', editable: false},
        {dataField: 'leaveType', text: 'Type', editable: false},
        {dataField: 'days', text: 'Leave (Days)', editable: false},           
        {dataField: 'approve', text: 'Total Approved', editable: false},
        {dataField: 'remain', text: 'Days Remain', editable: false}    
     ]
   
     const tableLeaveListHeader = [
        
        {dataField: 'staffName', text: 'Name', editable: false},
        {dataField: 'leaveName', text: 'Leave Type', editable: false},
        {dataField: 'fromDate', text: 'From Date', editable: false, formatter:(cell)=>
        long(cell)},
        {dataField: 'toDate', text: 'To Date', editable: false, formatter:(cell)=>
        long(cell)},        
        {dataField: 'days', text: 'Days', editable: false},        
        {dataField: 'status', text: 'Status', formatter:(cell, row)=>cell==='Approved'?<span className="pcoded-badge label label-success">{cell}</span>:<span className="pcoded-badge label label-danger">{cell}</span>, editor: {
            type: Type.SELECT, options:[
                { value: 'Pending', label: 'Pending' },
                { value: 'Approved', label: 'Approved' }
            ] }},        
        {dataField: 'sessionYear', text: 'Session', editable: false},
        {dataField: 'ID', text: 'Action', formatter: handleAction, editable: false}     
     ]

    
 const TableBasic=(props)=>{
       
    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
        fd.append('jwt', Token);
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
        
           let url = ServerUrl+'/updateCustom_controller/'+props.table
          axios.post(url, fd, config)
          //.then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        }  
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                            beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                            return true;
                            }
                            
                        }
                                    }) }
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }

     const  fetchStaff =()=>{  
        const fd = new FormData();
        fd.append('jwt', Token);
   let sql = "Select code, staffName, position, mobile, email,  fileNo from tbl_staff"
       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setStaff(result.data)) 
    }  


   
    const getDateFormat =()=>{
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var answer = 31;
       if(month ==='02'){
         answer = 28;}else if(month ==='04' || month ==='06' || month ==='09' || month ==='11'){
         answer = 30; 
         }

        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        
        var minDay = year + "-" + month + "-01"; 
        var maxMonth = year + "-" + month + "-" + answer;
//console.log(maxMonth)
   document.getElementById('fromDate').min =  minDay;    
   document.getElementById('fromDate').max = maxMonth;  
   document.getElementById('toDate').min =  minDay;
    }


    const UpdateLeave=(ID, status)=>{
        setNotice({...notice, 
            isLoading: true})   
    
            const fd = new FormData();
        fd.append('jwt', Token);         
            fd.append('status', status);
            fd.append('ID', ID); 
            
             let url = ServerUrl+'/update_controller/tbl_staff_leave'
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                        }else if(response.data.type ==='info'){
                            Alerts('Update', 'default', response.data.message)
                                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    setNotice({...notice, 
                        isLoading: false})     
            })
            
    }
    
    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(applyLeave));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};  
           
        if(values.staff.length===0){
            errors.staff ='Please select staff';
        } 
        if(values.leaveType.length===0){
            errors.leaveType ='Please select leave type';
        } 
        if(!values.fromDate){
            errors.fromDate ='Please enter effective date';
        } 
        if(!values.toDate){
            errors.toDate ='Please enter end date';
        } 
        if(newLeave.length===0){
            errors.staff ='There is no leave define for the staff selected';
        } 
        if(newLeave.length!==0){
        const result = newLeave.filter(list=>list.code===values.leaveType.value);
        const answer = result.map((c)=>c); 
        const remain = Number(answer[0].days)-Number(answer[0].approve)

        if(Number(answer[0].days)===Number(answer[0].approve)){
            errors.fromDate = 'it appears that the selected staff have exhusted his/her leave for the selected type';
        }
        if(Number(getDays())> Number(remain)){
          //  errors.fromDate ='The difference in the date range is more than leave remain for the selected type'
            errors.toDate ='The difference in the date range is more than leave remain for the selected type'
        }
    }
        if(Number(getDays())<=0){
            errors.toDate = 'Invalid date range selected'
           // errors.fromDate = 'Invalid date range selected'
        }
        if(!Number(getDays())){
           // errors.fromDate = 'Invalid date range selected'
            errors.toDate = 'Invalid date range selected'
        }

        return errors;
        }

    const getDays=()=>{

        var fromDate =  new Date(applyLeave.fromDate);
        var toDate =  new Date(applyLeave.toDate);
        var diff_time = toDate.getTime()-fromDate.getTime()
        var differ = diff_time/(1000*3600*24);

        return differ
    }  


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])
    const  submit=()=>{  
              
        setNotice({...notice, 
            isLoading: true})   
    
            const fd = new FormData();
        fd.append('jwt', Token);
          
            fd.append('staff', applyLeave.staff.value);
            fd.append('leaveType', applyLeave.leaveType.value); 
            fd.append('fromDate', applyLeave.fromDate);
            fd.append('toDate', applyLeave.toDate);
            fd.append('days', getDays());        
            fd.append('sessionYear', CurrentSession);
            
             let url = ServerUrl+'/save_controller/tbl_staff_leave'
            axios.post(url, fd, config)
            .then(response =>{
                
                if(response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                      } else{
                    Alerts('Error!', 'danger', response.data)
                      }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                setNotice({...notice, 
                  isLoading: false
                })
    setApplyLeave({
        staff:[],
            leaveType:[],
            fromDate:'',
            toDate:'',
            days:''
        })
        setNewLeave([])
    setLeaveList([])
    
                setIsSubmitting(false);
              }) 
         }    

    

useEffect(()=>{
    fetchStaff()    
fetchLeave()
    getDateFormat() 
handleSession()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Leave">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Apply Leave</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Apply Leave</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
   <div className="form-group">
<label >Select Staff</label><a href="/payroll/assign_salary_structure" className="pull-right">Define Leave</a>
<Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>` ${option.staffName} - ${option.label}`}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={applyLeave.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
   </div>
   
   <div className="form-group">
<label >From Date</label>
<input type="date" className={errors.fromDate ? 'form-control form-control-danger' : 'form-control'} name="fromDate" id="fromDate"  onChange={handleChange} value={applyLeave.fromDate} />
<span style={{color:'red'}}>{errors.fromDate && errors.fromDate}</span>
   </div>
</section>

<section className="col-md-6">
   


   <div className="form-group">
<label >Leave Type</label>
      <Select  options={
                           newLeave&&newLeave.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.leaveName}
                             })} 
onChange={handleSelect} onFocus={combineLeave} className={errors.leaveType ? 'form-control form-control-danger' : ''} name="leaveType" value={applyLeave.leaveType}  /> <span style={{color:'red'}}>{errors.leaveType && errors.leaveType}</span>
   </div>
   <div className="form-group">
<label >To Date</label>
<input type="date" className={errors.toDate ? 'form-control form-control-danger' : 'form-control'} name="toDate" id="toDate"  onChange={handleChange} value={applyLeave.toDate} />
<span style={{color:'red'}}>{errors.toDate && errors.toDate}</span>
   </div>
</section>


				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "><i className="fa fa-save"></i> Save</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div> </div> 
							
</div>

</div>        </div> </div> 
							
                            </div>
                            
                            </div>

                            {staffLeave.length!==0 && newLeave.length!==0?
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Assigned Leaves</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">

          <section className="col-md-12 table-responsive">
          
      <TableBasic data={newLeave}  table={'tbl_staff_leave_settings'} columns={tableAssignLeaveHeader}  /> 
          </section>
        

            </div>


    
    </div>
    
    </div>
    </div>:''}



    
    <div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listen All Leaves</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12">
            <div className="table-responsive">

          {notice.isDataFetching ? <TableLoader />:
      <TableBasic data={leaveList}  table={'tbl_staff_leave'} columns={tableLeaveListHeader}  />} 

            </div></div></div>
    
    </div>
    
    </div>
    </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ApplyLeave) 