import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, FormatNumber, Currency, config} from '../component/include'
import Select from 'react-select'
import { useSelector } from 'react-redux'


 const Transfer =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [CurrentSession, setCurrentSession] = useState('')
    const hostel = useSelector(state => state.rootReducer.hostel);
    const [rooms, setRooms]= useState([]) 
    const [room, setRoom]= useState([]) 
    const [students, setStudents] = useState([]) 
    const [toRooms, setToRooms]= useState([]) 

    const [studentTransfer, setStudentTransfer]= useState({
        fromHostel:'',
        fromRoom:'',
        student:'',
        toHostel:'',
        toRoom:''

})





const  handleSession =()=>{

    var sql ="Select  startYear from tbl_school_session where status ='Active'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config)
    .then(result => {
        if(result.data.length!==0){
        setCurrentSession(result.data[0].startYear)
    }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    }




    const fetchToRooms =(code)=>{
        setNotice({...notice,   isLoading: true})
        let sql = "Select r.ID, r.code, r.roomName, r.roomRate, r.roomStatus, h.hostelName from tbl_hostel_rooms r, tbl_hostel h where r.hostelCode =  h.code  and h.code = '"+code+"' order by r.ID ASC"
    
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{
            setToRooms(result.data)
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isLoading: false})
        })
        }


const fetchRooms =(code)=>{
    setNotice({...notice,   isLoading: true})
    let sql = "Select r.ID, r.code, r.roomName, r.roomRate, r.roomStatus, h.hostelName from tbl_hostel_rooms r, tbl_hostel h where r.hostelCode =  h.code  and h.code = '"+code+"' order by r.ID ASC"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        setRooms(result.data)
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,   isLoading: false})
    })
    }



const fetchStudent =(code)=>{
    setNotice({...notice,   isLoading: true})
    

    let sql = "Select  h.code,  s.studentName, s.email, s.admissionNumber, h.amountPaid, h.vacateDate from tbl_students s, tbl_hostel_allocation h where h.hostelCode = '"+studentTransfer.fromHostel.value+"' and h.currentSession = '"+CurrentSession+"' and  s.code = h.studentCode and  h.roomCode = '"+code+"' and isApproved = 'true'"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(Array.isArray(result.data)){
             setStudents(result.data)
        }else{
            setStudents([])
        } 
       
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,   isLoading: false})
    })
    }


    
const fetchRoom =(code)=>{
    setNotice({...notice,   isLoading: true})
    
    let sql = "Select  r.ID, r.code, r.roomName, r.roomRate,  r.roomStatus, r.noBedSpace, h.hostelName, (select count(*) from tbl_hostel_allocation h where h.roomCode = '"+code+"' and h.currentSession = '"+CurrentSession+"') as totalStudent from tbl_hostel_rooms r, tbl_hostel h where r.hostelCode =  h.code  and r.code = '"+code+"'"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(Array.isArray(result.data)){
             setRoom(result.data[0])
        }else{
            setRoom([])
        } 
       
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,   isLoading: false})
    })
    }


         const handleSelectHostel = (option, action)=>{
            fetchRooms(option.value)
            setStudentTransfer({...studentTransfer, [action.name]: option, fromRoom: '', student:''});
            setErrors({...errors, [action.name]:''})
             }


    const handleSelectToHostel = (option, action)=>{
            fetchToRooms(option.value)
            setStudentTransfer({...studentTransfer, [action.name]: option, toRoom: ''});
            setRoom([])
            setErrors({...errors, [action.name]:''})
        }

        const handleSelectFromRoom = (option, action)=>{
            fetchStudent(option.value)
            setStudentTransfer({...studentTransfer, [action.name]: option, student:''});
            setErrors({...errors, [action.name]:''})
        }


    const handleSelect = (option, action)=>{
       
        if(action.name==='toRoom'){
            fetchRoom(option.value)
        }
        setStudentTransfer({...studentTransfer, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }

  

 const handleSubmit = event =>{
    event.preventDefault();
    setErrors(ValidateInput(studentTransfer)); 
    setIsSubmitting(true);
} 



const ValidateInput =(values)=>{
	let errors = {};   
        
    if(values.fromRoom.value === values.toRoom.value ){
        errors.toRoom ='Same Room selected, kindly change room to be transfer to ';
    }

    if(values.fromHostel.length===0){
        errors.fromHostel ='Please select  current hostel';
	}
    if(values.fromRoom.length===0){
        errors.fromRoom ='Please select current room ';
        window.scrollTo(0,0);
    }

    if(students.length===0){
        errors.student ='There are no student in the selected room ';
        window.scrollTo(0,0);
    } 

    if(values.student.length===0){
        errors.student ='Please select student to transfer';
        window.scrollTo(0,0);
    }

    if(Number(room.totalStudent) >= Number(room.noBedSpace)){
        errors.toRoom ='The room selected is fully occupied';
    }

    if(values.toHostel.length===0){
        errors.toHostel ='Please select  hostel';
        window.scrollTo(0,0);
    }

    if(values.toRoom.length===0){
        errors.toRoom ='Please select  room';
        window.scrollTo(0,0);
    }
    
    
    return errors;
	}
	
	

function submit(){  
    setNotice({...notice, isLoading: true}) 
      const fd = new FormData(); 
    fd.append("jwt", Token);

    fd.append('hostelCode', studentTransfer.toHostel.value);
        fd.append('roomCode', studentTransfer.toRoom.value);
        fd.append('code', studentTransfer.student.value);


      let url = ServerUrl+'/update_controller/tbl_transfer_hostel'
	  axios.post(url, fd, config)
	  .then(response =>{
		if (response.data.type ==='info'){
			Alerts('Saved!', 'default', response.data.message)
            window.scrollTo(0,0)
		} else{
			setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	}  
	})
	.catch((error)=>{
        setErrors({...errors, errorMessage: JSON.stringify(error.message)})
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
    setRoom([])
    setRooms([])
    setToRooms([])
      setIsSubmitting(false); 
      setStudentTransfer({
        fromHostel:'',
        fromRoom:'',
        student:'',
        toHostel:'',
        toRoom:''
    });
})
}

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

        useEffect(()=>{
            handleSession() 
		},[]);
        
        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Transfer Student">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Hostel</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Transfer Student</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">
								

                                
{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


								<div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit"></i> Transfer Hostel or Room</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
		<fieldset>
        <div className="row">
<div className="col-sm-12">

				
	<div className="card z-depth-0">
										<section className="card-block">
                                           
										
	<div className="form-group">
	
	<label >Current Hostel <span style={{color:'red'}}>*</span> </label>
    <Select options={
                               hostel&&hostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
    onChange={handleSelectHostel} className={errors.fromHostel? 'form-control form-control-danger' : ''} name="fromHostel" value={studentTransfer.fromHostel}  /> <span style={{color:'red'}}>{errors.fromHostel && errors.fromHostel}</span>

				</div>

					<div className="form-group">
						<label>Current Room <span style={{color:'red'}}>*</span></label>
					<Select options={
                               rooms&&rooms.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, roomName:list.roomName  }
                                 })} 
                            getOptionLabel={option =>`${option.label} - ${option.roomName}`}
    onChange={handleSelectFromRoom} className={errors.fromRoom? 'form-control form-control-danger' : ''} name="fromRoom" value={studentTransfer.fromRoom}  /> <span style={{color:'red'}}>{errors.fromRoom && errors.fromRoom}</span>
</div>




	<div className="form-group">
						<label>Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                          students&&students.map((list, idx)=> {
                            return {key:idx, value: list.code, label:list.studentName, admissionNumber:list.admissionNumber, email:list.email }
                          })
                     }    
         getOptionLabel={option =>option.admissionNumber +' -'+ option.label}  
onChange={handleSelect} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={studentTransfer.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
											
                                            
                                            
                 <div className="form-group">
						<label>To Hostel <span style={{color:'red'}}>*</span></label>
                        <Select options={
                               hostel&&hostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
    onChange={handleSelectToHostel} className={errors.toHostel? 'form-control form-control-danger' : ''} name="toHostel" value={studentTransfer.toHostel}  /> <span style={{color:'red'}}>{errors.toHostel && errors.toHostel}</span>
												
										</div>



                                        <div className="form-group">
						<label>To Room <span style={{color:'red'}}>*</span></label>
						<Select options={
                               toRooms&&toRooms.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, roomName:list.roomName  }
                                 })} 
                            getOptionLabel={option =>`${option.label} - ${option.roomName}`}
    onChange={handleSelect} className={errors.toRoom? 'form-control form-control-danger' : ''} name="toRoom" value={studentTransfer.toRoom}  /> <span style={{color:'red'}}>{errors.toRoom && errors.toRoom}</span>
												
										</div>
		</section></div></div>
                                        
                                        
                                        </div>
                                        <div className="row">

    
<div style={{display:room.length===0?'none':'block'}} className="col-md-12">
<div className="alert alert-success alert-block background-success" >
    <h4 className="alert-heading " style={{textAlign:'center'}}> <i className="fa-fw fa fa-info"></i> Room Details</h4>
   
    <div className="table-responsive">
<table className="table">
<tbody>

<tr>
<td>Hostel Name:</td>
<th>{room&&room.hostelName}</th>
<td>Room Name:</td>
<th>{room&&room.roomName}</th>
</tr>

<tr>
<td>Room Rate</td>
<th>{room&&Currency+FormatNumber(room.roomRate)}</th>
<td>Room Status</td>
<th>{room&&room.roomStatus}</th>
</tr>


<tr>
<td>No of Bed Space</td>
<th>{room&&room.noBedSpace}</th>
<td>Occupied Space</td>
<th>{room&&room.totalStudent}</th>
</tr>

</tbody>
</table>
</div>



                        </div>
</div>
</div>
										</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary "><i className="fa fa-refresh"></i> Confirm Transfer</button>
	
								</footer>				
							</div>
							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Transfer) 