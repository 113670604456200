
const Tooltips = {

    messageToken:'%School.Name%, %School.URL%, %School.Email%, 	%School.Address%, %School.LogoURL%, %User.FullName%, %Facebook.URL%, %Twitter.URL%, %YouTube.URL%, %User.Email%, %School.Currency%,  %Order.OrderTotal%, %User.PasswordRecoveryURL%, %User.AccountActivationURL%, %User.EmailRevalidationURL%.  Please do not modify the message token',

    required:'This field is required',

    





}

export default Tooltips