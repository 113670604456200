import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, Currency, config } from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, FormatNumber} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'

const PrintVoucher =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [voucher, setVoucher] = useState({
        feecharge:[],
        status:{value: 'ALL', label: 'ALL'},
        studentClass:[],
        voucherType:{ value: 'Bank', label: 'Bank Voucher' },
        slipNo:'',
        student:[]
    })

    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const studentList  = useSelector(state => state.rootReducer.students);

    const [feecharge, setFeecharge] = useState([]);
    const [bank, setBank]= useState([])

    const [result, setResult]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    const fetchFeecharge =()=>{
        const fd = new FormData();
				  fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
        axios.post(url, fd, config).then(result=>
            setFeecharge(result.data.filter(item=>item.status=='true')))
    }

   
    const  fetchPaymentGateway =()=>{
        const fd = new FormData();
				  fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_payment_gateway/details/title/Bank'
        axios.post(url, fd, config)
        .then(result => {
            setBank(result.data)
        })
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
            
    }


    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(voucher));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.feecharge.length===0){
            errors.feecharge ='Please select fees';
        }   
        return errors;
        }

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        fetchResult()
        }
        },[errors])

const handleSelect = (option, action)=>{
    setVoucher({...voucher, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   setResult('')
}

const handleChange = event =>{
    const {name, value} = event.target
    setVoucher({...voucher, [name] : value });
   setErrors({...errors, [name]:''})
}


const fetchResult =()=>{

if(voucher.voucherType.value ==='Blank'){
    window.open(ServerUrl+'/printout/fees_voucher_blank.php?token=kayus_token', '_blank')
}else{

    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT sf.ID, sf.classID, sf.sectionID, sf.payment_status, s.admissionNumber, s.registerNumber, s.studentName, s.mobile, CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, sf.total,  (select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =sf.code) as paid from tbl_students s, tbl_fees_charge f, tbl_student_fees sf  where s.code = sf.studentCode and f.code = sf.feeCharge and sf.feeCharge = '"+voucher.feecharge.value+"'"

var feecharge =voucher.feecharge.value
var vtype =voucher.voucherType.value
var status =''
var code = ''
var slip = ''
var section = ''

    if(voucher.studentClass.length!==0){
        sql = sql + '  and sf.sectionID = "'+voucher.studentClass.value+'"'
        section = voucher.studentClass.value
    }
    if(voucher.status.value!=='ALL'){
        sql = sql + '  and sf.payment_status = "'+voucher.status.value+'"'
        status =voucher.status.value
    }
    if(voucher.student.length!==0){
        sql = sql + ' and sf.studentCode = "'+voucher.student.value+'"'
        code = voucher.student.value
    }

    if(voucher.slipNo!==''){
        sql = sql + ' and sf.ID = "'+voucher.slipNo+'"'
        slip = voucher.slipNo
    }
    
    sql = sql + ' order by sf.sectionID ASC'
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
setResult(result.data)

window.open(ServerUrl+'/printout/fees_voucher.php?feecharge='+feecharge+'&studentCode='+code+'&section='+section+'&slip='+slip+'&status='+status+'&voucher='+vtype+'&token=fee_voucher', '_blank')


    }else{
        Alerts('Info!', 'info', 'There are no voucher found')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}
}




useEffect(()=>{
    fetchFeecharge()
    fetchPaymentGateway()
},[]);

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Fees Voucher">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Print Fees Voucher</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Print Fees Voucher</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">
        <section className="col-md-4">
           <div className="form-group">
        
<label >Fee Charge<span style={{color:'red'}}>*</span> </label>
<Select  options={feecharge&&feecharge.map((list, idx)=> {
                               return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                             })
                        } 
                        getOptionLabel={option =>`${option.session}  ${option.label}`}       
onChange={handleSelect} className={errors.feecharge ? 'form-control form-control-danger' : ''} name="feecharge" value={voucher.feecharge}  />  
              <span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span> 
            </div>
     
     </section> 
                    <section className="col-md-4">
           <div className="form-group">
           <label> Search By Status </label>
         <Select  options={[
                    { value: 'ALL', label: 'ALL' },
                    { value: 'UNPAID', label: 'UNPAID'},
                    { value: 'PAID', label: 'PAID'},
                    { value: 'PARTIAL PAYMENT', label: 'PARTIAL PAYMENT'}
                ]} 
			onChange={handleSelect} name="status" value={voucher.status}  />	
            </div>
     
     </section>       

        	
     <section className="col-md-4">
           <div className="form-group">
           <label> Voucher Type </label>
         <Select  options={[
                    { value: 'Bank', label: 'Bank Voucher' },
                    { value: 'Cash', label: 'Cash Voucher'},
					{ value: 'Blank', label: 'Blank Bank Voucher'}
                ]} 
			onChange={handleSelect}  name="voucherType" value={voucher.voucherType}  />
 <span style={{color:'red'}}>{errors.voucherType && errors.voucherType}</span>	
 
 </div>
     
     </section>
     <section className="col-md-4">
            <div className="form-group">
         <label> Search By Class</label>
            <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={voucher.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
            </section>
            <section className="col-md-4">
									

        <div className="form-group">
                    <label>Search By Student </label>
                    <Select  options={
                        studentList.map((list, idx)=> {
                            return {key:idx, value: list.code, fatherName:list.fatherName, mobile:list.father_mobile, label: list.admissionNumber, studentName:list.studentName }
                            })} 
                    getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelect}  name="student" value={voucher.student}  /> 
                                        </div>
                         </section>

                         <section className="col-md-4">
									

        <div className="form-group">
                    <label>Search By Slip No </label>
                    <input type='text' name="slipNo" className="form-control" onChange={handleChange} value={voucher.slipNo} />
                                        </div>
                 </section>
				</div>	

	<footer className="pull-right">
	
	<button type="button"  onClick={handleSearch} className="btn btn-danger "><i className="fa fa-bar-chart"></i> Search Voucher</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(PrintVoucher) 