import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, staffCode, config} from '../../component/include'
import Select from 'react-select'



import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../component/globalFunction'


const ApplyLeave =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
const [CurrentSession, setCurrentSession] = useState('')
    const [total, setTotal]= useState([])
    const [staff, setStaff]= useState([])    
    const [leave, setLeave]=useState([])
    const [staffLeave, setStaffLeave]=useState([])    
    const [newLeave, setNewLeave]=useState([])
    const [leaveList, setLeaveList]= useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [applyLeave, setApplyLeave] = useState({
        staff:[],
        leaveType:[],
        fromDate:'',
        toDate:'',
        days:'',
        sessionID: CurrentSession
    })
    

    const fetchLeave =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_staff_leave_settings'
        axios.post(url, fd, config).then(result=>setLeave(result.data))

    }

const fetchStaffLeave =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_salary_structure/leaveStructure/staff/'+staffCode
        axios.post(url, fd, config).then(result=>setStaffLeave(result.data))
            
    }

    const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        }
    const combineLeave=()=>{
       let value = []
       staffLeave&&staffLeave.map((itm, idx)=>{
            
            value.push({ID:itm.ID, leaveName:getLeave(itm.ID, 'leaveName'), leaveType:getLeave(itm.ID, 'leaveType'), timeCycle:getLeave(itm.ID, 'timeCycle'), days:itm.days, remain:Number(itm.days) -getTotal(itm.ID), approve:getTotal(itm.ID)});
            return ''
        } );
        
        setNewLeave(value)
    }


    const getLeave = (ID, field) =>{
        const result = leave.filter(list=>list.ID===ID);
       const answer = result.map(c=>c[field]); 
         return  answer;
       }

       const getTotal = (ID) =>{
        const result = total.filter(list=>list.leaveType===ID);
       const answer = result.length!==0?result.map(c=>c.total):0; 
         return  Number(answer)
       }



       const  fetchTotalDays =()=>{  
        const fd = new FormData();
    fd.append('jwt', Token);
   let sql = "Select leaveType, SUM(days) as total from tbl_staff_leave where status ='Approved' and staff ='"+staffCode+"' and sessionYear='"+CurrentSession+"' Group by leaveType"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setTotal(result.data)) 
   
    }  



    const  fetchLeaveList =()=>{  
        const fd = new FormData();
    fd.append('jwt', Token);
   let sql = "Select s.ID, s.days, f.staffName, y.startYear, l.leaveName, 	s.fromDate,	s.toDate, s.status  from tbl_staff_leave s, tbl_staff_leave_settings l, tbl_school_session y, tbl_staff f where s.staff=f.code and s.leaveType = l.ID and s.sessionYear = y.startYear and s.staff ='"+staffCode+"'"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setLeaveList(result.data)) 
   
    }   






    

 

      

   


   


    const handleSelect= (option, action)=>{
        setApplyLeave({...applyLeave, [action.name]:option});
        setErrors({...errors, [action.name]:''})
       
    }
    
    const handleChange=(event)=>{
        setApplyLeave({...applyLeave, [event.target.name]:event.target.value});
        setErrors({...errors, [event.target.name]:''})
        
    }
  
    
    
   

    
    const getDiffer=(toDate)=>{

        var today =  new Date();
        var secondDate =  new Date(toDate);
        var diff_time = secondDate.getTime()-today.getTime()
        var differ = diff_time/(1000*3600*24);
        return differ
    } 




    const tableAssignLeaveHeader = [
        {dataField: 'leaveName', text: 'Leave Name', editable: false},
        {dataField: 'timeCycle', text: 'Time Circle', editable: false},
        {dataField: 'leaveType', text: 'Type', editable: false},
        {dataField: 'days', text: 'Leave (Days)', editable: false},        
        {dataField: 'approve', text: 'Total Approved', editable: false},
        {dataField: 'remain', text: 'Days Remain', editable: false}    
     ];
   


     const tableLeaveListHeader = [
        
        {dataField: 'staffName', text: 'Name', editable: false},
        {dataField: 'leaveName', text: 'Leave Type', editable: false},
        {dataField: 'fromDate', text: 'From Date', editable: false, formatter:(cell)=>
        longDate (cell)},
        {dataField: 'toDate', text: 'To Date', editable: false, formatter:(cell)=>
        longDate(cell)},        
        {dataField: 'days', text: 'Days', editable: false},        
        {dataField: 'status', text: 'Status', formatter:(cell, row)=>cell==='Approved'?<span className="pcoded-badge label label-success">{cell}</span>:<span className="pcoded-badge label label-danger">{cell}</span>, editable: false},        
        {dataField: 'startYear', text: 'Session', editable: false}   
     ];

    
 const TableBasic=(props)=>{
       
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                  
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }

    


   
    const getDateFormat =()=>{
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var answer = 31;
       if(month ==='02'){
         answer = 28;}else if(month ==='04' || month ==='06' || month ==='09' || month ==='11'){
         answer = 30; 
         }

        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        
        var minDay = year + "-" + month + "-01"; 
        var maxMonth = year + "-" + month + "-" + answer;
//console.log(maxMonth)
   document.getElementById('fromDate').min =  minDay;    
   document.getElementById('fromDate').max = maxMonth;  
   document.getElementById('toDate').min =  minDay;
    }


    
    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(applyLeave));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};  
        
        if(values.leaveType.length===0){
            errors.leaveType ='Please select leave type';
        } 
        if(!values.fromDate){
            errors.fromDate ='Please enter effective date';
        } 
        if(!values.toDate){
            errors.toDate ='Please enter end date';
        } 
        if(newLeave.length===0){
            errors.staff ='There is no leave define for the staff selected';
        } 

        if(newLeave.length!==0){
        const result = newLeave.filter(list=>list.ID===values.leaveType.value);
        const remain = Number(result[0].remain)

        if(Number(result[0].days)===Number(result[0].approve)){
            errors.fromDate = 'it appears that the selected staff have exhusted his/her leave for the selected type';
        }

        if(Number(getDays())> Number(remain)){
          //  errors.fromDate ='The difference in the date range is more than leave remain for the selected type'
            errors.toDate ='The difference in the date range is more than leave remain for the selected type'
        }
    }
        if(Number(getDays())<=0){
            errors.toDate = 'Invalid date range selected'
           // errors.fromDate = 'Invalid date range selected'
        }
        if(!Number(getDays())){
           // errors.fromDate = 'Invalid date range selected'
            errors.toDate = 'Invalid date range selected'
        }

        return errors;
        }

    const getDays=()=>{

        var fromDate =  new Date(applyLeave.fromDate);
        var toDate =  new Date(applyLeave.toDate);
        var diff_time = toDate.getTime()-fromDate.getTime()
        var differ = diff_time/(1000*3600*24);

        return differ
    }  


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    const  submit=()=>{  
              
    setNotice({...notice, 
        isLoading: true})   

        const fd = new FormData();
    fd.append('jwt', Token); 
      
        fd.append('staff', staffCode);
        fd.append('leaveType', applyLeave.leaveType.value); 
        fd.append('fromDate', applyLeave.fromDate);
        fd.append('toDate', applyLeave.toDate);
        fd.append('days', getDays());        
        fd.append('sessionYear', CurrentSession);
       
         let url = ServerUrl+'/save_controller/tbl_staff_leave'
        axios.post(url, fd, config)
        .then(response =>{
            
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                  } else{
                Alerts('Error!', 'danger', response.data)
                  }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setNotice({...notice, 
              isLoading: false
            })
setApplyLeave({
        leaveType:[],
        fromDate:'',
        toDate:'',
        days:''
    })

            setIsSubmitting(false);
            fetchStaffLeave() 
    fetchLeaveList()  
    fetchTotalDays()
          }) 
     }    

useEffect(()=>{  
fetchLeave()
    getDateFormat() 
    fetchStaffLeave() 
    fetchLeaveList()  
    fetchTotalDays()
    handleSession()
},[]);



        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Leave">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Apply Leave</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Apply Leave</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
   
   
   <div className="form-group">
<label >From Date <span style={{color:'red'}}>*</span></label>
<input type="date" className={errors.fromDate ? 'form-control form-control-danger' : 'form-control'} name="fromDate" id="fromDate"  onChange={handleChange} value={applyLeave.fromDate} />
<span style={{color:'red'}}>{errors.fromDate && errors.fromDate}</span>
   </div>
</section>

<section className="col-md-6">
   


   <div className="form-group">
<label >Leave Type <span style={{color:'red'}}>*</span></label>
      <Select  options={
                           newLeave&&newLeave.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.leaveName}
                             })} 
onChange={handleSelect} onFocus={combineLeave} className={errors.leaveType ? 'form-control form-control-danger' : ''} name="leaveType" value={applyLeave.leaveType}  /> <span style={{color:'red'}}>{errors.leaveType && errors.leaveType}</span>
   </div>
   </section>

<section className="col-md-6">

   <div className="form-group">
<label >To Date <span style={{color:'red'}}>*</span></label>
<input type="date" className={errors.toDate ? 'form-control form-control-danger' : 'form-control'} name="toDate" id="toDate"  onChange={handleChange} value={applyLeave.toDate} />
<span style={{color:'red'}}>{errors.toDate && errors.toDate}</span>
   </div>
</section>


<section className="col-md-6">
	<footer className="pull-left"><br/>
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "><i className="fa fa-save"></i> Send Request</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div> </div> 
							
</div>

</div>        </div> </div> 
							
                            </div>
                            
                            </div>

                            {newLeave.length!==0?
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> My Assigned Leaves ({CurrentSession})</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">

          <section className="col-md-12 table-responsive">
          
      <TableBasic data={newLeave}   columns={tableAssignLeaveHeader}  /> 
          </section>
        

            </div>


    
    </div>
    
    </div>
    </div>:''}



    {leaveList.length!==0?
    <div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> All Leaves History</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

          {notice.isDataFetching ? <TableLoader />:
      <TableBasic data={leaveList}  columns={tableLeaveListHeader}  />} 

            </div></div></div>
    
    
    </div>
    </div>:''}

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(ApplyLeave) 