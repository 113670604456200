import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {config, ServerUrl, Token} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';

import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
const Subjects=()=>{

    const Initials = {
        subjectName:'',
        abbreviation:'',
        studentClass:[],
        classSubject:[],
        teacher:[]
    }
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [subject, setSubject]=useState(Initials);
    const [errors, setErrors] = useState({});
    
    const staff  = useSelector(state => state.rootReducer.staff);
const [data, setData] =  useState([]);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [editSubject, setEditSubject] =  useState([]);
    const handleSelect = (option, action)=>{
        setSubject({...subject, [action.name]: option});
     }


    function submit(){  
        var teacher = subject.teacher;
        var teacherList = [];
        for (var i = 0; i< teacher.length;  i++) {
            teacherList.push(teacher[i].value)
        }
        setNotice({...notice, isLoading: true}) 
    
         let formData = subject; 
            const fd = new FormData(); 
            for(let k in formData){
                fd.append(k,formData[k]);
            }; 
            fd.append('jwt', Token);
            fd.append('staff', JSON.stringify(teacherList, null, 2));
            fd.append('code', 'sb'+Math.random().toString(36).substr(2,9))
   
             let url = ServerUrl+'/save_controller/tbl_subject';
            axios.post(url, fd, config)
            .then(response =>{
                if (response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }  
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            .finally(()=>{
              setNotice({...notice, isLoading: false}) 
            handleReset(); 
            handleFetchSubject()
            }) 
    }


    function submitUpdate(){  
        
        setNotice({...notice, isLoading: true}) 
            const fd = new FormData(); 
            fd.append('jwt', Token);
            fd.append('code', editSubject[0].code);
            fd.append('subjectName', editSubject[0].subjectName);
            fd.append('abbreviation', editSubject[0].abbreviation);
             fd.append('teacher', editSubject[0].teacher);
            
             let url = ServerUrl+'/update_controller/tbl_subject_update';
            axios.post(url, fd, config)
            .then(response =>{
                if (response.data.type ==='info'){
                    Alerts('Saved!', 'info', response.data.message)
                    setEditSubject([])
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }  
            })
            .catch((error)=>{
                //Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            }).finally(()=>{
                handleFetchSubject()
                setNotice({...notice, isLoading:false}) 
            }) 
    }


    const handleReset=()=>{
        setSubject(Initials);
    }


    
const handleChangeSubject = (e)=>{ 
       const value =e.target.value;
       editSubject[0][e.target.name] = value;
   setEditSubject(editSubject); 
   setErrors({...errors, [e.target.name]:''})
}


const handleSelectEditSubject = (option, action)=>{
    const total = option!==null?option.length:[]
    const std =editSubject   
  var value = [];
  for (var i = 0, l = total; i < l; i++) {
    value.push(option[i].value);
  }
  const teacher = JSON.stringify(value) 
  std[0][action.name]= JSON.stringify(value) 
  
        setEditSubject(std);
        setErrors({...errors, [action.name]:''})
}


        const handleSubmit =(event)=>{
            event.preventDefault();
            let  oldSub = data.filter(item=>item.subjectName.toUpperCase()===subject.subjectName.toUpperCase())

            if (oldSub.length>0 && oldSub[0].subjectName=== subject.subjectName.toUpperCase()){
                
                    errors.subjectName ='Subject name  already exist';
                }


            let errors = {};     
            if(!subject.subjectName){
                errors.subjectName ='Please enter subject name';
            }
           

setErrors(errors)
 if(Object.keys(errors).length === 0){
    submit()
    }
        }
    


    const ValidateExistence=()=>{
        if(subject.subjectName!==''){
            
            let  oldSub = data.filter(item=>item.subjectName.toUpperCase()===subject.subjectName.toUpperCase())

            if (oldSub.length>0 && oldSub[0].subjectName=== subject.subjectName.toUpperCase()){
                setSubject({...subject, subjectName:''})
                setErrors({...errors, subjectName:subject.subjectName+' already exist'})
            }
        }
    }


        const  handleFetchSubject =()=>{

            setNotice({...notice, isDataFetching:true})
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetch_controller/tbl_subject';
            axios.post(url, fd, config)
        .then(result => setData(result.data.sort((a, b) =>(a.subjectName > b.subjectName) ? 1 : -1)))
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        }).finally(()=>{
            setNotice({...notice, isDataFetching:false})
        }) }
    

    const handleChange = (event)=>{
        const {name, value} = event.target
        setSubject({...subject, [name] : value})
        setErrors({...errors, [name]:''})
    }

    const getStaffField = (code, field) =>{   
    
        const result = staff&&staff.filter(list=>list.code===code);
        const answer = result.map((c)=>c[field] ); 
        return  String(answer);
       }
       

  

    const getTemplate=(temp)=>{

        if(temp!=='[]'){
            
        var value = [];
     JSON.parse(temp).map((itm, idx)=>{
        value.push({value:itm, label:getStaffField(itm, 'staffName'), fileNo:getStaffField(itm, 'fileNo'), key:idx});
        } );
        
    return value;
    
    }else{
        return []
    }}



 const handleDelete =(ID)=>{
		let close =   document.getElementById('btnWarningDialog-'+ID)
		close.click();
		

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'ID')
		fd.append('data', ID)
        fd.append('jwt', Token);
	let url = ServerUrl+'/delete_controller/tbl_subject';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
				  } else{
			  Alerts('Error!', 'danger', JSON.stringify(response.data))
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
            handleFetchSubject()
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
}
    
    

useEffect(()=>{
    handleFetchSubject()
},[])
   
const handleStaffName=(cell,row)=>{
    if(cell!=='[]'){
    return JSON.parse(cell).map((itm, idx)=>{
        return <div  key={idx}>{getStaff(itm)}</div>
    } );
}else{
    return ''
} 
}


const getStaff = (code) =>{   
    
     const result = staff&&staff.filter(list=>list.code===code);
    const answer = result.length!==0?result.map(c=>`${c.fileNo} - ${c.staffName}` ):''

      return  answer;
    }

 
    const handleEditSubject =(item)=>{
        setEditSubject([item])
        //setIsEditTemplate(true)
        window.scrollTo(0,0);
        }


    const tableHeader = [
        {dataField: 'abbreviation', text: 'Abbreviation'},
        {dataField: 'subjectName', text: 'Subject Name', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Subject Name is required'
              };
            }
            return true;
          }, formatter:(cell)=>cell.toUpperCase()}, 
        {dataField: 'teacher', text: 'Teacher', formatter:handleStaffName, editable: false},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div> 

            <button type="button" className="btn btn-warning btn-sm" onClick={(e)=>handleEditSubject(row)} title="Edit"><i className="fa fa-edit"></i></button>{/* 
        <button type="button" className="btn btn-danger delete btn-sm mr-1 mb-1" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button> */}
         
        <WarningModal message='Once deleted, you will no be able to recover this record. Are you realy sure?' handleAction={()=>handleDelete(row.ID)} mID={row.ID} />  </div>, editable: false}
        
     ];

     const SubjectTable=(props)=>{
        
const handleUpdate=(column, newValue, ID)=>{       
    const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
        fd.append('jwt', Token);
       let url = ServerUrl+'/updateCustom_controller/tbl_subject'
      axios.post(url, fd, config)
      //.then(result => console.log(result.data))
      .then()
      .catch((error)=>Alerts('Error!', 'danger', error.message)) 
    } 
      
      
          const { SearchBar } = Search;
         
          const customTotal = (from, to, size) => (
             <span >&nbsp;Showing { from } to { to } of { size } items</span>
           );
        const options = {
            showTotal: false,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '50', value: 50}, {text: '150', value: 150}, {text: '500', value: 500},
        { text: 'All', value: props.data.length }]
           
          };
      
        return <ToolkitProvider search className="table-responsive"
                    keyField='ID' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            <div >
                                <SearchBar
                                { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />

<div className="dt-buttons btn-group pull-right"> 
         
<Link to="/exam/class_subject"  className="btn btn-outline-primary" ><i className="fa fa-pencil"></i>Assign Subject to Class </Link>


      </div> 
                                <BootstrapTable
                                { ...props.baseProps }
                                        hover
                                        noDataIndication={'No Record Found'}
                                    pagination={paginationFactory(options) }
                                    cellEdit={ cellEditFactory({
                                      mode: 'dbclick',
                                      blurToSave: true,
                                      autoSelectText: true,
                            beforeSaveCell: (oldValue, newValue, row, column) => {
                              if(oldValue !==newValue){
                              handleUpdate(column, newValue, row.ID);
                              
                             return '';
                            }
                            
                          }
                                    }) }
                                />
                            </div>
                            )
                        }
      </ToolkitProvider>
      }






        return ( <>
   {notice.isLoading ?<LoadEffect className="floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Create Subject">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-desktop"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Class & Section</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Create Subject</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                

            {editSubject.length>0 ?editSubject.map((item, index) =>     <div className="row " key={index}>
								<div className="col-sm-4 col-sm-6 col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Update Subject</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset>
				<div className="row">
				<section className="col-md-6">

<div className="form-group">
<label >Subject Name<span style={{color:'red'}}>*</span></label>
<input type="text" className={errors.subjectName ? 'form-control form-control-danger' : 'form-control'} name="subjectName"  onChange={handleChangeSubject} value={item.subjectName} />
<span style={{color:'red'}}>{errors.subjectName && errors.subjectName}</span>
   </div>

</section>
   <section className="col-md-6">
<div className="form-group">
<label >Abbreviation</label>
<input type="text" className='form-control' name="abbreviation"  onChange={handleChangeSubject} value={item.abbreviation} />
   </div>

</section>
   <section className="col-md-12">
   <div className="form-group">
<label >Subject Teacher</label>

<Select isMulti options={
                            staff.map((list, idx)=> {
                                return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                            })
                       }    
           getOptionLabel={option =>option.label +' - '+ option.fileNo} 
onChange={handleSelectEditSubject}  name="teacher" value={getTemplate(item.teacher)}  />
   </div>      

                </section>
            </div>
        </fieldset>	
                                
	<footer className="pull-right">
	
	<button type="button" className="btn btn-danger " onClick={()=>setEditSubject([])}>Cancel</button>&nbsp;
	<button type="button" className="btn btn-success " onClick={submitUpdate}>Update Subject</button>
	
								</footer>		
							</div>
							</div>
							</div>

                            </div>): <div className="row  ">
								<div className="col-sm-4 col-sm-6 col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Subject</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset>
				<div className="row">
				<section className="col-md-6">

<div className="form-group">
<label >Subject Name<span style={{color:'red'}}>*</span></label>
<input type="text" className={errors.subjectName ? 'form-control form-control-danger' : 'form-control'} name="subjectName" onBlur={ValidateExistence} onChange={handleChange} value={subject.subjectName} />
<span style={{color:'red'}}>{errors.subjectName && errors.subjectName}</span>
   </div>

</section>
   <section className="col-md-6">
<div className="form-group">
<label >Abbreviation</label>
<input type="text" className='form-control' name="abbreviation"  onChange={handleChange} value={subject.abbreviation} />
   </div>

</section>
   <section className="col-md-12">
   <div className="form-group">
<label >Subject Teacher</label>

<Select isMulti options={
                            staff.map((list, idx)=> {
                                return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                            })
                       }    
           getOptionLabel={option =>option.label +' - '+ option.fileNo} 
onChange={handleSelect}  name="teacher" value={subject.teacher}  />
   </div>      

                </section>
            </div>
        </fieldset>	
                                
	<footer className="pull-right">
	
	<button type="button" className="btn btn-inverse " onClick={handleSubmit}>Add Subject</button>
	
								</footer>		
							</div>
							</div>
							</div>

                            </div>}

                            <div className="row">
         <div className="col-sm-4 col-sm-6 col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Listing All Subject</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>

        

		<div className="card-block">


        <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <SubjectTable data={data}  columns={tableHeader} />}
	
	</div></div>

</div>

</div>


                </div>
          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(Subjects) 