import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, config } from '../component/include'

import { useSelector } from 'react-redux'
import { getClassName } from '../component/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom'

 const UploadStudents=()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    const [data, setData] =  useState([]);
    const [CurrentSession, setCurrentSession] = useState('')
const [csvFile, setCsvFile]=useState({
    studentCsv:'',
    fileName:'Please select .csv or .xlsx files (allowed file size 500KB)',
    sessionID:CurrentSession&&CurrentSession, 
})

const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const [errors, setErrors] = useState({});

		
const handleSession =()=>{
    let url = API_URL+'/session/all';
    axios.get(url, config).then(result=>{
        if(result.data.data.length!==0){
            let data = result.data.data.filter(item=>item.status==='Active')
            setCurrentSession(data[0].startYear)
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })  
}



const  handleSubmit=(event)=>{ 
    event.preventDefault(); 
    if(!csvFile.studentCsv){
        errors.studentCsv ='You have not choose any file to upload';
        Alerts('Error!', 'danger', errors.studentCsv)
    }else{

    
    let formData = csvFile;
    
    const fd = new FormData(); 
     for(let k in formData){
        fd.append(k,formData[k]);
    };  
    fd.append('regSession', CurrentSession)
    
    
     setNotice({...notice,   isLoading: true}) 
       let url = API_URL+'/student/imports';
      axios.post(url, fd, config).then(response =>{
        if (response.data.status ===200){
			Alerts('Saved!', 'success', response.data.message)
		 }else{
			setErrors({...errors, errorMessage:  response.data.message}) 
	}  
	})
	.catch((error)=>{
		setErrors({...errors, errorMessage: error.message}) 
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
     setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv or .xlsx files (allowed file size 500KB)'}); 
     document.getElementById('studentCsv').value=''
}) 
}
 }






    const  handleFile = function(fieldName){
        return function(newValue){  
           
            setErrors({...errors, [fieldName]:''})
       readURL(newValue);
       }
       
    } 
   

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        

        if (extFile==="csv" || extFile==="xlsx"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 20) {
            displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
           document.getElementById(input.target.name).value = '';
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                   const value = input.target.files[0]
            setCsvFile({...csvFile, studentCsv:value, fileName:value.name})
           
            }
        }
        }else{

            displayMessage = 'Only csv|xlsx  files are allowed with maximum of 500kb'
            document.getElementById(input.target.name).value = '';
            setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .xlsx or .csv files (allowed file size 500KB)'})
        }  
        
        if(displayMessage.length > 0){
            setErrors({...errors, errorMessage: JSON.stringify(displayMessage)})
            
          }
        
    } 




       const tableClassHeader = [
        
        {dataField: 'classID', text: 'Class ID', editable: false}, 
        {dataField: 'sectionID', text: 'Section ID', editable: false},
        {dataField: 'code', text: 'Class', editable: false, formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass)}
        
     ];


     const ClassTable=(props)=>{
        

          
              const { SearchBar } = Search;
             
              const customTotal = (from, to, size) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: false,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
          
            return <ToolkitProvider search className="table-responsive"
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                <div >
                                    <SearchBar
                                    { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                                    
    <div className="dt-buttons btn-group pull-right"> 

    <Link to="/setup/school_classes" className="btn btn-outline-primary"> <i className="fa fa-pencil"></i>Add More Class</Link>
          </div> 
                                    <BootstrapTable
                                    { ...props.baseProps }
                                            hover
                                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                        pagination={paginationFactory(options) }
                                       
                                        
                                    />
                                </div>
                                )
                            }
          </ToolkitProvider>
          }



          const handleFetchClassSubject =()=>{
            let url = API_URL+'/exam/class/subject';
            axios.get(url, config).then(result=>{
                if(result.data.subject.length!==0){
                    setData(result.data.classsubject)
                }else{
                    setData([])
                }
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
                    
        }


        useEffect(()=>{   
            handleSession()
            handleFetchClassSubject()
        },[]);

        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

                   {/* <!-- Page-header start --> */}

        <PageHeader title="Upload Students From Excel">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/students/view_students"> Students</Link></li> 
                    <li className="breadcrumb-item"><a href="#!">Students Upload</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}
        


                <div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert alert-success border-success background-success ">
					<h4 className="alert-heading ">Download Sample File</h4>
					
<p><span >The first line in the downloaded sample file should remain as it is. Please do not change the order of columns.</span><br/>

<span >The correct column order is (<b>
Register Number, Admission Number, Student Name, Gender, Student Email,  Mobile, Admission Date, Date Of Birth, Class ID, Section ID, Father Name, Mother Name </b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><p className="text-align-left">
						
                    <form method='GET' action={API_URL+'/download_file/student/demo'} target='_blank'>
                
        <button type="submit" className="btn btn-sm btn-inverse"> <i className="fa fa-download"></i> Download Sample</button>
        
        </form>
					</p>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
							<h4 className="alert-heading ">Upload file with .csv or .xlsx extention</h4>
							
							<section>
                            <div className="form-group">
							<label  className={errors.studentCsv  ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-inverse'}  htmlFor="studentCsv" > <i className="fa fa-upload"></i>Choose File </label>
                    <input id="studentCsv"  onChange={handleFile('studentCsv')} className="form-control file" name="studentCsv" type="file" accept=".csv, .xlsx" /> 
						<div className="note" style={{color:'red'}}>
			{csvFile.fileName}
		</div>	
										</div>          
                                        
							</section>
						</fieldset><span style={{color:'red'}}>{errors.studentCsv && errors.studentCsv}</span></div>
						<footer>
							<button type="button" id="submit" onClick={handleSubmit} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Students</button>
						</footer>

	</div>

</div>

</div></div>
	

								<div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>View Class List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">

		  <div className="card z-depth-0">
            <div className="card-block">
          <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <ClassTable data={data}  columns={tableClassHeader}  />}
        </div> </div></div>
	</div>
    </div>
    </div>
</div>


<div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Prepare your Students file</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >

                    <div className="well">
                        <div className="box-body">
                        <div className="row">


                            <div className="col-sm-3 text-right  text-danger"><u><b>Required Columns</b></u></div>
                            <div className="col-sm-7"><u><b>Allowed Values</b></u></div>                           
                                								
                            <div className="col-sm-3 text-right text-bold">Student Name</div>
                        <div className="col-sm-7">Name of that particular Student       <br/>  </div>   


                        <div className="col-sm-3 text-right text-bold">Gender</div>
                        <div className="col-sm-7">Sex of that particular Student       <br/>  </div>

                        <div className="col-sm-3 text-right text-bold">Date of Admission</div>
                        <div className="col-sm-7">The date in which student information was captured       <br/>  </div>

                        <div className="col-sm-3 text-right text-bold">Student Email</div>
                            <div className="col-sm-7">Email Address of the student, this will be used to recover password as well<br/>                           
                                </div> 

                                <div className="col-sm-3 text-right "><b>Admission Number</b></div>
                    <div className="col-sm-7">Unique Admission Number. Each student  are assigned their unique admission number and should be entered correctly. <br/>                       </div> 
                        <div className="col-sm-3 text-right text-bold">Class ID</div>
                        <div className="col-sm-7">The class ID in which the student will be admitted to      </div>

                        <div className="col-sm-3 text-right text-bold">Section ID</div>
                        <div className="col-sm-7">The Section ID in which the student will be admitted to   <br/>    <br/>  </div>
                        <div className="col-sm-3 text-right  text-warning"><u><b>Optional Columns</b></u></div>
                            <div className="col-sm-7 "><u><b>Allowed Values</b></u></div> 
                          

                          <div className="col-sm-3 text-right "><b>Father Name</b></div>
                            <div className="col-sm-7">The name of the father of that particular student<br/>                          
                                </div>                       
                           
								
								
								<div className="col-sm-3 text-right "><b>Mother Name</b></div>
                            <div className="col-sm-7">Student Mother's Name  <br/>     
                                </div> 
								<div className="col-sm-3 text-right "><b>Date of Birth</b></div>
                            <div className="col-sm-7">Student date of birth  <br/>     
                                </div> 
                             
                            <div className="col-sm-3 text-right "><b>Register Number</b></div>
                    <div className="col-sm-7">Unique register Number. Each student  are assigned their unique register number and should be entered correctly. <br/>                       </div>                          
                            <div className="col-sm-3 text-right "><b>Student Mobile</b></div>
                            <div className="col-sm-7">Any<br/>                          
                                </div>
                        </div>
                    </div>
                </div>
	</div>
    </div>

</div>
</div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(UploadStudents) 