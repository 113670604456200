import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {FormatNumber, Currency, config, allowDelete, API_URL, ServerUrl, Token} from '../component/include'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { longDate, toWords } from '../component/globalFunction'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'

const AssignSalaryStructure =()=> {
    const [errors, setErrors] = useState({});
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
const staff  = useSelector(state => state.rootReducer.staff);
const [template, setTemplate]=useState([])
const [salary, setSalary]=useState([]);
const [title, setTitle]=useState([])
    
const [structureList, setStructureList]=useState([])
    const [salaryStructure, setSalaryStructure]=useState([]);

    const [structure, setStructure] = useState({
        template:[],
        staff:[],
        effectiveMonth:'',
        remarks:''
    })
    
    const fetchTemplate =()=>{
        let url = API_URL+'/payroll/salary_title/display/all';
        axios.get(url,  config).then(result=>{
            if(result.data.data.length!==0){
                setTitle(result.data.data)
                setTemplate(result.data.template)
                setStructureList(result.data.structure)
                }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }


        const fetchSalaryStructure =(staff)=>{
            let url = API_URL+'/payroll/salary_structure/show/one/'+staff;
            axios.get(url,  config).then(result=>{
                if(result.data.data.length!==0){
                    setSalaryStructure(result.data.data)
                    }else{
                        setSalaryStructure([])
                    }
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }

            const handleChange = (e, code)=>{
                let  value = e.target.value.replace(/[^0-9.]/g, '');
                let newSalary = salary.map((item)=>{
                    if(item.code === code){
                        return {...item, 
                            amount:value,
                        }
                        };
                        return item;
                  })
                  setSalary(newSalary); 
            }

 


   const handleSelect= (option, action)=>{
    setStructure({...structure, [action.name]:option});
    setErrors({...errors, [action.name]:''})

    if(action.name==='template'){
        fetchTemplateTitle(option.value)            
    }
    if(action.name==='staff'){          
    fetchSalaryStructure(option.value);
    }
}

const  fetchTemplateTitle =(code)=>{          
    const result = template.filter(list=>list.code===code);
   const answer = result.map(c=>c.template); 

   let value = []
   JSON.parse(answer).map((itm, idx)=>{
       value.push(
        {
            code:itm.titleCode, 
            salaryHead:getTitle(itm.titleCode, 'salaryHead'), 
            title:getTitle(itm.titleCode, 'title'), 
            salaryCode:getTitle(itm.titleCode, 'salaryCode'), 
            amount:0, 
            key:idx});
       return ''
   } );

   setSalary(value)
 } 

   const getTitle = (code, field) =>{ 
    const result = title.filter(list=>list.code===code);
   const answer = result.map(c=>c[field]); 
     return  String(answer);
   }



const handleSubmit = event =>{
    event.preventDefault();   
    
    let errors = {};   
    if(structure.staff.length===0){
        errors.staff ='Please select staff';
    } 
    if(structure.template.length===0){
        errors.template ='Please select salary Template';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        submit()
    }
}





const  submit=()=>{  
          
setNotice({...notice,   isLoading: true})   

     const fd = new FormData();
         
    fd.append('staff', structure.staff.value);
    fd.append('template', structure.template.value);
    fd.append('salary', JSON.stringify(salary, null, 2)); 
    fd.append('amount', getTotalSalaryAmount()-getTotalSalaryDeduction()); 

     let url = API_URL+'/payroll/salary_structure/add'
    axios.post(url, fd, config)
    .then(response =>{
        if(response.data.status ===200){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setNotice({...notice,  isLoading: false
            })
        setStructure({
        template:[],
        staff:[]
    })
        setSalary([])
        setSalaryStructure([]);
 } )
}


     



    const getTotal =(salary)=>{
        var amount =0
        JSON.parse(salary).map((sl)=>
        sl.salaryHead==='EARNING'?
            amount += Number(sl.amount):amount -=Number(sl.amount)
        )
        return amount
    }

    

   
    const handleDelete=(code)=>{
        let close =   document.getElementById('btnWarningDialog-'+code)
        close.click();
        
        
        let url = API_URL+'/payroll/salary_structure/delete/'+code
    
    
        setNotice({...notice, isLoading: true}) 
          axios.get(url, config)
          .then(response =>{
           if(response.data.status ===200){
              Alerts('Saved!', 'default', response.data.message)
                  } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            fetchSalaryStructure(structure.staff.value)
              setNotice({...notice,  isLoading: false  })
          }) 
        
    }
          



    const getTotalSalaryDeduction =()=>{
        var amount =0
        salary.map((sl)=>{
            if(sl.salaryHead==='DEDUCTION'){
                amount += Number(sl.amount)
            }
        } )
        return amount
    }

       const getTotalSalaryAmount =()=>{
        var amount =0
        salary.map((sl)=>{
            if(sl.salaryHead==='EARNING'){
                amount += Number(sl.amount)
            }
        } )
        return amount
    }



    
    const tableHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
            
            {allowDelete==='Yes'? <a href="#!" data-toggle="modal" className="btn btn-sm btn-danger btn-sm"  data-target={'#warningdialog-'+row.code}     title="Delete Structure"><i className="fa fa-trash"></i></a>:[]}

        <WarningModal message="This will delete payment schedule. Are you really sure?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 
        </div>, editable: false},
        
        {dataField: 'staffName', text: 'Staff', editable:false},
        {dataField: 'templateName', text: 'Template',  editable:false},
        {dataField: 'amount', text: 'Gross Pay', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },
               
        {dataField: 'created_at', text: 'Date Created', editable:false, formatter:(cell)=>longDate(cell)}
     ];


    
 const TableBasic=(props)=>{
       
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='id' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             <div className="dt-buttons btn-group pull-right"> 


                            
   

        <form method='POST' action={ServerUrl+'/printout/staff_salary_list.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(structureList, null, 2)} />
               
                <input type='hidden' name='jwtToken' value ={Token} />
                <button type="submit" className="btn btn-outline-primary" > <i className="fa fa-print"></i>Print Preview</button>
        
        </form>


</div>        
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                   
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }


useEffect(()=>{
fetchTemplate()
},[]);



        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Assign Staff Salary ">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Assign Salary Structure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
   <div className="form-group">
<label >Select Staff</label>
      <Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>` ${option.staffName} - ${option.label} `}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={structure.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
   </div>

</section>

<section className="col-md-6">
   <div className="form-group">
<label >Salary Template</label>
      <Select  options={
                            template.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.templateName}
                             })} 
onChange={handleSelect} className={errors.template ? 'form-control form-control-danger' : ''} name="template" value={structure.template}  /> <span style={{color:'red'}}>{errors.template && errors.template}</span>
   </div>

</section>	
      
</div>
<div style={{display:salary.length===0?'none':'block'}}className="row">
               
               <div className="col-md-10">
                   <div className="table-responsive">
                       <table className="table">
<tbody>

                                
{salary&&salary.map((tit, id)=>

       <tr key={id}>
           <th scope="row" style={{color:tit.salaryHead==='EARNING'?'green':'red'}}>{'['+tit.salaryCode +'] '+ tit.title}</th>
           <td> 

          

          
<input type="text"
   pattern='[0-9]' onFocus={(e)=>e.target.select()} 
   className="form-control form-control-bold" placeholder='Amount' min="0" name='amount' value={tit.amount}  onChange={(e)=>handleChange(e, tit.code)} />
       </td>
       </tr>)}
       <tr className='text-primary'>
   <th>Total Net Pay</th>
  <th>{FormatNumber(getTotalSalaryAmount()-getTotalSalaryDeduction()) + ' (' + toWords((getTotalSalaryAmount()-getTotalSalaryDeduction()).toFixed(0)) + ')'}</th> 

  </tr>
           </tbody>
           </table>
            </div>
        </div>
        </div>


        <hr />	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-outline-success "><i className="fa fa-save"></i> Save Structure</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div> </div> 
							




<div style={{display:salaryStructure.length===0?'none':'block'}} className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Salary Structure</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12">
            <div className="table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <table className="table">
             <thead>
                 <tr>
                 <th>Salary Template </th>
                            <th>Assigned Salary</th>
                            <th>Action</th>
                 </tr>
             </thead>
           <tbody >
               {salaryStructure&&salaryStructure.map((st, id)=>
             <tr key={id}>
        
               <td>{st.templateName}</td>
             <td>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th>Amount ({Currency})</th>
                        </tr>
                        </thead>
                        <tbody >
                            {JSON.parse(st.salary).map((tit, idx)=>
                        <tr key={idx}>
                            <th scope="row" style={{color:tit.salaryHead==='EARNING'?'green':'red'}}>{'['+tit.salaryCode +'] '+ tit.title}</th>

                        <td>{tit.amount}</td>
                        </tr>
                        )}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th>Total</th>
                            <th>{Currency+FormatNumber(getTotal(st.salary))}</th>
                        </tr>
                        </tfoot>
                    </table>
                 
                 </td>
                 <td>

{allowDelete==='Yes'?<a href="#!" data-toggle="modal" className='btn btn-danger'  data-target={'#warningdialog-'+st.code}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>:[]}

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(st.code)} mID={st.code} /> 
</td> 
             </tr>
             )}
           </tbody>
           </table> 
        } 






            </div></div></div>


    
    </div>
    
    </div>
    </div>



    <div className="row  ">  
     <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Salary</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
                        <div className="card-block">
                        <div className="col-md-12">
                        <div className="table-responsive">

    {notice.isDataFetching ? <TableLoader />:
      <TableBasic data={structureList}  columns={tableHeader}  />} 

</div>
</div>
            </div>

            </div>
            </div>
            </div>
   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(AssignSalaryStructure) 