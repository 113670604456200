import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {  config, API_URL, API_IMAGE} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel} from '../component/globalFunction'

import { useSelector } from 'react-redux'

const StudentAttendance =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
  
    const [CurrentSession, setCurrentSession] = useState('')
    
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

    const [students, setStudents]= useState([])
    const [attendance, setAttendance] = useState({
        sectionID:{value:'', label:''},
        attendanceDate:'',
        sessionYear:CurrentSession,
        staff:{value:'', label:''}
    })


    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
			let url = API_URL+'/exam/result/fetch_raw'
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.data.length!==0){
            setCurrentSession(result.data.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
    

    const fetchAllHolidays =(value)=>{
       
        const fd = new FormData();
        let sql = 'Select startDate from tbl_events_holidays where eventType= "Holidays" and startDate = "'+value+'"'

			let url = API_URL+'/exam/result/fetch_raw'
            fd.append("sql", sql)
            axios.post(url, fd, config).then(response =>{
                if(Array.isArray(response.data.data)&& response.data.data.length!==0){
                    setErrors({...errors, attendanceDate:'Selected date is an Holiday'})
                    setAttendance({...attendance, attendanceDate:''})
                }
            }) 
    }



   
    const fetchStudent = event =>{
        event.preventDefault(); 

        let errors = {};     
           
    
        if(!attendance.attendanceDate===''){
            errors.attendanceDate ='Please select attendance date ';
        }

        if(!attendance.sectionID.value===''){
            errors.sectionID ='Please select Student Class';
        }

        const theDate = new Date(attendance.attendanceDate)
        if (theDate.getDay() === 0 || theDate.getDay() === 6) {
            errors.attendanceDate ='Selected date is an Holiday';
        }

        setErrors(errors)
      
        if(Object.keys(errors).length === 0){

        setNotice({...notice, isLoading: true}) 

        let url = API_URL+'/student/attendance/'+attendance.sectionID.value;
        axios.get(url, config).then(result=>{
            if(result.data.data.length!==0){
                setStudents(result.data.data)
            }else{
                Alerts('Info!', 'default', 'There are no studet found')  
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    .finally(()=>{
        setNotice({...notice, isLoading: false})
    })
    }
}




const handleSelect= (option, action)=>{
        setAttendance({...attendance, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        setStudents('')
    }

    const handleChange=(event)=>{
        setAttendance({...attendance, [event.target.name]:event.target.value});
        setErrors({...errors, [event.target.name]:''})
        setStudents('')
        fetchAllHolidays(event.target.value)
    }

    const getToday =()=>{
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        
        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        
        var today = year + "-" + month + "-" + day; 
        fetchAllHolidays(today)
   setAttendance({...attendance,
        attendanceDate:today
        })
    }


    const handleMarkAttendance=(e, index)=>{
        const std =JSON.parse(JSON.stringify(students))      
        std[index].indicator = std[index].indicator ==='P'?'H':std[index].indicator ==='H'?'A':std[index].indicator ==='A'?'L':'P';
        
       setStudents(std);
    }


const handleMarkAll=()=>{
    var options = students;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              value.push({
                ...options[i],
                 indicator:options[i].indicator ==='P'?'H':options[i].indicator ==='H'?'A':options[i].indicator ==='A'?'L':'P'});
            }
       setStudents(value);
}


         function handleSubmit(){  
            const fd = new FormData();
            fd.append('studentList', JSON.stringify(students, null, 2));
           fd.append('attendanceDate', attendance.attendanceDate);
           fd.append('sessionYear', CurrentSession);

         setNotice({...notice,  isLoading: true}) 
              let url = API_URL+'/student/attendance/create'
             axios.post(url, fd, config)
             .then(response =>{
               if(response.data.status ===200){
                   Alerts('Saved!', 'success', response.data.message)
                       } else{
                   Alerts('Error!', 'danger', response.data.message)
                       }   
               })
               .catch((error)=>{
                 Alerts('Error!', 'danger', error.message)
               }).finally(()=>{
                   setNotice({...notice, 
                       isLoading: false
                   })
                   setStudents([])
                   setAttendance({...attendance, 
                    sectionID:{value:'', label:''},
                })
               })  
              
        }



useEffect(()=>{
getToday()
    handleSession()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Attendance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Student Attendance</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



                <div className="row  ">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Create Attendance</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div  className="card-block">
		
        <div className="row">
        <section className="col-md-6">
            <div className="form-group">
          <label> Student Class<span style={{color:'red'}}>*</span></label>
          <Select  options={
                             schoolClass&&schoolClass.map((list, idx)=> {
                                return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                         } 
                         formatGroupLabel={formatGroupLabel}
 onChange={handleSelect} className={errors.sectionID ? 'form-control form-control-danger' : ''} name="sectionID" value={attendance.sectionID}  /> <span style={{color:'red'}}>{errors.sectionID && errors.sectionID}</span>
             </div>

      </section>
      
      <section className="col-md-6">
   
      
   <div className="form-group">
<label >Attendance Date <span style={{color:'red'}}>*</span></label>
<input type="date" className={errors.attendanceDate ? 'form-control form-control-danger' : 'form-control'} name="attendanceDate"  onChange={handleChange} value={attendance.attendanceDate} />
<span style={{color:'red'}}>{errors.attendanceDate && errors.attendanceDate}</span>
   </div>

   

</section>	

				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchStudent} className="btn btn-primary "><i className="fa fa-search"></i> Display Students</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div>
                                                   
                           </div>	</div>
                    
                           </div>




{students.length!==0?
        <div className="row  ">
        

        <div className="col-sm-12">
    
        <div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-edit" ></i> Mark Attendance</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            
            <section className="col-md-12">
            <span className="pcoded-badge label bg-simple-c-pink">Color Icon For Absent</span>
            
        <span className="pcoded-badge label label-info">Color Icon For Half Day</span>
            <span className="pcoded-badge label bg-simple-c-green">Color Icon For Present</span>
            </section>
    
            <div className="card-block">
            <div className="row" style={{backgroundColor:'yellow'}}>
        {students&&students.map((std, id)=>
        <section key={id} className="col-md-3"><br/>
            <button type="button" onClick={(e)=>handleMarkAttendance(e, id)} className={std.indicator==='P'?'btn btn-success':std.indicator==='A'?'btn btn-danger':std.indicator==='H'?'btn btn-info':'btn btn-default'} style={{width:'100%'}}>

            <img className="img-100" style={{height:'100px', width:'100px'}} onError={(e)=>{e.target.onerror = null; e.target.src=API_IMAGE+'/students/'+std.gender+'.png'}} src={`${API_IMAGE}/students/${std.passport}`} alt="" />

            <div ><b>{ std.studentName.length>20?std.studentName.slice(0, 20)+'...':std.studentName}</b></div>
            <label>({std.admissionNumber})</label>           
            </button><label>&nbsp;</label>
            </section>
            
)}
          
</div>

<div className="row">
        <footer className="col-md-12 pull-right">
	<hr/>
	<button type="button"  onClick={handleMarkAll} className="btn btn-inverse "><i className="fa fa-edit"></i> PresentAll/AbsentAll</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-success "><i className="fa fa-save"></i> Submit</button>
								</footer>
				</div>
                </div>
 </div> 
</div>

</div>:''}
	




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(StudentAttendance) 