import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, Currency, ImagesUrl} from '../component/include'
import Select from 'react-select'

import { useParams} from 'react-router'
import { useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Link } from 'react-router-dom'
import { formatGroupLabel, FormatNumber, getClassName, timeSince } from '../component/globalFunction'

const LearnersWallet =()=> {
    const [errors, setErrors] = useState({});
    let myRef = useRef()
    let params = useParams()
    const [notice, setNotice] = useState({
        isLoading : false, 
        isTransaction:false,
        isDataFetching: false,
    });


    const [transaction, setTransaction] = useState({
        transactionType:{value:'', label:'', mode:''},
        amount:0,
        description:'',
        notification:'No',
        student:[]
    })

    const [balance, setBalance]= useState(0)
    const [wallet, setWallet]= useState([])
    const [history, setHistory]= useState([])

    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

    const handleSelect = (option, action)=>{
        setTransaction({...transaction, [action.name]: option});
         setErrors({...errors, [action.name]:''})
        
          }




    const handleChange = event =>{
		let {name, value} = event.target
        setTransaction({...transaction, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleFetchWallet =()=>{
        var sql ="Select ID, studentCode, balance, lastUsed, dateCreate, status, remark from tbl_wallet where studentCode ='"+params.code+"' "; 
        const fd = new FormData(); 
        fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
        setWallet(result.data[0])
          }  else{ 
           setWallet([])

          }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

    const handleFetchStudent =()=>{
        setNotice({...notice,  isLoading: true})
        var sql ="Select studentName, code, admissionNumber, passport, classID, sectionID, gender, father_mobile, mother_mobile, fatherEmail, motherEmail from tbl_students where code ='"+params.code+"' "; 
        const fd = new FormData(); 
        fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
        setTransaction({...transaction, student:result.data[0]})
          }  else{
           Alerts('Info!', 'info', 'No record found')  
           setTransaction({...transaction, student:[]})

          }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isLoading: false})
    })
    }


    const fetchHistory =()=>{
        var sql ="Select ID, code, studentCode, transactionType, amount, dateCreate, balance, transactionMode, description, status from tbl_wallet_transactions where studentCode ='"+params.code+"' order by ID DESC"; 
        const fd = new FormData(); 
        fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
            setHistory(result.data)
            let debit =  result.data.filter(item=>item.transactionMode==='Debit')
            .reduce((acc, item)=>Number(item.amount)+Number(acc), 0)
            let credit =  result.data.filter(item=>item.transactionMode==='Credit')
            .reduce((acc, item)=>Number(item.amount)+Number(acc), 0)

            let balance= credit -debit;
            setBalance(balance)
          }  else{
            setHistory([])
            setBalance(0)
          }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }



    const handleDelete = (ID)=>{  

   setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'ID')
            fd.append('data', ID)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_wallet_transactions';
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
              fetchHistory()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 


    }




    const tableHeader = [
       
          {dataField: 'dateCreate', text: 'Date', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
          {dataField: 'transactionType', text: 'Type', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
          {dataField: 'description', text: 'Description', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
          {dataField: 'amount', text: 'Debit', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell, row)=>row.transactionMode==='Debit'?FormatNumber(cell):''},     
         
          {dataField: 'status', text: 'Credit', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell, row)=>row.transactionMode==='Credit'?FormatNumber(row.amount):''}, 
          {dataField: 'balance', text: 'Balance', editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell, row)=>FormatNumber(cell)},
          {dataField: 'ID', text: 'Action', headerStyle: {backgroundColor: '#f0ff4d'}, formatter: (cell, row)=><div>
          <a href="#!" data-toggle="modal"  className='btn btn-danger btn-sm'  data-target={`#warningdialog-${cell}`} title="Delete"><i className="icofont icofont-delete-alt"></i></a> &nbsp;

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} />
      </div>, editable: false},
     ];




     const handleSubmit = event =>{
        event.preventDefault();

        let errors = {};   
		let msg = 'This field is required'
        var amountRemain = balance

    if(transaction.transactionType.value===''){
        errors.transactionType =msg;
    }


    if(!transaction.amount || Number(transaction.amount)<=0){
        errors.amount =msg;
    }


    if(transaction.transactionType.transactionType ==='Credit'){
      amountRemain = Number(balance) + Number(transaction.amount)
        setBalance(amountRemain)
    }
    
    if(transaction.transactionType.transactionType ==='Debit'){ 
        amountRemain = Number(balance) - Number(transaction.amount)
        if(amountRemain <=0){
            errors.amount ='Insufficient funds';
            setBalance(amountRemain)
        }
    }


    setErrors(errors);


    if(Object.keys(errors).length === 0){
        submit(amountRemain)
    } 
    
}
   

function submit(amountRemain){  
    
       const fd = new FormData(); 
       fd.append("jwt", Token);
       fd.append("code", 'ts'+Math.random().toString(36).substr(2,9))
      fd.append("studentCode", params.code)
      fd.append("transactionType", transaction.transactionType.value)
      fd.append("amount", transaction.amount)
      fd.append("dateCreate", new Date().toISOString().slice(0,19))
      fd.append("balance", amountRemain)
      fd.append("transactionMode", transaction.transactionType.transactionType)
      fd.append("description", transaction.description)
      fd.append("notification", transaction.notification)
      fd.append("studentName", transaction.student.studentName)

    setNotice({...notice, isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_wallet_transaction'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setTransaction({
            ...transaction,
                transactionType:{value:'', label:'', mode:''},
                amount:0,
                description:'',
                notification:'No',
          }); 

          handleFetchWallet()
          fetchHistory()
              setNotice({...notice, 
                  isLoading: false
              })
          }) 
}

const handleUpdateWallet=(newValue)=>{  
    setNotice({...notice, isLoading: true})      
    const fd = new FormData(); 
    fd.append('newValue', newValue);
    fd.append('column', 'status');
    fd.append('ID', wallet.ID);
    fd.append("jwt", Token);
   let url = ServerUrl+'/updateCustom_controller/tbl_wallet'
  axios.post(url, fd, config)
   .then(res=>{
    handleFetchWallet()
   })
   .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
  }).finally(()=>{
    setNotice({...notice, 
        isLoading: false
    })

     }) 
 } 



useEffect(()=>{
    fetchHistory()
    handleFetchStudent()
    handleFetchWallet()
},[]);


const BasicTable=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
        fd.append("jwt", Token);
       let url = ServerUrl+'/updateCustom_controller/tbl_wallet_transactions'
      axios.post(url, fd, config)
       .then()
       .catch((error)=>alert(error)) 
       
     } 
  
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } entries</span>
      );
      
const { SearchBar } = Search;
     const options = {
         showTotal: false,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '250', value: 250},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <React.Fragment>  
    
             <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />


<div className="dt-buttons btn-group pull-right"> 


    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>

        <a href='#' className="btn dropdown-item waves-effect waves-light" > Export CSV</a>
        <a href='#' className="btn dropdown-item waves-effect waves-light" > Print Preview</a>
        </div>
    </div> 
    </div> 


                             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return props.fetchdata;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  </React.Fragment>
  }

  
    
        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Transaction History">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/wallet"> Wallet</Link></li> 
                    <li className="breadcrumb-item"><a href="#!">Transactions</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

             {transaction.student.length!==0?   <div className="row  ">
							

                            <div className="col-sm-12">
                        
                            <div className="card z-depth-0">
                                <div className="card-header">
                                    <h5><i className="fa fa-edit" ></i>Learner's Information</h5>
                                    <div className="card-header-right">
                                        <ul className="list-unstyled card-option">
                                            <li><i className="feather icon-maximizes full-card"></i></li>
                                            <li><i className="feather icon-minus minimize-card"></i></li>
                                            <li><i className="feather icon-trash-2 close-card"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="card-block">
                                <div className="card z-depth-0">
                                <div className="card-block">

                                <div className="row">
                                    
                        <div className="col-xl-12 col-md-12">
                            <div className="cards user-card-full">
                                <div className="row m-l-0 m-r-0">
                                    <div className="col-sm-4 bg-c-lite-green user-profile">
                                        <div className="card-block text-center text-white">
                                            <div className="m-b-25">
                                                <img onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+transaction.student.gender+'.png'}} src={`${ImagesUrl}/students/${transaction.student.passport}`} className="img-radius wallet-image img-100" alt="User-Profile-Image" />
                                            </div>
                                            <h6 className="f-w-600">{transaction.student.studentName}</h6>
                                            <p>{getClassName(transaction.student.classID, transaction.student.sectionID, schoolClass)}</p>
                                            <h3>{Currency+FormatNumber(Number(balance))}</h3>
                                          {wallet.status==='Active'?  <i className=" feather icon-check m-t-5 fa-2x"></i>:<i className="fa fa-times m-t-5 fa-2x"></i>}
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="card-block">
                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Details</h6>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <p className="m-b-10 f-w-600">Account Number</p>
                                                    <h6 className="text-muted f-w-400">{params.code.toUpperCase()}</h6>
                                                </div>
                                                <div className="col-sm-6">
                                                    <p className="m-b-10 f-w-600">Parent Phone</p>
                                                    <h6 className="text-muted f-w-400">{transaction.student.father_mobile +', '+transaction.student.mother_mobile}</h6>
                                                </div>
                                            </div>


                                            <h6 className="m-b-20 m-t-10 p-b-5 b-b-default f-w-600"></h6>
                                            <div className="row">
                                            <div className="col-sm-6">
                                                    <p className="m-b-10 f-w-600">Admission Number</p>
                                                    <h6 className="text-muted f-w-400">{transaction.student.admissionNumber}</h6>
                                                    
                                                </div>

                                                <div className="col-sm-6">
                                                    <p className="m-b-10 f-w-600">Email</p>
                                                    <h6 className="text-muted f-w-400">{transaction.student.fatherEmail+', '+transaction.student.motherEmail}</h6>
                                                </div>
                                               
                                            </div>


                                            <h6 className="m-b-20 m-t-10 p-b-5 b-b-default f-w-600">Last Transaction</h6>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <p className="m-b-10 f-w-600">Date</p>
                                                    <h6 className="text-muted f-w-400">{timeSince(new Date(wallet.lastUsed))}</h6>
                                                </div>
                                                <div className="col-sm-6">
                                                    <p className="m-b-10 f-w-600">Description</p>
                                                    <h6 className="text-muted f-w-400">{wallet.remark}</h6>
                                                    
                                                </div>
                                            </div>

                                            <div className="social-link list-unstyled m-t-50 m-b-5">
                                                
                            <button type="button" onClick={()=>setNotice({...transaction, isTransaction:!notice.isTransaction})}  className="btn btn-success "><i className="fa fa-plus"></i> Add Transaction</button>&nbsp;  
                            
                         {wallet.status==='Inactive'?   <button type="button" onClick={()=>handleUpdateWallet('Active')}  className="btn btn-primary "><i className="fa fa-check"></i> Enable Account</button>:

                            <button type="button" onClick={()=>handleUpdateWallet('Inactive')}  className="btn btn-danger "><i className="fa fa-times"></i> Disable Account</button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


 </div>

 </div>
 </div>
{notice.isTransaction?
 <div className="card z-depth-0">
                                <div className="card-block">
                                <h5> <i className='fa fa-plus'></i>Add Transaction </h5><hr/><br/>


                                <div className="row">
                        
                                            <section className="col-md-6">
                                   <div className="form-group">
                                 <label> Transaction Type<span style={{color:'red'}}>*</span></label>
                                <Select  options={
                                                   [{value:'Credit', label:'Credit', options:[
                                                    {value:'Deposit', label:'Deposit', transactionType:'Credit'},
                                                    {value:'Interest', label:'Interest', transactionType:'Credit'},
                                                    {value:'Dividend', label:'Dividend', transactionType:'Credit'},
                                                    {value:'Transfer In', label:'Transfer In', transactionType:'Credit'},
                                                    {value:'Bonus', label:'Bonus', transactionType:'Credit'},
                                                    {value:'Debit Reverse', label:'Debit Reverse', transactionType:'Credit'}
                                                ]},
                                                
                                                {value:'Debit', label:'Debit', options:[
                                                    {value:'Withdrawal', label:'Withdrawal', transactionType:'Debit'},
                                                    {value:'Bank Fees', label:'Bank Fees', transactionType:'Debit'},
                                                    {value:'Transfer Out', label:'Transfer Out', transactionType:'Debit'},
                                                    {value:'Commission', label:'Commission', transactionType:'Debit'},
                                                    {value:'Purchase', label:'Purchase', transactionType:'Debit'},
                                                    {value:'Fees', label:'Fees', transactionType:'Debit'}
                                                ]}]
                                                     
                                                } 

                                                formatGroupLabel={formatGroupLabel} 

                        onChange={handleSelect}  className={errors.transactionType ? 'form-control form-control-danger' : ''} name="transactionType" value={transaction.transactionType}  />  


                        <span style={{color:'red'}}>{errors.transactionType && errors.transactionType}</span>
                        
                                    </div>
                             
                             </section>       
                        
                             <section className="col-md-6">
                             <div className="form-group">
         <label> Amount  <span style={{color:'red'}}>*</span></label>

         <input type="text" min="0" className={errors.amount?'form-control form-control-danger': 'form-control'} onChange={handleChange} onFocus={(e)=>e.target.select()} name="amount" value={transaction.amount} />

		  <span style={{color:'red'}}>{errors.amount && errors.amount}</span>

            </div>
                             
                             </section>     
                        
                        
                             <section className="col-md-12">
                                                                    <div className="form-group">
                                                <label > Description</label> 
                                            

                                                <textarea  name="description" className="form-control" placeholder="Description" value={transaction.description} onChange={handleChange} rows="4" >{transaction.description}</textarea>


                                                                    </div>


                  <div  className="form-group"><label >Transaction Notification Will Be Sent</label>
     <div className="form-radio">
<div className="radio radio-inline"><label>
        <input type="radio" checked={transaction.notification ==="Yes"} onChange={handleChange} value='Yes' name='notification' />
            <i className="helper"></i>Yes</label></div>

            <div className="radio radio-inline"><label>
        <input type="radio" checked={transaction.notification ==="No"} onChange={handleChange} value="No" name='notification' />
            <i className="helper"></i>No</label></div>
</div> </div> 
                                               </section>
                        
                        
                                        </div>	
                        
                            <footer className="pull-right">
                            <button type="button"  onClick={()=>setNotice({...transaction, isTransaction:!notice.isTransaction})}  className="btn btn-inverse "><i className="fa fa-refresh"></i> Close</button>&nbsp;

                           
                            <button type="button" onClick={handleSubmit}   className="btn btn-primary "><i className="fa fa-save"></i> Record Transaction</button>
                                                        </footer>				
                                                    </div>
                                                    </div>:[]}
                                                    </div></div>
                        </div>
                        
                   
		
	
	

	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Transaction History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
        
        {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={history}   columns={tableHeader}  />} 
</div>
	</div>
    </div>
</div>

</div>:[]}
	
   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(LearnersWallet) 