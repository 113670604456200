import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, API_URL, config, Currency, FormatNumber, staffCode} from '../component/include'


const BulkIncome =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    
    const [account, setAccount] = useState([])
    const [incomeHead, setIncomeHead]=useState([])
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [incomeList, setIncomeList]= useState([])

    const Initials = 
    { 
        code:'',
        incomeHead:'',
        account:'',
        amount:0,
        incomeDate:'0000-00-00',
        remark:'',
        staffCode:staffCode	 
    }


    const fetchAccount=()=>{
      
          setNotice({...notice, isLoading: true}) 
          let url = API_URL+'/finance/account/balance'
          axios.get(url,  config).then(result=> setAccount(result.data.data))
          .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
          setNotice({...notice, isDataFetching: false})
  
          })  
      }
  
  
  
  
  
  
      const fetchIncomeHead =()=>{
        let url = API_URL+'/finance/expenses_income'
        axios.get(url,config).then(result=>{
            if(result.data){
  
              setIncomeHead(result.data.income)
            }
            
        })
    }
  
  




       const  GenerateRow =()=>{  
                var value = [];
                for (var i = 1; i <= 10; i++) {
                  value.push({...Initials,  code:Math.random().toString(36).substr(2, 9)});
                }      
                setIncomeList(value)

            } 


       const createNewRow =()=>{
        setIncomeList(incomeList.concat({...Initials, code:Math.random().toString(36).substr(2, 9)}))
        setErrors({...errors, incomeList:'', errorMessage:''})

    }

    const removeRow=(index)=>{
        setIncomeList(incomeList.filter((_, i) => i !== index)); 
       }


      
    const handleChangeIncome = (e, index)=>{
        const std =JSON.parse(JSON.stringify(incomeList))   
            let value =e.target.value;


            if(e.target.name==='amount'){
                value = value.replace(/[^0-9]/g, '');
              }


           std[index][e.target.name] = value;
           setIncomeList(std);
         //  setErrors({...errors,  errorMessage:''})
    }

    

    const handleSubmit = event =>{
		event.preventDefault();
		setErrors(validateInput());
		setIsSubmitting(true);
        window.scrollTo(0,0);
	} 
	
const validateInput =()=>{
	let errors = {};   
    if(incomeList.length===0){
        errors.incomeList ='Please register at least one income';
    }
	
    return errors;
	}
	




useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
       submit()
    }
    },[errors])


    
    const  submit=()=>{  
                
        var options = incomeList;

        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if(options[i].incomeHead!=='' || options[i].account!=='' || options[i].amount!==0 ){
                 value.push({
            
            code:options[i].code, 
            incomeHead:options[i].incomeHead,
            account:options[i].account,
            amount:options[i].amount,
            incomeDate:options[i].incomeDate,
            remark:options[i].remark,
            staffCode:options[i].staffCode
        })
            }
       
    }

    if(value.length!==0){

        setNotice({...notice, isLoading: true})   

        
const fd = new FormData();
fd.append('jwt', Token)    
fd.append('incomeList',  JSON.stringify(value, null, 2))

     let url = ServerUrl+'/save_controller/tbl_bulk_income' 
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Success', 'success', response.data.message)
                            }else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                    }   
            })
            .catch((error)=>{
             setErrors({...errors, errorMessage: JSON.stringify(error.message)})
            }).finally(()=>{
                setIsSubmitting(false)
                GenerateRow()
        setNotice({...notice,  isLoading: false}) 
    }) 

}else{
    setErrors({...errors, errorMessage: 'Please fill at least one row complete before you can submit'})
    window.scrollTo(0,0);
}
     }    



useEffect(()=>{
    GenerateRow()
    fetchIncomeHead();
    fetchAccount()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                
                   {/* <!-- Page-header start --> */}

                   <PageHeader title="Income Bulk Registration">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Finance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Bulk Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

<div  className="row  ">   
<div className="well">You can use this page to add bulk income. Please be careful that you select the right Income Head and Income Account. You can enter as many rows as you like by clicking on Add more.</div>
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Create Bulk Income</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            

{incomeList.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    <th>Income Type</th>
                    <th>Amount</th>
                    <th>Account</th>
                    <th>Date of Income</th>  
                    <th>Remark</th> 
                    
    <th ><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>


                {incomeList&&incomeList.map((pl, id)=>
            <tr key={id}>
                
                    <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>
                    <td > 
                        <select onChange={(e)=>handleChangeIncome(e, id)}  name="incomeHead"  value={pl.incomeHead} className="form-control">

<option value="">Select</option>
{ incomeHead&&incomeHead.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.headName}</option> })}
    </select>
                     </td>

                    <td><input  pattern="\d*" className="form-control" name='amount' value={pl.amount} type="text" min='0' onFocus={(e)=>e.target.select()} onChange={(e)=>handleChangeIncome(e, id)} /></td>

                    <td>
                    <select onChange={(e)=>handleChangeIncome(e, id)}  name="account"  value={pl.account} className="form-control">

<option value="">Select</option>
{ account&&account.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.accountName +' Balance: '+Currency+FormatNumber(list.openingBalance)}</option> })}
    </select>
    
                      </td>


                      <td>
                          
                          <input type="date" className="form-control" name='incomeDate' autoComplete="false" value={pl.incomeDate}  onChange={(e)=>handleChangeIncome(e, id)} />
                      
                      </td>

                      <td>
                      <input type="text" className="form-control" name='remark' autoComplete="false" value={pl.remark}  onChange={(e)=>handleChangeIncome(e, id)} />
                      
                      </td>
                   
                    <td > <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan='9'>

                    
                <div className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
        </table>

        </div>
        </div>
        </div>
</div>:''}


     

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BulkIncome) 