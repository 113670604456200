import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, ImagesUrl, Token, config, schoolName, API_URL} from '../component/include'
import { longDate, FormatNumber } from '../component/globalFunction'
import { userToken } from '../component/authentication'


const PaymentReport=()=>{
	
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
const [payment, setPayment] =  useState([]);
const [search, setSearch]=useState({
    fromDate:'',
    toDate:'',
    status:'',
    transactionID:''
})

const fetchPayment =async(event)=>{
    event.preventDefault()

	setNotice({...notice, isLoading: true}) 

   let sql ="select code, amount, platform, status, transaction_id, tx_ref, customer, remark, dateCreate from tbl_online_payment_report where ID <> '' "

    
    if(search.fromDate.length!==0 && search.toDate.length!==0 ){
        sql = sql + ' and dateCreate between  "'+search.fromDate+'" and "'+search.toDate+'"'
    }

    if(search.transactionID !==''){
        sql = sql + '  and tx_ref = "'+search.transactionID+'"'
    }

    if(search.status.length!==0){
        sql = sql + '  and status =   "'+search.status.value+'"'
    }
    
    const fd = new FormData();
fd.append("sql", sql);
let url = API_URL+'/exam/result/fetch_raw'
await axios.post(url, fd, config).then(result=>{
	if(result.data.data.length!==0){

        var option = result.data.data

        let response =[];
    for(var i = 0; i < option.length; i++)
    {

        let customerData =  option[i].customer.includes('{')?JSON.parse(option[i].customer):option[i].customer
        let customer = customerData.studentName?customerData.studentName + ' - '+ customerData.label:customerData

       
        response.push({
            code:option[i].code,
            amount:option[i].amount,
            platform:option[i].platform,
            flw_ref:option[i].flw_ref,
            transaction_id:option[i].transaction_id,
            tx_ref:option[i].tx_ref,
            customer:customer,
            remark:option[i].remark,
            dateCreate:option[i].dateCreate,
            status:option[i].status,
        })
    }

setPayment(response)
    }else{
        setPayment([])
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})	
})
}


const handleSelect = (option, action)=>{
	setSearch({...search, [action.name]: option});
 }

const handleChange = event =>{
    const {name, value} = event.target
    setSearch({...search, [name] : value });
  
}


const getName=(temp)=>{

    if(temp!=='[]'){
return JSON.parse(temp).name;

}else{
    return '[]'
}}


useEffect(()=>{
    //fetchPayment()
},[]);

   return ( <>
			
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Online Payment Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Payment History</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Payment History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
        <form method='post' onSubmit={fetchPayment}> 
		<div className="card-block">
		
        <div className="row">


<section className="col-md-6">
                

                <div className="form-group">
                            <label>From Date</label>
                            <input type='date' name="fromDate" 
                            required
                            className='form-control' onChange={handleChange} value={search.fromDate} />
            </div>
         </section>

                    <section className="col-md-6">
                                            
        
                <div className="form-group">
                            <label>To Date</label>
                            <input type='date' name="toDate" className='form-control' onChange={handleChange} required value={search.toDate} />         </div>
                            </section>

            <section className="col-md-6">
                            

    <div className="form-group">
                <label>Reference</label>
                <input type='text' name="toDate" className='form-control' onChange={handleChange} value={search.transactionID} />         </div>
                </section>





           <section className="col-md-6">
                    <div className="form-group">
         <label> Transaction Status</label>
            <Select  options={[{value:'success', label:'successful'},
            {value:'declined', label:'declined'}]
							 
                        } 
onChange={handleSelect} name="status" value={search.status}  /> 

            </div>
                            </section>
									</div>
										
                <div className="row">
                <section className="col-md-12">
<button type="submit"  className="btn btn-inverse pull-right"><i className="fa fa-search"></i> Search Record</button>
</section>	                </div>
							</div>

                            </form>
							</div>

							</div>
                </div>


{payment.length!==0?
<div className="card z-depth-0" >
		<div className="card-block">
	
                       <table className='table' >
       <tbody>
                <tr>
                <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
 </td>
                </tr>
            </tbody>
       </table>  
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Payment Report</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
                
            </tbody>
       </table>
        <table id='paymentTable' className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                <th>SN</th> 
                <th> Reference No</th>
                
                <th> Remark</th>
                <th>Amount </th>
                <th>Platform</th>
                <th>Status</th>
                <th> Paid On</th>
                <th> Student</th>
                </tr>
            </thead>
            <tbody >
            
                    {payment&&payment.map((dat, id)=>
                    	<tr key={id}>
                            <td>{id+1}</td>
                    <td>{dat.tx_ref}</td>
                    <td>{dat.remark}</td>
                   <td>{FormatNumber(dat.amount)}</td>
					<td>{dat.platform}</td>
					<td>{dat.status==='success'?<span className="pcoded-badge label label-success">{dat.status}</span>:<span className="pcoded-badge label label-danger">{dat.status}</span>}</td>
					
					<td>{longDate(dat.dateCreate)}</td>
                    <td style={{maxWidth:'250px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><span >{dat.customer}</span></td>
						</tr>)}
				</tbody>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">

        <form method='POST' action={ServerUrl+'/printout/payment_history_online.php?token='+userToken} target='_blank'>
                <input type='hidden' name='payment' value ={JSON.stringify(payment, null, 2)} />
                <input type='hidden' name='jwtToken' value ={Token} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>


        </div></div>

 </div>
        </div>:''}


          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(PaymentReport) 