import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  config} from '../component/include'


const BulkRegistration =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    const [position, setPosition]=useState([])
    const [department, setDepartment]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [staffList, setStaffList] = useState([]);

    const [group, setUsergroup] = useState([])


    const Initials = 
    { 
        code:'',
        staffName:'',
        fileNo:'',
        mobile:'',
        department:'',
        email:'',
        position:'',
        gender:'Male',
        userType:''	 
    }

    

const fetchDepartment =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
	let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1';
	axios.post(url, fd, config)
	.then(result =>setDepartment(result.data))
}

const fetchPosition =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
	let url = ServerUrl + '/fetchField/tbl_staff_settings/position/ID/1';
	axios.post(url, fd, config)
	.then(result =>setPosition(result.data))
}





       const  GenerateRow =()=>{  
                var value = [];
                for (var i = 1; i <= 10; i++) {
                  value.push({...Initials,  code:Math.random().toString(36).substr(2, 9)});
                }      
                setStaffList(value)

            } 


       const createNewRow =()=>{
        setStaffList(staffList.concat({...Initials, code:Math.random().toString(36).substr(2, 9)}))
        setErrors({...errors, staffList:''})

    }

    const removeRow=(index)=>{
        setStaffList(staffList.filter((_, i) => i !== index)); 
       }


      
    const handleChangeStaff = (e, index)=>{
        const std =JSON.parse(JSON.stringify(staffList))   
            const value =e.target.value;
           std[index][e.target.name] = value;
        setStaffList(std);
    }

    
    const handleSubmit = event =>{
		event.preventDefault();
        var errormessage = [];
       
        if(staffList.length===0){
            let msg ='Please register at least one staff';
			setErrors({...errors, errorMessage:msg})
			errormessage.push(msg);
        }
        window.scrollTo(0,0);

        if (errormessage.length<=0) {
			submit()
		}
	} 


    
    const  submit=()=>{  
                
        var options = staffList;

        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if(options[i].staffName!==''){
                 value.push({
            
            code:options[i].code, 
            gender:options[i].gender,
            staffName:options[i].staffName,
            fileNo:options[i].fileNo,
            email:options[i].email,
            mobile:options[i].mobile,
            position:options[i].position,
            department:options[i].department,
            userType:options[i].userType
        })
            }
       
    }

        setNotice({...notice, isLoading: true})   

        
const fd = new FormData();
fd.append('jwt', Token)    
fd.append('staffList',  JSON.stringify(value, null, 2))

     let url = ServerUrl+'/save_controller/tbl_staff_bulk_registration' 
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Success', 'info', response.data.message)
                            }else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                    }   
            })
            .catch((error)=>{
             setErrors({...errors, errorMessage: JSON.stringify(error.message)})
            }).finally(()=>{
                setIsSubmitting(false)
                GenerateRow()
        setNotice({...notice,  isLoading: false}) 
    }) 
     }    


     const fetchGroup =()=>{
        var sql ="Select code, status, groupName from tbl_usertype order by groupName ASC" ;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{  
            setUsergroup(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }
useEffect(()=>{
    GenerateRow()
    fetchGroup()
    fetchDepartment()
	fetchPosition();
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                
                   {/* <!-- Page-header start --> */}

                   <PageHeader title="Bulk Registration">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/staff/view_staff">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Bulk Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

<div  className="row  ">   
<div className="well">You can use this page to add bulk registration. Please be careful that you select the right Gender, Department and Position. You can enter as many rows as you like by clicking on Add more.</div>
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Register Staff</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            

{staffList.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <form method='post' onSubmit={handleSubmit}> 
        <table className="table">
            <thead>
                <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    <th>File No</th>
                    <th>Staff Name</th>
                    <th>Gender</th>
                    <th>Email</th>  
                    <th>Position</th> 
                    <th>Department</th> 
                    <th>Group</th>
                    <th>Mobile</th> 
                    
    <th ><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>


                {staffList&&staffList.map((pl, id)=>
            <tr key={id}>
                
                    <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>
                    <td ><input type="text"  className="form-control"  name='fileNo' value={pl.fileNo}  onChange={(e)=>handleChangeStaff(e, id)} /></td>

                    <td><input type="text" className="form-control" required name='staffName' value={pl.staffName}  onChange={(e)=>handleChangeStaff(e, id)} /></td>

                    <td>
                        <select onChange={(e)=>handleChangeStaff(e, id)} required  name="gender"  value={pl.gender} className="form-control">

                        <option value="">Select</option>
                        <option  value="Male" >Male</option> 
                        <option  value="Female" >Female</option>
                            </select>
    
                      </td>


                      <td>
                          
                          <input type="email" className="form-control" name='email'  autoComplete="false" value={pl.email}  onChange={(e)=>handleChangeStaff(e, id)} />
                      
                      </td>

                      <td>
                          
                      <select onChange={(e)=>handleChangeStaff(e, id)}  name="position"  value={pl.position} className="form-control">

<option value="">Select</option>
{ position&&position.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.positionName}</option> })}
    </select>
                      
                      </td>

                      <td>
                          
                          <select onChange={(e)=>handleChangeStaff(e, id)}  name="department"  value={pl.department} className="form-control">
    
    <option value="">Select</option>
    { department&&department.map((list, idx)=> {
               return  <option key={idx} value={list.code} >{list.departmentName} </option> })}
        </select>
                          
                          </td>
                          <td>
                          
                          <select onChange={(e)=>handleChangeStaff(e, id)}  name="userType"  value={pl.userType} className="form-control">
    
    <option value="">Select</option>
    { group&&group.map((list, idx)=> {
               return  <option key={idx} value={list.code} >{list.groupName} </option> })}
        </select>
                          
                          </td>
                  
                      <td><input type="text" pattern="\d*" max={11} className="form-control" name='mobile'  value={pl.mobile}  onChange={(e)=>handleChangeStaff(e, id)} /></td>
                   
                    <td > <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan='9'>

                    
                <div className="pull-right">
	
	<button type="submit"  className="btn btn-success"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
        </table>
        </form>
        </div>
        </div>
        </div>
</div>:''}


     

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BulkRegistration) 