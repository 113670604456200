import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import { LoadEffect, TableLoader } from '../component/loader'
import {Alerts} from '../component/notify'
import {ServerUrl, DatabaseName, Token, config} from '../component/include'
import axios from 'axios'

import {useHistory} from 'react-router'
 const Backup =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
    }); 

	 let history = useHistory();
    
    const [permission, setPermission]=useState(true)

    function formatBytes(bytes, decimals) {
        if(bytes === 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
     }
        
    const [database, setDatabase] = useState([]);

    const getTotalRow=()=>{
          var total =0
        database.map((sl)=>
            total += Number(sl.tableRow)
        )
        return total
    }
    
    const getTotalSize=()=>{
        var total =0
      database.map((sl)=>
          total += Number(sl.tableSize)
      )
      return total
  }
  
  const createBackup=()=>{
    setNotice({...notice, 
        isLoading: true}) 

    const fd = new FormData();
    fd.append("jwt", Token);
	fd.append("createbackup", 'backup-on-'+new Date().toISOString().slice(0,10)+' '+new Date().toLocaleTimeString())

		let url = ServerUrl+'/save_controller/tbl_create_backup'
    axios.post(url, fd, config)
    .then(response =>{        
    if (response.data.type ==='success'){       
            Alerts('Success!', 'success', response.data.message)
        }else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
          }       
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            }) 
        })   
  }

    const fetchDatabase =()=>{
        setNotice({...notice, isDataFetching:true})
        var sql = "SELECT table_name, table_rows, ROUND(data_length + index_length)  as tb_size FROM INFORMATION_SCHEMA.TABLES WHERE table_schema = '"+DatabaseName+"'"
        
            

        const fd = new FormData();
        fd.append("jwt", Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
		axios.post(url, fd, config).then(result=>{    
            var option = JSON.parse(JSON.stringify(result.data));   
            var value = [];
            var items;
            for (var i = 0, l = (option || []).length; i < l; i++) {        
              items = {ID:i, tableName:option[i].table_name, tableSize:option[i].tb_size, tableRow:Number(option[i].table_rows)+1, status:true}
            value.push(items);       
            }

          setDatabase(value) 
          setNotice({...notice, isDataFetching:false})
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }) 
      }

      const  handleChange=(status, i)=>{
        let newdatabase = [...database];
        newdatabase[i] = {...newdatabase[i], status: !status};
        setDatabase(newdatabase) 
      }

      const selectAll =()=>{          
        var option = JSON.parse(JSON.stringify(database)); 
        for (var i in option){
            option[i].status =!permission
          setDatabase(option) 
    setPermission(!permission) 
     }
    }

   useEffect(()=>{
    fetchDatabase()
},[]);

        return (<>
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Database"
	   				menu="Backup"
					   />


{/* The Page Body start here */}
<div className="page-body">
						
			
	<div className="row  ">
	<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Backup Database</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
        <div className="row mt-4">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card  shadow-primary">
            <div className="card-body p-4">
            <div className="table-responsive">
                    {notice.isDataFetching ? <TableLoader />:
                    
                    <table className="table">
                        <thead><tr>
                            <th>
                            <div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" name='all' checked={permission}  id='all' onChange={selectAll} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>  </th>
                            <th><i className="fa fa-table"></i>Table Name</th>
                            <th>Total Row</th>
                            <th>Size</th></tr>
                        </thead>
                        <tbody>
                            {database&&database.map((data, id)=>
                            <tr key={id}>
                                <td>
                                <div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" name={data.ID} checked={data.status} id={id} onChange={()=>handleChange(data.status, id)} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
                                    
                                 </td>
                                <td>{data.tableName}</td>
                                <td>{data.tableRow}</td>
                                <td>{formatBytes(data.tableSize, 2)}</td>
                            </tr>
                            )}
                        </tbody>
                        <tfoot><tr>
                            <th>Total:</th>
                            <th> {database.length}</th>
                            <th>{getTotalRow()}</th>
                            <th>{formatBytes(getTotalSize(),2)}</th>
                            </tr>
                        </tfoot>
                    </table>
                    
                    }  
 <hr/><div className="form-group ">
<button type="button" onClick={()=>history.goBack()}  className="btn btn-dark">
    <i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
<button type="button" onClick={createBackup}  className="btn btn-primary"><i className="fa fa-download"></i> Create Backup</button>
 </div>

            </div>
  

            </div>
          </div>
        </div>
      </div>  
							</div>

							</div>
							</div>
	                   
                            </div>

		

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 

 );
        
}

export default React.memo(Backup) 