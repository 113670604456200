import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  staffCode,  Token, ImagesUrl, config} from '../component/include'

const ChangeSignature =()=>{


    const [notice, setNotice] = useState({
      isLoading : false,
      isDataFetching: true
  });
  const [signature, setSignature] = useState({}); 

  const [content, setContent] = useState({
      staffCode:staffCode,
      imageUrl:'',
      signature:''
  })  	
  

   function submitSignature(){  

    if(content.imageUrl===''){
      Alerts('Error!', 'danger', 'Kindly upload your signature')
    }else{
              setNotice({...notice, 
                isLoading: true})     
            
                let formData = content;
                const fd = new FormData();
                fd.append('jwt', Token);
                for(let k in formData){
                fd.append(k, formData[k])
                }
                
                 let url = ServerUrl+'/update_controller/tbl_signature'
                axios.post(url, fd, config)
                .then(response =>{
                 if(response.data.type ==='info'){
                      Alerts('Saved!', 'default', response.data.message)
                        } else{
                  Alerts('Error!', 'danger', response.data)
                    }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                  setNotice({...notice, 
                    isLoading: false
                  })
                }) 
            }
          }



            const fetchSignature =()=>{

              let sql = "Select signature from tbl_staff where code = '"+staffCode+"'"
  
              const fd = new FormData();
              fd.append('jwt', Token);
              fd.append("sql", sql);
              let url = ServerUrl+'/fetchBySql_controller'
              axios.post(url, fd, config).then(result=>{ 
                if(result.data.length!==0){
                  setSignature(result.data[0])
                }
                  
              })
              .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
              })
      
              }



            const  handleFile = function(fieldName){
              return function(newValue){                 
                  const value = newValue.target.files[0]
                // setImage(value)
             readURL(newValue);
             }
             
            } 
         
      
          const readURL = (input)=>{
             
              let displayMessage = '';
              const PreviewImage = input.target.name;
                  var fileName = document.getElementById(PreviewImage).value;
              var idxDot = fileName.lastIndexOf(".") + 1;
              var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      
              if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
                  //TO DO    
                  
                   var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
              if (FileSize > 0.6) {
                  displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
                  document.getElementById(PreviewImage).value = '';
                 setContent({...content, imageUrl:'',  signature:''})
              } else {
                  if (input.target.files && input.target.files[0]) { //Check if input has files.
                      var reader = new FileReader();//Initialize FileReader.
                      reader.onload = function (e) {
                        
                        setContent({...content, signature:e.target.result, imageUrl:input.target.files[0]})
                          
                      }
                      reader.readAsDataURL(input.target.files[0]);
                      
                  }
              }
          }else{
              displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500Kb'
             document.getElementById(PreviewImage).value = '';
             setContent({...content, imageUrl:'',  signature:''})
          }   
          
          if(displayMessage.length > 0){
              Alerts('Error!', 'danger', displayMessage)
            }
              
          } 


useEffect(()=>{
  fetchSignature()
}, [])

        return (  
            <>
     
     
     {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Change Signature">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-user"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Signature</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
                <div  className="row  ">
							
                               
                          <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Change Signature</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">
									
				<section className="col-md-4">
                                    
        <div className="form-group">
                   
                    <input id="passport"  onChange={handleFile('passport')} className="form-control " name="passport" type="file" accept="image/*" /> 
									</div>



                                    <div className="form-group"> 
                            {signature.imageUrl!==''?content.signature===''?
            
                <img id="viewPassport2" className="img-90" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/no.jpg'}} src={`${ImagesUrl}/staff/signature/${signature.signature}`}   alt='signature' /> :

                <img id="viewPassport" className="img-50"  src={content.signature}  /> :  

                 <img id="viewPassport" className="img-50" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/no.jpg'}} src={content.signature} />      }

                                            </div>


            </section>
          
          
            <section className="col-md-4">
            <button type="button" id="submit" onClick={submitSignature} className="btn btn-info "> <i className='fa fa-image'></i> Update Signature</button></section>
            </div>
											

                                 
							</div>
                            </div>
                            </div>
                            
                            </div>



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</>
 );
        
}

export default React.memo(ChangeSignature) 