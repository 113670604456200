import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {config, staffCode, API_URL} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, longDate, sort} from '../component/globalFunction'
import { useSelector } from 'react-redux'
 const ResultEntry =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 


    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        subject:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
    })


    const steps = [{title: 'Search Exam'}, {title: 'Enter Score'}]
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
   
    const [examtype, setExamtype]=useState([])
    const [subject, setSubject]= useState([]) 
    const [studentList, setStudentList]=useState([])
    const [errors, setErrors] = useState({});
    const [grade, setGrade] = useState([])
    const [grademark, setGrademark]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [remark, setRemark]=useState([])
const [activeStep, setActiveStep] = useState(1);
const handleOnClickStepper = (step) => {
        setActiveStep(step); 
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep])


    const fetchExamType =()=>{
       
        let url = API_URL+'/exam/result/examtype';
        axios.get(url,  config).then(result=>{
           
            let examtyp = result.data.examType.filter(item=>item.term ==="MOCK EXAM" && item.publish ==='No')
            setExamtype(examtyp)
        
            let settings = result.data.grademark;
            var grad = settings[0].grade
        
        var gradeRemar = settings[0].gradeRemark
        var gradeMar = settings[0].gradeMark
        setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
        setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))
        setGrade(JSON.parse(grad).sort(sort))
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }


    

        const fetchSubject =(type, section)=>{
            setNotice({...notice, isLoading: true}) 
        let url = API_URL+'/subject/exam/'+type+'/'+section;
        axios.get(url, config).then(result=>{
            if(Array.isArray(result.data.data)){
                setSubject(result.data.data)
            }else{
                setSubject([]) 
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
        })
        }




const fetchStudentList =()=>{
    
    let errors = {};   
   
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.subject.length===0){
        errors.subject ='Please select subject';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        setNotice({...notice, isLoading: true}) 
        const fd = new FormData(); 
        
fd.append("examtype", result.examtype.value);
fd.append('subject', result.subject.value)
fd.append("orderBy", result.orderBy.value);
fd.append('studentClass', result.studentClass.value)


let url = API_URL+'/exam/result/student';

axios.post(url, fd, config).then(result=>{
    if(result.data.data.length!== 0 && Array.isArray(result.data.data)){
       
    //staffCode
   
    
    const stf = result.data.data[0].teacher;

if(stf.includes(staffCode)){
    setStudentList(result.data.data)
    setActiveStep(2)
}else{
    setStudentList([])
    Alerts('Error!', 'danger', 'Only subject teacher can record or modify exam scores ');
}

    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
    }
}




const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   if(action.name==='studentClass'){
    fetchSubject(result.examtype.value, option.value)
   }else if(action.name==='examtype'){
   fetchSubject(option.value, result.studentClass.value)
   }
   setStudentList([])
  
}



const handleChangeScore =(event, index)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const {name, value} = event.target
    student[index][name] = value;
    setStudentList(student); 
       } 


const handleChangeGrade =(event, index)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const currentResult = student.filter((item, id)=>id===index)
    let {name, value} = event.target
    var fCA = Number(currentResult[0].firstCA)
    var sCA = Number(currentResult[0].secondCA)
    var exam = Number(currentResult[0].exam)
    var total = Number(currentResult[0].total)
    var max = Number(currentResult[0].maxMark)
    value = value.replace(/[^0-9]/g, '');
     if(name==='exam'){
        if(value > 100){
            value= 0;
            
        }
        total = fCA+sCA+Number(value)
    }


    if(total > max){
        Alerts('Head up!', 'info', 'Total score cannot be more than maximum score')
        value= 0;
    }else{
        student[index][name] = value;
        student[index].total = total
        student[index].grade = getGrade(total, 'grade')
        student[index].remark = getGrade(total, 'remark')
        setStudentList(student); 
    }
       } 

  
const getGradeScore =(code)=>{
const ans = grade.filter(item=>item.code===code)
return ans[0].gradeName
}

const getRemark =(code)=>{
    const ans = remark.filter(item=>item.code===code)
    return ans[0].remarkName
    }

const getGrade =(score, field)=>{
    const ans = grademark.filter(itm=>itm.classtype===result.studentClass.classtype&& Number(score) >= Number(itm.minimum) && Number(score) <= Number(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   
return field ==='grade'?String(grade):String(remark)
}

 
const handleSubmit = event =>{
    event.preventDefault();    
    
    let errors = {};   
    if(studentList.length===0){
        errors.studentClass ='There are no student in the selected class';
    } 
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.subject.length===0){
        errors.subject ='Please select subject';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       submit()
    }


}

   

useEffect(()=>{
    //update every 4 minutes
    const interval =   setInterval(()=>{
        if(studentList.length!==0){
            const fd = new FormData(); 
            fd.append('studentList', JSON.stringify(studentList, null, 2));
             let url = API_URL+'/exam/result/update'; 
                   
                 axios.post(url, fd, config)
                 .then(response =>{
                     if(response.data.status ===200){
                    //Alerts('Updated!', 'default', 'You information has been saved')
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  //  Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{
                   
                  })
    
                }
    }, 2 * 60 * 1000);
    return () => {
        if(interval){
          clearInterval(interval)
        }
      }
   },[])
    


    function submit(){
    
setNotice({...notice, 
    isLoading: true})    
   
    const fd = new FormData(); 
    fd.append('studentList', JSON.stringify(studentList, null, 2));
     let url = API_URL+'/exam/result/update'; 
           
         axios.post(url, fd, config)
         .then(response =>{
             if(response.data.status ===200){
                Alerts('Updated!', 'default', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
              //  Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                
                  setNotice({...notice, 
                      isLoading: false })
                  
                setIsSubmitting(false);
                  setActiveStep(1)
              })   
  }
    



const handleReset=()=>{
    setResult({ 
        studentClass:[],
        examtype:[],
        subject:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
        })

        setStudentList([])
}





    useEffect(()=>{
      
        fetchExamType()
    },[]);
  

        return (  <> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Mock Result Entry">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Mock Result Entry</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



               


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Exam Result Entry</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '1px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="examType">
		  
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

      
   <div className="card-block">
      
  <div className="row">
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>
     </div>
     <div className="row">
     <section className="col-md-6">
           <div className="form-group">
         <label> Subject<span style={{color:'red'}}>*</span></label>
         <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={result.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	</div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Order</label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>
     
     </div>

     <div  className="row"> 
     
     <section className="col-md-12"> 


     <footer className="pull-right">
	<button type="button" onClick={handleReset} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={fetchStudentList} className="btn btn-primary "><i className="fa fa-graduation-cap"></i> Display Student</button>
    </footer>						
     </section>

     </div> 
    </div>
                               
       </div>	     
       
       	</div>
       
       </div>	
			
       </div>
    
</div> :
<div className="row setup-content" id="extras">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
   <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                <th>SN</th>
                    <th>Student Details</th>
                    <th>Exam (100)</th>
                    <th>Total (100%)</th>
                    <th>Max</th>
                    <th>Grade</th>
                    <th>Remark</th>          
                    <th>Absent</th>
                </tr>
            </thead>
            <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
                <tr key={idx}>
<td>{idx+1}</td>
                    <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.admissionNumber} - {st.studentName}</b> {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} 
                    
                    <textarea name='comment' placeholder='Subject Comment' value={st.comment} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.comment}

                    </textarea>
                    
                    </td>


                    <td><input type="number" onFocus={(e)=>e.target.select()} onBlur={(e)=>handleChangeGrade(e, idx)} className="form-control" pattern='[0-9]' min={0} step={1} id={idx} name='exam' value={st.exam}  onChange={(e)=>handleChangeGrade(e, idx)} /></td>

                    <td>{st.total}</td>
                    <td>{st.maxMark}</td>
                    <td><b>{st.grade}</b></td>
                    <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.remark}</b></td>
                    <td>

                    <select name="absent" value={st.absent} style={{width:'70px'}} className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
                    <option  value='No'>No</option>
                    <option  value='Yes'>Yes</option>
					</select>

                    </td>
                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div>   
<div style={{color:'red'}}>{errors.subject && errors.subject}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
       </div>			
       </div>       
       </div>

</div>}


     <button onClick={activeStep === steps.length ? handleSubmit: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Result </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }

                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ResultEntry) 