import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, Currency, config} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, sort, formatGroupLabel, FormatNumber, getClassName } from '../component/globalFunction'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
const ClassFees =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
  
    const [feecharge, setFeecharge] = useState([]);
const [studentFee, setStudentFee]=useState({
    studentClass:[],
    feecharge:[],
    remark:'',
    discountAmount:0
});
const [errors, setErrors] = useState({});
const [studentList, setStudentList] = useState([]);
const [classFee, setClassFee] =  useState([]);
const [actualFee, setActualFee] =  useState([]);
const [students, setStudents] =  useState([]);
    
const [totalPrice, setTotalPrice] =  useState(0);

const [structure, setStructure] =  useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);
  


const fetchFeecharge =()=>{
    const fd = new FormData();
            fd.append('jwt', Token);
    let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
    axios.post(url, fd, config).then(result=>{
        setFeecharge(result.data.filter(item=>item.status=='true'))
    })
}



const fetchStudent =(sectionID)=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT admissionNumber, registerNumber, studentName, code from tbl_students where sectionID ='"+sectionID+"' and termination <>'Yes' and admissionStatus = 'Studying'"
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
    
setStudentList(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}



const  handleFetchClassfee =(sectionID)=>{
    const fd = new FormData();
            fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_class_fees/feesDetail/sectionID/'+sectionID
    axios.post(url, fd, config)
    .then(result => {

        var total = 0
        if(result.data.length!==0){
            result.data.map(item=>total+=Number(item.price))
        }
        setTotalPrice(total)


        //loop through the outcome and assign price to tuition

        var options = result.data;
        let fees = [];
        for (var i = 0, l = options.length; i < l; i++) {
            fees.push(
                {
                    ...options[i],
                    tuition:options[i].price
                });
        }

        setClassFee(fees)
        setActualFee(fees)

    })
    .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
     }

       
        const handleSelect = (option, action)=>{
            
            setStudentFee({...studentFee, [action.name]: option});
            setErrors({...errors, [action.name]:''})
            if(action.name==='studentClass'){
                fetchStudent(option.value);
                handleFetchClassfee(option.value)
            }
            setStudents('')
         }



             const handleChange = event =>{
                const {name, value} = event.target
                setStudentFee({...studentFee, [name] : value });
               setErrors({...errors, [name]:''})
            }

            


const handleChangeStatus =(index, e)=>{
        
    const fees =JSON.parse(JSON.stringify(classFee))  
  fees[index].status = fees[index].status ==='true'? 'false':'true';
  let newFees = fees.filter(item=>item.status==='true')
  var total = newFees.reduce((initial, item)=>initial+Number(item.price), 0)

  setTotalPrice(total)
   setClassFee(fees)
    
}

            const handleChangeFee =(index, e, ID)=>{


                const actualFees = getActualFee(ID);
                const fees =JSON.parse(JSON.stringify(classFee))   
               let value =e.target.value;
               value = value.replace(/[^0-9]/g, '');
             
	  fees[index].price = value;
      fees[index].tuition = value;
      
	  fees[index].discount = value;
	  fees[index].discountAmount = 0;
      
	  fees[index].discount_percentage = 0;
	  fees[index].actualFees= actualFees;


              let newFees = fees.filter(item=>item.status==='true')
          var total = newFees.reduce((initial, item)=>initial+Number(item.price), 0)

              
              setTotalPrice(total)
               setClassFee(fees)
                
           }


           const getActualFee = (ID) =>{        
            const result = actualFee && actualFee.filter(list=>list.ID===ID);
            const answer =result.length!==0?result[0].price:''
            return   answer
    
            }




const handleFetchStructure =()=>{
    setNotice({...notice, isDataFetching: true}) 
    var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, s.feeCharge, s.code, s.dateCreate, s.classID, s.sectionID  from tbl_fees_charge f, tbl_student_fees s where s.feeCharge = f.code group by s.feeCharge, s.sectionID order by s.dateCreate DESC" ;
    
	const fd = new FormData();
            fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	axios.post(url, fd, config).then(result=>setStructure(result.data))
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
        
    setNotice({...notice, isDataFetching: false}) 
    })
}

   
const handleFetchStudent=(row)=>{
    setNotice({...notice,  isDataFetching: true})
    var sql ="SELECT s.admissionNumber, s.studentName, s.code as studentCode, f.feeCharge, f.total, f.payment_status, f.remark, f.code, f.classID, f.sectionID from tbl_students s, tbl_student_fees f where f.studentCode = s.code  and s.admissionStatus = 'Studying' and f.sectionID ='"+row.sectionID+"' and f.feeCharge ='"+row.feeCharge+"' " ;
    
	const fd = new FormData();
            fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	axios.post(url, fd, config).then(result=>{

        setStudents(result.data)
        
    })
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false})
            
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
    })
}


     

const ValidateInput =(values)=>{
    let errors = {};     
    if(studentList.length===0){
        errors.studentClass ='There are no student found in the selected class';
    }
    if(classFee.length===0){
        errors.studentClass ='There are no fees structure define for the selected class';
    }
    if(values.studentClass.length===0){
        errors.studentClass ='Please select class';
    }
    if(values.feecharge.length===0){
        errors.feecharge ='Please select fee charge';
    }
    
    return errors;
    }

const handleSubmit =(event)=>{
        event.preventDefault();
        setErrors(ValidateInput(studentFee));
        setIsSubmitting(true);
    }

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }else{
            window.scrollTo(0,0);
        }
    },[errors])

   

function submit(){  
    
   
     const fd = new FormData();
            fd.append('jwt', Token);
    fd.append('classID', studentFee.studentClass.classID);
    fd.append('sectionID', studentFee.studentClass.value);
    fd.append('feecharge', studentFee.feecharge.value);
    fd.append('studentList', JSON.stringify(studentList, null, 2));
     fd.append('classFee', JSON.stringify(classFee, null, 2));
     fd.append('remark', studentFee.remark);
     
	fd.append('discountAmount', studentFee.discountAmount);
     fd.append('total', totalPrice);

     setNotice({...notice,  isLoading: true}) 

         let url = ServerUrl+'/save_controller/tbl_student_fees'
        axios.post(url, fd, config)
        .then(response =>{
          if (response.data.type ==='success') {
                Alerts('Saved!', 'success', response.data.message)
            }else if (response.data.type ==='info') {
                Alerts('Update!', 'default', response.data.message)
            }  else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }   
                
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false})  
        setIsSubmitting(false)
        setStudentFee({...studentFee, studentClass: [], feecharge:[], remark:'' });
        handleFetchStructure(); 
        setClassFee('')
        setActualFee('')
        setStudentList('')
        })  
          
}

const handleDelete = (row)=>{  
    setNotice({...notice, 
       isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+row.code)
          close.click();
          const fd = new FormData();
            fd.append('jwt', Token);
          fd.append('code', row.studentCode)
           fd.append('feesCharge', row.feeCharge)
           fd.append('sectionID', row.sectionID)

           let url = ServerUrl+'/update_controller/tbl_delete_fees'
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='info'){
              Alerts('Info!', 'info', response.data.message)
                  } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }   
          })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
           handleFetchStructure()
           handleFetchStudent({sectionID:row.sectionID, feeCharge:row.feeCharge})
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}




      const tableStudentHeader = [
        {dataField: 'admissionNumber', text: 'Admission Number',  editable: false},
        {dataField: 'studentName', text: 'Student Name',  editable: false},
        {dataField: 'classID', text: 'Class', formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass), editable: false},
        {dataField: 'total', text: 'Total ('+Currency+')', editable: false, formatter:(cell)=>FormatNumber(cell)},
        {dataField: 'payment_status', text: 'Status',  editable: false, formatter: (cell)=>cell==='PAID'?<span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>},
        {dataField: 'remark', text: 'Remark',  editable: false},
        {dataField: 'code', text: 'Action', editable: false, formatter:(cell, row)=><div>
            
            <form method='POST' action={ServerUrl+'/printout/student_fees_preview.php?token='+userToken} target='_blank'>
        <input type='hidden' name='feecharge' value ={row.feeCharge} />
                <input type='hidden' name='student' value ={row.studentCode} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='section' value ={row.sectionID} />
                <button type="submit" className="btn btn-info btn-sm " title='Preview' > <i className="fa fa-print"></i> Print</button>
                &nbsp;
                <button type="button" className="btn btn-danger btn-sm "  data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button>
        </form>
        <WarningModal  handleAction={()=>handleDelete(row)} mID={cell} message='All associated payment with this fees will be deleted as well. Are you sure?' />
        </div>},      
     ];

     const TableStudent=(props)=>{
       
            
    
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
    
                        <SearchBar
                                     { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                   
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }
    


    const tableClassFeesHeader = [
        {dataField: 'classID', text: 'Class', formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass), editable: false},
        {dataField: 'fees', text: 'Fees',  editable: false},
       
        {dataField: 'dateCreate', text: 'Created On', editable: false, formatter:(cell)=>longDate(cell)},
        {dataField: 'code', text: 'Action', editable: false, formatter:(cell, row)=><button className="btn btn-primary btn-sm" type="button" onClick={()=>handleFetchStudent(row)}> <i className="fa fa-graduation-cap"></i>View Student</button>},      
     ];

    

     const TableClassFees=(props)=>{
       
            
    
    const { SearchBar } = Search;
    
    const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } items</span>
    );
    const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
    
    };
    return  <ToolkitProvider search columnToggle 
                keyField='code' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        
                        <div className="form-group">

                    <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                
                               
                <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}

useEffect(()=>{
    handleFetchStructure()
    fetchFeecharge()
},[]);

        return (  <>
   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">

        <PageHeader title="Student Fees Structure">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a></li>
      <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
<li className="breadcrumb-item"><a href="#!">Student Fees Structure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                
                <div className="row  ">
					<div className="col-sm-12">
	<div className="card z-depth-0">
                <div className="card-header">
                    <h5><i className="fa fa-edit" ></i> Student Fees Structure</h5>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="feather icon-maximizes full-card"></i></li>
                            <li><i className="feather icon-minus minimize-card"></i></li>
                            <li><i className="feather icon-trash-2 close-card"></i></li>
                        </ul>
                    </div>
                </div>
		<div className="card-block">
		    
            <div className="row">
		    
            <section className="col-md-6">
            <div className="form-group">
         <label> Fee Charge For <span style={{color:'red'}}>*</span></label><a href="/fees/fee_charge" className="pull-right" >Add more fee charge</a>
         <Select  options={feecharge&&feecharge.map((list, idx)=> {
                               return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                             })
                        } 
                        getOptionLabel={option =>`${option.session}  ${option.label}`}       
onChange={handleSelect} className={errors.feecharge ? 'form-control form-control-danger' : ''} name="feecharge" value={studentFee.feecharge}  /> <span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>

            </div>
            </section>


            <section className="col-md-6">
            <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={studentFee.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
            </section>

            </div>


           
            

    {classFee.length!==0  ?     <div className="row">
    
    <div className="col-md-12 table-responsive"> 
    <label><b>Class Structure</b></label>

      <table className="table ">
        <tbody >

{ classFee.length!==0  ? classFee.map((fee, idx)=> {
                return   <tr key={idx}>
				<td>{fee.fees}<br/>
                
        <span style={{fontSize: '10px', color: '#fa0000'}}>Actual Fee {Currency+FormatNumber(getActualFee(fee.ID))}</span></td>

				<td>
                    <input type="text" min="0" className="form-control" onChange={(e)=>handleChangeFee(idx, e, fee.ID)}  name="feePrice" value={fee.price} />
                </td>

            {/*   <td>

                <div className="checkbox-color checkbox-primary">
        <input id={"status"+idx}  name="status[]" type="checkbox"  onChange={(e)=>handleChangeStatus(idx, e)}  checked={fee.status==='true'? true:false} />
                <label htmlFor={"status"+idx}>Applicable</label>
            </div>


                </td>  */}

			</tr>
				}):
            <tr>
                <td colSpan="2">
					<div className="alert alert-danger background-danger">There are no fee structure define for the selected class <a href="/fees/class_fees">[Click here to Set Fee]</a></div>
					</td>
                </tr>
			
			}

<tr>
				
				<th>Amount To Pay</th>
				<th >{FormatNumber(totalPrice)}</th>
			</tr>

<tr>
			<td colSpan="3">

			<textarea  name="remark" className="form-control" placeholder="Remark" value={studentFee.remark} onChange={handleChange} rows="2" >{studentFee.remark}</textarea>
			</td>
			</tr>

				</tbody>
              
		</table>
        </div>
        </div>:""}
        
        
	
<hr/>

	<footer className="pull-right">
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-inverse ">Save Fee Structure</button>
	</footer>				
            </div>
            </div>
            </div>
</div>


<div className="row">
<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th" ></i>  All Student Fees Schedule</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
	<div className="card-block">
    <div className="col-md-12 table-responsive">
      
       
    {notice.isDataFetching ? <TableLoader />:
        <TableClassFees data={structure}  columns={tableClassFeesHeader} />}

	</div></div>

</div>
</div> 
</div> 

{students.length!==0?
<div className="row">
<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th" ></i>  Student Fees</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
	<div className="card-block">
    <div className="col-md-12 table-responsive">
      
       
    {notice.isDataFetching ? <TableLoader />:
        <TableStudent data={students}  columns={tableStudentHeader} />}

	</div></div>

</div>
</div> 
</div> :''}
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ClassFees) 