import React, {useState, useEffect} from 'react'
import PageHeader from '../component/pageheader'
import {LoadEffect } from '../component/loader'
import { useSelector } from 'react-redux'
 const Startup =(Props)=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });


    const menu  = useSelector(state => state.rootReducer.sidebar);
    const userRole  = useSelector(state => state.rootReducer.roles);

   useEffect(()=>{
       
  
},[]);
 


        return (
        <>
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title={'Available Modules'}
					   />






{/* The Page Body start here */} 
<div className="page-body">
         


         
<div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" >




      <div className="card">
<div className="card-body">

<div className="row">
{menu.length!==0?menu.map((data, index)=>userRole.includes(data.ID)&&data.isActive==='Yes'?



<div className="label-icon col-sm-4" key={index}>
<a className="btn btn-inverse btn-block mb-5 " href={data.menuUrl} ><i className={data.menuIcon+ ' fa-2x'}></i> 
    <br/><br/>{data.menuName} </a>

</div>:[]):[]}


</div>


</div>
</div>









          </div>
        </div>
      </div>

                </div> 
          {/* The Page Body close here */}

            </div>
            </div>

</> 

 );
        
}

export default React.memo(Startup) 