import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, API_URL, config} from '../component/include'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

 const AccountHead =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
   
    
const [income, setIncome]=useState([])
const [expenses, setExpenses]=useState([])

const [master, setMaster] = useState({
    income:'',
    expenses:''
})
const [errors, setErrors] = useState({});
 
const [activeStep, setActiveStep] = useState(1);

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }
    const steps = [{title: 'Expenses Type'}, {title: 'Income Type '}]
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])
    
    const fetchIncome =()=>{
        let url = API_URL+'/finance/expenses_income'
        axios.get(url,config).then(result=>{
            if(result.data){

               setIncome(result.data.income)
                setExpenses(result.data.data)
            }
            
        })
    }

   
  
    const handleSubmit=()=>{

    }


    useEffect(()=>{
       fetchIncome()
    },[]);


function submitIncome(event){ 
    event.preventDefault();
    if(!master.income){
        setErrors({...errors, income:'Income type field is Required'})
    }else if(income.filter(item=>item.code===master.income).length!==0){
        setErrors({...errors, expenses:'Income type already exist'})
    }else{
    
    setNotice({...notice, 
        isLoading: true})         
        const fd = new FormData();
        fd.append('headName', master.income);
       
         let url = API_URL+'/finance/income_head/add'
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.status ===200){
                Alerts('Saved!', 'success', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false}) 
                    fetchIncome()
                setMaster({...master, income : ''});
              })
        
    }
}



function submitExpenses(event){ 
    event.preventDefault();
    if(!master.expenses){
        setErrors({...errors, expenses:'Expenses field is Required'})
    }else if(expenses.filter(item=>item.code===master.expenses).length!==0){
        setErrors({...errors, expenses:'Expenses already exist'})
    }else{          
    setNotice({...notice, 
        isLoading: true})         
        const fd = new FormData();
        fd.append('headName', master.expenses);
         let url = API_URL+'/finance/expenses_head/add'
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.status ===200){
                Alerts('Saved!', 'success', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  fetchIncome()
                setNotice({...notice, 
                    isLoading: false}) 
                setMaster({...master, expenses : ''});
              })
    } 
}

    const handleChange = event =>{
        const {name, value} = event.target
       setMaster({...master, [name] : value });
       setErrors({...errors, [name]:''})
    }


    const handleDelete = (code, table)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token);
           fd.append('ID', 'code')
            fd.append('data', code)
            let url = ServerUrl+'/delete_controller/'+table
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
            fetchIncome()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }



    const tableIncomeHeader = [
       /*  {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
            
        <a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+row.code}  className='btn btn-danger delete'   title="Delete"><i className="icofont icofont-delete-alt"></i></a>
         
         <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code, 'tbl_income_head')} mID={row.code} /> 
        </div>, editable: false}, */
        {dataField: 'headName', text: 'Income Type',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Income type is required'
              };
            }
            return true;
          } }     
     ];


     const tableExpensesHeader = [
       /* {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
            
        <a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+row.code} className='btn btn-danger delete'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>
         
         <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code, 'tbl_expenses_head')} mID={row.code} /> 
        </div>, editable: false}, */
        {dataField: 'headName', text: 'Expenses Type',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Expenses Type is required'
              };
            }
            return true;
          } }     
     ];
    



     const TableAccount=(props)=>{
       
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
                fd.append('newValue', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
              
               let url = ServerUrl+'/updateCustom_controller/'+props.table
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            }  
            
            const { SearchBar } = Search;
            
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='code' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                
                                <div className="form-group">
                                    <SearchBar
                                    { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                        
                                               
                        <BootstrapTable
                                    { ...props.baseProps }
                                                striped
                                            hover
                                            condensed
                                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                        pagination={paginationFactory(options) }
                                        cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                                beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.ID);
                                
                                return true;
                                }
                                
                            }
                                        }) }
                                    />
                                </div>
                                )
                            }
        </ToolkitProvider>
        }
        

        return (  
   <> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Income & Expense Head">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Finance</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Income & Expense Head</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Income & Expense Head</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="Expenses">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                       <input type="text" placeholder='Enter Expenses Head' name='expenses' onChange={handleChange} value={master.expenses } className={errors.expenses ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitExpenses}>Add</button>
              </div>
            <span style={{color:'red'}}>{errors.expenses && errors.expenses}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <TableAccount data={expenses} table={'tbl_expenses_head'} columns={tableExpensesHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
	  
	 
      :    <div className="row setup-content" id="Income">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12">

      <div className="form-group">
          <div className="input-group">
                      <input type="text" placeholder='Enter Income Title' name='income' onChange={handleChange} value={master.income} className={errors.income ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                     <button className="btn btn-inverse" type="button" onClick={submitIncome}>Add</button>
             </div>

              <span style={{color:'red'}}>{errors.income && errors.income}</span>
          </div>		
     </section>
         </div>
                              </div>
                              
      </div>	
            <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12 table-responsive">
      {notice.isDataFetching ? <TableLoader />:
   <TableAccount data={income}  table={'tbl_income_head'} columns={tableIncomeHeader}  />} 
    </section>
              </div>
              </div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>}

                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(AccountHead) 