import React from 'react'
import { ImagesUrl} from '../../component/include'
import Preloader from '../component/preloader'
import { useHistory } from 'react-router-dom'

 const Error404 =()=> {
	let history = useHistory()
        return (<>
          <Preloader />

		  <section style={{background: `url(${ImagesUrl}/bg.jpg) no-repeat`}} 
		  className="login-block">
      <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center error-pages">
                            <h1 className="error-title text-primary"> Error 404</h1>
    
                            <p className="error-message text-white text-uppercase">Sorry, an error has occured, We could not find the page you were looking for!</p>
                            
                            <div className="mt-4">
                              <a href="/staffportal/dashboard" className="btn btn-primary btn-round shadow-primary m-1">Go To Home </a>
                              <a href="#!" onClick={()=>history.goBack()} className="btn btn-outline-primary btn-round m-1">Previous Page </a>
                            </div>
    
                            <div className="mt-4">
                                <p className="text-light">Copyright © 2020  <span className="text-primary">Admotek Solutions </span>| All rights reserved.</p>
                            </div>
                               <hr className="w-50" />
                            <div className="mt-2">
                                <a href="#!" className="btn-social btn-social-circle btn-facebook waves-effect waves-light m-1"><i className="fa fa-facebook"></i></a>
                                <a href="#!" className="btn-social btn-social-circle btn-google-plus waves-effect waves-light m-1"><i className="fa fa-google-plus"></i></a>
                                <a href="#!" className="btn-social btn-social-circle btn-behance waves-effect waves-light m-1"><i className="fa fa-behance"></i></a>
                                <a href="#!" className="btn-social btn-social-circle btn-dribbble waves-effect waves-light m-1"><i className="fa fa-dribbble"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>



         
</> 

 );
        
}

export default React.memo(Error404) 