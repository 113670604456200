import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config} from '../component/include'
import Select from 'react-select'

import Cookies from 'js-cookie'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { formatGroupLabel, getClassName } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
const ClassTeacher =()=> {
   
    const [errors, setErrors] = useState({});
    
const staff  = useSelector(state => state.rootReducer.staff);
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

    const [classTeacher, setClassTeacher] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [teacher, setTeacher] = useState({
        studentClass:[],
        staff:'',
    })

    const handleDelete = (ID)=>{ 
        
            setNotice({...notice,  isLoading: true}) 
            let close =   document.getElementById('btnWarningDialog-'+ID)
               close.click();
            var sql ="UPDATE tbl_staff set sectionID ='', ClassID ='' where code ='"+ID+"'"    
        
            const fd = new FormData();
            fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{
            Alerts('Saved!', 'success', 'Record deleted')
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        .finally(()=>{
            fetchClassTeaher()
               setNotice({...notice, 
                   isLoading: false
               })
            
        })
        
    }


    
const loginAsStaff =(row)=>{

   //1000 * 60 * SESSION_IDEL_MINUTES
   var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
   var inOneMinutes = Date.now() + 58 * 60 * 1000;
   var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);

    Cookies.set('kayus_code', row.code, {expires: inOneHours })
    Cookies.set('portal', 'Staff', {expires: inOneHours })

    Cookies.set('kayus_ed', 'No', {expires: inOneHours })
    Cookies.set('kayus_dt', 'No',  {expires: inOneHours })

    Cookies.set('clkysd', row.classID,  {expires: inOneHours })
    Cookies.set('sckayd', row.sectionID,  {expires: inOneHours })

    localStorage.setItem('kayus_link', [])

    window.open('/staffportal/dashboard', '_self')

   }




    const tableHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
            <a href="#!" data-toggle="modal" className='btn btn-danger btn-sm'  data-target={`#warningdialog-${cell}`} title="Delete"><i className="icofont icofont-delete-alt"></i> </a>&nbsp;
            
            <a href="#!"  className='btn btn-inverse btn-sm' onClick={()=>loginAsStaff(row)}  title="Login As Staff"><i className="icofont icofont-login"></i> </a> &nbsp;

<WarningModal message="Are you really sure, you want to remove this teacher?" handleAction={()=>handleDelete(cell)} mID={cell} />
        </div>, editable: false},
        {dataField: 'classID', text: 'Class', formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass), editable: false},
        
        {dataField: 'staffName', text: 'Staff', editable: false},       
     ];

    const handleSelect = (option, action)=>{
        
        setTeacher({...teacher, [action.name]: option});
        setErrors({...errors, [action.name]:''})
       
         }

      
     const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(teacher));
        setIsSubmitting(true);
    } 
    
const ValidateInput =(values)=>{
	let errors = {};   
		
   

    if(!Array.isArray(values.staff)){
        errors.staff ='Please select staff';
       // window.scrollTo(0,0);
    }

     if(values.studentClass.length ===0){
        errors.studentClass ='Please select class ';
    }   
   
    return errors;
	}
    
   

function submit(){  
    var options = teacher.staff;
    var value = [];
    for (var i = 0, l = (options || []).length; i < l; i++) {
      value.push({code:options[i].value});
    }
    const fd = new FormData();
    fd.append('jwt', Token);
      fd.append("staffList", JSON.stringify(value, null, 2))
      fd.append("classID", teacher.studentClass.classID)
      fd.append("sectionID", teacher.studentClass.value)
    setNotice({...notice,  isLoading: true}) 

       let url = ServerUrl+'/update_controller/tbl_class_teacher'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='info'){
            Alerts('Update!', 'default', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setIsSubmitting(false);
            
          fetchClassTeaher()
            setTeacher({staff:[], studentClass:[]}); 
             
              setNotice({...notice, 
                  isLoading: false
              })
          }) 
}

const fetchClassTeaher =()=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT  code, staffName, classID, sectionID from tbl_staff where sectionID <>'' and classID <>'' order by classID ASC"    

    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
setClassTeacher(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
.finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}





useEffect(()=>{
    fetchClassTeaher()
},[]);


const BasicTable=(props)=>{

  
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } entries</span>
      );
      
const { SearchBar } = Search;
     const options = {
         showTotal: false,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <React.Fragment>  
    
             <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                             
                             <div className="dt-buttons btn-group pull-right"> 
                             <form method='POST' action={ServerUrl+'/printout/class_teachers.php?token='+userToken} target='_blank'>
                <input type='hidden' name='jwtToken' value ={Token} />
                
        <button type="submit" className="btn btn-outline-success waves-effect waves-light" > <i className="fa fa-print"></i> Print</button>
        
        </form>


            </div>
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  </React.Fragment>
  }

  
useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])
    
        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Assign Class Teacher">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-user"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Define Class Teacher</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
								<div className="col-sm-5">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Assign Class Teacher</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset><div className="row">
        <section className="col-md-12">
    
    <div className="form-group">
    
    <label >Select Staff <span style={{color:'red'}}>*</span></label>
    <Select isMulti options={staff&&staff.map((list, idx)=> {
                                   return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                                 })
                            }    
                getOptionLabel={option =>option.label +' - '+ option.fileNo}    
    onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={teacher.staff}  />   <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
                </div>

											<div className="form-group">
						<label >Select Class<span style={{color:'red'}}>*</span></label>
						<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} name="studentClass" className={errors.studentClass ? 'form-control form-control-danger' : ''}  value={teacher.studentClass}  /> <span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
												
											</div>
											
										</section>
										</div>
										
																</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Confirm</button>
	
								</footer>				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-7">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Class Teachers</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
        
        {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={classTeacher}   columns={tableHeader}  />} 
</div>
	</div>
    </div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ClassTeacher) 