import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getTime } from '../component/globalFunction'

const StudentDeparture =()=> {
    const [errors, setErrors] = useState({});
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
const [arrivalDeparture, setArrivalDeparture] = useState({
        student:[],
        arrivalType:[],
        arrivalDate:'',
        arrivalTime:'',
        description:''

})

    const [allArrival, setAllArrival]= useState([])
    const [students, setStudents] = useState([]);

    const [CurrentSession, setCurrentSession] = useState('')
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
   
const long=(conv)=>{
    var date = new Date(conv).toDateString().slice(3, 15)
    
    return date
}

const getDateFormat =()=>{
    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    
    var today = year + "-" + month + "-" + day;   

document.getElementById('arrivalDate').max = today
setArrivalDeparture({...arrivalDeparture,
    arrivalDate:today
    })
}

    const handleChange = event =>{
		 const {name, value} = event.target
        setArrivalDeparture({...arrivalDeparture, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSelect = (option, action) =>{
        setArrivalDeparture({...arrivalDeparture, [action.name]: option});
      setErrors({...errors, [action.name]:''}); 
   }


    const fetchStudents =()=>{
        const fd = new FormData();
            fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_students'
        axios.post(url, fd, config).then(result=>setStudents(result.data))
    }

   
   
    const fetchAllArrival =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let sql = "Select a.ID, a.code, s.studentName, s.admissionNumber, a.arrivalType, a.arrivalDate,	a.arrivalTime,	a.description from tbl_student_arival_departure a, tbl_students s  where a.student = s.code order by a.ID DESC"

        let url = ServerUrl+'/fetchBySql_controller'      
        fd.append("sql", sql)
        axios.post(url, fd, config).then(result =>setAllArrival(result.data)   
    ).catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
    }


 const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(arrivalDeparture));
        setIsSubmitting(true);
    } 


        const ValidateInput=(values)=>{
            let errors = {};  
            if(!values.arrivalDate){
                errors.arrivalDate ='Please select date';
            }
            if(values.arrivalType.length===0){
                errors.arrivalType ='Please select type';
            }

            if(values.student.length===0){
                errors.student ='Please select Student';
            }
            if(!values.arrivalTime){
                errors.arrivalTime ='Please select time';
            }
            
            return errors;
            }


function submit(){  
      
    const fd = new FormData();
            fd.append('jwt', Token); 
    				
    fd.append('student', arrivalDeparture.student.value);
    fd.append('arrivalType', arrivalDeparture.arrivalType.value);
    fd.append('arrivalDate', arrivalDeparture.arrivalDate); 
    fd.append('arrivalTime', arrivalDeparture.arrivalTime); 
    fd.append('description', arrivalDeparture.description)
    fd.append('sessionYear', CurrentSession)
    

    setNotice({...notice, 
	  isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_student_arival_departure'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else if(response.data.type ==='info'){
                    Alerts('Info!', 'default', response.data.message)}
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            fetchAllArrival()
            setNotice({...notice, isLoading: false})
            setIsSubmitting(false); 
      setArrivalDeparture({ 
        student:[],
        arrivalType:[],
        arrivalDate:'',
        arrivalTime:'',
        description:''}); 
        })
      
}



useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])


    const handleDelete = (code)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller/tbl_student_arival_departure'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
            fetchAllArrival()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }
        
    
    const tableHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>

<a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+cell}    className='btn btn-danger delete' title="Delete"><i className="icofont icofont-delete-alt"></i></a>
         
         <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} />  

        </div>, editable: false},
        {dataField: 'studentName', text: 'Student',  editable:false, formatter: (cell, row)=>`(${row.admissionNumber}) ${cell}  `},
        {dataField: 'arrivalType', text: 'Type', editor: {
            type: Type.SELECT, options:[
                { value: 'Not Using Transport', label: 'Not Using Transport'},
              { value: 'Late Arrival', label: 'Late Arrival'},
            { value: 'Early Departure', label: 'Early Departure' }
    ]
          } },       
        {dataField: 'arrivalDate', text: 'Date', formatter:(cell, row)=>long(cell), editor: {
            type: Type.DATE
          } },
    {dataField: 'arrivalTime', text: 'Time', formatter:(cell)=>getTime(cell)}, 
    {dataField: 'description', text: 'Description', formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>}
     ];

     
    const TableRecord=(props)=>{
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token); 
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
          
            let url = ServerUrl+'/updateById_controller/tbl_student_arival_departure'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                           
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
     
useEffect(()=>{
    fetchAllArrival()
    fetchStudents();
    getDateFormat();
    handleSession()
},[]);

        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Arrival & Departure">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Late Arrival & Early Departure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						   <div className="col-sm-5">
                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Late Arrival & Early Departure</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
									
			<section className="col-md-12">
                                 
<div className="form-group">
						<label>Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            students.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName, co:list.fatherName, phone:list.father_mobile  }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} C/O  ${option.co}  ${option.phone}`}
onChange={handleSelect} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={arrivalDeparture.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>





                                            <div className="form-group">
          <label> Type <span style={{color:'red'}}>*</span></label>
          <Select  options={[
                     { value: 'Not Using Transport', label: 'Not Using Transport'},
              { value: 'Late Arrival', label: 'Late Arrival'},
                     { value: 'Early Departure', label: 'Early Departure' },
                 ]} 
 onChange={handleSelect} className={errors.arrivalType ? 'form-control form-control-danger' : ''} name="arrivalType" value={arrivalDeparture.arrivalType}  /> 
 <span style={{color:'red'}}>{errors.arrivalType && errors.arrivalType}</span>
             </div>

	<div className="form-group">
						<label >Date<span style={{color:'red'}}>*</span></label>
		<input type="date" name="arrivalDate" id="arrivalDate" min="2020-01-01" max="" value={arrivalDeparture.arrivalDate} onChange={handleChange} className={errors.arrivalDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.arrivalDate && errors.arrivalDate}</span>	
			</div>

            <div className="form-group">
						<label >Time<span style={{color:'red'}}>*</span></label>
		<input type="time" id="arrivalTime" name="arrivalTime" value={arrivalDeparture.arrivalTime} min="2020-01-01" max="" onChange={handleChange} className={errors.arrivalTime  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.arrivalTime && errors.arrivalTime}</span>	
			</div>

            <div className="form-group">
						<label >Description</label>
        <textarea  type="text" name="description" className="form-control " value={arrivalDeparture.description}  rows="3" onChange={handleChange}  >{arrivalDeparture.description}</textarea>
			</div>
			
            </section>
										</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>


                            </div>
		
	

	<div className="col-sm-7">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Student Records</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allArrival}   columns={tableHeader}  />} 

    </div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(StudentDeparture) 