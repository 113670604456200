import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config, schoolName, selectStyles} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getClassName, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const ClubList =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [club, setClub] = useState([]);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [allResult, setAllResult]=useState([])

    const [result, setResult] = useState({
        studentClass:[],
        club:[],
        orderBy:{value:' order by studentName ASC', label:'order by Name'}
    })

    const [stat, setStat] = useState({
        male:0,
        female:0,
        none:0,
        total:0
    })
  


const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
  
}


const fetchClub =()=>{
    var sql ="SELECT  club from  tbl_student_master where ID="+1;
  const fd = new FormData();
  fd.append("sql", sql);
  fd.append('jwt', Token)
  let url = ServerUrl+'/fetchBySql_controller';
  axios.post(url, fd, config).then(result=>{
  if(result.data.length>0){
  setClub(JSON.parse(result.data[0].club))
  }
  })
  .catch((error)=>{
  Alerts('Error!', 'danger', error.message)
  })
  }
  

const fetchResult =()=>{
    let errors = {};   
    if(result.club.length===0){
        errors.club ='Please select club';
    }   
     
    setErrors(errors)

    if(Object.keys(errors).length === 0){
        setNotice({...notice, isLoading: true}) 
var sql ="SELECT admissionNumber, gender, registerNumber, studentName, termination, termination_date, termination_remark, club, classID, sectionID FROM tbl_students  where admissionStatus='Studying' and club ='"+result.club.value+"' "

if(result.studentClass.length!==0 ){
    sql = sql + '  and sectionID = "'+result.studentClass.value+'" '
}

sql = sql + result.orderBy.value 

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{

    
    if(response.data.length!==0){
        var male = response.data.filter(item=>item.gender==='Male')
        var female = response.data.filter(item=>item.gender==='Female')
setStat({
    female:female.length,
    male:male.length,
    total:response.data.length
})

setAllResult(response.data)
structure.current.scrollIntoView({ behavior: "smooth" });

    }else{
        setAllResult([])
        Alerts('Info!', 'info', 'There are no student found for the selected class or house')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
}) 
    }
}


const fetchClubName =(code)=>{
    const result = club.filter(list=>list.code===code);
  const answer = result.map(ans=>ans.clubName)
    return String(answer)
  }


useEffect(()=>{
    fetchClub()
},[]);


        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Club Summary Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/students/view_students"> Students</Link></li> 
                    <li className="breadcrumb-item"><a href="#!">Club Summary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Club Summary</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">

                    <section className="col-md-4">
           <div className="form-group">
         <label> Student Club<span style={{color:'red'}}>*</span></label>
         <Select   options={club.map((dat, idx)=> {
                                    return {key:idx, value: dat.code, label: dat.clubName };
                                  })}
onChange={handleSelect}  name="club" value={result.club}  styles={selectStyles} /> 
                          
<span style={{color:'red'}}>{errors.club && errors.club}</span>

            </div>
     
     </section>       

     <section className="col-md-4">
           <div className="form-group">
         <label> Student Class</label>
         <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  name="studentClass" value={result.studentClass}  /> 

            </div>
     
     </section>     


     <section className="col-md-4">
           <div className="form-group">
         <label> Order</label>
         <Select  options={[
             {value:' order by studentName ASC', label:'order by Name'},
                    { value: ' order by registerNumber ASC', label: 'Order By Register Number'},
             { value: ' order by admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>

				</div>	

	<footer className="pull-right">
	
	<button type="button"  onClick={fetchResult} className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	



{allResult.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
       <tr>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>{result.club&&result.club.label.toUpperCase()} POPULATION  </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">
<table className="table table-bordered  table-full-width">
       <tbody>
               
                <tr>
                    <td >Total Student: <b>{stat.total}</b></td>
                    <td >Male: <b> {stat.male}</b></td>
                    <td >Female: <b> {stat.female}</b></td>
                </tr>
            </tbody>
       </table>

        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                <th>Adm. No</th>
                    <th>Name</th>
                    <th>Class</th>
                    <th>Gender</th>
                    <th>Club</th>
                </tr>
            </thead>
            <tbody>{allResult&&allResult.map((row, idx)=>
            
            <tr key={idx}>
                    <td>{idx+1}</td>
                    <td>{row.admissionNumber}</td>
                <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.studentName} <br/>
                {row.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Terminated on {longDate(row.termination_date)} {row.termination_remark}</span>:''}</td>

                    <td>{getClassName(row.classID, row.sectionID, schoolClass)}</td>
                    <td>{row.gender}</td>
                    <td>{fetchClubName(row.club)}</td>
                </tr>)}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/club_summary.php?token='+userToken} target='_blank' className='pull-right' >
        <input type='hidden' name='club' value ={result.club.label} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='allResult' value ={JSON.stringify(allResult, null, 2)} />
                <input type='hidden' name='stat' value ={JSON.stringify(stat, null, 2)} />
               
                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ClubList) 