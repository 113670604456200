import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import {LoadEffect, TableLoader } from '../component/loader'
import {Alerts} from '../component/notify'
import {ServerUrl, FormatNumber, Currency, Token, config, ImagesUrl, menuLink,  allowDelete, allowEdit} from '../component/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { compare, convertDate,  getClassName,  longDate, getTime, getDays} from '../component/globalFunction'
import {Bar, Line, Pie} from 'react-chartjs-2'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import Error403 from '../settings/error403'

import { useLocation } from 'react-router-dom'
 const Dashboard =(Props)=> {
	
    let location =  useLocation()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [schoolClass, setSchoolClass]=useState([]);
    const [studentClass, setStudentClass]=useState([]);
    const [studentGender, setStudentGender] = useState([])
    var today = new Date().toISOString().slice(0,10);
    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
  
    const [CurrentSession, setCurrentSession] = useState('')
    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)
    const [feesPayment, setFeesPayment] = useState([])
    const [logList, setLogList] = useState([])
    const [students, setStudents] = useState([])
    const [books, setBooks]= useState([]) 
    const [finance, setFinance]= useState({
        income:0,
        expenses:0,
        salary:0
    })
    const [allStaff, setAllStaff] = useState([]);
    const [statistics, setStatistics] = useState({
        student:0,
        asset:0,
        staff:0,
        subject:0,
        fees:0,
        books:0,
        admission:0,
        ticket:0,
        family:0
    });


   
 const [allHolidays, setAllHolidays]= useState([]) 
const [allHoliday, setAllHoliday]= useState([])
    
const fetchAllHoliday =()=>{

  const fd = new FormData();
  fd.append('jwt', Token);
    let sql = "Select startDate as date, eventType as event, color as colors, title as comment,  startTime as begin, endTime as stop from tbl_events_holidays "

        let url = ServerUrl+'/fetchBySql_controller'
      
        fd.append("sql", sql)
       
        axios.post(url, fd, config).then(result =>setAllHoliday(result.data)) 
}

    const fetchAllHolidays =()=>{
      
        const fd = new FormData();
        let sql = "Select * from tbl_events_holidays where  sessionYear = '"+CurrentSession+"'  order by startDate DESC"
     
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>setAllHolidays(result.data)) 
    }

    const tableHeader = [
        {dataField: 'eventType', text: 'Title', editable:false},
        {dataField: 'title', text: 'Reason', sort:true, editable:false},
        {dataField: 'startDate', text: 'Date',sort:true, editable:false, formatter: (cell, row)=>`${longDate(cell)} `},       
        {dataField: 'endDate', text: 'Day', editable:false, sort:true, formatter: (cell, row)=>getDays(row.startDate)}
     ];


     const HolidayTable=(props)=>{

       
  
       
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                    
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    



      function renderEventContent(eventInfo){ 
        var data = eventInfo.event.extendedProps 
        return  <div style={{backgroundColor:data.colors}} className="attachment-block text-center">  
        <b className="text-green">{data.event}</b><br/>
        <b className="text-green" style={{display:'block',  width:150, whiteSpace:'normal', wordWrap:'break-word' }}>{data.comment}</b>
    <br/>
    <strong className="text-green">{getTime(data.begin)+ ' ~ '+getTime(data.stop)} </strong>
    <br/>
        
          
            
            <br/>
        </div>
    }


        
        const transactions = {
            labels: ['Income', 'Expenses', 'Salary Payment'],
            datasets: [
              {
                label: 'Total Amount',
                backgroundColor: [
                  '#ffffff', '#ffffff', '#ffffff' ],
                hoverBackgroundColor: ['#2DCEE3', '#fe5d70', '#b2891b' ],
                data: [finance.income, finance.expenses, finance.salary]
              }
            ]
          }
        
        const studentList = {
            labels: schoolClass.map((data)=>data.label),
            datasets: [
              {
                         
                label: 'Total Student',
                backgroundColor: [
                  '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
                hoverBackgroundColor: [
                '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
                data: schoolClass.map((data)=>data.data)
              }
            ]
          }


          
const getAllSection =(school)=>{
    let section = []
    var newClass = [...school];

     for (var i in newClass){
            if(newClass[i].section[0].sname === ''){   
            section.push({classID:newClass[i].ID, sectionID:newClass[i].ID})
            } else{ 
                for(var k in newClass[i].section){
                    section.push({classID:newClass[i].ID, sectionID:newClass[i].section[k].ID})
                    }
        } 
    } 

    return section
}


        const  handleFetchClass =()=>{
            
         const fd = new FormData();
         fd.append('jwt', Token)
            let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1';
            axios.post(url, fd, config)
            .then(result =>{
                const rrs = result.data
                setStudentClass(result.data)
               

               
                let sqlString = getAllSection(result.data).map(data=>"sum(case when sectionID = '"+data.sectionID+"'  then 1 else 0 end) AS '"+ getClassName(data.classID, data.sectionID, rrs)+"'")

                 let sql =   'SELECT '+String(sqlString)+ ' from tbl_students  where  admissionStatus="Studying"'

                 const fd = new FormData();
                fd.append("sql", sql);
                fd.append('jwt', Token)
                 let url2 = ServerUrl+'/fetchBySql_controller';
                 axios.post(url2, fd, config).then(response=>{ 

                    var school = getAllSection(result.data);
                    let responseData = []

                      for (var i = 0; i< school.length;  i++) {
                        responseData.push(
                            {  label:String(getClassName(school[i].classID, school[i].sectionID, result.data)),
                                data:response.data[0][getClassName(school[i].classID, school[i].sectionID, result.data)]
                            });
                            } 
                    setSchoolClass(responseData) 
                 })
                 
            })
            .catch((error)=>Alerts('Error!', 'danger', 'Dashboard: '+JSON.stringify(error.message))) 
                
        }



        const genderStudent= {
            labels: studentGender.map((data)=>data.gender),
            datasets: [
              {
                label: 'Total',
                backgroundColor: [ '#404E67', '#5555ff', ],
                hoverBackgroundColor: ['#CACACA', '#3a8300'],
                data: studentGender.map((data)=>data.data), //[65, 59, 80, 81, 56]
              }
            ]
          }

            const fetchStudentGender =()=>{
                var sql ="Select gender, count(*) as data from tbl_students where admissionStatus='Studying' group by gender" ;
                const fd = new FormData();
                fd.append("sql", sql);
                fd.append('jwt', Token)
                let url = ServerUrl+'/fetchBySql_controller';
                axios.post(url, fd, config).then(result=>{ 
                    setStudentGender(result.data)
                })
            }



            const getFullMonth =(date)=>{
                const ndate  = new Date(date)
                const month = ndate.toLocaleString('default', {month:'long'})
                return month
              }


              const loginReport = {
                labels: feesPayment.map((data)=>getFullMonth(data.label)),
                datasets: [
                  {
                    label: 'Amount '+Currency,
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: 'rgba(75,192,192,1)',
                    borderColor: 'rgba(0,0,0,1)',
                    borderWidth: 2,
                    data: feesPayment.map((data)=>data.data)
                  }
                ]
              }



              const feeChart = {
                labels: feesPayment.map((data)=>data.label),
                datasets: [
                  {
                    label: 'Amount '+Currency,
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: 'rgba(75,192,192,1)',
                    borderColor: 'rgba(0,0,0,1)',
                    borderWidth: 2,
                    data: feesPayment.map((data)=>data.data)
                  }
                ]
              }
    
              const fetchFees =()=>{
                //var sql = "Select datePaid as label, sum(amount) as data from tbl_fees_payment_history where datePaid >=  '"+convertDate(firstDayOfYear)+"' and datePaid  <= '"+convertDate(lastDayOfYear)+"' GROUP BY MONTH(datePaid) order by label  "
     
                var sql = "Select datePaid as label, sum(amount) as data from tbl_fees_payment_history where datePaid >=  '"+convertDate(first)+"' and datePaid  <= '"+convertDate(last)+"' GROUP BY datePaid order by label  "

                 const fd = new FormData();
                 fd.append("sql", sql);
                 fd.append('jwt', Token)
                 let url = ServerUrl+'/fetchBySql_controller';
                 axios.post(url, fd, config).then(result=>{ 
                    
                     setFeesPayment(result.data)
                 })
             }
    


           
             const fetchFinance =()=>{
                const fd = new FormData();
                let sql = " select (Select sum(amount) from tbl_expenses where expensesDate >= '"+convertDate(first)+"' and expensesDate  <= '"+convertDate(last)+"' ) as expenses, (Select sum(amount) from tbl_income where incomeDate >= '"+convertDate(first)+"' and incomeDate  <= '"+convertDate(last)+"' ) as income, (Select sum(totalAmount) from tbl_salary_payment where paymentDate >= '"+convertDate(first)+"' and paymentDate  <= '"+convertDate(last)+"' ) as salary   "
         
                fd.append("sql", sql);
                fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(result=> {
                
               /*  setFinance({
                    income:result.data[0].income,
                    expenses:result.data[0].expenses,
                    salary:result.data[0].salary
                }) */
                //setAllExpenses(result.data)
                
            })
            }
           
           
             const handleFetchAllStudents =()=>{
                var sql = "select  studentName,  gender, classID, sectionID, passport, dateOfBirth from tbl_students where admissionStatus ='Studying' and date_format(dateOfBirth, '%m%d') >= date_format(now(), '%m%d') and date_format(dateOfBirth, '%m%d') < date_format(DATE_ADD(NOW(), INTERVAL 7 DAY), '%m%d')  order by rand() limit 6"
        
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(result=>setStudents(result.data))
          
            }

            const handleFetchAllStaff =()=>{
               
             var sql = "select fileNo, staffName, passport, gender, joinDate, employmentStatus, birthDate from tbl_staff where employmentStatus='Working' and date_format(birthDate, '%m%d') >= date_format(now(), '%m%d') and date_format(birthDate, '%m%d') < date_format(DATE_ADD(NOW(), INTERVAL 7 DAY), '%m%d')  order by rand() limit 5"

            const fd = new FormData();
            fd.append("sql", sql);
            fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(result=>setAllStaff(result.data))
            
            }
            

    const fetchBooks =()=>{


        let sql = "Select  b.ID, b.code, b.bookName, b.publishYear, b.classMark, b.accessNo,  b.subjectId, b.imageUrl,  b.barcode, b.authorName, b.publisher, s.subjectName, b.purposeId, (select sum(issuedCount)  from tbl_book_issued where returnStatus ='No' and bookCode = b.code group by b.code) as totalIssued, b.totalBook  from tbl_books b LEFT JOIN tbl_subject s  on b.subjectId = s.code and   b.dateCreate >= '"+convertDate(first)+"' and b.dateCreate <= '"+convertDate(last)+"' order by b.subjectId limit 10"


        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{ 
           if(Array.isArray(result.data)){
               setBooks(result.data)
           }else{
            Alerts('Error!', 'danger', JSON.stringify(result.data))
           }
        })

        }



        const tableBooks = [
       
            {dataField: 'bookName', text: 'Book Name'},
            {dataField: 'authorName', text: 'Author Name'},       
            {dataField: 'publisher', text: 'Publisher'},
        {dataField: 'subjectName', text: 'Subject', editable: false},
        {dataField: 'totalBook', text: 'Total Books', editable: false},
        {dataField: 'totalIssued', text: 'Total Issued', editable: false}
             ];

    const TableBooks=(props)=>{
                const { SearchBar } = Search;
                const { ToggleList } = ColumnToggle;
                //const { ExportCSVButton } = CSVExport;
                const customTotal = (from, to, size) => (
                   <span >&nbsp;Showing { from } to { to } of { size } items</span>
                 );
                const options = {
                   showTotal: true,
                 paginationTotalRenderer: customTotal,
                 sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
                 { text: 'All', value: props.data.length }]
                  
                 };
                return  <ToolkitProvider search columnToggle 
                            keyField='ID' data={ props.data } columns={ props.columns } >
                                
                                {
                                    props => (
                                    <div >
                                        
                            <BootstrapTable 
                               { ...props.baseProps }
                               striped
                           hover
                           condensed
                           noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                       pagination={paginationFactory(options) }
                     
                                        />
                                    </div>
                                    )
                                }
             </ToolkitProvider>
             }




        const fetchStatistics =()=>{
            var sql ="Select (select count(*) from tbl_assets ) as asset, (select count(*) from tbl_admission_request where admissionStatus='Pending' and submitted ='true') as admission, (select count(*) from tbl_students where admissionStatus='Studying') as student, (select count(*) from tbl_books ) as books, (select count(*) from tbl_tickets ) as ticket, (select count(*) from tbl_family ) as family, (select count(*) from tbl_elibrary ) as library, (select count(*) from tbl_staff where employmentStatus ='Working') as staff, (select count(*) from tbl_subject ) as subject, (select sum(amount) from tbl_fees_payment_history where datePaid >=  '"+convertDate(firstDayOfYear)+"' and datePaid  <= '"+convertDate(lastDayOfYear)+"' ) as fees "
              
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            
            axios.post(url, fd, config).then(result=>{ 
                
                setStatistics({
                    family:result.data[0].family,
                    asset:result.data[0].asset,
                    student:result.data[0].student,
                    staff:result.data[0].staff,
                    subject:result.data[0].subject,
                    fees:result.data[0].fees,
                    ticket:result.data[0].ticket,
                    admission:result.data[0].admission,
                    books:Number(result.data[0].books)+Number(result.data[0].library)
                })
            })
        
        }


         const fetchLog =()=>{
           
            const fd = new FormData();
            fd.append('jwt', Token)
            fd.append("url", "loginreport.json");
        fd.append("actionType", "getData");
            let url = ServerUrl+'/fetch_jsonData';
            axios.post(url, fd, config).then(result=>{  
                setLogList(JSON.parse(result.data))
              
            })
        }

        const fetchStudentLog =()=>{
           
           /*  const fd = new FormData();
            fd.append('jwt', Token)
            fd.append("url", studentController+"/loginreport.json");
        fd.append("actionType", "getData");
            let url = ServerUrl+'/fetch_jsonData';
            axios.post(url, fd, config).then(result=>{ 
                const loginR = JSON.parse(result.data)
                var value = new Date().getFullYear()+'-'+new Date().getMonth()
                const days =  new Date(value.slice(0,4), value.slice(5,7), 0).getDate();
                
                const start = value+'-01';
                const end = value+'-'+days;

                var startDate = new Date(start);
                var endDate = new Date(end);
        
                var resultData = loginR.filter(function (a) {
                    var hitDates = a.dateCreate || {};
                    hitDates = Object.keys(hitDates);
                   var hitDateMatchExists = hitDates.some(function(dateStr) {
                        var date = new Date(dateStr);
                        return date >= startDate && date <= endDate
                    });
                    return hitDateMatchExists;
                });

                 setStudentLog(resultData)
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            }) */
        }




        const tableLogHeader = [  
           
        {dataField: 'fullname', text: 'Name', headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},        
            {dataField: 'dateTime',  text: 'Login On', headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},
            {dataField: 'ip', text: 'IP Address', headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},  
            {dataField: 'device', text: 'Device',headerStyle: {backgroundColor: '#f0ff4d'}, editable:false}
               
             ];
            
        
            
             const TableLogRecord=(props)=>{
                 const { SearchBar } = Search;  
                 const customTotal = (from, to, size) => (
                    <span >&nbsp;Showing { from } to { to } of { size } items</span>
                  );
                 const options = {
                    showTotal: true,
                  paginationTotalRenderer: customTotal,
                  sizePerPageList: [{text: '5', value: 5}, {text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
                  { text: 'All', value: props.data.length }]
                   
                  };
                 return  <ToolkitProvider search columnToggle 
                             keyField='order' data={ props.data } columns={ props.columns } >
                                 
                                 {
                                     props => (
                                     <div >
                    
                             <BootstrapTable 
                                { ...props.baseProps }
                                striped
                            hover
                            condensed
                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                        pagination={paginationFactory(options) }
                      
                                         />
                                     </div>
                                     )
                                 }
              </ToolkitProvider>
              }


           
const  handleSession =()=>{

    var sql ="Select  startYear from tbl_school_session where status ='Active'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config)
    .then(result => {
        if(result.data.length!==0){
        setCurrentSession(result.data[0].startYear)
    }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    }
   useEffect(()=>{
       
    setIsAuthenticated(menuLink.includes(location.pathname))

    handleSession()
    fetchAllHolidays();
    fetchAllHoliday()

    fetchBooks()
     fetchStatistics()
    handleFetchAllStaff()
    handleFetchAllStudents()
    fetchFinance()
    fetchStudentLog()
    fetchLog()
    fetchFees()
    fetchStudentGender()
    handleFetchClass()  
},[]);
 

        return (
        <>
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
   {isAuthenticated?<div className="page-wrapper">
       <PageHeader title={Props.menu}
					   />






{/* The Page Body start here */} 
<div className="page-body">
         


         
<div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" >

      <div className="row">

<div className="col-md-6 col-xl-3">
    <div className="card user-widget-card bg-c-blue">
        <div className="card-block">
            <i className="feather icon-user bg-simple-c-blue card1-icon"></i>
            <h4>{statistics.student}</h4>
            <p>Total Student</p>
            <a href="/students/view_students" className="more-info">More Details</a>
        </div>
    </div>
</div>

<div className="col-md-6 col-xl-3">
    <div className="card user-widget-card bg-c-pink">
        <div className="card-block">
            <i className="fa fa-graduation-cap bg-simple-c-pink card1-icon"></i>
            <h4>{statistics.admission}</h4>
            <p>Pending Admission</p>
            <a href="/admission/admission_request" className="more-info">More Details</a>
        </div>
    </div>
</div>

<div className="col-md-6 col-xl-3">
<div className="card user-widget-card bg-c-green">
    <div className="card-block">
        <i className="fa fa-users bg-simple-c-green card1-icon"></i>
        <h4>{statistics.staff}</h4>
        <p>Total Staff</p>
        <a href="/staff/view_staff" className="more-info">More Details</a>
    </div>
</div>
</div>

<div className="col-md-6 col-xl-3">
        <div className="card user-widget-card bg-c-yellow">
            <div className="card-block">
                <i className="feather icon-home bg-simple-c-yellow card1-icon"></i>
                <h4>{statistics.family}</h4>
                <p>Total Family</p>
                <a href="/students/family_summary" className="more-info">More Details</a>
            </div>
        </div>
    </div>

      </div>



      <div className="card">
<div className="card-body">

<div className="row">

<div className="label-icon col-sm-2">
<button className="btn btn-inverse " onClick={()=>window.open('/people/users', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-users fa-2x"></i> 
    <br/>Users </button>

</div>
<div className="label-icon col-sm-2">
<button className="btn btn-inverse" onClick={()=>window.open('/timetable/view_timetable', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-table fa-2x"></i> 
    <br/>Time Table </button>
    </div>

    <div className="label-icon col-sm-2">
<button className="btn btn-inverse" onClick={()=>window.open('/cms/gallery', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-camera fa-2x"></i>
    <br/>Gallery </button>
    </div>
    <div className="label-icon col-sm-2">
<button className="btn btn-inverse" onClick={()=>window.open('/payroll/salary_payment', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-money fa-2x"></i> 
    <br/>Payroll </button>

    </div>
    
    <div className="label-icon col-sm-2">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-map fa-2x"></i> 
    <br/>Sitemap </button>
    </div>
    <div className="label-icon col-sm-2">
        
<button className="btn btn-inverse" onClick={()=>window.open('/setup/school_details', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-cogs fa-2x"></i> 
    
    <br/>Settings  </button>
    </div>

</div>


</div>
</div>




<div className='row'>

        <div className="col-xl-3 col-md-6">
        <div className="card">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col-8">
                        <h4 className="text-c-yellow f-w-600">{statistics.asset}</h4>
                        <h6 className="text-muted m-b-0">Total Assets</h6>
                    </div>
                    <div className="col-4 text-right">
                        <i className="feather icon-home f-28"></i>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-c-yellow">
                <div className="row align-items-center">
                    <div className="col-9">
                        <p className="text-white m-b-0">Includes  Missing</p>
                    </div>
                    <div className="col-3 text-right">
                        <i className="feather icon-trending-up text-white f-16"></i>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <div className="col-xl-3 col-md-6">
        <div className="card">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col-8">
                        <h4 className="text-c-green f-w-600">{statistics.books}</h4>
                        <h6 className="text-muted m-b-0">Library Books</h6>
                    </div>
                    <div className="col-4 text-right">
                        <i className="feather icon-file-text f-28"></i>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-c-green">
                <div className="row align-items-center">
                    <div className="col-9">
                        <p className="text-white m-b-0">Include eLibrary</p>
                    </div>
                    <div className="col-3 text-right">
                        <i className="feather icon-trending-up text-white f-16"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="col-xl-3 col-md-6">
        <div className="card">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col-8">
                        <h4 className="text-c-pink f-w-600">{statistics.ticket}</h4>
                        <h6 className="text-muted m-b-0"> Tickets</h6>
                    </div>
                    <div className="col-4 text-right">
                        <i className="feather icon-calendar f-28"></i>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-c-pink">
                <div className="row align-items-center">
                    <div className="col-9">
                        <p className="text-white m-b-0">Messages</p>
                    </div>
                    <div className="col-3 text-right">
                        <i className="feather icon-trending-up text-white f-16"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="col-xl-3 col-md-6">
        <div className="card">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col-8">
                        <h4 className="text-c-blue f-w-600">{statistics.subject}</h4>
                        <h6 className="text-muted m-b-0"> Subjects</h6>
                    </div>
                    <div className="col-4 text-right">
                        <i className="feather icon-book f-28"></i>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-c-blue">
                <div className="row align-items-center">
                    <div className="col-9">
                        <p className="text-white m-b-0">Total Subject</p>
                    </div>
                    <div className="col-3 text-right">
                        <i className="feather icon-trending-up text-white f-16"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div className='row'>


<div className={allStaff.length>0?"col-md-6 ":"col-md-12 "} >
    <div className="card table-card">
        <div className="card-header">
            <h5>Upcoming Birthday- Students</h5>
            <div className="card-header-right">
                <ul className="list-unstyled card-option">
                    <li><i className="fa fa-window-maximize full-card"></i></li>
                    <li><i className="fa fa-minus minimize-card"></i></li>
                    <li><i onClick={handleFetchAllStudents} className="fa fa-refresh reload-card"></i></li>
                    <li><i className="fa fa-trash close-card"></i></li>
                </ul>
            </div>
        </div>
        <div className="card-block">
        <div className="col-md-12 table-responsive">
                <table className="table table-hover table-borderless">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Student</th>
                            <th>Class</th>
                            <th>Birth Date</th>
                        </tr>
                    </thead>
                    <tbody>

                        {students&&students.map((row, idk)=>
                        
                        <tr key={idk}>
                            <td> <img className="img-fluid img-30" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/students/"+row.gender+".png"}} src={ImagesUrl+'/students/'+row.passport} title={row.staffName}  alt={row.staffName} /></td>
                            <td>{row.studentName}</td>
                            <td>{getClassName(row.classID, row.sectionID, studentClass)}</td>
                            <td><b>{longDate(row.dateOfBirth)}</b></td>
                        </tr>
                        )}

                       
                    </tbody>
                </table>
                <div className="text-right m-r-20">
                    <a href="/communication/birthdays" className=" b-b-primary text-primary">View all upcoming birthdays</a>
                </div>
            </div>
        </div>
    </div>
</div>



<div className="col-md-6 " style={{display:allStaff.length>0?'block':'none'}}>
    <div className="card feed-card">
            <div className="card-header">
                <h5>Upcoming Birthday - Staff</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="fa fa-window-maximize full-card"></i></li>
                        <li><i className="fa fa-minus minimize-card"></i></li>
                        <li><i onClick={handleFetchAllStaff} className="fa fa-refresh reload-card"></i></li>
                        <li><i className="fa fa-trash close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">

{allStaff&&allStaff.map((row, id)=>
                <div className="row m-b-25" key={id}>
                    <div className="col-auto p-r-0">
                    <img className="img-fluid img-30" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/staff/"+row.gender+".png"}} src={ImagesUrl+'/staff/'+row.passport} title={row.staffName}  alt={row.staffName} />
                    </div>
                    <div className="col">
                        <h6 className="m-b-5">{row.fileNo+' - '+row.staffName}</h6>

                        {row.employmentStatus==='Working'?<p className="text-c-green m-b-0">Working</p> : <p className="text-c-pink m-b-0">{row.employmentStatus}</p>}
                    <span>Birthday: <b>{longDate(row.birthDate)}</b></span>
                        
                    </div>
                </div>
)}


                <div className="text-center">
                    <a href="/communication/birthdays" className="b-b-primary text-primary">View all upcoming birthdays</a>
                </div>
            </div>
        </div>
        </div>
</div>
       
       
<div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <FullCalendar
             events={allHoliday}          
             eventContent={renderEventContent}
            headerToolbar = { {
            left: "prev, today",
            center: "title",
            right: "next"
          }}
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
      />
             </div>
          </div>
        </div>
      </div>



      <div className="row">
      {/* <div className="col-8 col-lg-8 col-xl-8">
          <div className="card">
		   <div className="card-header"><strong>
      Fees Payment (Year: {new Date().getFullYear()})</strong>
				</div>
             <div className="card-body">

              <Line
          data={feeChart}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
        /> 

             </div>
          </div>
        </div> */}

        <div className="col-12 col-lg-12 col-xl-12">

<div className="card label-success">
<div className="card-header">
              <strong> TRANSACTIONS IN ({getFullMonth(new Date())})</strong>
				</div>
             <div className="card-body">
                <div className="card-body" >

            <Bar
          data={transactions}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
        />  
        </div>

             </div>
  </div></div></div>



  <div className="row">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card">
          <div className="card-header">
            <strong> Recently Added Library Books ({getFullMonth(new Date())}) </strong>
				</div>
            <div className="card-body">

          <div className="col-md-12 table-responsive">
          
          {notice.isDataFetching ? <TableLoader />:
   <TableBooks data={books} columns={tableBooks}  />}   
</div>
            </div> </div>
            </div>
          </div>


  <div className="row">
      <div className="col-6 col-lg-6 col-xl-6">
          <div className="card">
		   <div className="card-header"><strong>
     Today Log Report ({convertDate(today)})</strong>
				</div>
             <div className="card-body">

             <div className="col-md-12 table-responsive">
          
          {notice.isDataFetching ? <TableLoader />:
   <TableLogRecord data={logList.filter(item=>item.dateCreate ===today).sort(compare)} columns={tableLogHeader}  />}   
    </div>

             </div>
          </div>
        </div>

        <div className="col-6 col-lg-6 col-xl-6">

<div className="card">
<div className="card-header">
              <strong> Student Gender</strong>
				</div>
             <div className="card-body">
                <div className="card-body" >

        <Pie data={genderStudent}             
            options={{
            legend:{
            display:true,
            position:'right'
            }
        }}
        />
        </div>

             </div>
  </div></div>


  </div> 


  <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
      Daily Fees Payment ( {getFullMonth(new Date())})</strong>
				</div>
             <div className="card-body">

              <Line
          data={feeChart}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
        /> 

             </div>
          </div>
        </div></div>



  <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> LEARNERS STATS</strong>
				</div>
             <div className="card-body">

              <Bar
          data={studentList}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
        />  
             </div>
          </div>
        </div></div>



          </div>
        </div>
      </div>

                </div> 
          {/* The Page Body close here */}

            </div>:
            
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(Dashboard) 