import React from 'react'


const PageHeader = (props)=>{
    return <div className="page-header no-print">
                        <div className="row align-items-end">
                            <div className="col-lg-8">
                                <div className="page-header-title">
                                    <div className="d-inline">
                                    <h4>{props.title}</h4>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4">
                                <div className="page-header-breadcrumb">
                                    <ul className="breadcrumb-title">
                                    {props.children}                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
}
export default React.memo(PageHeader)