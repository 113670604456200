import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getTime } from '../component/globalFunction'

const BiometricDevice =()=> {

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
    let initial ={
        machine_name:'',
        model_no:'',
        expiry_date:'',
        service_tag_id:'',
        management_api:'',
        auth_token:'',
        encryption_key:'',
        callback_url:'',
        user_type:'',
        purchase_date:''
    }
const [machine, setMachine] = useState(initial)
const [device, setDevice] = useState([])

    const handleChange = event =>{
		 const {name, value} = event.target
        setMachine({...machine, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSelect = (option, action) =>{
        setMachine({...machine, [action.name]: option});
      setErrors({...errors, [action.name]:''}); 
   }
   
   
    const fetchDevice=()=>{
         const fd = new FormData();
        fd.append('jwt', Token); 
        let sql = "Select ID, code,	machine_name,	model_no,	expiry_date, service_tag_id,	management_api,	auth_token,	encryption_key,	callback_url,	user_type,	purchase_date FROM tbl_biometric_device"

        let url = ServerUrl+'/fetchBySql_controller'      
        fd.append("sql", sql)
        axios.post(url, fd, config).then(result =>setDevice(result.data)   
    ).catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }) 
    }


 const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(machine));
        setIsSubmitting(true);

    } 


        const ValidateInput=(values)=>{
            let errors = {}; 
            let msg = 'This field is required';


            if(!values.machine_name){
                errors.machine_name =msg;
            }

            if(!values.user_type.value){
                errors.user_type =msg;
            }
            if(!values.service_tag_id){
                errors.service_tag_id =msg;
            }

            if(!values.model_no){
                errors.model_no =msg;
            }

            if(!values.management_api){
                errors.management_api =msg;
            }
            

            if(!values.auth_token){
                errors.auth_token =msg;
            }

            if(!values.purchase_date){
                errors.purchase_date =msg;
            }

            if(!values.expiry_date){
                errors.expiry_date =msg;
            }

            if(!values.callback_url){
                errors.callback_url =msg;
            }

            return errors;
            }


function submit(){  
      
    const fd = new FormData();
    let formData = machine; 

    fd.append('jwt', Token); 
	for(let k in formData){
        fd.append(k,formData[k]);
    }; 

    fd.append('user', machine.user_type.value);
    setNotice({...notice,  isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_biometric_device'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                }
                    else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
	  .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            fetchDevice()
            setNotice({...notice, isLoading: false})
            setIsSubmitting(false); 
        setMachine(initial); 
        })
}



useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])


    const handleDelete = (code)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller/tbl_biometric_device'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
            fetchDevice()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }
        
    
    const tableHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>

<a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+cell}    className='btn btn-danger delete' title="Delete"><i className="icofont icofont-delete-alt"></i></a>
         
         <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} />  

        </div>, editable: false},
       
        {dataField: 'user_type', text: 'Device User', editor: {
            type: Type.SELECT, options:[
                { value: 'Staff', label: 'Staff'},
                { value: 'Student', label: 'Student'},
    ]
          } },  
          {dataField: 'machine_name', text: 'Machine Name',   formatter: (cell, row)=>`${row.machine_name} - ${row.model_no}  `},     
      
          {dataField: 'auth_token', text: 'Auth Token', formatter:(cell, row)=>cell},

          {dataField: 'service_tag_id', text: 'Service Tag ID ',   formatter: (cell, row)=>`${cell}   `},
          {dataField: 'purchase_date', text: 'Purchase Date', formatter:(cell, row)=>cell, editor: {
            type: Type.DATE
          } },
          {dataField: 'expiry_date', text: 'Expiry Date', formatter:(cell, row)=>cell, editor: {
            type: Type.DATE
          } },
    {dataField: 'management_api', text: 'Management API', formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
    {dataField: 'callback_url', text: 'Callback Url', formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>}
     ];

     
    const TableRecord=(props)=>{
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token); 
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
          
            let url = ServerUrl+'/updateById_controller/tbl_biometric_device'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                           
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
     
useEffect(()=>{
  fetchDevice()
},[]);

        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Biometric Device">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Biometric Device</a> </li>
                    </PageHeader>


{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						   <div className="col-md-12">
                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Add a Device</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
									
			<section className="col-md-6">

            <div className="form-group">
          <label> Device User Type <span style={{color:'red'}}>*</span></label>
          <Select  options={[
                     { value: 'Staff', label: 'Staff'},
              { value: 'Student', label: 'Student'},
                 ]} 
 onChange={handleSelect} 
 className={errors.user_type ? 'form-control form-control-danger' : ''} 
 name="user_type" value={machine.user_type}  /> 
 <span style={{color:'red'}}>{errors.user_type && errors.user_type}</span>
             </div>


            <div className="form-group">
						<label> Machine Name <span style={{color:'red'}}>*</span></label>
		<input type="text"  name="machine_name" autoComplete='off' value={machine.machine_name}  
        onChange={handleChange}
         className={errors.machine_name  ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.machine_name && errors.machine_name}</span>	
			</div>


            <div className="form-group">
						<label >Service Tag ID <span style={{color:'red'}}>*</span></label>
		<input type="text"  name="service_tag_id"
         value={machine.service_tag_id} autoComplete='off' onChange={handleChange} 
         className={errors.service_tag_id  ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.service_tag_id && errors.service_tag_id}</span>	
			</div>


            <div className="form-group">
						<label >Model No<span style={{color:'red'}}>*</span></label>
		<input type="text"  name="model_no" autoComplete='off' value={machine.model_no}  onChange={handleChange} className={errors.model_no  ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.model_no && errors.model_no}</span>	
			</div>


            <div className="form-group">
						<label >Auth Token<span style={{color:'red'}}>*</span></label>
		<input type="text"  name="auth_token" autoComplete='off' value={machine.auth_token}  
        onChange={handleChange} className={errors.auth_token  ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.auth_token && errors.auth_token}</span>	
			</div>

        
			
            </section>

            <section className="col-md-6">

            <div className="form-group">
						<label >Management API URL<span style={{color:'red'}}>*</span></label>
		<input type="url"  name="management_api" value={machine.management_api}  onChange={handleChange} 
        className={errors.management_api  ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.management_api && errors.management_api}</span>	
			</div>


            <div className="form-group">
						<label >Callback URL<span style={{color:'red'}}>*</span></label>
		<input type="url"  name="callback_url" value={machine.callback_url} 
         onChange={handleChange} className={errors.callback_url  ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.callback_url && errors.callback_url}</span>	
			</div>


            <div className="form-group">
						<label >Encryption Key</label>
		<input type="text"  name="encryption_key" value={machine.encryption_key}  
        onChange={handleChange} className='form-control' /> 
        <span style={{color:'red'}}>{errors.encryption_key && errors.encryption_key}</span>	
			</div>


            <div className="form-group">
						<label >Purchase Date <span style={{color:'red'}}>*</span></label>
		<input type="date"  name="purchase_date" value={machine.purchase_date} 
         onChange={handleChange} className={errors.purchase_date  ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.purchase_date && errors.purchase_date}</span>	
			</div>



            <div className="form-group">
						<label >Subscription Expiry Date <span style={{color:'red'}}>*</span></label>
		<input type="date"  name="expiry_date" value={machine.expiry_date} 
         onChange={handleChange} className={errors.expiry_date ? 'form-control form-control-danger' : 'form-control '} /> 
        <span style={{color:'red'}}>{errors.expiry_date && errors.expiry_date}</span>	
			</div>

            </section>
				
                </div>	

<hr/>
	<footer className="pull-right">
	<button type="button"  className="btn btn-danger  btn-round">Reset</button>&nbsp;
	

	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary  btn-round">Save Device</button>
	
								</footer>				
							</div>
							</div>


                            </div>
		
	

	<div className="col-md-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Biometric Device</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

      {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={device}   columns={tableHeader}  />}  

    </div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BiometricDevice) 