import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config, API_URL} from '../component/include'
import Select from 'react-select'
import { FormatNumber, getCAPoint, getRank, longDate} from '../component/globalFunction'
import { formatGroupLabel} from '../component/globalFunction'
import { userToken } from '../component/authentication'
import { useSelector } from 'react-redux'
const ClassMarks =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    
const staff  = useSelector(state => state.rootReducer.staff);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [classSubject, setClassSubject]=useState([]);
    const [allSubject, setAllSubject]=useState([]);
    const [sqlQuery, setSqlQuery] = useState([]);
    
    const [subjectAverage,  setSubjectAverage] = useState([]);
    const [classTeacher, setClassTeacher] = useState([]);
    const [classresult, setClassresult]= useState([])
    const [grossPoint, setGrossPoint]= useState([])

    const [result, setResult] = useState({
        studentClass:[],
        sectionClass:[],
        examtype:[],
        orderBy:{value:'Full', label:'End of Term'}
    })
    const [stat, setStat] = useState({
        average:0,
        below:0,
        above:0,
        total:0
    })

    
    const handleSearch = event =>{
        event.preventDefault();  
    var errormessage = [];

   

    if(result.examtype.length===0){

        var examtype ='Please select exam';
        setErrors({...errors, examtype:examtype})
        errormessage.push(examtype);
    }

    if(result.sectionClass.length===0 && result.studentClass.length===0){
        var sectionClass ='Please select class';
        setErrors({...errors, sectionClass:sectionClass})
        errormessage.push(sectionClass);

    }

    if (errormessage.length<=0) {
        fetchResult()
    }

    }  




    const fetchExamType =()=>{
       
        let url = API_URL+'/exam/result/examtype';
        axios.get(url,  config).then(result=>{
            if(result.data.examType.length!==0){
                setExamtype(result.data.examType)
               let grossP = result.data.grademark[0].grossPoint
                setGrossPoint(JSON.parse(grossP))
                }
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }
        


const fetchClassTeaher =(classID, sectionID)=>{
    var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
    setClassTeacher(newStaff)
}




    const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)
    const getGrade =(score, field)=>{
   
        const ans = grossPoint&&grossPoint.filter(itm=>parseFloat(score) >= parseFloat(itm.min) && parseFloat(score) <= parseFloat(itm.max));


        const remark = ans.length!==0? ans[0].remark:''
        const grade = ans.length!==0? ans[0].gp:''
       
    
    return field ==='grade'?grade:remark
    }

      

    const  handleFetchAllSubject =()=>{
        
        let url = API_URL + '/exam/class/subject'
        axios.get(url,  config)
    .then(result => setAllSubject(result.data.subject))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }) 

}


    const getSubjectName = (code, field) =>{        
        const response = allSubject&&allSubject.filter(list=>list.code === code);
        return   String(response.map(c=>c[field]));
        }
        

        const handleSelectClass =(option, action)=>{     
          
            if(action.name==='sectionClass'){
                fetchClassTeaher(option.classID, option.value)
                setResult({...result, [action.name]:option, examtype:[], studentClass:[]});
            }else{

                setResult({...result, [action.name]:option, examtype:[], sectionClass:[]});
            }
            setErrors({...errors, [action.name]:''})
            setClassresult([])
        }



        const handleSelectExam =(option, action)=>{     
          
            if(result.sectionClass.length!==0){
                fetchSectionSubject(result.sectionClass.value, option.value)
            }else if(result.studentClass.length!==0){
                fetchClassSubject(result.studentClass.value, option.value)
            }
           
            setResult({...result, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            setClassresult([])
        }
        
        
        
    const handleSelectType =(option, action)=>{     
        setResult({...result, [action.name]:option, studentClass:[], examtype:[]});
        setErrors({...errors, [action.name]:''})
        setClassresult([])
       setSqlQuery([])

    }
      


        const fetchSectionSubject =async(sectionID, exam)=>{
            setNotice({...notice, isLoading: true}) 
            let url = API_URL+'/exam/subject_list/'+sectionID+'/'+exam;
            axios.get(url, config).then(response=>{
                if(response.data.data.length!==0){
        var subjectList = response.data.data
                setClassSubject(response.data.data)
    
                let final = '';
    if(result.orderBy.value==='Full'){
            let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
                
            

                   
              final =   'SELECT  sum(case when e.total >0 then 1 else 0 end) as subject, rank() over (order by  cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) desc) position,  s.admissionNumber, s.termination, s.termination_date, s.termination_remark,  sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.registerNumber, s.studentName, e.maxMark, e.absent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode order by s.studentName ASC '
    
    
    }else{
    

             var point = getCAPoint(result.studentClass.classtype).ca
                
        let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then (e.firstCA/20)*100 else 0 end) AS '"+data.subjectName+"'")
                       
     
    final =   'SELECT  sum(case when e.firstCA >0 and e.absent="No" then 1 else 0 end) as subject, rank() over (order by  cast(sum(case when e.firstCA >0 and e.absent="No" then  ((e.firstCA/20)*100) else 0 end)/sum(case when e.firstCA >0 and e.absent="No" then 1 else 0 end) as decimal(10,2))  desc) position,  cast(sum(case when e.firstCA >0 and e.absent="No" then  ((e.firstCA/20)*100) else 0 end)/sum(case when e.firstCA >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.admissionNumber, s.termination, s.termination_date,  s.termination_remark, sum(case when e.firstCA >0 and e.absent="No" then ((e.firstCA/20)*100) else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, s.registerNumber, s.studentName, e.maxMark, e.absent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode order by s.studentName ASC '

    
                }
    
    
            setSqlQuery(final)
            
            }
            }).finally(()=>{
                setNotice({...notice, isLoading: false}) 
            })
        }
    

    const fetchClassSubject =async(classID, exam)=>{
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT  subjectCode  from tbl_exam_result  where examCode ='"+exam+"' and classID ='"+classID+"' group by subjectCode";

        
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
      await  axios.post(url, fd, config).then(response=>{
            if(response.data.length!==0 && Array.isArray(response.data)){
        
                    var subj = response.data;
            var subjectList = [];
            for (var i = 0; i< subj.length;  i++) {
                subjectList.push(
                    {  subjectCode:subj[i].subjectCode,
                        subjectName:getSubjectName(subj[i].subjectCode, 'subjectName')
                    });
                    }

            setClassSubject(subjectList)
            let final = '';
if(result.orderBy.value==='Full'){
        let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
            

               
          final =   'SELECT  sum(case when e.total >0 then 1 else 0 end) as subject, rank() over (order by  cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) desc) position,   s.admissionNumber, s.termination, s.termination_date, s.termination_remark,  sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.registerNumber, s.studentName, e.maxMark, e.absent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.classID ="'+classID+'" group by e.studentCode order by s.studentName ASC '


}else{

         var point = getCAPoint(result.studentClass.classtype).ca

         //var point = getCAPoint(result.studentClass.classtype).ca
         //let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then e.firstCA else 0 end) AS '"+data.subjectName+"'")
        
         let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then (e.firstCA/20)*100 else 0 end) AS '"+data.subjectName+"'")
                       
     
 final =   'SELECT  sum(case when e.firstCA >0 and e.absent="No" then 1 else 0 end) as subject, rank() over (order by  cast(sum(case when e.firstCA >0 and e.absent="No" then ((e.firstCA/20)*100) else 0 end)/sum(case when e.firstCA >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) desc) position,  cast(sum(case when e.firstCA >0 and e.absent="No" then  ((e.firstCA/20)*100) else 0 end)/sum(case when e.firstCA >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.admissionNumber, s.termination, s.termination_date,  s.termination_remark, sum(case when e.firstCA >0 and e.absent="No" then ((e.firstCA/20)*100) else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, s.registerNumber, s.studentName, e.maxMark, e.absent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.classID ="'+classID+'" group by e.studentCode order by s.studentName ASC '

    
    


            }


        setSqlQuery(final)
        
        }
        }).finally(()=>{
            setNotice({...notice, isLoading: false}) 
        })
    }


    

    

        const fetchResult =async()=>{
            setNotice({...notice, isLoading: true}) 
           
         const fd = new FormData();
        fd.append("sql", sqlQuery);
        let url = API_URL+'/exam/result/fetch_raw';
      await  axios.post(url, fd, config).then(result=>{
            
             if(result.data.data.length!==0){

                var rs= result.data.data;
                var subList = [];
                 for (var i = 0; i < rs.length; i++){
                     subList.push(
                             {...rs[i],
                                 obtainable:parseFloat(rs[i].subject)*100,
                                 cgp:getGrade(parseFloat(rs[i].average).toFixed(1), 'grade'),
                                 remark:getGrade(parseFloat(rs[i].average).toFixed(1), 'remark')
                                 
                             });
                 } 
                 var averageList = [];
                 for (var j = 0; j < rs.length; j++){
                     for (var m = 0; m < classSubject.length; m++){
                         if(parseFloat(rs[j][classSubject[m].subjectName])>0){
                                 averageList.push(
                                     {
                                         subjectCode:classSubject[m].subjectCode,
                                         subjectName:classSubject[m].subjectName,
                                         score:rs[j][classSubject[m].subjectName]
                                         
                                     })
                                 }
                         }        
                 }
 var count = { }
 averageList&&averageList.forEach( element => {
   if ( count[ element.subjectName ]) {
     count[ element.subjectName ] += parseFloat(element.score)
     count [element.subjectName+'total'] +=1;
   } else {
     count[ element.subjectName ] = parseFloat(element.score)
     count [element.subjectName+'total'] =1;
   }
 })
 
 

 var total = rs.reduce((acumulator, item)=>acumulator + Number(item.obtained), 0)
 let total_student = rs.filter(item=>Number(item.obtained)>0)

 var avg = total/total_student.length
   var above = rs.filter(item=>Number(item.obtained)>=avg)
   var below = rs.filter(item=>Number(item.obtained)< avg)

setStat({
average:FormatNumber(total/total_student.length),
below:below.length,
above:above.length,
total:total_student.length

})

 
        
 setSubjectAverage(count)

        setClassresult(subList)
        
structure.current.scrollIntoView({ behavior: "smooth" });
            }else{
                setClassresult([])
                Alerts('Info!', 'info', 'There are no subject assigned for the selected class')
            }  
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
        }


        
        const handleRankName = ()=>{

            const newData = [...classresult]
            let newResult = newData.sort((a,b)=>b.studentName > a.studentName?-1:1)
            setClassresult(newResult)
        }
        
                const handleRank = ()=>{
                    const newData = [...classresult]
                    let newResult = newData.sort((a,b)=> (Number(b.position) > Number(a.position))?-1:1)
                    setClassresult(newResult)
                }


                const getAverage = () =>{        
            const total = classresult&&classresult.reduce((acc, item)=>Number(item.average)+Number(acc),0)
            const student = classresult.reduce((acc, item)=>Number(item.average)>0?1+Number(acc):Number(acc),0)

            const average = (total/student)

                    return   {total:total.toFixed(2), student:student, average:average.toFixed(2)}
                    }


useEffect(()=>{
    fetchExamType()
    handleFetchAllSubject()
},[]);


        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Class Marks">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Marks</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Class Marks</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">

        <section className="col-md-6">
           <div className="form-group">
         <label> Report Type</label>
         <Select  options={[
             {value:'Half', label:'Half Term'},
                    { value: 'Full', label: 'End of Term'}
                ]} 
                onChange={handleSelectType}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>
     <section className="col-md-6">
           <div className="form-group">
         <label> Search By  Section<span style={{color:'red'}}>*</span></label>
         <Select  options={
                        schoolClass&&schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectClass}  className={errors.sectionClass ? 'form-control form-control-danger' : ''} name="sectionClass" value={result.sectionClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.sectionClass && errors.sectionClass}</span>
            </div>
     
     </section> 

     <section className="col-md-6">
           <div className="form-group">
         <label> Search By Class</label>
         <Select  options={
                            schoolClass&&schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, classtype:list.arms} 
							 })
							 
                        } 
onChange={handleSelectClass}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section> 


    
    
     <section className="col-md-6">
            <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam, classtype:list.arms }
                             })
                        } 
                       
onChange={handleSelectExam} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
      
      </section>
      </div>

      <div className="row">
      <div className="col-md-12 ">  
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-primary pull-right"><i className="fa fa-search"></i> Search Result</button>
		 </div>
			</div>	             
				
							</div>
                            </div>
							</div></div>
</div>

</div>

{classresult.length!==0?
<div className="card z-depth-0">
{result.orderBy.value==='Half'?
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
       <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>{result.examtype.label} (HALF TERM AT A GLANCE RESULT) </b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{result.sectionClass.length!==0?result.sectionClass.label:result.studentClass.label}</b></td>
                    <td >Form Tutor: <b>{classTeacher&&classTeacher.map(item=>item.staffName+' ')}</b></td>
                    <td >Total Student: <b>{stat.total}</b></td>
        </tr>
        <tr>
                
                    <td >Class Average: <b> {stat.average}</b></td>
               
                    <td >On & Above Average: <b> {stat.above}</b></td>
                    <td>Below Average: <b> {stat.below}</b></td>
                </tr>
            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th>S/N</th>
                    <th >Admission No.</th>
                    <th onClick={handleRankName} style={{ cursor:'pointer' }}>Name of Students <i className="fa fa-caret-down" ></i></th>
                    
                    {classSubject&&classSubject.map((data, id)=><th key={id} className="vertical-header"><span>{data.subjectName}</span></th>)}
                     <th className="vertical-header"><span>No. of Subjects</span></th>
                    <th className="vertical-header"><span>Mark Obtainable</span></th>
                    <th className="vertical-header"><span>Mark Obtained</span></th>
                    <th className="vertical-header"><span>Average</span></th>
                    <th onClick={handleRank} style={{ cursor:'pointer' }} className="vertical-header"><span>Rank <i className="fa fa-caret-down" ></i></span></th>
                   <th className="vertical-header"><span>1ST G.P.A</span></th> 
                    <th className="vertical-header"><span>REMARK</span></th>  
                </tr>
            </thead>
            <tbody>
            <tr>
                    <td></td>
                    <th colSpan={2}>MARK OBTAINABLE</th>
                    {classSubject&&classSubject.map((data, id)=><th key={id} >100</th>)}
                    
                    <th>{classSubject.length}</th>
                    <th>{classSubject.length*100}</th>
                    <th></th>
                    <th>100</th>
                    <th></th>
                    <th>4.0</th>
                    <th></th>
                </tr>
                 {classresult.length!==0?classresult.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                    <td>{st.admissionNumber}</td>
                    <td>{st.studentName} <br/>{st.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Suspended on {longDate(st.termination_date)} {st.termination_remark}</span>:''}</td>
                    {classSubject&&classSubject.map((data, id)=><td key={id} >{Number(st[data.subjectName]).toFixed(2)}</td>)}
                    
                     <td>{st.subject}</td>
                    <td>{st.obtainable}</td>
                    <td>{Number(st.obtained).toFixed(2)}</td>
                    <td>{st.average}</td>
                    <td>{st.position}<sup>{getRank(st.position)}</sup></td>
                   <td>{st.cgp}</td>
                    <td>{st.remark}</td> 

                </tr>):[]} 

                <tr>
              <td></td>
                    <th colSpan={2}>SUBJECT AVERAGE</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {(parseFloat(subjectAverage[st.subjectName])/subjectAverage[st.subjectName+'total']).toFixed(2)}
                   

                        </th>)} 
                        <th colSpan={3}></th>
                        <th >{getAverage().average}</th>
                </tr>  
                <tr>
              <td></td>
                    <th colSpan={2}>TOTAL SCORE</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {FormatNumber(subjectAverage[st.subjectName])}
                   
                        </th>)} 
                        <th colSpan={3}></th>
                        <th >{getAverage().total}</th>
                </tr> 

                <tr>
              <td></td>
                    <th colSpan={2}>No. of Students</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {subjectAverage[st.subjectName+'total']}
                   

                        </th>)} 
                        <th colSpan={3}></th>
                        <th >{getAverage().student}</th>
                </tr>  
            </tbody> 
        </table>
 </div>	 </div> 

 <div className="row no-print" ref={structure}>
        <div className="col-md-12">
            <form method='POST' action={ServerUrl+'/printout/class_marks.php?token='+userToken} target='_blank'>
                <input type='hidden' name='classSubject' value ={JSON.stringify(classSubject, null, 2)} />
                <input type='hidden' name='classresult' value ={JSON.stringify(classresult, null, 2)} />
                 <input type='hidden' name='classID' value ={result.sectionClass.length!==0?result.sectionClass.classID:result.studentClass.value} />
        <input type='hidden' name='sectionID' value ={result.sectionClass.length!==0?result.sectionClass.value:result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='resultType' value ={result.orderBy.value} />
                <input type='hidden' name='teacher' value ={classTeacher&&classTeacher.map(item=>item.staffName+' ')} />
                <input type='hidden' name='average' value ={JSON.stringify(getAverage(), null, 2)} />
                <input type='hidden' name='subjectAverage' value ={JSON.stringify(subjectAverage, null, 2)} />
                <input type='hidden' name='stat' value ={JSON.stringify(stat, null, 2)} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>:

<div className="card-block">
	
{schoolInfo&&schoolInfo.map((st, id)=>
               <div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
</td>
        </tr>
    </tbody>
</table>

</div> 
               )}

<div className="row">
<div className="col-md-12">
<h5 style={{textAlign:'center'}}><u><b>END OF {result.examtype.label} (AT A GLANCE RESULT) </b></u></h5>
</div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
<tbody>
        <tr>
            <td >Class: <b>{result.sectionClass.length!==0?result.sectionClass.label:result.studentClass.label}</b></td>
            <td >Form Tutor: <b>{classTeacher&&classTeacher.map(item=>item.staffName+' ')}</b></td>
            <td >Total Student: <b>{stat.total}</b></td>
        </tr>
        <tr>
                
                    <td >Class Average: <b> {stat.average}</b></td>
               
                    <td >On & Above Average: <b> {stat.above}</b></td>
                    <td>Below Average: <b> {stat.below}</b></td>
                </tr>

    </tbody>
</table></div>
<div className="col-md-12 table-responsive">
<table className="table table-striped table-bordered">


    <thead>
        <tr>
            <th>S/N</th>
            <th >Admission No.</th>
            <th onClick={handleRankName} style={{ cursor:'pointer' }} >Name of Students <i className="fa fa-caret-down" ></i></th>
            
            {classSubject&&classSubject.map((data, id)=><th key={id} className="vertical-header"><span>{data.subjectName}</span></th>)}
             <th className="vertical-header"><span>No. of Subjects</span></th>
            <th className="vertical-header"><span>Mark Obtainable</span></th>
            <th className="vertical-header"><span>Mark Obtained</span></th>
            <th className="vertical-header"><span>Average</span></th>
                    <th onClick={handleRank} style={{ cursor:'pointer' }} className="vertical-header"><span>Rank <i className="fa fa-caret-down" ></i></span></th>
       <th className="vertical-header"><span>G.P.A</span></th> 
            <th className="vertical-header"><span>REMARK</span></th>  
        </tr>
    </thead>
    <tbody>
    <tr>
                    <td></td>
                    <th colSpan={2}>MARK OBTAINABLE</th>
                    {classSubject&&classSubject.map((data, id)=><th key={id} >100</th>)}
                    
                    <th>{classSubject.length}</th>
                    <th>{classSubject.length*100}</th>
                    <th></th>
                    <th>100</th>
                    <th></th>
                    <th>4.0</th>
                    <th></th>
                </tr>
         {classresult.length!==0?classresult.map((st, idx)=> 
        <tr key={idx}>
        <td>{idx+1}</td>
            <td>{st.admissionNumber}</td>
            <td>{st.studentName} <br/>{st.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Terminated on {longDate(st.termination_date)} {st.termination_remark}</span>:''}</td>
            {classSubject&&classSubject.map((data, id)=><td key={id} >{st[data.subjectName]}</td>)}
            
             <td>{st.subject}</td>
            <td>{st.obtainable}</td>
            <td>{st.obtained}</td>
            <td>{st.average}</td>
            <td>{st.position}<sup>{getRank(st.position)}</sup></td>
          <td>{st.cgp}</td>
            <td>{st.remark}</td> 

        </tr>):[]} 
        <tr>
              <td></td>
                    <th colSpan={2}>SUBJECT AVERAGE</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {(parseFloat(subjectAverage[st.subjectName])/subjectAverage[st.subjectName+'total']).toFixed(2)}
                   

                        </th>)} 
                        <th colSpan={3}></th>
                        <th >{getAverage().average}</th>
                </tr>  
                <tr>
              <td></td>
                    <th colSpan={2}>TOTAL SCORE</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {FormatNumber(subjectAverage[st.subjectName])}
                   

                        </th>)} 
                        <th colSpan={3}></th>
                        <th >{getAverage().total}</th>
                </tr> 

                <tr>
              <td></td>
                    <th colSpan={2}>No. of Students</th>
                    
                   {classSubject&&classSubject.map((st, id)=><th key={id} className="vertical-header">
                   {subjectAverage[st.subjectName+'total']}
                   

                        </th>)} 
                        <th colSpan={3}></th>
                        <th >{getAverage().student}</th>
                </tr> 
    </tbody> 
</table>
</div>	 </div> 

<div className="row no-print" ref={structure}>
<div className="col-md-12">
    <form method='POST' action={ServerUrl+'/printout/class_marks.php?token='+userToken} target='_blank'>
        <input type='hidden' name='classSubject' value ={JSON.stringify(classSubject, null, 2)} />
        <input type='hidden' name='classresult' value ={JSON.stringify(classresult, null, 2)} />
        <input type='hidden' name='classID' value ={result.sectionClass.length!==0?result.sectionClass.classID:result.studentClass.value} />
        <input type='hidden' name='sectionID' value ={result.sectionClass.length!==0?result.sectionClass.value:result.studentClass.value} />
        <input type='hidden' name='examCode' value ={result.examtype.value} />

        <input type='hidden' name='average' value ={JSON.stringify(getAverage(), null, 2)} />

        <input type='hidden' name='resultType' value ={result.orderBy.value} />
        <input type='hidden' name='teacher' value ={classTeacher&&classTeacher.map(item=>item.staffName+' ')} />

        <input type='hidden' name='stat' value ={JSON.stringify(stat, null, 2)} />
        <input type='hidden' name='subjectAverage' value ={JSON.stringify(subjectAverage, null, 2)} />

        <input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>

</form>
</div></div>

</div>}


        </div>:''}
 
   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ClassMarks) 