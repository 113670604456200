import React from 'react'
import {ImagesUrl} from '../../component/include'
import Preloader from '../component/preloader'
import { useHistory } from 'react-router-dom'

 const Error403 =()=> {
	let history = useHistory()
        return (<>
          <Preloader />

		  <section style={{background: `url(${ImagesUrl}/bg.jpg) no-repeat`}} 
		  className="login-block">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <div className="row">
             <div className="col-md-12">
                 <div className=" error-pages">
                     <h1 className="error-title text-danger"> 403</h1>
                     <h2 className="error-sub-title text-white">Oops! Access is forbidden</h2>

                     <p className="error-message text-white text-uppercase"> We could not let you access this page.
                     YOU DON'T HAVE PERMISSION TO DO THAT</p>
                     
                     <div className="mt-4">
                       <a href="/staffportal/dashboard" className="btn btn-danger btn-round shadow-danger m-1">Go To Home </a>
                       <a href="#!" onClick={()=>history.goBack()} className="btn btn-outline-danger btn-round m-1">Previous Page </a>
                     </div>

                 </div>
             </div>
         </div>
                </div>
            </div>
        </div>
    </section>



         
</> 

 );
        
}

export default React.memo(Error403) 