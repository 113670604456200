import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl, Currency, FormatNumber, selectStyles} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {Type} from 'react-bootstrap-table2-editor';
import Select from 'react-select'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import {  getTime, longDate } from '../component/globalFunction'

const HostelVisitors=()=> {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})


const student = useSelector(state => state.rootReducer.students);
const [students, setStudents]= useState([])
const [allVisitors, setAllVisitors]= useState([]) 
const hostel = useSelector(state => state.rootReducer.hostel);
const [visitors, setVisitors] = useState({
    hostelCode:'',
    visitorsName:'',
    relationship:'',
    visitDate:'',
    timeVisit:'',
    student:''

})



const handleSelect = (option, action)=>{
    setVisitors({...visitors, [action.name]: option});
    setErrors({...errors, [action.name]: ''})
     } 

     const handleSelectHostel = (option, action)=>{
        fetchStudents(option.value)
        setVisitors({...visitors, [action.name]: option, student:[]});
        setErrors({...errors, [action.name]: ''})
         } 



 const handleChange = (event)=>{
    const {name, value} = event.target
    setVisitors({...visitors, [name]: value}); 
    setErrors({...errors, [name]: ''})
}


const fetchStudents =(code)=>{

    setNotice({...notice,   isLoading: true})
    let sql = "Select s.studentName, s.admissionNumber, s.code, a.hostelCode from tbl_hostel_allocation a, tbl_students s where s.code = a.studentCode and a.hostelCode = '"+code+"' and a.isApproved ='true' and a.status = 'Occupied'  "

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setStudents(result.data))
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,   isLoading: false})
    })
    }



    const fetchVisitors =()=>{
        setNotice({...notice,   isDataFetching: true})
        let sql = "Select h.hostelName, v.code,	v.studentList,	v.visitorName,	v.visitDate,	v.visitTime,	v.relationship,	v.outTime,	v.dateCreate	from tbl_hostel_visitors v, tbl_hostel h where h.code = v.hostelCode  "
    
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setAllVisitors(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isDataFetching: false})
        })
        }


        const handleSubmit = event =>{
            event.preventDefault();
        setErrors(ValidateInput(visitors));
        setIsSubmitting(true);
        } 
    
        const ValidateInput=(values)=>{
            let errors = {};   
           
            if(values.hostelCode.length===0){
                errors.hostelCode ='Please select hostel';
            }
            
    
            if(!Array.isArray(values.student)){
                errors.student ='Please select student';
            }
    
    
            if(!values.visitorsName){
                errors.visitorsName ='Please enter visitor fullname';
            }
    
            if(!values.visitDate){
                errors.visitDate ='Please enter visit Date';
            }
    
            if(!values.timeVisit){
                errors.timeVisit ='Please enter visit time';
            }
    
    
            if(!values.relationship){
                errors.relationship ='Please enter relationship';
            }
    
            return errors;
            }
        


const  submit=()=>{  
    setNotice({...notice,   isLoading: true})

      let formData = visitors; 
      const fd = new FormData();

      var value = [];
      var data = visitors.student;
        for (var i = 0, l = data.length; i < l; i++) {
             value.push({code:data[i].value});
             }


      fd.append('jwt', Token); 
         for(let k in formData){
             fd.append(k,formData[k]);
         }; 
         fd.append("studentList", JSON.stringify(value, null, 2))
         fd.append("hostel", visitors.hostelCode.value)
      fd.append("code", Math.random().toString(36).substr(2,9))
      
             let url = ServerUrl+'/save_controller/tbl_hostel_visitors'
            axios.post(url, fd, config)
            .then(response =>{
            window.scrollTo(0,0)
            if (response.data.type ==='success'){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            
                }  
                         
                 })
                 .catch((error)=>{
                    setErrors({...errors, errorMessage: error.message})
              })
              .finally(()=>{
                setIsSubmitting(false);
                fetchVisitors()
                setNotice({...notice,  isLoading: false}) 
                setVisitors({
                    hostelCode:'',
                    visitorsName:'',
                    relationship:'',
                    visitDate:'',
                    timeVisit:'',
                    student:''
                })
              })
    } 



    const handleDelete = (code)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'code')
            fd.append('data', code)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_hostel_visitors';
           axios.post(url, fd, config)
           .then(response =>{
            if (response.data.type ==='success'){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            
                }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               setNotice({...notice,  isLoading: false })
           }) 
    }




    const TableRecord=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
    
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_hostel_visitors'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>


      <div className="dt-buttons btn-group pull-right"> 
    
        
    
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=hostel_visitor'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/hostel.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div> 
        </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }

     
      const handleStudentName=(cell,row)=>{
        return JSON.parse(cell).map((itm, idx)=>{
            return <div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} key={idx}>{getStudent(itm.code)}</div>
        } );
    }

	const getStudent = (code) =>{   
        
         const result = student.filter(list=>list.code===code);
        const answer = result.length!==0?result.map(c=>`(${c.admissionNumber}) ${c.studentName}` ):''

          return  answer;
        }
      
    var i =1;
    const tableHeader = [
        {dataField: 'ID', text: 'SN', editable:false, formatter:(cell)=>i++},
                {dataField: 'hostelName', text: 'Hostel', editable:false},
                {dataField: 'visitorName', text: 'Visitor'},  
                {dataField: 'relationship', text: 'Relationship'},
                {dataField: 'studentList', text: 'Student', formatter:handleStudentName, editable: false}, 
                {dataField: 'visitDate', text: 'Visit Date', editor: {
                    type: Type.DATE
                  }, formatter:(cell)=>longDate(cell)},

                  {dataField: 'visitTime', text: 'Time IN', formatter:(cell)=>getTime(cell)},
                  {dataField: 'outTime', text: 'Time OUT', formatter:(cell)=>getTime(cell)},
            
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
        
        <button type="button" className="btn btn-danger btn-sm delete" data-toggle="modal"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></button>
        &nbsp;&nbsp;
        
        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} /> 
        </div>, editable: false}
         ]




 useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    
useEffect(()=>{
    fetchVisitors()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Hostel Visitors">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                            <li className="breadcrumb-item"><a href="#!" >Visitors</a> </li>

                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              

                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

                           
                
                            <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Register New Visitor</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">


        <section className="col-md-4">
                         <div className="form-group">
						<label > Hostel Name <span style={{color:'red'}}>*</span></label> 
                        <Select options={
                               hostel&&hostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
                 onChange={handleSelectHostel} className={errors.hostelCode? 'form-control form-control-danger' : ''} name="hostelCode" value={visitors.hostelCode} styles={selectStyles} /> 
                 <span style={{color:'red'}}>{errors.hostelCode && errors.hostelCode}</span>
											</div>
         </section>


         <section className="col-md-4">
                                    
                    <div className="form-group">
                    <label >Select Student<span style={{color:'red'}}>*</span></label>
                    <Select isMulti options={
                        students&&students.map((list, idx)=> {
                        return {key:idx, value: list.code, label:list.studentName, admissionNumber:list.admissionNumber, email:list.email }
                        })
                }    
                    getOptionLabel={option =>option.admissionNumber +' -'+ option.label}    
        onChange={handleSelect}  name="student" value={visitors.student} className={errors.student? 'form-control form-control-danger' : ''} /> 
        
         <span style={{color:'red'}}>{errors.student && errors.student}</span>
    
              </div>
    
                </section>

               
        <section className="col-md-4">
                            <div className="form-group">
        <label > Visitor's Name <span style={{color:'red'}}>*</span></label> 
        <input type="text" className={errors.visitorsName ? 'form-control form-control-danger' : 'form-control'} name="visitorsName" value={visitors.visitorsName}  onChange={handleChange} />
        <span style={{color:'red'}}>{errors.visitorsName && errors.visitorsName}</span>
                            </div>
        </section>

        <section className="col-md-4">
                            <div className="form-group">
        <label > Relationship <span style={{color:'red'}}>*</span></label> 
        <input type="text" className={errors.relationship ? 'form-control form-control-danger' : 'form-control'} name="relationship" value={visitors.relationship}  onChange={handleChange} />
        <span style={{color:'red'}}>{errors.relationship&& errors.relationship}</span>
                            </div>
        </section>
                      

        <section className="col-md-4">
                            <div className="form-group">
        <label > Visit Date <span style={{color:'red'}}>*</span></label> 
        <input type="date" className={errors.visitDate ? 'form-control form-control-danger' : 'form-control'} name="visitDate" value={visitors.visitDate}  onChange={handleChange} />
        <span style={{color:'red'}}>{errors.visitDate && errors.visitDate}</span>
                            </div>
        </section>


        <section className="col-md-4">
                            <div className="form-group">
        <label > Visit Time <span style={{color:'red'}}>*</span></label> 
        <input type="time" className={errors.timeVisit? 'form-control form-control-danger' : 'form-control'} name="timeVisit" value={visitors.timeVisit}  onChange={handleChange} />
        <span style={{color:'red'}}>{errors.timeVisit && errors.timeVisit}</span>
                            </div>
        </section>

                         </div>


                <div className="row">
                <section className="col-md-4">
<button type="button"  className="btn btn-inverse " onClick={handleSubmit} ><i className="fa fa-users"></i> Add Visitor</button>
</section>	                </div>
							</div>
							</div>

							</div>
                </div>


                            <div className="row">
  <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Visitors</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRecord data={allVisitors}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(HostelVisitors) 