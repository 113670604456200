import Cookies from 'js-cookie'
import { decrypt } from './authentication'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const APP_KEY =process.env.REACT_APP_APP_KEY;
export  const mainUrl = process.env.REACT_APP_MAIN_URL
export  const ParentUrl=  "https://parent."+mainUrl
export  const applicantUrl=  "https://form."+mainUrl
export  const ServerUrl = "https://app."+mainUrl+'/controller'
export  const ImagesUrl = "https://app."+mainUrl+'/images'
export  const API_URL = "https://"+mainUrl+'/api'
export  const API_IMAGE = "https://"+mainUrl+'/public/images'

export  const LocalUrl = 'localhost'
export  const KAYUS_URL = 'https://kayus.org'
export const DatabaseName= process.env.REACT_APP_DATABASE_NAME
export const domain = process.env.REACT_APP_DOMAIN
export const smsAccountUrl = process.env.REACT_APP_SMS_ACCOUNT_URL;

export const TINYMCE_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;
export const menuLink = localStorage.getItem('kayus_link')? JSON.parse(decrypt(localStorage.getItem('kayus_link'))):[]


export const allowEdit = Cookies.get('kayus_ed')?Cookies.get('kayus_ed'):undefined
export const allowDelete = Cookies.get('kayus_dt')?Cookies.get('kayus_dt'):undefined

export const classID = Cookies.get("clkysd") ? Cookies.get("clkysd"):undefined
export const sectionID = Cookies.get("sckayd") ? Cookies.get("sckayd") :undefined

export const reset_password = Cookies.get("tkrspw") ? Cookies.get("tkrspw") : "null";
export const staffCode = Cookies.get('kayus_code')?Cookies.get('kayus_code'):undefined
export const Token = Cookies.get('kayus_token')?CryptoJS.AES.decrypt(Cookies.get('kayus_token'), APP_KEY).toString(CryptoJS.enc.Utf8):undefined
export const PortalType = Cookies.get('portal')?Cookies.get('portal'):null;

export const groupCode = [''] 
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const selectStyles = {
    container: (css) => ({ ...css, flex: "1 1 auto", alignSelf: "stretch" }),
    menu: (css) => ({ ...css, zIndex: 999 }),
    control: (css) => ({ ...css,  height:40 })
  };

//school name and LocalUrl should be entered manually
export const Currency = '₦' 

export const staffName = 'No name'
export const schoolName = ''
