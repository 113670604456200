import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config,  API_URL} from '../component/include'
import Select from 'react-select'
import { longDate} from '../component/globalFunction'
import { formatGroupLabel} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'

const AssignTeacher =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    const [editSubject, setEditSubject] =  useState([]);
    const AllStaff  = useSelector(state => state.rootReducer.staff);

     const [staff, setStaff] =  useState([]); 
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [subject, setSubject]= useState([])

    const [isExam, setIsExam] = useState({
        '0':false,
        teacher:'[]',
            code:'',
            examCode:'',
            sectionID:'',
            subjectCode:'',
        examDate:''});

    const [result, setResult] = useState({
        studentClass:[],
        examtype:[]
    })

  

    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        }   
        if(values.examtype.length===0){
            errors.examtype ='Please select subject';
        } 
        return errors;
        }


        const fetchExamType =()=>{
       
            let url = API_URL+'/exam/result/examtype';
            axios.get(url,  config).then(result=>{
            
                setExamtype(result.data.examType)
            
            
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }
       
useEffect(()=>{
    if(AllStaff.length!==0){
       
setStaff(AllStaff.filter(item=>item.employmentStatus ==='Working'))
    }

}, [AllStaff])

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        fetchResult()
        }
        },[errors])

const handleSelect =(option, action)=>{     
          
            setResult({...result, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            setSubject([])
        }

        const fetchResult =()=>{
            setNotice({...notice, isLoading: true}) 
            let url = API_URL+'/exam/display_result/'+result.studentClass.value+"/"+result.examtype.value;

            axios.get(url,  config).then(result=>{
                 if(result.data.data.length!==0){
            setSubject(result.data.data)
        structure.current.scrollIntoView({ behavior: "smooth" });
            }else{
                Alerts('Info!', 'info', 'There are no schedule found for the selected exam')
            } 
        })
        .catch((error)=>{
        //Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        })
        }

        const handleStaffName=(cell)=>{
            if(cell!=='[]'){
            return JSON.parse(cell).map((itm, idx)=>{
                return <div  key={idx}>{getStaff(itm)}</div>
            } );
        }else{
            return ''
        } 
        }
        
        
        const getStaff = (code) =>{   
            
             const result = staff&&staff.filter(list=>list.code===code);
            const answer = result.length!==0?result.map(c=>` ${c.staffName}` ):''
        
              return  answer;
            }


            const handleSelectEditSubject = (option, action)=>{
                
                const total = option!==null?option.length:[]
                const std =editSubject
                var value = [];
                    for (var i = 0, l = total; i < l; i++) {
                      value.push(option[i].value);
                    }

                    const teacher = JSON.stringify(value) 
                    std[0][action.name]= JSON.stringify(value) 

                    setIsExam({...isExam, teacher:teacher})
                     
            }

            
            const handleChangeSubject = (e)=>{ 
                const value =e.target.value;
                const std =editSubject
                std[0][e.target.name] = value;
                setIsExam({...isExam, examDate:value})
              
            }

            const handleEditSubject =(item)=>{
                setEditSubject([item])
                setIsExam({
                    [item.code]:true,
                    teacher:item.teacher,
                    code:item.code,
                    examCode:item.examCode,
                    sectionID:item.sectionID,
                    subjectCode:item.subjectCode,
                    examDate:item.examDate
                })
                }

               
                const getStaffField = (code, field) =>{   
    
                    const result = staff&&staff.filter(list=>list.code===code);
                    const answer = result.map((c)=>c[field] ); 
                    return  String(answer);
                   }
                   
            
                const getTemplate=(temp)=>{

                    if(temp!=='[]'){
                        
                    var value = [];
                 JSON.parse(temp).map((itm, idx)=>{
                    value.push({value:itm, label:getStaffField(itm, 'staffName'), fileNo:getStaffField(itm, 'fileNo'), key:idx});
                    } );
                    
                return value;
                
                }else{
                    return []
                }}



const handleUpdateField =()=>{
    const fd = new FormData(); 
    fd.append('examCode', isExam.examCode);
    fd.append('subjectCode', isExam.subjectCode);
    fd.append('sectionID', isExam.sectionID);
    fd.append('teacher', isExam.teacher);
    fd.append('examDate', isExam.examDate);
    setNotice({...notice, isLoading: true}) 
    

    let url = API_URL+'/exam/result/assign_staff';
    axios.post(url, fd, config)
    .then(response =>{
      if (response.data.status ===200){
            Alerts('Saved!', 'info', response.data.message)
        } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
}  
    })
    .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
        setNotice({...notice, isLoading: false})
        fetchResult()
        setIsExam({
            '0':false,
            teacher:'[]',
            code:'',
            examCode:'',
            subjectCode:'',
            sectionID:'',
            examDate:''})


    })
}



useEffect(()=>{
    fetchExamType()
},[]);


        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Assign Staff To Exam Subject">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Exam Schedule</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Exam Schedule</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">

                    <section className="col-md-6">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>     
    
     <section className="col-md-6">
            <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
      
      </section>
      </div>

      <div className="row">
      <div className="col-md-12 ">  
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-primary pull-right"><i className="fa fa-search"></i> Search Exam Schedule</button>
		 </div>
			</div>	             
				
							</div>
                            </div>
							</div></div>
</div>

</div>
{subject.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
                {/*     <td className="text-center" ><img id="viewSchoolLogo2" className="img-80" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{schoolName}</b></h4>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}>  {st.mobile} &nbsp; {st.altMobile} </div>
 <div style={{textAlign:'center'}}>  {st.email} </div>
 </td>
           */}   
           
           
           <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}     />
 </td>   </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Examination Schedule Detail </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{result.studentClass.label}</b></td>
                    <td>Exam Type: <b> {result.examtype.label} Examination</b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
        

            <thead>
                <tr>
                    <th>Exam Date</th>
                    <th>Subject</th>
                    <th>Subject Teacher</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>{subject.length!==0?subject.map((st, idx)=> 
                <tr key={idx}>
                <td>
                {isExam[st.code]===true?  <input type='date' name='examDate' onChange={handleChangeSubject} className='form-control' value ={isExam.examDate} />:longDate(st.examDate)}
                </td>
                    <td>{st.subjectName}</td>
                    <td>
                    
                    {isExam[st.code]===true?  
<Select isMulti options={
                            staff.map((list, idx)=> {
                                return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                            })
                       }    
           getOptionLabel={option =>option.label +' - '+ option.fileNo} 
onChange={handleSelectEditSubject}  name="teacher" value={getTemplate(isExam.teacher)}  />:<b>{handleStaffName(st.teacher)}</b>}
                    </td>
                    <td>
                        
                     {isExam[st.code]===true?   
                        <button type='button' className='btn btn-success btn-sm' onClick={handleUpdateField} > Update</button>:<button type='button' className='btn btn-secondary btn-sm'  onClick={()=>handleEditSubject(st)}> Edit</button>}
                    </td>
                    
                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
        
        <form method='POST' action={ServerUrl+'/printout/exam_schedule.php?token='+userToken} target='_blank'>
        <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}
 


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(AssignTeacher) 