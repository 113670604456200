import React, {useState, useEffect} from 'react'
import {useLocation, useHistory, useParams} from 'react-router'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, ImagesUrl, Token,  config} from '../component/include'
import {backgroungImage} from '../component/images'
import Select from 'react-select'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
const StaffProfile =()=>{
    let history = useHistory();
    let params = useParams()

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [staff, setStaff] = useState([]);
const [position, setPosition]=useState([])
const [department, setDepartment]=useState([])

const [profile, setProfile]=useState({
    staff:[]
});


const allStaff  = useSelector(state => state.rootReducer.staff);

const fetchDepartment =()=>{
    const fd = new FormData();
            fd.append('jwt', Token); 
    let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1'
    axios.post(url, fd, config)
    .then(result =>setDepartment(result.data))
}

const fetchPosition =()=>{
    const fd = new FormData();
            fd.append('jwt', Token); 
    let url = ServerUrl + '/fetchField/tbl_staff_settings/position/ID/1'
    axios.post(url, fd, config)
    .then(result =>setPosition(result.data))
}
const getPosition =(code)=>{
const answer =  position.filter(item=>item.code===code)
const reply = answer.length!==0?answer[0].positionName:''
return reply
}


const getDepartment =(code)=>{
const result = department.filter(item=>item.code===code);
const answer = result.length!==0?result[0].departmentName:''
return  answer
}

const handleSelect = (option, action)=>{
	setProfile({...profile, [action.name]: option});
 }
   
    const handleFetchStaff =(code)=>{
        setNotice({...notice, isLoading: true})
        var sql ="Select *  from tbl_staff where code ='"+code+"' "; 
        const fd = new FormData();
        fd.append('jwt', Token); 
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setStaff(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
    }
    

useEffect(()=>{   
    fetchDepartment();
    fetchPosition();
handleFetchStaff(params.code)
},[]);

        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper"> 

        <PageHeader title="Staff Profile">
        <li className="breadcrumb-item"><a  href="#!"> <i className="feather icon-home"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Profile</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div>



                
                <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Staff</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-12">
    
        <div className="form-group">
        
        <label >Select Staff </label>
        <Select  options={
            allStaff&&allStaff.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                                     })
                                }    
                    getOptionLabel={option =>option.label +' - '+ option.fileNo}    
        onChange={handleSelect}  name="staff" value={profile.staff}  />   
                    </div>
			</section>

									</div>
										
                <div className="row">
                <section className="col-md-6">
<button type="button"  onClick={()=>handleFetchStaff(profile.staff.value)} className="btn btn-danger pull-right "><i className="fa fa-search"></i> Search Record</button>
</section>	                </div>
							</div>
							</div>

							</div>
                </div>



                {/* <!-- Edit student start here  --> */}

            {staff.length!==0?   <div className="row">
                 <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">


    <div className="card z-depth-0">
        
                                    
                                    
    {staff&&staff.map((item, idx)=>
        <div key={idx} className="row  ">
        
        <div className="col-lg-12">
            <div className="cover-profile">
                <div className="profile-bg-img img-fluid" style={{backgroundImage:`url(${backgroungImage})`, display:'block', height:'200px', maxWidth:'100%'}}>
                    <div className="card-block user-info">
                        <div className="col-md-12">
                            <div className="media-left">
                                <a href="#!" className="profile-image">
                <img className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/staff/'+item.gender+'.png'}} src={ImagesUrl+'/staff/'+item.passport} alt="user-img" style={{display:'block', height:'150px',width:'150px'}} /> </a> </div>
                            <div className="media-body row">
                                <div className="col-lg-12">
                                    <div className="user-title">
                                        <h2>{item.staffName}</h2>
                                        <h2 >{getDepartment(item.department)}</h2>
                                    </div></div><div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    

<div className="col-sm-12">
<div className="row ">
<div className="col-sm-1"></div>
    <div className="col-sm-7">
    <br/>
                 
        <ul className="list-unstyled">
            <li> <span className="txt-color-darken"><b>Birth Date</b>:&nbsp;{item.birthDate}</span></li>
            <li>  <span className="txt-color-darken"><b>Date Of Joining</b>:&nbsp;{item.joinDate}</span></li>
            <li><span className="txt-color-darken"><b>Father Name </b>:&nbsp;{item.fatherName}</span></li>
            <li><span className="txt-color-darken"><b>Mother Name </b>:&nbsp;{item.motherName}</span></li>
            <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Position </b>:&nbsp;{getPosition(item.position)}</span> </p> </li>
                    <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Department </b>:&nbsp;{getDepartment(item.department)}</span> </p> </li>
        </ul>
        <br/>                                           
        <p className="font-md" ><i >Present Address</i></p><p> {item.presentAddress}</p>
            
        <p className="font-md" ><i >Permanent Address</i></p><p> {item.permanentAddress}</p>

    <p className="font-md" ><i >Employment Status</i></p><p>{item.employmentStatus==='Working'?<span className="pcoded-badge label label-success">Working</span>: <span className="pcoded-badge label label-danger">{item.employmentStatus}</span>}</p>
            
    </div>
    <div className="col-sm-4">
        <h1><small >Contact Info</small></h1>
    <ul className="list-unstyled">
        <li> <p className="text-muted">
                <i className="fa  fa-mobile"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.mobile}</span>
            </p> </li>
        <li><p className="text-muted">
                <span className="txt-color-darken" style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                    <i className="fa fa-envelope"></i>&nbsp;&nbsp;{item.email}                                                  </span></p>
        </li>
        <li><p className="text-muted"><i className="fa fa-mobile-phone"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.altMobile}</span></p></li>
        <li><p className="text-muted"><i className="fa fa-at"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.email}</span></p></li>
       
        <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Username </b>:-&nbsp;{item.username}</span> </p> </li>
       

       <li>
       <form method='POST' action={ServerUrl+'/printout/staff_profile.php?token='+userToken} target='_blank'>
                <input type='hidden' name='staffCode' value ={item.code} />
                <input type='hidden' name='jwtToken' value ={Token} />
                
        <button type="submit" className="btn btn-inverse  no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>


       </li>
    </ul>
                        </div>
                    </div>
                </div>

            </div>
            )}
            
            
            </div>
            	

    </div> </div>
            </div> </div>:''}
                </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>
</> 
 );
        
}
export default React.memo(StaffProfile) 