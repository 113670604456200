import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import {LoadEffect, TableLoader } from '../component/loader'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import axios from 'axios'
import {Alerts} from '../component/notify'
import {ServerUrl, Token, config} from '../component/include'

 const LoginReport =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const SearchTable =(event)=>{
        event.preventDefault();
        
     }
     const [logList, setLogList] = useState([])
     const handleChange =(event)=>{
        let {name, value} = event.target;
        setSearch({...search, [name] : value });
    }
    const [search, setSearch]= useState({
        startDate:'',
        endDate:'',
    })

    const fetchLog =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
        
		const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("url", "loginreport.json");
        fd.append("actionType", "getData");
		let url = ServerUrl+'/fetch_jsonData'
		axios.post(url, fd, config).then(result=>{  
            setLogList(JSON.parse(result.data).sort(compare))
            setNotice({...notice, 
                isDataFetching: false
            });
		})
		.catch((error)=>{
            Alerts('Error!', 'danger', error.message)
		})
    }

    const Clearlog =()=>{
        setNotice({...notice, 
            isLoading: true
        });
        
		const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("url", "loginreport.json");
        fd.append("actionType", "deleteData");
		let url = ServerUrl+'/fetch_jsonData'
		axios.post(url, fd, config).then(result=>{  
            fetchLog();
            setNotice({...notice, 
                isLoading: false
            });
		})
		.catch((error)=>{
            Alerts('Error!', 'danger', error.message)
		})
    }
    
    const tableInHeader = [  
      {dataField: 'fullname',  text: 'Name',  sort: true, editable: false},
      {dataField: 'dateCreate',  text: 'Date Login',  sort: true, editable: false},
      {dataField: 'browser',  text: 'Browser',  sort: true, editable: false},
      {dataField: 'device',  text: 'Device',  sort: true, editable: false},
      {dataField: 'ip',  text: 'IP',  sort: true, editable: false}
     ];
    

     const compare=(a,b)=>{
        let result = 0;
        if(b.order >a.order){
            result = 1
        }else if (b.order <a.order){
            result = -1
        }
        return result
        }
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='dateTime' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                             <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />

        <div className="dt-buttons btn-group pull-right"> 
            <a href="#!" onClick={()=>Clearlog()} className="btn btn-outline-danger" > <i className="fa fa-trash"></i>Clear Log</a>         
                </div>
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      useEffect(()=>{
        fetchLog();
       },[]);
   
        return (<>
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Login Report"
	   				menu="System"
					   />


{/* The Page Body start here */}
<div className="page-body">
         

         {/* 
<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                            <label className='text-bold pull-right'>Start Date</label>
					</div>
									
                    <div className="col-lg-8 col-md-8 col-sm-8">
							<div className="input-group">
						<input type="date" onChange={handleChange}  name="startDate" value={search.startDate} className='form-control '/>
							
							</div>
							</div>
					</div>
					
                    
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
               

                <div className="form-group row">
					<div className="col-lg-4 col-md-4 col-sm-4">
                    <label className='text-bold pull-right'>End Date</label>
					</div>
									
                    <div className="col-lg-8 col-md-8 col-sm-8">
							<div className="input-group">
						<input type="date" onChange={handleChange}  name="endDate" value={search.endDate} className='form-control '/>
							
							</div>
							</div>
					</div>

                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div> */}


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Log</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12 table-responsive">
          {notice.isDataFetching ?<TableLoader />:
          <TableRecord data={logList}  columns={tableInHeader}  />}  
            </div>
           
    
    </div></div></div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 

 );
        
}

export default React.memo(LoginReport) 