import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, config} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel} from '../component/globalFunction'


import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const GraduateStudent =()=>{
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    
const [promotion, setPromotion] = useState({
    fromClass:[],
    toClass:[]
})
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const [students, setStudents] = useState([]);  
const [isSubmitting, setIsSubmitting] = useState(false);
  
        


    const handleSelectFrom = (option, action)=>{
        handleStudents(option.value);
        setPromotion({...promotion, [action.name]: option});
         
         setErrors({...errors, [action.name]:'', studentList:''})
     }


     

    const  handleStudents =(section)=>{  
        setNotice({...notice,  isLoading: true });
        
       let url = API_URL+'/student/section/'+section;
        axios.get(url,  config).then(result=>{
            if(result.data.data.length!==0){
            var options = result.data.data;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              value.push({code:options[i].code, studentName:options[i].studentName, registerNumber:options[i].registerNumber, admissionNumber:options[i].admissionNumber, status:true});
            }
            setStudents(value)
        } 
        })
        .catch((error)=>{
          //  Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,  isLoading: false });
        })
        
         }


         const handleChange =(index)=>{
            const student =JSON.parse(JSON.stringify(students))   
           const value = !student[index].status 
           student[index].status = value;
           
          setStudents(student); 
                
               }





     const handleSubmit = event =>{
        event.preventDefault();
        setErrors(ValidateInput(promotion));
		setIsSubmitting(true);
	} 

    
const ValidateInput =(values)=>{
	let errors = {};   
		
	
    if(!values.fromClass.value){
        errors.fromClass ='Please select from Class ';
    }

   

    if(students.filter(item=>item.status===true).length===0){
        errors.studentList ='Please tick box of  at least one student to graduate';
    }

     if(students.length===0){
        errors.studentList ='There are no student in the selected class';
    }
    
    
    return errors;
	}
	
	

function submit(){ 
    
      const fd = new FormData(); 
    let graduate = students.filter(list=>list.status===true);
        fd.append('studentList', JSON.stringify(graduate.filter(lst=>lst.code), null, 2));
    setNotice({...notice, isLoading: true}) 
      let url = API_URL+'/student/graduated';
	  axios.post(url, fd, config)
	  .then(response =>{
		if (response.data.status ===200){
			Alerts('Saved!', 'success', response.data.message)
		} else{
			setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	}  
	})
	.catch((error)=>{
		setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
      setIsSubmitting(false);
    setStudents([])
    setPromotion({
        fromClass:[]
    })
    }) 
	 
}

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])



        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Graduate Students">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/students/view_students"> Students</Link></li> 
                    <li className="breadcrumb-item"><a href="#!">Graduation</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">
    

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


<div className="row">
<div className="col-sm-12">

				
	<div className="card z-depth-0">
	
		<div className="card-header">
			<h5>Student Graduation</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
<div className="container">
  

          <div className="row setup-content" id="class">
        <div className="col-md-12">
                <div className="card z-depth-0">
            <div className="card-block">
                <div className="row">


		  <div className="col-md-12">
	
			<div className="form-group">
			  <label className="control-label">Select Current Class</label>
              <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectFrom} className={errors.fromClass ? 'form-control form-control-danger' : ''} name="fromClass" value={promotion.fromClass}  /> <span style={{color:'red'}}>{errors.fromClass && errors.fromClass}</span>

			</div>
			</div>
			
            </div>
		  </div>
		  
		  </div>

		  </div>
		  
          </div>
            
          <div className="row setup-content" id="promotion">
		    
            <div className="col-md-12">
                <div className="card z-depth-0">
            <div className="card-block">
                <div className="row">
                <div className="table-responsive">   
		   <table className="table">
	<thead >
		<tr >
            <th>Register No</th>
		
        <th  >Admission Number</th>
		<th  >Student Name</th>
        <th  >Graduate</th>
	</tr>
	</thead>
	<tbody>
    {students&&students.map((st, idx)=> 
		<tr key={idx} >
			<td>{st.registerNumber}</td>
			
				<td> {st.admissionNumber} </td>
                <td> {st.studentName} </td>
                <td>
                        <div className="checkbox-color checkbox-primary">
                     <input id={`status${st.code}`} name={st.code} value={st.status} type="checkbox" onChange={()=>handleChange(idx)} checked={st.status}  /> 
                     <label htmlFor={`status${st.code}`}></label>
                     </div>
                </td>
		</tr>

    )}
		
</tbody>

</table></div>

		</div>	 

<span style={{color:'red'}}>{errors.studentList && errors.studentList}</span><br/>
        </div>	 </div>	 </div>	 </div>	

            </div>


<button onClick={handleSubmit} className={"btn btn-success pull-right"}  type="button" >Graduate Selected Students</button>


        
	</div>

</div>

</div></div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(GraduateStudent) 