import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, Currency,  config, ImagesUrl,  staffCode, staffName} from '../../component/include'
import { FormatNumber } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
const ClassFees =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [fees, setFees] =  useState([]);
    
   


const handleFetchData =()=>{
	var sql ="Select effectiveMonth, salary  from tbl_salary_structure where staff ='"+staffCode+"'" ;
	const fd = new FormData();
fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
             setFees(result.data[0])
        }
       
    })
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}


const getTotalEarn =(nsalary)=>{
        
    var amount =0
  
    nsalary.map((sl)=>
  sl.salaryHead==='EARNING'?amount += Number(sl.amount):''
  )
  return Number(amount) 
}

const getTotalDeduction=(nsalary)=>{
    var amount =0
    nsalary.map((sl)=>
   sl.salaryHead==='DEDUCTION'?amount += Number(sl.amount):''
   )
   return Number(amount) 
}

useEffect(()=>{
    handleFetchData(); 
},[]);

        return (  <>
   {notice.isLoading ?<LoadEffect className="floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">

        <PageHeader title="Staff salary Structure">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a></li>
      <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
<li className="breadcrumb-item"><a href="#!">Salary Structure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                




<div className="card z-depth-0" >	<div className="card-block">
	
                       <table className='table' >
       <tbody>
                 <tr>
                <td className="text-center" >
                    
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}    />
</td>
            </tr>
            </tbody>
       </table> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Staff Salary Structure</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan='2'>Staff: <b>{staffName}</b></td>
                    <td>Effective Month: <b>{fees.effectiveMonth} </b></td>
                </tr>
            </tbody>
       </table>{fees.length!==0?
                        <table className="table table-bordered invoice-detail-table">
                            <thead>
                                <tr className="thead-default">
                                    <th>Description</th>
                                    <th>Earnings ({Currency})</th>
                                    <th>Deductions ({Currency})</th>
                                </tr>
                            </thead>
                            <tbody>

                             {JSON.parse(fees.salary).map((str, idx)=>

                                <tr key={idx}>
                                    <td>
                                        <h6>{str.title} </h6>
                                    </td>
                                    <td>{str.salaryHead==='EARNING'? FormatNumber(str.amount):'-'}</td>
                                    <td>{str.salaryHead==='DEDUCTION'?FormatNumber(str.amount):'-'}</td>
                                    
                                </tr>
                                )} 
                               
                            </tbody>
                            <tfoot>
                            <tr>
                                <th  className='text-right'>Total Earnings :</th>
                                <td colSpan='2' className='text-left' >{Currency+FormatNumber(getTotalEarn(JSON.parse(fees.salary)))}</td>
                            </tr>
                            <tr>
                                <th className='text-right'>Total Deductions :</th>
                                <td colSpan='2' className='text-right' >{Currency+FormatNumber(getTotalDeduction(JSON.parse(fees.salary)))}</td>
                            </tr>
                            <tr className="text-info">
                                <td>
                                    <h5 className="text-primary">NET Salary :</h5>
                                </td>
                                <td colSpan='2'>
                            <h5 className="text-primary">{ Currency+FormatNumber(Number(getTotalEarn(JSON.parse(fees.salary))) - Number(getTotalDeduction(JSON.parse(fees.salary))))}</h5>
                                </td>
                            </tr>
                            </tfoot> 
                        </table>:''}
                    </div>
 </div> 

 <div className="row">
        <div className="col-md-12">
     {fees.length!==0?   <form method='POST' action={ServerUrl+'/printout/staff_salary_schedule.php?token='+userToken} target='_blank'>
                 <input type='hidden' name='result' value ={JSON.stringify(JSON.parse(fees.salary), null, 2)} />
                <input type='hidden' name='deduction' value ={getTotalDeduction(JSON.parse(fees.salary))} />
                <input type='hidden' name='earning' value ={getTotalEarn(JSON.parse(fees.salary))} />
                <input type='hidden' name='total' value ={Number(getTotalEarn(JSON.parse(fees.salary))) - Number(getTotalDeduction(JSON.parse(fees.salary)))} /> 
                <input type='hidden' name='staffCode' value ={staffCode} />
                <input type='hidden' name='effective' value ={fees.effectiveMonth} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>:''}
        </div></div>

 </div></div>	 



                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ClassFees) 