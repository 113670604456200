import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config,  classID, sectionID} from '../../component/include'
import { getClassName, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'

const FamilyList =()=> {
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [termination, setTermination]=useState([])
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [allResult, setAllResult]=useState([])

  


    const getTermination =(code)=>{

        const ans = termination.filter(item=>item.code===code)
        const final = ans.length!==0?ans[0].terminationName:'Transfered '
      return final
      
      }

      


const fetchResult =()=>{
    if(sectionID !==undefined && sectionID !==''){

    

        setNotice({...notice, isLoading: true}) 
var sql ="SELECT f.familyName, f.username, s.admissionNumber, s.gender, s.registerNumber, s.studentName, s.termination, s.termination_date, s.termination_remark, s.sectionID, s.classID, s.email, s.fatherName, s.father_mobile, s.fatherEmail, s.mother_mobile, s.motherEmail, s.altMobile, s.mobile, s.currentAddress, s.family_name FROM tbl_students s, tbl_family f  where f.code = s.family_name and family_name <> ''  and s.sectionID = '"+sectionID+"' and s.admissionStatus = 'Studying' order by s.studentName ASC"


const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{

    if(response.data.length!==0){
       
setAllResult(response.data)
structure.current.scrollIntoView({ behavior: "smooth" });

    }else{
        setAllResult([])
        Alerts('Info!', 'info', 'There are no student found for the selected family or class')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
}) 
    
}
}






useEffect(()=>{
    fetchResult()
},[]);

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Family Summary">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Students</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Family Summary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

          
	



{allResult.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
       <tr>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt=''  />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>FAMILY SUMMARY </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                    <th>Family Name</th>
                    <th>Current Address</th>
                    <th>Telephone</th>
                    <th>Email</th>
                    <th>Username</th>
                     <th>Student </th>
                     <th>Class</th>
                </tr>
            </thead>
            <tbody>{allResult&&allResult.map((row, idx)=>
            
            <tr key={idx}>
                    <td>{idx+1}</td>
                    <td>{row.familyName}</td>
                    

                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.currentAddress}</td>
                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.father_mobile+' '+row.mother_mobile}</td>
                  
                    <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.fatherEmail+' '+row.motherEmail}</td>
                    <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.username}</td>
                    
                <td style={{maxWidth:'450px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{ row.admissionNumber+ " - "+row.studentName} <br/>
                {row.termination==='Yes'?<span className="pcoded-badge label label-danger"> {getTermination(row.termination_reason)} on {longDate(row.termination_date)} {row.termination_remark}</span>:''}</td>
                
                <td>{getClassName(row.classID, row.sectionID, schoolClass)}</td>
                </tr>)}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/family_summary.php?token='+userToken} target='_blank' className='pull-right' >
        <input type='hidden' name='classID' value ={classID} />
                <input type='hidden' name='sectionID' value ={sectionID} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='allResult' value ={JSON.stringify(allResult, null, 2)} />
               
                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:<div className='label label-success'><h3>There are no family setup for your student yet</h3></div>}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(FamilyList) 