import React, {useState, useEffect,  useRef} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import { config,  API_URL, API_IMAGE} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import TextEditor from '../api/editor'
import Error403 from '../settings/error403'

const Faq =()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
   const [image, setImage] = useState('');

    const [question, setQuestion] = useState({
      question:'',
      answer:'',
     
    })
    const [content, setContent] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [errors, setErrors] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const ValidateInput =()=>{
      let errors = {};   
      if(!question.question){
        errors.question ='Please enter partner name.';
      }
      if(!question.answer){
        errors.answer ='Enter message';
      }

         
      return errors;
      }



    const fetchContent =()=>{

      let url = API_URL+'/faq/display/all';
         axios.get(url, config).then(result=>{  
          if(Array.isArray(result.data.data)){
            setContent(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }


  
  const handleEditorChange = (content, editor) => {
    setQuestion({...question, answer : content });
      setErrors({...errors, answer:''});
    }

    
    const handleChange = event =>{		
      let {name, value} = event.target;	
     
     setQuestion({...question, [name] : value });
      setErrors({...errors, [name]:''});
      }


    function submit(){  
      setNotice({...notice,  isLoading: true})     
         
        const fd = new FormData();

        for(let k in question){
          fd.append(k,question[k]);
      };
      fd.append('display_order',content.length+1);

           let url = API_URL+'/faq/add';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status ===200){
              Alerts('Saved!', 'success', response.data.message);
                  } else{
              Alerts('Error!', 'danger', response.data.message)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, isLoading: false  })

              setQuestion({
                question:'',
                answer:''
              })
              setIsSubmitting(false);
              fetchContent()
          }) 
  }
  

  const handleSubmit = event =>{
    event.preventDefault();         
    setErrors(ValidateInput(content));
        setIsSubmitting(true);
  }
  
useEffect(()=>{
  if(Object.keys(errors).length === 0 && isSubmitting){
  submit()
  }
  },[errors, isSubmitting])

  useEffect(()=>{
   fetchContent()
   },[]);


const DataTable =(props)=>{
  const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } entries</span>
  );

  
  const { SearchBar } = Search;
  const handleUpdate=(column, newValue, code)=>{       
    const fd = new FormData();
    fd.append('newValue', newValue);
    fd.append('column', column.dataField);
    fd.append('code', code);
    let url = API_URL+'/faq/change_field'
    axios.post(url, fd, config)
        //.then(result => console.log(result.data))
        .then()
        .catch((error)=>Alerts('Error!', 'danger', error.message))
        .finally(()=>{
            fetchContent()
        }) 
    } 
    const options = {
        showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, { text: 'All', value: props.data.length }]
       
      };
 

  return <React.Fragment>  
 
          <ToolkitProvider keyField='code' data={ props.data } columns={ props.columns } >                  
                  {
                      props => ( 
                        <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;

                             </div>                            
                        <BootstrapTable
                         { ...props.baseProps }
                                     striped
                                 hover
                                 condensed
                                 noDataIndication={<div className="alert alert-danger">No Record Found</div>}
                             pagination={paginationFactory(options) } 
                             cellEdit={ cellEditFactory({
                              mode: 'dbclick',
                              blurToSave: true,
                              autoSelectText: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      if(oldValue !==newValue){
                      handleUpdate(column, newValue, row.code);                        
                     return props.fetchdata;
                    }                      
                  }
                         }
                         
                         ) }                              
                         />
                          </div>
                      )
                  }
</ToolkitProvider>
</React.Fragment>
}


const handleDelete =(code)=>{
  let close =   document.getElementById('btnWarningDialog-'+code)
  close.click();
 
  setNotice({...notice, 
      isLoading: true}) 

let url = API_URL+'/faq/delete/'+code;
    axios.get(url, config)
    .then(response =>{
     if(response.data.status ===200){
        Alerts('Saved!', 'default', response.data.message)
            } else{
        Alerts('Error!', 'danger', JSON.stringify(response.data))
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        fetchContent()
        setNotice({...notice, 
            isLoading: false
        })
    }) 
}






const tableHeader = [
    

  {dataField: 'question', text: 'Question',  validator: (newValue) => {
    if (!newValue) {
      return {
        valid: false,
        message: 'This field is required'
      };
    }
    return true;
  }, formatter:(cell)=><span style={{display:'block',  width:300, whiteSpace:'normal', wordWrap:'break-word' }} >{cell}</span>, editor: {
    type: Type.TEXTAREA}},
    {dataField: 'answer', text: 'Answer',  validator: (newValue) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'This field is required'
          };
        }
        return true;
      }, formatter:(cell)=><span style={{display:'block',  width:300, whiteSpace:'normal', wordWrap:'break-word' }} dangerouslySetInnerHTML={{__html:cell}} ></span>, editor: {
        type: Type.TEXTAREA}},
        {dataField: 'display_order',  text: 'Order'},
      {dataField: 'status', text: 'Status', validator: (newValue) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'This field is required'
          };
        }
        return true;
      },  formatter: (cell, row)=>row.status==='Active'?<span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>,editor: {
        type: Type.SELECT, options:[
          {value:'Active', label:'Active'}, 
          {value:'Inactive', label:'Inactive'}]
      }},


    {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>  
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} className='btn btn-danger btn-sm'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>
<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

        </div>, editable: false},  
 ];



        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Faq">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">General</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Faq</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

         
                <div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Add Faq</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-12">
   

   <div className="form-group">
<label className='text-bold'>Question</label>

<input type="text" name="question" onChange={handleChange} 
value={question.question} className={errors.question? 'form-control form-control-danger' : 'form-control'} /> 

<span style={{color:'red'}}>{errors.question&& errors.question}</span>
   </div>

</section>

  <section className="col-md-12">
   

   <div className="form-group">
<label className='text-bold'>Answer </label>

<div style={{ width: '100%'}}> 
                
				<TextEditor
					height={200}
					
					value={question.answer}
					onEditorChange={handleEditorChange}        
				  /> 
		  
						</div>

<span style={{color:'red'}}>{errors.answer && errors.answer}</span>
   </div>


</section>

</div>
      

<hr/>

   <footer className="pull-right">
   
   <button type="button" className="btn btn-inverse " ><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
   <button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success "><i className="fa fa-save"></i> Save</button>
            </footer>
 </div> 
			
 </div>
                    </div>
                      </div>
                    </div></div>					
           </div>
                            
       </div>


                <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Faq</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="col-md-12">
        <div className="table-responsive">

        {notice.isDataFetching ? <TableLoader />:
  <DataTable data={content} columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Faq) 