import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config, classID, sectionID} from '../../component/include'
import Select from 'react-select'
import { getAge, getResultHead, getPosition, getRank, sort, getCAPoint, getBritishDate, getClassField, getClassName, longDate, getNumWorkDays } from '../component/globalFunction'
import { userToken } from '../component/authentication'
import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'

const Transcript =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [remark, setRemark]=useState([])
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo[0]);




let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [assesmentGrade, setAssesmentGrade]=useState([])
const staff  = useSelector(state => state.rootReducer.staff);
 const [studentList, setStudentList] = useState([]);

const [student, setStudent]=useState([])
const [disabledResult, setDisabledResult]=useState([])
const [studentAssessment, setStudentAssessment]=useState([])
const [assessment, setAssessment]=useState([])
const [house, setHouse]=useState([])
const [grade, setGrade] = useState([])
const [grademark, setGrademark]=useState([])
    const [scores, setScores]=useState([])
    const [onlyCategory, setOnlyCategory]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [remarkResults, setRemarkResults] = useState([])


    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        resultHead:'Secondary',
        orderBy:'',
        session:[]
    })

    const [resultSession, setResultSession]=useState([])
    const [category, setCategory]=useState([])
    const [attendance, setAttendance]=useState([])
    const [atResult, setAtResult]=useState({
        attendanceStart:'',
        attendanceEnd:'',
        nextTermBegin:'',
        nextTermBeginDay:''
    })


    var classType = String(getClassField(classID, schoolClass, 'arms'))

    const [firstTerm, setFirstTerm]=useState([])
    const [secondTerm, setSecondTerm]=useState([])
    const [thirdTerm, setThirdTerm]=useState([])






    const getScores =(code)=>{
        let scr =  [...scores];
        const rs = scr.filter(item=>item.studentCode===code)

//filter subject here
const newSubject=	rs.map(e=>e['subjectCode'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>rs[e])
                        .map(e=>rs[e])


        return newSubject.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1);
    
    }



    
const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)

const getGrade =(score, field)=>{
const ans = grademark.filter(itm=>itm.classtype===result.studentClass.classtype&& roundNum(score) >= roundNum(itm.minimum) && roundNum(score) <= roundNum(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   
return field ==='grade'?String(grade):String(remark)
}




   const getResultScore =(studentCode, examCode,  subjectCode, field, dataList)=>{
    const ans = dataList.filter(item=>item.studentCode===studentCode && item.examCode===examCode && item.subjectCode===subjectCode)
return ans.length!==0?ans[0][field]:'0'
    }


const mergeAllResult =()=>{
    const answer = [...firstTerm, ...secondTerm, ...thirdTerm]
    //const answer = allRes.filter(item=>item.studentCode==='620610393c6db');

    var list = [];
    for (var i = 0; i< answer.length;  i++) {

        var average = ((Number(getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'totalAverage', answer))+Number(getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'totalAverage', answer))+Number(getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'totalAverage', answer)))/3).toFixed(2)
        
        var grade =getGrade(average, 'grade')
        var remark = getGrade(average, 'remark')

        var overallTotal = Number(getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'total', answer))+Number(getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'total', answer))+Number(getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'total', answer))



        list.push({
            subjectName:answer[i].subjectName,
            subjectCode:answer[i].subjectCode,
            studentCode:answer[i].studentCode,
            studentName:answer[i].studentName,

            firstTermExamCode:result.session.firstTerm,
            firstTerm1stCA:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'firstCA', answer),
            firstTerm2ndCA:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'secondCA', answer),
            firstTermExam:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'exam', answer),
            firstTermTotal:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'total', answer),
            firstTermComment:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'comment', answer),
            firstTermGrade:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'grade', answer),
            firstTermAbsent:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'absent', answer),
            firstTermRemark:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'remark', answer),
            firstTermPosition:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'position', answer),
            firstTermAverage:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'totalAverage', answer),


            secondTermExamCode:result.session.secondTerm,
            secondTerm1stCA:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'firstCA', answer),
            secondTerm2ndCA:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'secondCA', answer),
            secondTermExam:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'exam', answer),
            secondTermTotal:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'total', answer),
            secondTermComment:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'comment', answer),
            secondTermGrade:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'grade', answer),
            secondTermAbsent:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'absent', answer),
            secondTermRemark:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'remark', answer),
            secondTermPosition:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'position', answer),
            secondTermAverage:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'totalAverage', answer),



           thridTermExamCode:result.session.thirdTerm,
           thridTerm1stCA:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'firstCA', answer),
           thridTerm2ndCA:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'secondCA', answer),
           thridTermExam:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'exam', answer),
           thridTermTotal:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'total', answer),
           thridTermComment:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'comment', answer),
           thridTermGrade:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'grade', answer),
           thridTermAbsent:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'absent', answer),
           thridTermRemark:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'remark', answer),
           thridTermPosition:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'position', answer),
           thridTermAverage:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'totalAverage', answer),
           average:average,
           grade:grade,
           remark:remark,
           overallTotal:overallTotal 


        })

    }
   setScores(list)

}

    const fetchAnalysisA =(exam)=>{
        fetchAnalysisB(exam)
        setNotice({...notice, isLoading: true}) 
        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+exam.firstTerm+'" and e.sectionID ="'+sectionID+'" '
        
        const fd = new FormData();
        fd.append("sql", final);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
            
             if(response.data.length!==0){
        setFirstTerm(response.data)
            }else{
                setFirstTerm([]) 
            }  
        })
        }

    const fetchAnalysisB =(exam)=>{
       
      fetchAnalysisC(exam)
        
       setNotice({...notice, isLoading: true}) 
        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+exam.secondTerm+'" and e.sectionID ="'+sectionID+'" '
       
       const fd = new FormData();
       fd.append("sql", final);
       fd.append('jwt', Token)
       let url = ServerUrl+'/fetchBySql_controller';
      axios.post(url, fd, config).then(response=>{
            if(response.data.length!==0){
                setSecondTerm(response.data)
           }else{
               setSecondTerm([]) 
           }  
       })

    }



    const fetchAnalysisC =(exam)=>{

        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+exam.thirdTerm+'" and e.sectionID ="'+sectionID+'" '

        
        const fd = new FormData();
        fd.append("sql", final);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
       axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){
                setThirdTerm(response.data)
            }else{
                setThirdTerm([]) 
            }  
        }).finally(()=>{
            setNotice({...notice, isLoading: false}) 
        })
    }





    const fetchAllResultSession =()=>{

        var sql ="SELECT r.code, r.firstTerm, r.secondTerm, r.thirdTerm, r.sessionCode, s.startYear from tbl_result_session r, tbl_school_session s where r.sessionCode =s.ID " ;

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            setResultSession(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }


        
        const fetchSettings =()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
            axios.post(url, fd, config).then(result=>{
                setSettings(result.data[0])
              
            })
        }



   
    
    const fetchRemarks =()=>{
        var sql ="SELECT code, teacherRemark, headofSchool, teacherName, headRemark, teacherName, headName, resultHead, studentCode from tbl_result_remarks where  examType = '"+result.session.thirdTerm+"' and sectionID = '"+sectionID+"'" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0){
        setRemarkResults(response.data)
    }else{
        setRemarkResults('')
    }
    
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}



const handleSelectSession =(option, action)=>{     
    setResult({...result, [action.name]:option, studentClass:[], orderBy:[]});
    fetchExamAttendance(option.thirdTerm)
    fetchStudentList(option.thirdTerm)
    fetchAnalysisA(option)
    fetchClassTeaher()
        fetchGradeMark()
    setScores([])
    setStudent([])
    setErrors({...errors, [action.name]:''})

}

const handleSelectSorting =(option, action)=>{     
    setResult({...result, [action.name]:option});
    setErrors({...errors, [action.name]:''})
    
    fetchStudentAssessment() 
    mergeAllResult()
    getAttendance()
    fetchRemarks()
}


    const fetchStatistics =()=>{
        setNotice({...notice, isLoading: true}) 
        let final =   'SELECT  rank() over (order by sum(e.total) desc) position, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, e.subjectCode, e.classID, e.sectionID, e.maxMark, e.absent from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+result.session.thirdTerm+'" and e.sectionID ="'+sectionID+'" group by e.studentCode '


     const fd = new FormData();
    fd.append("sql", final);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
         if(result.data.length!==0){
            
    setStudent(result.data)
        }else{
            setStudent([]) 
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }

    

    const fetchDisbaledStudent =(exam)=>{

        var sql ="SELECT studentList from  tbl_disable_result where  examType ='"+exam+"' and sectionID ='"+sectionID+"' Limit 1";
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
    
    if(result.data.length!==0 && Array.isArray(result.data)){
        const options = JSON.parse(result.data[0].studentList)
    
        if(options.length!==0){
            const rs = options.map((item)=>item.studentCode)
    setDisabledResult(rs)
        }else{
            
    setDisabledResult([])
        }
    
    }
    
    })
    
    }

    
    
        const fetchStudentList =(exam)=>{
            fetchDisbaledStudent(exam)
            setNotice({...notice, isLoading: true}) 
           var sql ="SELECT s.passport, s.code, s.gender, s.admissionNumber, s.house, s.dateOfBirth, s.registerNumber, s.studentName, e.examCode from tbl_students s, tbl_exam_result e where s.code = e.studentCode and e.examCode ='"+exam+"' and s.termination <> 'Yes' and  e.sectionID ='"+sectionID+"' group by s.code"

        
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            if(result.data.length!==0){
           setStudentList(result.data)

    }else{
        setStudentList([]) 
    } 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
           
        })
        }

      
    
        const fetchAssessment =()=>{
            var sql ="SELECT category, assessment, assesmentGrade from tbl_standards_setting where ID="+1;
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
        var categor = result.data[0].category
        var assessmen = result.data[0].assessment
    
       setCategory(JSON.parse(categor))
       setAssessment(JSON.parse(assessmen).sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))
      
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

       
        const getAssessment = (code, field) =>{        
       
            const reply = assessment&&assessment.filter(list=>list.code===code);
            const answer = reply.length!==0?reply[0][field]:'';
            return  String(answer)
            }

          

            const getCategory=(code)=>{
                const result = category && category.filter(list=>list.code===code);
              const answer = result.map((c)=>c.categoryName);
              return  String(answer)
            }

        const fetchStudentAssessment =()=>{
           setNotice({...notice, isLoading: true}) 
            var sql ="SELECT  assessmentCode, grade, studentCode from tbl_assessment_result where examCode ='"+result.session.thirdTerm+"' and sectionID ='"+sectionID+"'" ;


         const fd = new FormData();
        fd.append("sql", sql);
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){

                var assess = response.data;
    var list = [];
    for (var i = 0; i< assess.length;  i++) {
        list.push(
            {  code:assess[i].assessmentCode,
                assessmentName:getAssessment(assess[i].assessmentCode, 'assesmentName'),
                type:getCategory(getAssessment(assess[i].assessmentCode, 'categoryCode')),
                grade:assess[i].grade,
                studentCode:assess[i].studentCode
            });
            }

            const onlyCategory =	list.map(e=>e['type'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>list[e])
                        .map(e=>list[e])

            setOnlyCategory(onlyCategory)

        setStudentAssessment(list)
        
            }else{
                setOnlyCategory([])
        setStudentAssessment([])
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false}) 
        })
        }

       
        
        const fetchGradeMark =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_exam_settings/gradeMark/ID/1';
            axios.post(url, fd, config)
            .then(result =>{

                if(result.data.length!==0){
                    var grade = result.data.filter(item=>item.classtype===classType)
                    setGrademark(grade.sort((a, b) =>(Number(b.maximum) > Number(a.maximum)) ? 1 : -1))
                }else{
                    setGrademark([])
                }
            })             
        }


        const fetchReMark =()=>{
            var sql ="SELECT grade, gradeRemark, gradeMark from tbl_exam_settings where ID="+1;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
            
var grad = result.data[0].grade
        var gradeRemar = result.data[0].gradeRemark
        setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
        setGrade(JSON.parse(grad).sort(sort))
        }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        }


        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
        


            const fetchExamAttendance =(value)=>{
                setNotice({...notice, isLoading: true})
                let sql ='select startDate, endDate, nextTermBegin, nextTermBeginDay from tbl_result_attendance where exam = "'+value+'"'
        
                const fd = new FormData();
                fd.append('jwt', Token); 
                fd.append("sql", sql);
                let url = ServerUrl+'/fetchBySql_controller'
                axios.post(url, fd, config).then(response=>{
                    if(Array.isArray(response.data)&& response.data.length!==0){
                        setAtResult({...atResult, attendanceStart:response.data[0].startDate, 
                            attendanceEnd:response.data[0].endDate,
                        nextTermBegin:response.data[0].nextTermBegin,
                        nextTermBeginDay: response.data[0].nextTermBeginDay })
                       
                    }else{
                        setAtResult({...atResult, attendanceStart:'', attendanceEnd:'', nextTermBegin:'',  nextTermBeginDay:''  })
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
            })
        }

      
        const fetchAllHolidays =()=>{
            var total = Number(getNumWorkDays(atResult.attendanceStart, atResult.attendanceEnd));

            const fd = new FormData();
            fd.append('jwt', Token);
            let sql = 'Select count(ID) as total from tbl_events_holidays where eventType= "Holidays" and startDate >= "'+atResult.attendanceStart+'" and startDate <= "'+atResult.attendanceEnd+'"'

                let url = ServerUrl+'/fetchBySql_controller'
                fd.append("sql", sql)
                axios.post(url, fd, config).then(response =>{

                    if(Array.isArray(response.data)&& response.data.length!==0){
                    setAtResult({...atResult, totalOpen:(total - Number(response.data[0].total))*2 })
                    }else{

                        setAtResult({...atResult, totalOpen:total*2 })
                    }
                }) 
        }



               


            const getAttendance =()=>{
                fetchAllHolidays()


                let final =   'SELECT s.code, s.admissionNumber, s.studentName, a.studentCode, sum(case when a.indicator  = "P" then 2 else 0 end) as P, sum(case when a.indicator  = "A" then 2 else 0 end) as A, sum(case when a.indicator  = "H" then 1 else 0 end) as H  from tbl_students s, tbl_student_attendance a where   s.code = a.studentCode and a.attendanceDate >= "'+atResult.attendanceStart+'" and a.attendanceDate <= "'+atResult.attendanceEnd+'" and a.attendanceType ="fullday" group by a.studentCode '

        
                const fd = new FormData();
                fd.append('jwt', Token); 
                fd.append("sql", final);
                let url = ServerUrl+'/fetchBySql_controller'
                axios.post(url, fd, config).then(response=>{
                    if(Array.isArray(response.data)&& response.data.length!==0){

                        setAttendance(response.data)
                    }else{
                        setAttendance([])
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }


        const fetchHouse =()=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl + '/fetchField/tbl_student_master/house/ID/1';
            axios.post(url, fd, config)
            .then(result =>setHouse(result.data))
        }

        const fetchAssementGrade =()=>{
            var sql ="SELECT category, assessment, assesmentGrade from tbl_standards_setting where ID="+1;
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
        var assesmentGrad = result.data[0].assesmentGrade
        setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))
    
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }


        const getHouseName = (code) =>{      
            
            
            const response = house&&house.filter(list=>list.code === code);
            return  response.length? String(response[0].houseName):'';
            }



    const getRemark =(code)=>{
        const ans = remark&&remark.filter(item=>item.code===code)
        return ans[0].remarkName
        }


            const fetchClassTeaher =()=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }


        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
               
        fetchStatistics()
            }
            },[errors])


            
    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   



        if(values.session.length===0){
            errors.session ='Please select session';
        }   
     
        if(values.orderBy.length===0){
            errors.orderBy ='Please select order ';
        }

        return errors;
        }




     const handleStaffName=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
        } );
    }else{
        return ''
    } 
    }

    const handleStaffSignature=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <img id="viewPassport2" key={idx} className="img-100" style={{ maxHeight:'50px' }}  src={`${ImagesUrl}/staff/signature/${getStaffField(itm, 'signature')}`}   alt='' />
        } );
    }else{
        return ''
    } 
    }


       const getStaffField = (code, field) =>{   
    
        const res = staff&&staff.filter(list=>list.code===code);
        const answer = res.map((c)=>c[field] ); 
        return  String(answer);
       }


    



const getStudent =(code)=>{
    let scr =  [...student];
    const rs = scr.filter(item=>item.code===code)
    return rs[0];

}


const getAt =(code)=>{
    let scr =  [...attendance];
   const rs = scr.filter(item=>item.code===code)

   if(rs.length!==0){
       return rs[0]
   }else{
    return {A:0, P:0}
   }
   

}


const getData =(code)=>{
     let scr =  [...remarkResults];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs[0];

} 



useEffect(()=>{
    fetchHouse()
    fetchSettings()
    fetchReMark()
    fetchAssessment()
    fetchAssementGrade()
    fetchAllResultSession() 
},[]);



        return (
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Transcript">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Class Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">
       
        <section className="col-md-6">
                <div className="form-group">
         <label> Session<span style={{color:'red'}}>*</span></label>
         <Select  options={resultSession&&resultSession.map((list, id)=>{
             return {key:id, value:list.code, label:list.startYear, firstTerm:list.firstTerm, secondTerm:list.secondTerm, thirdTerm:list.thirdTerm}
         })}    

 onChange={handleSelectSession} className={errors.session ? 'form-control form-control-danger' : ''} name="session" value={result.session} id='session'  />

 <span style={{color:'red'}}>{errors.session && errors.session}</span>
            </div>
            </section>


                   
     <section className="col-md-6">
           <div className="form-group">
         <label> Order By</label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelectSorting} className={errors.orderBy ? 'form-control form-control-danger' : ''} name="orderBy" value={result.orderBy}  /> 
<span style={{color:'red'}}>{errors.orderBy && errors.orderBy}</span>

            </div>
     
     </section>


				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

{student.length!==0?studentList.map((std, index)=>

getResultHead(classType)!=='Pre-Nursery'?<div key={index}>
<div className="card z-depth-0">
		
        
    <div>    <div className="card-block">
	
    <div className="row">

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolInfo.schoolName}  />
     </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div>
                

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> STUDENT ACADEMIC TRANSCRIPT</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>

       <tr>
                    <td colSpan={2}>NAME OF STUDENT: <b>{std.studentName}</b></td>
                    
                    
                 <td >ADMISSION NO: <b> {std.admissionNumber} </b></td>
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                   
                </tr>
               
                <tr>
                    
                    <td colSpan={2} >CLASS: <b> {getClassName(classID,sectionID,schoolClass)} </b></td>
                    <td >GENDER: <b>{std.gender}</b></td> 
                <td >NUMBER IN CLASS: <b>{studentList.length}</b></td>
                   </tr>
               
                
                   <tr>
                    
                   <td>TIME SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
                    <td>TIMES PRESENT: <b>{(Number(getAt(std.code).P)+Number(getAt(std.code).H))} </b></td>
                    <td colSpan={2}>NEXT TERM BEGIN: <b>{longDate(atResult.nextTermBegin)} </b></td>
                </tr>
                
                   
                <tr>
                    
                    <td colSpan={2}>TOTAL MARK OBTAINABLE: <b>{getStudent(std.code).obtainable}</b></td>
                    <td >TOTAL MARK OBTAINED: <b>{getStudent(std.code).obtained} </b></td>
                    <td >AVERAGE: <b> {getStudent(std.code).average}  </b></td>
                </tr>


              
                   
                

            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th >SUBJECTS</th>
                   
                
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }} >THIRD TERM CAT ({getCAPoint(classType).ca})</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>THIRD TERM EXAM ({getCAPoint(classType).exam})</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>THIRD TERM TOTAL (100%)</th>
                    
                    {getPosition(classType)==='Position'? <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SUBJ POSITION</th>:[]}
                    
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SECOND TERM (100)</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>FIRST TERM (100)</th>
                    
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>CUMM. AVG</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>GRADE</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>REMARK</th>
                </tr>
            </thead>
            <tbody>
                
            {getScores(std.code).map((st, idx)=>st.overallTotal>0?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                   
                    
                    <td>{st.thirdTerm1stCA}</td>
                    <td>{st.thirdTerm1stExam}</td>
                    <td>{st.thridTermTotal}</td>
                    
                    {getPosition(classType)==='Position'?
                    <td>{st.thridTermPosition}<sup>{getRank(st.thridTermPosition)}</sup></td>:[]}


                    <td>{st.secondTermTotal}</td>
                    <td>{st.firstTermTotal}</td>
                    
                    <td>{st.average}</td>
                     <td>{st.grade}</td>
                     <td>{st.remark}</td>
 
                
            </tr>:[])} 

            </tbody> 
        </table>
 </div>	 
  
 <div className="col-md-12">
     <h6>*CAT Continuous Assessment Test</h6>
     <table className='table table-bordered table-responsive'>
         <tbody>
             <tr>
             <td  style={{ align:'center', valign:'center' }}  colSpan={2} rowSpan={2}><b >KEY TO GRADES </b></td>
                 <td><b>Score Range </b></td>
                 {grademark.map((data, index)=><td key={index}>{data.minimum + ' - ' + data.maximum}</td>)}

             </tr>
             <tr>
                 <td><b>Grade </b></td>
                 {grademark.map((data, index)=><td key={index}>{getGradeScore(data.gradeCode)}</td>)}
                 
             </tr>
         </tbody>
     </table>

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>

     {getData(std.code).resultHead==='Nursery' ||getData(std.code).resultHead==='Pre-Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                    
                {getData(std.code).headRemark}

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: getData(std.code).resultHead==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headofSchool, 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headofSchool, 'signature')}`}   alt={getStaffField(getData(std.code).headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>
 </div>



 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/view_transcript.php?token='+userToken} target='_blank'>

        <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
                <input type='hidden' name='examPoint' value ={getCAPoint(classType).exam} />
                <input type='hidden' name='caPoint' value ={getCAPoint(classType).ca} />
                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={getResultHead(classType)} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={classID} />
                <input type='hidden' name='sectionID' value ={sectionID} />
                <input type='hidden' name='finalGrade' value ={classType} />
                <input type='hidden' name='resultHead' value ={getPosition(classType)} />
                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div></div>
 

 
 {disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'black',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'white',  }}> Result not available at the momemt, please contact the Account's Department</h3>

</div>
:''} 

        </div>

        </div>:
 <div className="card z-depth-0" key={index}>
 <div className="card-block">
	
 <div className="row">

<table className="table table-responsive table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolInfo.schoolName}  />
     </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div>



<div className="row">
 <div className="col-md-12">
 <h5 style={{textAlign:'center'}}><u><b>  STUDENT ACADEMIC TRANSCRIPT</b></u></h5>
 </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
<tbody>
         <tr>
             <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
          <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
          <td >CLASS: <b> {getClassName(classID,sectionID,schoolClass)} </b></td>
         </tr>

         <tr>
             
         <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
         <td>TIME SCHOOL OPENED: <b> {Number(getAt(std.code).P)+Number(getAt(std.code).A)+Number(getAt(std.code).H)} </b></td>
                    <td>TIMES PRESENT: <b>{(Number(getAt(std.code).P)+Number(getAt(std.code).H))} </b></td>
          
         <td >NO. IN CLASS: <b>{studentList.length}</b></td>
        
         </tr>
         

     </tbody>
</table></div>
<div className="col-md-12 table-responsive">
 <table className="table table-striped table-bordered">
     <thead>
 <tr>
                    <th >SUBJECTS</th>
                   
                
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }} >THIRD TERM </th>
                    
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SECOND TERM </th>
                    
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>FIRST TERM</th>
                </tr>
            </thead>
 {getScores(std.code).map((st, idx)=> st.absent!=='Yes'?
          <tbody key={idx}>
         <tr >
         
             <td > <b>{st.subjectName.toUpperCase()}</b></td>
             <td >{st.summerTermComment}</td>
             <td style={{ overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.secondTermComment}</td>
             <td style={{  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.firstTermComment}</td>
         
     </tr></tbody>
         :[])} 
 </table>
</div>	 
<div className="col-md-12"> <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>

     {getData(std.code).resultHead==='Nursery' ||getData(std.code).resultHead==='Pre-Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                    
                {getData(std.code).headRemark}

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: getData(std.code).resultHead==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headofSchool, 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headofSchool, 'signature')}`}   alt={getStaffField(getData(std.code).headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>

</div>
</div> 
<div className="row no-print">
 <div className="col-md-12 pull-right">
 <form method='POST' action={ServerUrl+'/printout/result_pre_transcript.php?token='+userToken} target='_blank'>

                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
                <input type='hidden' name='examPoint' value ={getCAPoint(classType).exam} />
                <input type='hidden' name='caPoint' value ={getCAPoint(classType).ca} />
                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={getResultHead(classType)} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={classID} />
                <input type='hidden' name='sectionID' value ={sectionID} />
                <input type='hidden' name='finalGrade' value ={classType} />
                <input type='hidden' name='resultHead' value ={getPosition(classType)} />
                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
 <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
 
 </form>
 </div></div>
 
 </div>

 
 
{disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'#E1E1E1',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'blue',  }}> Result not available at the momemt, please contact your child's class teacher</h3>

</div>
:''} 


 </div>):''}


        
   


   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Transcript) 