import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {Currency, FormatNumber, ImagesUrl, ServerUrl, API_URL,  Token, config} from '../component/include'

import { useSelector } from 'react-redux'
import Select from 'react-select'
import {  longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

const DailyRegister =()=> {
    const [errors, setErrors] = useState({});
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [account, setAccount]=useState([])

    const [result, setResult] = useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
    const [statement, setStatement] = useState({
       account:[],
       fromDate:'',
        toDate:'',
        income:'true',
        expenses:'false',
        credit:0,
        debit:0
    })

   
  
    const handleCheckbox =(event)=>{
        let {name, value} = event.target
        value = value ==='true'? 'false':'true';
        setStatement({...statement, [name]:value})
        setErrors({...errors, [name]:''})
        }

        const fetchAccount=()=>{
      
            setNotice({...notice, isLoading: true}) 
            let url = API_URL+'/finance/account/balance'
            axios.get(url,  config).then(result=> setAccount(result.data.data))
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
            setNotice({...notice, isDataFetching: false})
    
            })  
        }

        
        const handleSelect= (option, action)=>{
            setStatement({...statement, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            setResult('')
        }

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setStatement({...statement, [name]:value})
            setErrors({...errors, [name]:''})
            setResult('')
            
        }
    
            const fetchResult =()=>{
                 if(statement.account.length===0){
                    setErrors({...errors, account:'Please select account'})
                }else if(!statement.fromDate){
                    setErrors({...errors, fromDate:'Please select from date'})
                }else if(!statement.toDate){
                    setErrors({...errors, toDate:'Please select to date'})
                } else{ 
                
                
  setNotice({...notice, isLoading: true}) 
  const fd = new FormData();

  fd.append("account", statement.account.value); 
  fd.append("fromDate", statement.fromDate); 
  fd.append("toDate", statement.toDate); 

            fd.append("type", statement.expenses);
            let url = API_URL+'/finance/account/daily'
            axios.post(url, fd, config).then(result=>{
                if(result.data.data.length!==0){
                    var credit = 0, debit = 0;
                    result.data.data.map(data=>data.type==='debit'?debit+=Number(data.amount):credit+=Number(data.amount))
                    setResult(result.data.data)
                    setStatement({...statement, debit:debit, credit:credit})
                }else{
                    Alerts('Info!', 'default', 'No record found for the selected criterials')
                }
                
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })   
        } 
            }
    
     
     useEffect(()=>{
        fetchAccount()
    },[]);


        return (  
   < >{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Daily Register">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Finance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Daily Register</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Daily Register</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
        
        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-6">
            <div className="form-group">
						<label >Account <span style={{color:'red'}}>*</span> </label>
						<Select options={
                             account&&account.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.accountName, balance:list.openingBalance}
                             })} 
                             getOptionLabel={option =>` ${option.label} Balance: ${Currency+FormatNumber(option.balance)}`}
onChange={handleSelect} className={errors.account? 'form-control form-control-danger' : ''} name="account" value={statement.account}  /> <span style={{color:'red'}}>{errors.account && errors.account}</span>
										  </div>

      </section>
      
      <section className="col-md-6">
      <div className="form-group">
   <label >From Date</label>
   <input type="date" className={errors.fromDate ? 'form-control form-control-danger' : 'form-control'} name="fromDate"   onChange={handleChange} value={statement.fromDate} />

   <span style={{color:'red'}}>{errors.fromDate && errors.fromDate}</span>
               </div>

   

</section>
      	
      
      <section className="col-md-6">
      <div className="form-group"><br/>
            <div className="checkbox-color checkbox-primary">
        <input id="income"  name="income" type="checkbox" onChange={handleCheckbox} value={statement.income} checked={statement.income==='true'? true:false} />
                <label htmlFor="income">Income</label>
            </div>	

            <div className="checkbox-color checkbox-primary">
        <input id="expenses"  name="expenses" type="checkbox" onChange={handleCheckbox} value={statement.expenses} checked={statement.expenses==='true'? true:false} />
                <label htmlFor="expenses">Expenses</label>
            </div> </div>
            </section>

            <section className="col-md-6">
      <div className="form-group">
   <label >To Date</label>
   <input type="date" className={errors.toDate ? 'form-control form-control-danger' : 'form-control'} name="toDate"   onChange={handleChange} value={statement.toDate} />

   <span style={{color:'red'}}>{errors.toDate && errors.toDate}</span>
               </div>

</section>
				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={()=>fetchResult()} className="btn btn-primary "><i className="fa fa-search"></i> Search</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	

    
       	
{schoolInfo&&schoolInfo.map((st, id)=>
               <div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
</td>
        </tr>
    </tbody>
</table>

</div> 
               )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Daily Register </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Account: <b>{statement.account.value+' - ' +statement.account.label}</b></td>
                    <td >Date Range: From <b>{longDate(statement.fromDate)+ ' to '+ longDate(statement.toDate)}</b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>S/N</th>
                <th>Record Date</th>
                <th>Txn Date</th>
                    <th>Head Name</th>
                    <th>Account</th>
                    <th>Amount</th>
                    <th>Remark</th>
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                <td>{longDate(st.created_at)}</td>
                <td>{longDate(st.incomeDate)}</td>
                <td>{st.headName}</td>
                <td>{st.accountName}</td>
                <td>{FormatNumber(st.amount)}</td>
                <td>{st.remarks}</td>
                </tr>):[]}
              </tbody>
           
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/daily_register.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='account' value ={statement.account.value+' - ' +statement.account.label} />
                <input type='hidden' name='dateRange' value ={longDate(statement.fromDate)+ ' to '+ longDate(statement.toDate)} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(DailyRegister) 